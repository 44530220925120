import { inject, Injectable } from '@angular/core';
import { hexToRgb, isColorDark } from '../utils/colors';
import { makeHttps } from '../utils/make-https';
import { WINDOW } from './tokens';

@Injectable({ providedIn: 'root' })
export class NativeBridgeService {
  // private browserService = inject(BrowserService);
  private window = inject(WINDOW);

  public downloadFile(url: string, allowInsecure = false): boolean {
    // Try sending a message to iOS WebView.
    if (this.window['webkit']?.messageHandlers?.downloadFile?.postMessage) {
      this.window['webkit']?.messageHandlers?.downloadFile?.postMessage({
        url: makeHttps(url, allowInsecure),
      });
      return true;
    }
    // Try sending a message to Android WebView.
    else if (this.window['AndroidBridge']?.downloadFile) {
      this.window['AndroidBridge']?.downloadFile(JSON.stringify({ url: makeHttps(url, allowInsecure) }));
      return true;
    }
    return false;
  }

  public setNativeUnreadNotificationCount(unreadCount: number): boolean {
    // Try sending a message to iOS WebView.
    if (this.window['webkit']?.messageHandlers?.notificationNumber) {
      this.window['webkit'].messageHandlers.notificationNumber.postMessage(unreadCount);
      return true;
    }
    // Try sending a message to Android WebView.
    else if (this.window['AndroidBridge']?.notificationNumber) {
      this.window['AndroidBridge'].notificationNumber(JSON.stringify({ notificationNumber: unreadCount }));
      return true;
    }
    return false;
  }
  public hideLoadingView(): boolean {
    // Try sending a message to iOS WebView.
    if (this.window['webkit']?.messageHandlers?.hideLoadingView) {
      this.window['webkit'].messageHandlers.hideLoadingView.postMessage({});
      return true;
    }
    // Try sending a message to Android WebView.
    else if (this.window['AndroidBridge']?.hideLoadingView) {
      this.window['AndroidBridge'].hideLoadingView();
      return true;
    }
    return false;
  }
  public signOut(source: string, error?: unknown): boolean {
    // Try sending a message to iOS WebView.
    if (this.window['webkit']?.messageHandlers?.signOut?.postMessage) {
      this.window['webkit'].messageHandlers.signOut.postMessage({ source, error });
      return true;
    }
    // Try sending a message to Android WebView.
    if (this.window['AndroidBridge']?.signOut) {
      this.window['AndroidBridge'].signOut(JSON.stringify({ source, error }));
      return true;
    }
    return false;
  }

  // #region Android specific
  /**
   * Check if the native bridge is available to show acknowledgments on Android
   */
  get checkAndroidShowAcknowledgmentsAvailable() {
    return this.window['AndroidBridge']?.showAcknowledgments;
  }

  /**
   * show acknowledgments on Android if the native bridge is available
   */
  showAcknowledgments() {
    this.window['AndroidBridge']?.showAcknowledgments();
  }
  // #endregion

  // #region Device Status Bar
  private isIosStatusbar() {
    return (
      this.window['webkit']?.messageHandlers?.setStatusBarLightContent &&
      this.window['webkit']?.messageHandlers?.setStatusBarDarkContent.postMessage
    );
  }
  private isAndroidStatusBar() {
    return (
      this.window['AndroidBridge']?.setStatusBarLightContent && this.window['AndroidBridge']?.setStatusBarDarkContent
    );
  }

  public checkNativeMessageHandlerAvailable() {
    return this.isIosStatusbar() || this.isAndroidStatusBar();
  }

  /**
   *
   * @param statusBarTheme 'light' or 'dark' will call the appropriate
   * native function to set the status bar theme setStatusBar[Light|Dark]Content
   * @returns void
   */
  public setDeviceStatusBarTheme(statusBarTheme: 'light' | 'dark') {
    if (this.isIosStatusbar()) {
      if (statusBarTheme === 'light') {
        this.window['webkit'].messageHandlers.setStatusBarLightContent.postMessage({});
      } else {
        this.window['webkit'].messageHandlers.setStatusBarDarkContent.postMessage({});
      }
    } else if (this.isAndroidStatusBar()) {
      if (statusBarTheme === 'light') {
        this.window['AndroidBridge'].setStatusBarLightContent();
      } else {
        this.window['AndroidBridge'].setStatusBarDarkContent();
      }
    }
  }

  /**
   *
   * @param color as HEX value. will be converted to RGB and tested for brightness
   * @param invertResult boolean to invert the result of the brightness test
   * @returns 'light' or 'dark' based on the brightness of the color
   */
  public setDeviceStatusBarThemeFromColor(color: string, invertResult = false): 'dark' | 'light' {
    let backgroundIsDark = isColorDark(hexToRgb(color));
    if (invertResult) {
      backgroundIsDark = !backgroundIsDark;
    }
    if (this.isIosStatusbar()) {
      if (backgroundIsDark) {
        this.window['webkit'].messageHandlers.setStatusBarLightContent.postMessage({});
      } else {
        this.window['webkit'].messageHandlers.setStatusBarDarkContent.postMessage({});
      }
    } else if (this.isAndroidStatusBar()) {
      if (backgroundIsDark) {
        this.window['AndroidBridge'].setStatusBarLightContent();
      } else {
        this.window['AndroidBridge'].setStatusBarDarkContent();
      }
    }
    return backgroundIsDark ? 'light' : 'dark';
  }

  // #endregion
}
