import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '@ao/environments';
import { SearchResultItem } from '@ao/data-models';

@Injectable({ providedIn: 'root' })
export class SearchService {
  private http = inject(HttpClient);

  search(searchText: string, filters?: any, sorting?: string, page = 1, pageSize = 20): Observable<SearchResultItem[]> {
    const offset = (page - 1) * pageSize;
    return this.http
      .get<{ data: SearchResultItem[] }>(`${environment.apiBaseUrl}/api/v1/search`, {
        params: {
          searchText,
          limit: pageSize,
          offset: offset.toString(),
          ...filters,
        },
      })
      .pipe(map((res) => res.data as SearchResultItem[]));
  }
}
