<div class="ao-add-to-homescreen__content">
  <!-- icon -->
  <div class="ao-add-to-homescreen__hs-icon" *ngIf="imageUrl">
    <img [aoSrc]="imageUrl" />
  </div>

  <!-- Name -->
  <div class="ao-add-to-homescreen__name" *ngIf="name">
    {{ name }}
  </div>

  <!-- Instructions -->
  <div class="ao-add-to-homescreen__indications">
    <p translate>Install the app on your phone:</p>
    <ng-container [ngSwitch]="compatibleBrowser">
      <ng-container *ngSwitchCase="true" [ngSwitch]="browser">
        <ul *ngSwitchCase="samsung" class="ao-add-to-homescreen__steps">
          <li [innerHTML]="'Tap #samsung_menu# in the toolbar below' | translate: params"></li>
          <li [innerHTML]="'Tap #samsung_add# <strong>Add page to</strong>' | translate: params"></li>
          <li [innerHTML]="'Tap <strong>Home screen</strong>' | translate: params"></li>
        </ul>
        <p
          *ngSwitchDefault
          [innerHTML]="'Tap #icon# and then <strong>“Add to Home Screen”</strong>' | translate: params"
        ></p>
      </ng-container>

      <ng-container *ngSwitchCase="false">
        <ng-container [ngSwitch]="os">
          <p
            *ngSwitchCase="'iOS'"
            [innerHTML]="
              'If you want to add this app on the homescreen of your phone, use #safari# Safari.' | translate: params
            "
          ></p>
          <p
            *ngSwitchDefault
            [innerHTML]="
              'Open the browser option menu and tap on <strong>“Add to homescreen”</strong>' | translate: params
            "
          ></p>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- point to menu -->
<div
  *ngIf="compatibleBrowser"
  class="ao-add-to-homescreen__pointer"
  [ngClass]="['ao-add-to-homescreen__pointer--' + osName]"
  [class.ao-add-to-homescreen__pointer--samsung]="browser === samsung"
>
  <ao-icon name="arrow-upward" [size]="48"></ao-icon>
</div>

<!-- skip -->
<div class="ao-add-to-homescreen__skip" [ngClass]="['ao-add-to-homescreen__skip--' + osName]">
  <a role="button" (click)="close.emit($event)" translate>Skip</a>
</div>
