<ao-task-page-guard>
  <ao-base-header [sticky]="true" [showBackButton]="isIsolatedRoute" (dismiss)="goHome()">
    <span class="ao-font-body2-bold">{{ 'Tasks' | translate }}</span>
  </ao-base-header>

  <hr />
  <ao-tabs [chatUi]="true">
    <a ao-tab [viewerUi]="true" [active]="activeTab === 'open'" (click)="setActiveTab('open')">
      <span>{{ 'Ongoing' | translate }}</span>
    </a>
    <a ao-tab [viewerUi]="true" [active]="activeTab === 'completed'" (click)="setActiveTab('completed')">
      <span>{{ 'Completed' | translate }} </span>
    </a>
  </ao-tabs>

  <ng-container *ngIf="tasksIsLoading$ | async">
    <ao-flex-block [pad]="'default'" direction="column" gap="24px">
      <ao-task-list-section [loadingSkeleton]="true" [isOpen]="true">
        <ao-flex-block direction="column" gap="8px">
          <ao-task-list-item [loadingSkeleton]="true" subTitle="."></ao-task-list-item>
          <ao-task-list-item [loadingSkeleton]="true" subTitle="."></ao-task-list-item>
          <ao-task-list-item [loadingSkeleton]="true" subTitle="."></ao-task-list-item>
        </ao-flex-block>
      </ao-task-list-section>
    </ao-flex-block>
  </ng-container>

  <ng-container *ngIf="(tasksIsLoading$ | async) !== true">
    <ng-container *ngIf="activeTab === 'open'">
      <ao-flex-block
        [pad]="'default'"
        direction="column"
        gap="24px"
        *ngIf="((uncompletedTasks$ | async) || []).length > 0; else nothingInTaskList"
      >
        <ao-task-list-section
          *ngFor="let section of uncompletedTaskSections$ | async"
          [title]="section.title"
          [count]="section.tasks.length.toString()"
          [isOpen]="isSectionOpen(section.id)"
          (click)="toggleSectionOpen(section.id)"
          [warn]="!!section.warn"
        >
          <ao-flex-block direction="column" gap="8px">
            <ao-task-list-item
              *ngFor="let task of section.tasks"
              [priority]="getPrioAsNumber(task)"
              [urgency]="getUrgency(task)"
              [title]="task.headline || task.title"
              [subTitle]="(task.dueDate | date) || undefined"
              [isComplete]="task.status === 'completed'"
              [isRecurrent]="task.type === 'recurrent'"
              [isToMany]="task.completionType === 'oneToMany'"
              (clickHandler)="goToTask(task)"
              (checkboxkHandler)="toggleTaskCompletion(task)"
              [loadingSkeleton]="(isTaskUpdating$(task.id) | async) || false"
            >
            </ao-task-list-item>
          </ao-flex-block>
        </ao-task-list-section>
      </ao-flex-block>
    </ng-container>

    <ng-container *ngIf="activeTab === 'completed'">
      <ao-flex-block
        [pad]="'default'"
        direction="column"
        gap="24px"
        *ngIf="(completedTasks$ | async)!.length > 0; else nothingInCompletedTaskList"
      >
        <p></p>
        <ao-flex-block direction="column" gap="8px" *ngIf="completedTasks$ | async as completedTasks">
          <ao-task-list-item
            *ngFor="let task of completedTasks"
            [priority]="getPrioAsNumber(task)"
            [urgency]="getUrgency(task)"
            [title]="task.headline || task.title"
            [subTitle]="(task.dueDate | date) || undefined"
            [isComplete]="true"
            [isRecurrent]="task.type === 'recurrent'"
            [disabled]="task.completeByOther"
            [isToMany]="task.completionType === 'oneToMany'"
            (clickHandler)="goToTask(task)"
            (checkboxkHandler)="task.completeByOther ? null : uncompleteTaskPrompt(task)"
            [loadingSkeleton]="(isTaskUpdating$(task.id) | async) || false"
          >
          </ao-task-list-item>
        </ao-flex-block>
      </ao-flex-block>

      <ao-task-uncomplete-prompt
        [isOpen]="_showConfirmReopen"
        [completionType]="_confirmationTaskType"
        (dismiss)="dismissUncomplete()"
        (confirm)="uncompleteTaskConfirmation()"
      ></ao-task-uncomplete-prompt>
    </ng-container>

    <div *ngIf="hasUnloadedTasks$ | async">
      <button (click)="fetchMoreTasks()" ao-button [buttonColor]="'transparent'">
        {{ ((tasks$ | async) || []).length }} / {{ paginationTotalCount$ | async }} {{ 'Load more' | translate }}
      </button>
    </div>
  </ng-container>
</ao-task-page-guard>
<!-- templates -->
<ng-template #nothingInTaskList>
  <ao-default-error
    errorImageSrc="/assets/img/illustrations/task-list.svg"
    [bgColor]="'grey'"
    [title]="'You are all up to date!' | translate"
    [description]="'You have no tasks to do.' | translate"
  >
  </ao-default-error>
</ng-template>
<ng-template #nothingInCompletedTaskList>
  <ao-default-error
    errorImageSrc="/assets/img/illustrations/blank-canvas.svg"
    [bgColor]="'grey'"
    [title]="'Nothing to see here' | translate"
    [description]="'Try completing an assigned task' | translate"
  >
  </ao-default-error>
</ng-template>
