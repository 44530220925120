import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SAVED_CONTENT_FEATURE_KEY,
  SavedContentState,
  selectAllAcademies,
  selectAllMessages,
  selectAllPosts,
} from '../reducers/saved-content.reducer';

export const selectSavedContentState = createFeatureSelector<SavedContentState>(SAVED_CONTENT_FEATURE_KEY);

export const selectSavedContentLoaded = createSelector(
  selectSavedContentState,
  (state: SavedContentState) => state.loaded,
);

export const selectSavedContentError = createSelector(
  selectSavedContentState,
  (state: SavedContentState) => state.error,
);

export const isLoaded = createSelector(selectSavedContentState, (state) => state.loaded);

// Selector for all messages
export const selectAllSavedMessages = createSelector(selectSavedContentState, (state) =>
  selectAllMessages(state.messages),
);

// Selector for all academies
export const selectAllSavedAcademies = createSelector(selectSavedContentState, (state) =>
  selectAllAcademies(state.academies),
);

// Selector for all posts
export const selectAllSavedPosts = createSelector(selectSavedContentState, (state) => selectAllPosts(state.posts));
