import { Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Directive({
  selector: '[aoLongPress]',
})
export class LongPressDirective implements OnDestroy {
  @Output() longPress = new EventEmitter<MouseEvent | TouchEvent>();

  private pressEvents = new Subject<MouseEvent | TouchEvent>();
  private longPressSubscription: Subscription;
  private readonly longPressDuration = 300; // milliseconds

  constructor(private elementRef: ElementRef) {
    this.longPressSubscription = this.pressEvents
      .pipe(
        debounceTime(this.longPressDuration),
        filter((value) => !!value),
      )
      .subscribe((event) => this.longPress.emit(event));
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onPressStart(event: MouseEvent | TouchEvent): void {
    this.pressEvents.next(event);
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  @HostListener('touchend')
  @HostListener('touchcancel')
  onPressEnd(): void {
    this.pressEvents.next(null);
  }

  ngOnDestroy(): void {
    if (this.longPressSubscription) {
      this.longPressSubscription.unsubscribe();
    }
  }
}
