<ng-template>
  <div
    class="ao-tooltip"
    (click)="onClick($event)"
    [@state]="'visible'"
    [id]="id"
    role="tooltip"
    aria-hidden="false"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    [ngClass]="{ 'ao-tooltip--narrow': narrow }"
  >
    <div class="ao-tooltip__tip-wrapper">
      <div class="ao-tooltip__tip"></div>
    </div>
    <div class="ao-font-tiny ao-tooltip__content" [ngClass]="'ao-tooltip__content--' + padding + '-padding'">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
