import { ReactionType, SocialReactionAggregatePerType } from '@ao/shared-data-models';

export const sortReactions = (reactions: SocialReactionAggregatePerType[]): SocialReactionAggregatePerType[] => {
  const order = [ReactionType.Love, ReactionType.Like, ReactionType.Laugh, ReactionType.Surprised, ReactionType.Ponder];

  if (reactions && Array.isArray(reactions)) {
    return [...reactions].sort((a, b) => {
      if (a.count !== b.count) {
        return b.count - a.count;
      } else {
        return order.indexOf(a.reaction_type) - order.indexOf(b.reaction_type);
      }
    });
  } else {
    return [];
  }
};
