import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

interface Ng1Event {
  type: string;
  event: any;
}

/*
 * This is a small service to be able to propagate events from ng1 into admin-ui components.
 * One example is for the modal service to close the modals when there's a route change in ng1.
 */
@Injectable()
export class Ng1Events implements OnDestroy {
  private _events$ = new Subject<Ng1Event>();
  private _eventsObs$ = this._events$.asObservable();

  public get events$() {
    return this._eventsObs$;
  }

  next(type: string, event: any) {
    this._events$.next({ type, event });
  }

  ngOnDestroy() {
    this._events$.complete();
  }
}
