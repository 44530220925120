<ng-container *ngIf="customTemplate; else standard">
  <ng-content></ng-content>
</ng-container>
<ng-template #standard>
  <div class="ao-admin-info-box__content">
    <ao-icon class="ao-admin-info-box__custom-icon" *ngIf="icon" [name]="icon" [size]="20"></ao-icon>
    <span class="ao-font-small ao-admin-info-box__text">{{ infoMessage }}</span>
    <ao-icon
      class="ao-admin-info-box__close-icon"
      *ngIf="showCloseButton"
      name="close"
      [size]="20"
      (click)="closeInfoBox.emit()"
    ></ao-icon>
  </div>
</ng-template>
