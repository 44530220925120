<ao-tabs>
  <ng-container *ngFor="let insight of insights; trackBy: itemTrackBy">
    <a
      ao-tab
      [disableStyling]="true"
      routerLinkActive="ao-tab--active"
      #active="routerLinkActive"
      [style.color]="color"
      [active]="active.isActive"
      [ngClass]="{ 'ao-tab--default-cursor': insight.type !== insight.key && insight.type !== 'action' }"
      (click)="onInsightClick(insight)"
      [routerLink]="
        insight.type === insight.key
          ? ['profile', contactId, insight.key === 'rating' ? 'one-on-one' : insight.key]
          : $any(false)
      "
    >
      <ao-insights-value class="viewer-module-insights__tab" [item]="insight"></ao-insights-value>
    </a>
  </ng-container>
</ao-tabs>
