import { MsgModuleFeedback, MsgModuleFeedbackData, MsgModuleFeedbackItem } from '@ao/data-models';
import { createAction, props } from '@ngrx/store';

export const LoadFeedback = createAction(
  '[Message/Module Feedback] Load Feedback',
  props<{ module: MsgModuleFeedback }>(),
);
export const LoadFeedbackSuccess = createAction(
  '[Message/Module Feedback] Load Feedback Success',
  props<{ module: MsgModuleFeedback; data: MsgModuleFeedbackData }>(),
);
export const LoadFeedbackFail = createAction('[Message/Module Feedback] Load Feedback Fail', props<{ error: any }>());

export const FeedbackSubmit = createAction(
  '[Message/Module Feedback] Submit Feedback',
  props<{ module: MsgModuleFeedback; content: string }>(),
);
export const FeedbackSubmitSuccess = createAction(
  '[Message/Module Feedback] Submit Feedback Success',
  props<{ module: MsgModuleFeedback; comment: MsgModuleFeedbackItem }>(),
);
export const FeedbackSubmitFail = createAction(
  '[Message/Module Feedback] Submit Feedback Fail',
  props<{ error: any; modules: MsgModuleFeedback }>(),
);

export const FeedbackUpdate = createAction(
  '[Message/Module Feedback] Update Feedback',
  props<{ module: MsgModuleFeedback; comment: MsgModuleFeedbackItem }>(),
);

// These can only occur when edit feedback modules in Pulse/Rating messages
export const FeedbackDelete = createAction(
  '[Message/Module Feedback] Delete Feedback',
  props<{ module: MsgModuleFeedback }>(),
);
export const FeedbackDeleteSuccess = createAction(
  '[Message/Module Feedback] Delete Feedback Success',
  props<{ module: MsgModuleFeedback }>(),
);
export const FeedbackDeleteFail = createAction(
  '[Message/Module Feedback] Delete Feedback Fail',
  props<{ error: any; modules: MsgModuleFeedback }>(),
);
