import { EventEmitter, inject, Injectable } from '@angular/core';
import { BrowserService } from './browser.service';
import { WINDOW } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class OneTrustConsentService {
  _window = inject(WINDOW);
  browserService = inject(BrowserService);

  oneTrust?: {
    OnConsentChanged: (callback: () => void) => void;
    ToggleInfoDisplay: () => void;
  };
  performanceAllowedUpdate = new EventEmitter();

  requiredGroupCode = 'C0001';
  performanceGroupCode = 'C0002';
  domainList = ['beta.actimo.com', 'app.actimo.com'];

  init() {
    this.oneTrust = this._window['OneTrust'];

    if (!this.oneTrust) {
      console.warn('OneTrust is not available');
      const lateInit = () => {
        this.oneTrust = this._window['OneTrust'];
        if (this.oneTrust) {
          this.afterInit();
          console.info('OneTrust is now available');
          window.removeEventListener('oneTrustReadyNow', lateInit);
        }
      };
      this._window.addEventListener('oneTrustReadyNow', lateInit);
    } else {
      this.afterInit();
    }
  }

  isActive() {
    const host = this._window.location.hostname;

    const isDev = host.includes('actimo.dev');
    if ((this.oneTrust && !this.browserService.isNativeAppCookieSet() && this.domainList.includes(host)) || isDev) {
      return true;
    }
    return false;
  }

  afterInit() {
    if (this.isActive()) {
      this.updateStyles();
    }
    this.performanceAllowedUpdate.emit(this.performanceAllowed);
    this.oneTrust.OnConsentChanged(() => {
      this.performanceAllowedUpdate.emit(this.performanceAllowed);
    });
  }

  updateStyles() {
    // add override style taht hides the floating button
    const styles = this._window.document.createElement('style');
    styles.id = 'cookie-consent-styles';
    styles.innerHTML = `
      .ot-floating-button{
        display: none !important;
      }
    `;
    this._window.document.head.appendChild(styles);
    // remove the cookie override style taht hides them initially
    const cookieOverride = this._window.document.getElementById('cookieOverride');
    if (cookieOverride) {
      cookieOverride.remove();
    }
  }

  get performanceAllowed() {
    const allowlist = this._window['OnetrustActiveGroups'] || this.requiredGroupCode;
    const allowed = allowlist.includes(this.performanceGroupCode);
    // keep allowing tracking if OneTrust is not available (not to break current tracking)
    return !this.oneTrust ? true : allowed;
  }

  openCookieSettings() {
    this.oneTrust?.ToggleInfoDisplay();
  }
}
