import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Self, SimpleChanges } from '@angular/core';
import { color } from '@ao/utilities';
import { ThemeHandlerService } from '../../services/theme-handler/theme-handler.service';

@Component({
  selector: 'ao-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  providers: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnChanges, OnInit {
  @Input() size: 'bigger' | 'huge' | 'default' = 'default';

  constructor(@Self() private ngClass: NgClass, themeHandler: ThemeHandlerService) {
    themeHandler.registerStyle(
      'LoaderComponent',
      (theme) => `
      .ao-loader {
        color: ${color(theme.module_btn_bg_color)};
      }
    `,
    );
  }

  ngOnInit() {
    this._updateClasses();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this._updateClasses();
  }

  private _updateClasses() {
    this.ngClass.ngClass = {
      [`ao-loader`]: true,
      [`ao-loader--size-${this.size}`]: true,
    };
    this.ngClass.ngDoCheck();
  }
}
