import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
} from '@angular/core';
import { ThemeHandlerService, addLoginStyles } from '@ao/common-ui';
import { AdminSupportSettings, ErrorMessage, LoginTheme } from '@ao/data-models';

@Component({
  selector: 'ao-viewer-error-page',
  templateUrl: './viewer-error-page.component.html',
  styleUrls: ['./viewer-error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewerErrorPageComponent implements OnDestroy {
  @HostBinding('class.ao-viewer-error-page') className = true;

  @Input() ssoEnabled = false;
  @Input() errorMessage: ErrorMessage;
  @Input() support: AdminSupportSettings;
  @Input() theme: LoginTheme;
  @Input() errorCode?: string;
  @Input() domain?: string;
  @Input() destination?: string;

  @Output() findDifferentClick = new EventEmitter();
  @Output() logout = new EventEmitter();

  supportModalOpen = false;

  constructor(@Optional() private themeHandler: ThemeHandlerService) {
    if (themeHandler) {
      themeHandler.registerLoginStyle('.ao-viewer-error-page', this.addStyles);
    }
  }

  ngOnDestroy(): void {
    this.themeHandler.unregisterLoginStyle('.ao-viewer-error-page');
  }

  toggleSupportModal() {
    this.supportModalOpen = !this.supportModalOpen;
  }
  // using some parts of the message theme
  addStyles = (theme: LoginTheme) => {
    return addLoginStyles(theme, '.ao-viewer-error-page', false);
  };
}
