<ao-task-page-guard>
  <ao-base-header [sticky]="true" (dismiss)="goToTaskList()">
    <span class="ao-font-body2-bold">{{ 'Tasks' | translate }}</span>
  </ao-base-header>
  <hr />
  <ng-container *ngIf="isLoading$ | async">
    <ao-flex-block [pad]="'default'" direction="column" gap="16px">
      <ao-flex-block gap="8px">
        <ao-icon-box [isLoading]="true"></ao-icon-box>
        <ao-skeleton-block [fullWidth]="true"></ao-skeleton-block>
      </ao-flex-block>
      <ao-flex-block gap="8px">
        <ao-icon-box [isLoading]="true"></ao-icon-box>
        <ao-skeleton-block [fullWidth]="true" heightStyle="3em"></ao-skeleton-block>
      </ao-flex-block>
      <hr />
      <ao-flex-block gap="8px">
        <ao-icon-box [isLoading]="true"></ao-icon-box>
        <ao-skeleton-block [fullWidth]="true"></ao-skeleton-block>
      </ao-flex-block>
      <hr />
      <ao-flex-block gap="8px">
        <ao-icon-box [isLoading]="true"></ao-icon-box>
        <ao-skeleton-block [fullWidth]="true"></ao-skeleton-block>
      </ao-flex-block>
      <hr />
      <ao-flex-block gap="8px">
        <ao-icon-box [isLoading]="true"></ao-icon-box>
        <ao-skeleton-block [fullWidth]="true"></ao-skeleton-block>
      </ao-flex-block>
    </ao-flex-block>
  </ng-container>

  <ng-container *ngIf="(isLoading$ | async) === false && (taskError$ | async) as error">
    <ao-flex-block justifyContent="center" [pad]="'bigger'">
      <p>{{ error }}</p>
    </ao-flex-block>
  </ng-container>

  <ng-container *ngIf="(isLoading$ | async) === false && (task$ | async) as task">
    <ao-flex-block [pad]="'default'" direction="column" gap="16px" class="ao-text--break-word">
      <ao-flex-block alignItems="center" gap="8px">
        <ao-icon-box
          class="task-item__checkmark"
          [ngClass]="{ 'task-item__checkmark--completedByOther': completedByOthers(task) }"
          [iconName]="isComplete(task) ? 'check' : undefined"
          [bgColor]="isComplete(task) ? 'blue' : undefined"
          [isLoading]="(isUpdating$(task.id) | async) || false"
          (click)="!completedByOthers(task) ? taskCompletionToggle($event, task, 'topCheckbox') : null"
        >
        </ao-icon-box>

        <div class="ao-font-body1-bold" [ngClass]="{ 'ao-color-ink--lighter': isComplete(task) }">
          {{ task.headline || task.title }}
        </div>
      </ao-flex-block>

      <ao-flex-block gap="8px">
        <ao-icon name="notes" color="inkLighter"></ao-icon>
        <div [innerHTML]="task.description"></div>
      </ao-flex-block>
      <hr />
      <ao-flex-block gap="8px" alignItems="center">
        <ao-avatar [name]="task.creator?.firstName + ' ' + task.creator?.lastName || '?'" size="tiny"></ao-avatar>
        <div>{{ task.creator?.firstName + ' ' + task.creator?.lastName }}</div>
      </ao-flex-block>

      <ng-container *ngIf="task.dueDate">
        <hr />
        <ao-flex-block gap="8px" alignItems="center">
          <ao-icon name="date-range" color="inkLighter"></ao-icon>
          <div>{{ 'Due:' | translate }} {{ task.dueDate | date }}</div>
        </ao-flex-block>
      </ng-container>

      <ng-container *ngIf="task.priority">
        <hr />
        <ao-flex-block gap="8px" alignItems="center">
          <ao-icon name="outlined-flag" [color]="getPrioColor(task)"></ao-icon>
          <ng-container [ngSwitch]="task.priority">
            <div *ngSwitchCase="'PRIORITY_1'">{{ 'Priority 1' | translate }}</div>
            <div *ngSwitchCase="'PRIORITY_2'">{{ 'Priority 2' | translate }}</div>
            <div *ngSwitchCase="'PRIORITY_3'">{{ 'Priority 3' | translate }}</div>
          </ng-container>
        </ao-flex-block>
      </ng-container>

      <ng-container *ngIf="task.type === 'recurrent' && task.frequency">
        <hr />
        <ao-flex-block gap="8px" alignItems="center">
          <ao-icon name="repeat" color="inkLighter"></ao-icon>
          <div>{{ 'Repeats #frequencyLabel#' | translate: { frequencyLabel: getFrequencyLabel(task.frequency) } }}</div>
        </ao-flex-block>
      </ng-container>
      <ng-container *ngIf="task.completionType === 'oneToMany'">
        <hr />
        <ao-flex-block gap="8px" alignItems="center">
          <ao-icon name="people" color="inkLighter"></ao-icon>
          <div *ngIf="!isComplete(task)">{{ 'Team task' | translate }}</div>
          <div *ngIf="isComplete(task)">
            <div *ngIf="(contactId$ | async) === task.completedByContactId">
              {{ 'You completed this task' | translate }}
            </div>
            <div *ngIf="completedByOthers(task)">
              {{ 'Completed by' | translate }} {{ completedByContactName$ | async }}
            </div>
          </div>
        </ao-flex-block>
      </ng-container>
    </ao-flex-block>
    <hr />
    <ao-flex-block [pad]="'default'" direction="column" gap="16px" *ngIf="task.mediaFiles.length > 0">
      <div class="ao-font-body2-bold">{{ 'Support Media' | translate }} ({{ task.mediaFiles.length }})</div>

      <ng-container *ngIf="getImageOrVideoAttachments(task) as mediaList">
        <ao-flex-block gap="8px" wrap="wrap" *ngIf="mediaList.length > 0">
          <ng-container *ngFor="let media of mediaList">
            <ao-media-attachment
              *ngIf="isImage(media)"
              [images]="media.imageVersions"
              [removable]="false"
              [small]="true"
              (click)="openMediaViewer(media)"
            >
            </ao-media-attachment>

            <ao-media-attachment
              *ngIf="isVideo(media)"
              [images]="[{ url: media?.videoThumbnailUrl || '', size: { width: 100, height: 100, original: true } }]"
              [removable]="false"
              [small]="true"
              [videoIcon]="true"
              [overlay]="true"
              (click)="openVideoViewer(media.url)"
            ></ao-media-attachment>
          </ng-container>
        </ao-flex-block>
      </ng-container>

      <ng-container *ngIf="getNonImageOrVideoAttachments(task) as attachemntList">
        <div *ngFor="let file of attachemntList">
          <ao-download-uploaded-file
            [file]="{
              name: file.title,
              mimeType: file.fileType,
              url: file.url
            }"
            (nativeFileDownload)="nativeAppDownloadFile($event)"
          ></ao-download-uploaded-file>
        </div>
      </ng-container>
    </ao-flex-block>
    <ao-flex-block [pad]="'default'" direction="column" gap="16px" *ngIf="!completedByOthers(task)">
      <button
        ao-button
        [rounded]="true"
        [buttonColor]="isComplete(task) ? 'snow' : 'green'"
        (click)="taskCompletionToggle($event, task, 'bottomButton')"
        [disabled]="(isUpdating$(task.id) | async) || false"
      >
        {{ isComplete(task) ? ('Mark as ongoing' | translate) : ('Mark as completed' | translate) }}
      </button>
    </ao-flex-block>
    <ao-task-uncomplete-prompt
      [isOpen]="_showConfirmReopen"
      [completionType]="task.completionType"
      (dismiss)="dismissUncomplete()"
      (confirm)="uncompleteTask(task)"
    >
    </ao-task-uncomplete-prompt>

    <ao-lightbox-modal *ngIf="_fullScreenViewImage" [open]="true" (closeHandler)="closeViewers()">
      <img [src]="_fullScreenViewImage" loading="lazy" />
    </ao-lightbox-modal>

    <ao-lightbox-modal [open]="true" *ngIf="_fullScreenViewVideo" (closeHandler)="closeViewers()">
      <video width="100%" height="100%" controls>
        <source [src]="_fullScreenViewVideo" type="video/mp4" />
        {{ 'Your browser does not support the video tag.' | translate }}
      </video>
    </ao-lightbox-modal>
  </ng-container>
</ao-task-page-guard>
