import {
  CompanyContext,
  Contact,
  ContactStatus,
  GenericMessageToast,
  InsightsValue,
  MessageTrackingAttributes,
  ModalStatus,
  PendingMessage,
  UpdateContactInfoModel,
  UserContext,
  ViewerLoginConfig,
  ViewerSettingsFE,
} from '@ao/data-models';
import { ImageSource } from '@ao/shared-data-models';
import { createAction, props } from '@ngrx/store';

// App context & navigation
export const LoadAppContext = createAction(
  '[Core] Load App Context',
  props<{
    origin: string;
    keycode: string;
    queryParams?: { [name: string]: string };
  }>(),
);
export const LoadAppContextSuccess = createAction(
  '[Core] Load App Context Success',
  props<{ companyContext: CompanyContext; userContext: UserContext }>(),
);
export const LoadAppContextFail = createAction('[Core] Load App Context Fail', props<{ error: any }>());

export const UpdateMessageTrackingAttributes = createAction(
  '[Core] update Message Tracking Attributes',
  props<{ trackingAttributes: MessageTrackingAttributes }>(),
);

export const ResetAppContext = createAction('[Core] Reset App Context');

export const SetCurrentRouteDetails = createAction(
  '[Core] Set Keycode',
  props<{
    origin: string;
    keycode: string;
    pageId: number;
    queryParams?: Record<string, string>;
    extraState?: Record<string, string>;
  }>(),
);
export const Logout = createAction('[Core] Logout', props<{ keycode: string; origin: string }>());
export const LogoutSuccess = createAction('[Core] Logout success', props<{ redirectUrl: string }>());
export const LogoutFail = createAction('[Core] Logout fail', props<{ error: any }>());

export const LogoutWithLatestKeycode = createAction('[Core] Logout with latest keycode');

export const AppReady = createAction('[Core] App Ready');

export const ResetNative = createAction('[Core] Reset native session', props<{ error?: unknown; source: string }>());
export const ResetNativeSuccess = createAction('[Core] Reset native session success');
export const ResetNativeFail = createAction('[Core] Reset native session fail', props<{ error: any }>());

export const HandleNativePostMessages = createAction('[Core] Handle native post messages');
export const NativePostMessageReceived = createAction('[Core] Native post message Received');
export const HandleAndroidLayoutMessages = createAction('[Core] Native Android layout messages');

export const setStatusBarThemeSuccess = createAction(
  '[Core] set status bar font theme success',
  props<{ statusBarTheme: 'dark' | 'light' }>(),
);

export const TriggerError = createAction('[Core] Trigger Error', props<{ error: any }>());
export const LoadClientBasicConfig = createAction('[Core] Load Client Basic Config', props<{ redirectUrl?: string }>());
export const LoadClientBasicConfigSuccess = createAction(
  '[Core] Load Client Basic ConfigSuccess',
  props<{ loginConfig: ViewerLoginConfig }>(),
);
export const LoadClientBasicConfigFail = createAction('[Core] Load Client Basic Config Fail', props<{ error: any }>());

export const LoadViewerSettings = createAction('[Core] Load Viewer Settings');
export const LoadViewerSettingsSuccess = createAction(
  '[Core] Load Viewer Settings Success',
  props<{ settings: ViewerSettingsFE }>(),
);
export const LoadViewerSettingsFail = createAction('[Core] Load Viewer Settings Fail', props<{ error: any }>());

// Unread count
export const LoadUnreadCount = createAction('[Core] Load Unread Count');
export const LoadUnreadCountSuccess = createAction(
  '[Core] Load Unread Count Success',
  props<{ counts: Record<number, number> }>(),
);
export const LoadUnreadCountFail = createAction('[Core] Load Unread Count Fail', props<{ error: any }>());

// Contact

export const UpdateContact = createAction('[Core] Update Contact', props<{ keycode: string; contactId: number }>());
export const UpdateContactSuccess = createAction(
  '[Core] Update Contact Success',
  props<{ id: number; newValue: Contact }>(),
);
export const UpdateContactFail = createAction('[Core] Update Contact Fail', props<{ error: any }>());

export const UpdateContactInfo = createAction(
  '[Core] Update Contact Info',
  props<{
    id: number;
    newValue: UpdateContactInfoModel['contactFields'];
    sendHomePage?: boolean;
    avatar?: Blob;
    showToast?: boolean;
  }>(),
);
export const UpdateContactInfoSuccess = createAction(
  '[Core] Update Contact Info Success',
  props<{
    id: number;
    newValue: UpdateContactInfoModel['contactFields'];
    avatarUrl?: string;
    avatarImages?: ImageSource[];
    contactAuthCode?: string;
    sendHomePage?: boolean;
  }>(),
);
export const UpdateContactInfoFail = createAction(
  '[Core] Update Contact Info Fail',
  props<{ id: number; error: any }>(),
);

export const ResetCreateContact = createAction('[Create-contact] Reset Create Contact');

export const CreateContact = createAction('[Create-contact] Create Contact', props<{ formData: any }>());
export const CreateContactSuccess = createAction(
  '[Create-contact] Create Contact Success',
  props<{ status: string; data: Contact; creatorId: number }>(),
);
export const CreateContactFail = createAction('[Create-contact] Create Contact Fail', props<{ error: any }>());

export const UploadContactAvatarSuccess = createAction(
  '[Core] Upload Contact Avatar Success',
  props<{ targetId: number; newValue: any }>(),
);

// Status
export const ToggleModalStatus = createAction('[Core] Update Modal Status', props<{ modalStatus: ModalStatus }>());
export const UpdateContactStatus = createAction(
  '[Core] Update Contact Status',
  props<{ contactStatus: ContactStatus }>(),
);

export const UpdateSystemAllowPushNotification = createAction(
  '[Core] Update System Allow Push Notification',
  props<{ system_allow_push: boolean }>(),
);

export const SetPendingRecurringMsgs = createAction(
  '[Core] Set Pending Recurring Messages',
  props<{ pendingRecurringMsgs: PendingMessage[] }>(),
);

export const UpdateSubscriptionStatus = createAction(
  '[Core] Update Subscription Status',
  props<{ isSubscribing: boolean }>(),
);
export const UpdateSubscriptionStatusSuccess = createAction(
  '[Core] Update Subscription Status Success',
  props<{ isSubscribing: boolean }>(),
);
export const UpdateSubscriptionStatusFail = createAction(
  '[Core] Update Subscription Fail',
  props<{ isSubscribing: boolean; error: any }>(),
);

// Insights
export const LoadInsights = createAction('[Core] Load Insights');
export const LoadInsightsSuccess = createAction('[Core] Load Insights Success', props<{ data: InsightsValue[] }>());
export const LoadInsightsFail = createAction('[Core] Load Insights Fail', props<{ error: any }>());

// Toasts
export const ShowGenericMessageToast = createAction(
  '[Core] Show Generic Message Toast',
  props<{ toast: GenericMessageToast }>(),
);
export const DismissGenericMessageToast = createAction('[Core] Dismiss Generic Message Toast');

// Navbar UI
export const HideNavbar = createAction('[Core] Hide navbar');
export const ShowNavbar = createAction('[Core] Show navbar');
