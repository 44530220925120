import { getModuleRef } from '@ao/utilities';
import { on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, moduleAdapter } from './app-store.state';

export const moduleChartOns = [
  on(appActions.LoadChartDataSuccess, (state: AppState, { module, data }): AppState => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: data,
        },
        { ...state.modules },
      ),
    };
  }),
];
