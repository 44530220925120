import { Injectable } from '@angular/core';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import * as appActions from '../actions';

@Injectable()
export class ModuleLikeEffects {
  constructor(private actions$: Actions, private viewerCoreFacade: ViewerCoreFacade) {}

  trackLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.Like),
      map(({ module }) => {
        return appActions.TrackEvent({ event: { key: `like:${module.id}`, event: 'click' } });
      }),
    ),
  );

  updateLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LikeUpdate),
      withLatestFrom(this.viewerCoreFacade.contactId$),
      filter(([{ module, contact }, currentContactId]) => !(contact.id === currentContactId && module.liked)),
      map(([{ module, contact }, currentContactId]) => {
        return appActions.LikeUpdateSuccess({ module, contact, currentContactId });
      }),
    ),
  );
}
