import type { ImageSource } from '../media';

export type ShareDestination = 'privateGroup' | 'companyGroup';
export type ShareSource = 'link' | 'shareButtonPressed';

export type Attachment = {
  id: number;
  type: 'message';
};
export type AttachmentInput = Attachment & {
  tracking: {
    shareDestination: ShareDestination;
    shareSource?: ShareSource;
  };
};

export type MessageAttachmentPreview = {
  type: string;
  id: number;
  title?: string;
  oneLiner?: string;
  media?: {
    mediaFileId?: number;
    images: ImageSource[];
  };
  categories?: {
    categoryId: number;
    name: string;
  }[];
};

export type LinkPreview = {
  title: string;
  imageUrl: string;
  url: string;
};

export enum ReactionType {
  Like = 'like',
  Love = 'love',
  Laugh = 'laugh',
  Surprised = 'surprised',
  Ponder = 'ponder',
}

export type SocialReactionAggregatePerType = {
  post_id: number;
  reaction_type: ReactionType;
  count: number;
};

export type PostContent = {
  content?: {
    ops?: { insert: string | Record<string, unknown> }[];
    attachments?: Attachment[];
    media?: { id: number }[];
    linkPreview?: LinkPreview;
  };
};

export type ExtractedPostContent = {
  content: { ops: { insert: string | Record<string, unknown> }[] } | null;
  attachments: Attachment[];
  media: { id: number }[];
  linkPreview?: LinkPreview;
  pinned?: boolean;
};
