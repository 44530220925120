import { Injectable } from '@angular/core';
import { AoTrackEvent } from '@ao/data-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MessageActionHandler } from '../../services/message-action-handler.service';
import * as appActions from '../actions';

@Injectable()
export class ModuleSliderEffects {
  constructor(private actions$: Actions, private messageActionHandler: MessageActionHandler) {}

  triggerButtonAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.TrackEvent),
        tap(({ type, event }) => this.handleActions([event], type)),
      ),
    { dispatch: false },
  );

  triggerButtonActionResults$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.TrackEventSuccess, appActions.TrackEventFail, appActions.TrackBulkEvents),
        tap(({ type, events }) => this.handleActions(events, type)),
      ),
    { dispatch: false },
  );

  handleActions(events: AoTrackEvent[], type: string) {
    const filteredEvents = events.filter(
      (eventData) => eventData.key.startsWith('slider:') && eventData.event.startsWith('slider_') && eventData.data,
    );

    if (!filteredEvents.length) {
      return;
    }

    for (const event of filteredEvents) {
      if (
        (type !== appActions.TrackEvent.type && type !== appActions.TrackBulkEvents.type) ||
        this.messageActionHandler.shouldTriggerImmediately(event.data.actions)
      ) {
        this.messageActionHandler.trigger(event.data, 'slider');
      }
    }
  }
}
