import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InsightsValue } from '@ao/data-models';

@Component({
  selector: 'ao-insights-value',
  templateUrl: './insights-value.component.html',
  styleUrls: ['./insights-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsValueComponent {
  @Input() item: InsightsValue;

  get hasValue() {
    return typeof this.item.value !== 'undefined' && this.item.value !== null;
  }

  get isNumeric() {
    if (['engagement', 'knowledge', 'points', 'pulse', 'progress'].indexOf(this.item.type) >= 0) {
      return this.hasValue;
    }
    return typeof this.item.value === 'number';
  }

  get hasThreshold() {
    return Boolean(this.item.lowerThreshold || this.item.upperThreshold);
  }
}
