<div class="ao-viewer-toast-display" [ngClass]="{ hasHeader: hasHeader }">
  <ng-container *ngIf="toast">
    <ao-viewer-toast
      class="ao-viewer-toast-display__toast"
      [displayDuration]="toast.displayDuration || 5"
      [hasHeader]="true"
      [hasFixedSidebar]="true"
      [toast]="toast"
      (dismissed)="dismissGenericToast(toast)"
      (toastActionClick)="genericToastAction(toast.linkAction)"
    ></ao-viewer-toast>
  </ng-container>
</div>
