import { getModuleRef } from '@ao/utilities';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, moduleAdapter } from './app-store.state';

export const moduleContactInfoOns = [
  on(appActions.ContactInfoData, (state: AppState, { module, result }): AppState => {
    const moduleRef = `contactinfo:${module.id}`; // TODO: to be reviewed later on. was needed due to changes coming in from data picker module

    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: moduleRef,
          changes: {
            fields: result.fields || module.fields || [],
            data: { ...(module.data || {}), ...(result.data || {}) },
            field_info: { ...(module.field_info || {}), ...(result.field_info || {}) },
            options_data: result.optionsData || [],
            preparedFields: result.preparedFields || false,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.ContactInfoSubmit, (state: AppState, { module, contactData }): AppState => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            data: { ...(module.data || {}), ...(contactData.data || {}) },
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.ContactInfoSubmitSuccess, (state: AppState, { module }): AppState => {
    return {
      ...state,
      modules: moduleAdapter.map(
        (m) => {
          if (
            (m.type === 'contactinfo' && m.id === module.id) ||
            (m.type === 'datapicker' && m.id === module.datapicker)
          ) {
            const changes =
              m.type === 'contactinfo' && m.id === module.id ? { submitted: true, errors: [] } : { _disabled: true };
            return {
              ...m,
              ...changes,
            };
          }
          return m;
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.ContactInfoSubmitFail, (state: AppState, { module, error }): AppState => {
    let errors = [];

    // Some errors from the backend are split up into arrays - like invalid email format
    if (error?.error?.data?.length > 0) {
      // Avoiding duplicate errors
      // eslint-disable-next-line prefer-spread
      const errorList = [].concat.apply([], error.error.data).filter((e) => {
        return !(module.errors || []).includes(e);
      });
      errors = [...(module.errors || []), ...errorList];
    }

    if (error?.error?.code === 'DB_CREATION_FAILED' && error?.error?.error?.code === 'POLICY_DENIED') {
      errors.push({ error: i18n('Contact information exists and updates are not allowed'), params: {} });
    } else if (error?.error?.code === 'DUPLICATE_FOUND') {
      errors.push({ code: 'DUPLICATE_FOUND' });
    }

    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            errors,
          },
        },
        { ...state.modules },
      ),
    };
  }),

  on(appActions.DataPickerDataSelected, (state: AppState, { module, data }): AppState => {
    const newData = module.data_set.fieldMappings.reduce((acc, mapping) => {
      return data
        ? {
            ...acc,
            [mapping.to]: data[mapping.from],
          }
        : acc;
    }, {});

    return {
      ...state,
      modules: moduleAdapter.map(
        (m) => {
          if (m.type === 'contactinfo' && m.datapicker === module.id) {
            return {
              ...m,
              data: {
                ...m.data,
                ...newData,
              },
            };
          }
          return m;
        },
        { ...state.modules },
      ),
    };
  }),
];
