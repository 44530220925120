import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ao-task-uncomplete-prompt',
  templateUrl: './task-uncomplete-prompt.component.html',
  styleUrls: ['./task-uncomplete-prompt.component.scss'],
})
export class TaskUncompletePromptComponent {
  @Input() isOpen = false;
  @Input() completionType: 'oneToOne' | 'oneToMany' = 'oneToOne';

  @Output() dismiss = new EventEmitter<Event>();
  @Output() confirm = new EventEmitter<Event>();

  onDismiss(evt: Event) {
    this.dismiss.emit(evt);
  }
  onConfirm(evt: Event) {
    this.confirm.emit(evt);
  }
}
