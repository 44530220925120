import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getDeep } from '../utils';
import { RouterState } from './router.reducer';
import { RouterStateUrl } from './routerStateUrl';

export const selectRouter = createFeatureSelector<RouterState>('router');
export const getRouteState = createSelector(
  selectRouter,
  (state) => (state && getDeep(state, ['state'])) || <RouterStateUrl>{},
);
export const selectRoutePath = createSelector(getRouteState, (state) => (state && state.path) || '');
export const getRouteParams = createSelector(getRouteState, (state) => (state && state.params) || {});
export const getRouteOutlets = createSelector(getRouteState, (state) => (state && state.outlets) || {});
export const getRouteQuery = createSelector(getRouteState, (state) => (state && state.queryParams) || {});
export const getRouteData = createSelector(getRouteState, (data) => getDeep(data, ['data']));
export const getRouteDataPage = createSelector(getRouteState, (data) => getDeep(data, ['data', 'page']));
export const getRouteFragment = createSelector(getRouteState, (state) => (state && state.fragment) || null);
export const getRouteUrl = createSelector(getRouteState, (state) => (state && state.url) || null);

export const selectRouteNestedParams = createSelector(selectRouter, (router) => router?.state?.params);
export const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, (params) => params?.[param]);
