import { HttpErrorResponse } from '@angular/common/http';
import {
  ContactInfoFields,
  InsightsValue,
  MessageList,
  MessageListData,
  MessageListRequestParams,
  Profile,
  SubordinatesPage,
} from '@ao/data-models';
import { ImageSource } from '@ao/shared-data-models';
import { createAction, props } from '@ngrx/store';

export const LoadProfile = createAction('[Profile] Load Profile', props<{ profileId: number }>());
export const LoadProfileSuccess = createAction(
  '[Profile] Load Profile Success',
  props<{ profile: Profile; profileId: number }>(),
);
export const LoadProfileFail = createAction('[Profile] Load Profile Fail', props<{ profileId: number; error: any }>());

export const SendHomepage = createAction('[Profile] Send Homepage', props<{ profileId: number }>());
export const SendHomepageSuccess = createAction('[Profile] Send Homepage Success', props<{ profileId: number }>());
export const SendHomepageFail = createAction(
  '[Profile] Send Homepage Fail',
  props<{ profileId: number; error: any }>(),
);
export const SendHomepageReset = createAction('[Profile] Send Homepage Reset', props<{ profileId: number }>());

export const UpdateProfileSettings = createAction(
  '[Profile] Update Profile Settings',
  props<{ targetId: number; newValue: any; oldValue?: any }>(),
);
export const UpdateProfileSettingsSuccess = createAction(
  '[Profile] Update Profile Settings Success',
  props<{ targetId: number; newValue: any }>(),
);
export const UpdateProfileSettingsFail = createAction(
  '[Profile] Update Profile Settings Fail',
  props<{ targetId: number; oldValue: any; error: any }>(),
);

export const ProfileUpdateContactInfo = createAction(
  '[Profile] Update Contact Info',
  props<{ id: number; newValue: ContactInfoFields; sendHomePage?: boolean; avatar?: Blob }>(),
);
export const ProfileUpdateContactInfoSuccess = createAction(
  '[Profile] Update Contact Info Success',
  props<{ targetId: number; newValue: ContactInfoFields; avatarUrl?: string; avatarImages?: ImageSource[] }>(),
);

export const UploadContactAvatar = createAction(
  '[Profile] Upload Profile Avatar',
  props<{ targetId: number; newValue: any; oldValue: any }>(),
);

export const UploadContactAvatarSuccess = createAction(
  '[Profile] Upload Profile Avatar Success',
  props<{ targetId: number; newValue: any }>(),
);
export const UploadContactAvatarFail = createAction(
  '[Profile] Upload Profile Avatar Fail',
  props<{ targetId: number; oldValue: any; error: any }>(),
);

export const UploadContactAvatarPoll = createAction(
  '[Profile] Upload Profile Avatar Poll',
  props<{ targetId: number; mediaId: number; attempt?: number }>(),
);

export const SaveContactAvatarMedia = createAction(
  '[Profile] Save Profile Avatar Media',
  props<{ contactId: number; mediaId: number; authCode: string }>(),
);
export const SaveContactAvatarMediaSuccess = createAction(
  '[Profile] Save Profile Avatar Media Success',
  props<{ mediaId: number }>(),
);
export const SaveContactAvatarMediaFail = createAction(
  '[Profile] Save Profile Avatar Media Fail',
  props<{ contactId: number; mediaId: number; error: unknown }>(),
);

export const UpdateEmitterProfileContact = createAction(
  '[Profile] Emitter update contact',
  props<{ targetId: number; newValue: any; oldValue: any }>(),
);

export const LoadInsightData = createAction(
  '[Profile] Load Insight Data',
  props<{ insightType: string; before?: number }>(),
);
export const LoadInsightDataSuccess = createAction(
  '[Profile] Load Insight Data Success',
  props<{ data: InsightsValue; profileId: number; insightType?: string }>(),
);
export const LoadInsightDataFail = createAction('[Profile] Load Insight Data Fail', props<{ error: any }>());

export const LoadInsightHistory = createAction(
  '[Profile] Load Insight History',
  props<{ insightType: string; before?: number }>(),
);
export const LoadInsightHistorySuccess = createAction(
  '[Profile] Load Insight History Success',
  props<{ data: InsightsValue; profileId: number; insightType?: string }>(),
);
export const LoadInsightHistoryFail = createAction('[Profile] Load Insight History Fail', props<{ error: any }>());

export const LoadSubordinates = createAction(
  '[Profile] Load Subordinates',
  props<{ profileId: number; searchQuery: string; pageSize: number; pageNumber: number }>(),
);
export const LoadSubordinatesSuccess = createAction(
  '[Profile] Load Subordinates Success',
  props<{ profileId: number; data: SubordinatesPage }>(),
);
export const LoadSubordinatesFail = createAction(
  '[Profile] Load Subordinates Fail',
  props<{ profileId: number; error: HttpErrorResponse }>(),
);

export const UpdateProfileInsights = createAction('[Profile] Update Profile Insights', props<{ contactId: number }>());

export const ClearProfileMessageListMessages = createAction(
  '[Profile] Clear Profile MessageList Messages',
  props<{ profileId: number }>(),
);

export const LoadProfileMessageListData = createAction(
  '[Profile] Load Profile MessageList Data',
  props<{ profileId: number; list: MessageList; params?: MessageListRequestParams; appendMessages?: boolean }>(),
);
export const LoadProfileMessageListDataSuccess = createAction(
  '[Profile] Load Profile MessageList Data Success',
  props<{
    profileId: number;
    list: MessageList;
    data: MessageListData;
    params?: MessageListRequestParams;
    appendMessages?: boolean;
  }>(),
);
export const LoadProfileMessageListDataFail = createAction(
  '[Profile] Load Profile MessageList Data Fail',
  props<{ profileId: number; error: any }>(),
);
