import { NotificationSettingType } from '@ao/shared-data-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromNotifications from './notifications-store.reducer';

export const selectNotificationsState = createFeatureSelector<fromNotifications.NotificationsState>(
  fromNotifications.NOTIFICATIONS_FEATURE_KEY,
);
export const selectNotificationsEntities = createSelector(selectNotificationsState, (state) =>
  fromNotifications.notificationsAdapter.getSelectors().selectEntities(state),
);
export const selectAllNotifications = createSelector(selectNotificationsState, (state) =>
  fromNotifications.notificationsAdapter.getSelectors().selectAll(state),
);
export const selectActiveTab = createSelector(selectNotificationsState, (state) => state.activeTab);
export const selectHasUnread = createSelector(selectNotificationsState, (state) => state?.hasUnread);
export const selectFilterUnread = createSelector(selectNotificationsState, (state) => state.filterUnread);
export const selectUnreadNotifications = createSelector(selectAllNotifications, (state) => {
  return state.filter((notification) => !notification.read);
});
export const selectNotificationsLoadingState = createSelector(selectNotificationsState, (state) => state.loadingState);
export const selectNotificationsLoadedAll = createSelector(selectNotificationsState, (state) => state.loadedAll);
export const getNotificationById = (notificationId: number) =>
  createSelector(selectNotificationsEntities, (notificationEntities) => {
    const notification = notificationEntities?.[notificationId];
    return notification || null;
  });

export const selectUserSettingsGroups = createSelector(selectNotificationsState, (state) => {
  const withActive = state.settingsGroups.map((group) => {
    return {
      ...group,
      activeList: group.settings
        .map((s) => (s.value ? s.label : null))
        .filter((s) => !!s)
        .join(', '),
    };
  });
  return withActive;
});

export const selectUserSettingsGroupBySettingType = (settingType: NotificationSettingType) =>
  createSelector(selectUserSettingsGroups, (groups) => {
    return groups.find((g) => g.settingType === settingType);
  });
