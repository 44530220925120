import { inject, Injectable } from '@angular/core';
import {
  AcademyMessageSettings,
  AoTrackEvent,
  Contact,
  EmitterChannel,
  GATrackingData,
  Insights,
  InsightsSettings,
  InsightsValue,
  InterpolateParams,
  Language,
  MessageError,
  MessageListRequestParams,
  MessageNew,
  MessageQuizRetakeNotification,
  MessageState,
  MsgModule,
  MsgModuleButtonGroup,
  MsgModuleChart,
  MsgModuleConfirm,
  MsgModuleContactInfoViewer,
  MsgModuleDataPicker,
  MsgModuleFeedback,
  MsgModuleFeedbackItem,
  MsgModuleLike,
  MsgModuleMessageList,
  MsgModuleSmsVerify,
  MsgModuleSmsVerifyData,
  MsgModuleUpload,
  Page,
  Questionnaire,
  QuestionnaireValue,
  Sidebar,
  Theme,
  TrainingMessageSettings,
  TRANSLATION_STATUS,
} from '@ao/data-models';
import { interpolate, NativeBridgeService, onceWithLatest, RouterFacade, WINDOW } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { ViewerTaskFacade } from '@ao/viewer-task-management-store';
import { Action, select, Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  first,
  interval,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import * as appActions from './+state/actions';
import * as fromApp from './+state/reducers/app-store.state';
import * as appSelectors from './+state/selectors';
import { MessageQuizStatus } from './+state/selectors';

@Injectable({ providedIn: 'root' })
export class AppFacade {
  viewerCoreFacade = inject(ViewerCoreFacade);
  window = inject(WINDOW);
  routerFacade = inject(RouterFacade);
  nativeBridge = inject(NativeBridgeService);

  errorState$: Observable<MessageError> = this.store.pipe(select(appSelectors.selectErrorState));
  errorCode$ = this.store.select(appSelectors.selectErrorCode);
  errorLanguage$ = this.store.select(appSelectors.selectErrorLanguage);
  errorSupport$ = this.store.select(appSelectors.selectErrorSupport);
  errorTheme$ = this.store.select(appSelectors.selectErrorTheme);
  emitterChannels$: Observable<EmitterChannel[]> = this.store.pipe(select(appSelectors.selectEmitterChannels));

  // Insights
  insights$: Observable<Insights> = this.store.pipe(select(appSelectors.selectInsights));
  insightsSettings$: Observable<Record<string, InsightsSettings>> = this.store.pipe(
    select(appSelectors.selectInsightsSettings),
  );
  ratingSettings$: Observable<InsightsSettings> = this.store.pipe(select(appSelectors.selectRatingSettings));
  filteredInsightsData$: Observable<InsightsValue[]> = this.store.pipe(select(appSelectors.selectFilteredInsightsData));

  // Themes
  currentTheme$: Observable<Theme> = this.store.pipe(select(appSelectors.selectCurrentTheme));

  // Sidebars
  currentSidebar$: Observable<Sidebar> = this.store.pipe(select(appSelectors.selectCurrentSidebar));
  menuSideBarIsOpen$: Observable<boolean> = this.store.pipe(select(appSelectors.selectMenuSideBarIsOpen));
  socialSidebarIsOpen$: Observable<boolean> = this.store.pipe(select(appSelectors.selectSocialSidebarIsOpen));

  // Messages
  currentMessage$: Observable<MessageNew> = this.store.pipe(select(appSelectors.selectCurrentMessage));
  currentMessageIsSaved$: Observable<boolean> = this.store.pipe(select(appSelectors.selectCurrentMessageIsSaved));
  currentMessageId$: Observable<number> = this.store.pipe(select(appSelectors.selectCurrentMessageId));
  messageTranslationStatus$: Observable<TRANSLATION_STATUS> = this.store.pipe(
    select(appSelectors.selectCurrentAutoTranslationStatus),
  );
  isSpecialMessage$: Observable<boolean> = this.store.pipe(select(appSelectors.selectIsSpecialMessage));
  isTest$: Observable<boolean> = this.store.pipe(select(appSelectors.selectIsTest));
  private previewTopHeaderIsOpenedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  previewTopHeaderIsOpened$ = this.previewTopHeaderIsOpenedSubject$.asObservable();
  isAcademy$: Observable<boolean> = this.store.pipe(select(appSelectors.selectIsAcademy));
  currentMessageKeycode$: Observable<string> = this.store.pipe(select(appSelectors.selectCurrentMessageKeycode));
  currentMessagePages$: Observable<Page[]> = this.store.pipe(select(appSelectors.selectCurrentMessagePages));
  currentMessagePage$: Observable<Page> = this.store.pipe(select(appSelectors.selectCurrentMessagePage));
  currentMessagePageModules$: Observable<MsgModule[]> = this.store.pipe(
    select(appSelectors.selectCurrentMessagePageModules),
  );
  currentMessageModules$: Observable<MsgModule[]> = this.store.pipe(select(appSelectors.selectCurrentMessageModules));
  currentMessagePageVisibleModules$: Observable<MsgModule[]> = this.store.pipe(
    select(appSelectors.selectCurrentMessagePageVisibleModules),
  );
  currentMessagePageVisibleModulesLoaded$: Observable<boolean> = this.store.pipe(
    select(appSelectors.selectCurrentMessagePageVisibleModulesLoaded),
  );
  currentAcademyId$: Observable<number> = this.store.pipe(select(appSelectors.selectCurrentAcademyId));
  currentAcademy$: Observable<AcademyMessageSettings> = this.store.pipe(select(appSelectors.selectCurrentAcademy));
  commentsVisible$: Observable<boolean> = this.store.pipe(select(appSelectors.selectCommentsVisible));
  messageCommentsEnabled$: Observable<boolean> = this.store.pipe(select(appSelectors.selectMessageCommentsEnabled));
  messageReactionsEnabled$: Observable<boolean> = this.store.pipe(select(appSelectors.selectMessageReactionsEnabled));
  messageShareEnabled$: Observable<boolean> = this.store.pipe(select(appSelectors.selectMessageShareEnabled));
  messagePostId$: Observable<number> = this.store.pipe(select(appSelectors.getSelectedMessagePostId));
  currentMessageSubject$: Observable<string> = this.store.pipe(select(appSelectors.selectCurrentMessageSubject));
  disablePushNotifications$: Observable<boolean> = this.store.pipe(select(appSelectors.selectDisablePushNotifications));
  messageStatpack$: Observable<any> = this.store.pipe(select(appSelectors.selectMessageStatpack));
  messageClientId$: Observable<number> = this.store.pipe(select(appSelectors.selectMessageClientId));
  messageTraining$: Observable<TrainingMessageSettings> = this.store.pipe(select(appSelectors.selectMessageTraining));
  messageType$: Observable<string> = this.store.pipe(select(appSelectors.selectMessageType));
  messageAnonymous$: Observable<boolean> = this.store.pipe(select(appSelectors.selectMessageAnonymous));
  messageAuth$: Observable<any> = this.store.pipe(select(appSelectors.selectMessageAuth));
  viewerAuthEnabled$: Observable<boolean> = this.store.pipe(select(appSelectors.selectMessageViewerAuthEnabled));
  messageAddToHomepage$: Observable<number> = this.store.pipe(select(appSelectors.selectMessageAddToHomepage));
  messageQuestionnaire$: Observable<Questionnaire> = this.store.pipe(select(appSelectors.selectMessageQuestionnaire));
  messageQuestionnaireRetake$: Observable<boolean> = this.store.pipe(
    select(appSelectors.selectMessageQuestionnaireRetake),
  );
  messageQuestionnaireReady$: Observable<boolean> = this.store.pipe(
    select(appSelectors.selectMessageQuestionnaireReady),
  );
  messageInterpolationContext$: Observable<InterpolateParams> = this.store.pipe(
    select(appSelectors.selectMessageInterpolationContext),
  );
  messageContentLanguage$: Observable<string> = this.store.pipe(select(appSelectors.selectMessageContentLanguage));
  hasPagination$: Observable<boolean> = this.store.pipe(select(appSelectors.selectIfCurrentPageHasPagination));
  messagePagination$: Observable<number[]> = this.store.pipe(select(appSelectors.selectMessagePagination));
  messageQuizStatus$: Observable<MessageQuizStatus> = this.store.pipe(select(appSelectors.selectMessageQuizStatus));
  messageQuizDataRecent$ = this.store.pipe(select(appSelectors.selectMessageQuizDataMostRecent));
  messageQuizRetakeNotification$ = this.store.pipe(select(appSelectors.selectQuizRetakeNotification));
  messageCompletedSurveyNotification$: Observable<boolean> = this.store.pipe(
    select(appSelectors.selectCompletedSurveyNotification),
  );
  questionnaireIsSubmitted$: Observable<boolean> = this.store.pipe(
    select(appSelectors.selectIfCurrentPageQuestionnaireIsSubmitted),
  );
  currentPageLastSubmit$: Observable<boolean> = this.store.pipe(select(appSelectors.selectIfCurrentPageLastSubmit));
  currentPageAllQuestionsAnswered$: Observable<boolean> = this.store.pipe(
    select(appSelectors.selectIfCurrentPageAllQuestionsAnswered),
  );
  currentRatingIsEditable$: Observable<boolean> = this.store.pipe(select(appSelectors.selectIfCurrentRatingIsEditable));
  messageState$: Observable<MessageState> = this.store.pipe(select(appSelectors.selectMessageState));
  messageQuestionnaireLatestPageIndex$: Observable<number> = this.store.pipe(
    select(appSelectors.selectMessageQuestionnaireLatestPageIndex),
  );

  // Compositions
  showHeader$: Observable<boolean> = this.store.pipe(select(appSelectors.selectShowHeader));
  hasSidebar$: Observable<boolean> = this.store.pipe(select(appSelectors.selectHasSidebar));
  hasSidebarUnreads$: Observable<boolean> = this.store.pipe(select(appSelectors.selectHasSidebarUnreads));
  sidebarUnreadCounts$: Observable<number[][]> = this.store.pipe(select(appSelectors.selectSidebarUnreadCounts));
  forceExpandSidebar$: Observable<boolean> = this.store.pipe(select(appSelectors.selectForceExpandSidebar));
  profileIsShown$: Observable<boolean> = this.store.pipe(select(appSelectors.selectProfileIsShown));
  isBackButtonShown$: Observable<boolean> = this.store.pipe(select(appSelectors.selectIsBackButtonShown));
  isUnsubscribeUrlShown$: Observable<boolean> = this.store.pipe(select(appSelectors.selectIfUnsubscribeUrlIsShown));
  insightsIsShown$: Observable<boolean> = this.store.pipe(select(appSelectors.selectInsightsIsShown));
  analyticsTrackingData$: Observable<GATrackingData> = this.store.pipe(
    select(appSelectors.selectAnalyticsTrackingData),
  );
  showCompleteTrainingFooter$: Observable<boolean> = this.store.pipe(
    select(appSelectors.selectShowCompleteTrainingFooter),
  );

  //
  profileLink$ = combineLatest([
    this.profileIsShown$,
    this.viewerCoreFacade.origin$,
    this.viewerCoreFacade.keycode$,
    this.viewerCoreFacade.contact$,
  ]).pipe(
    map(([active, origin, keycode, contact]) => (active ? ['/', origin, keycode, 'profile', '' + contact.id] : null)),
  );

  // quiz banner
  showQuizRetakeNotification$: Observable<MessageQuizRetakeNotification> = combineLatest([
    this.messageQuizRetakeNotification$,
    this.routerFacade.routeDataPage$,
  ]).pipe(map(([notification, page]) => (page === 'MESSAGE' ? notification : null)));

  pendingRecurringMsgs$ = this.viewerCoreFacade.pendingRecurringMsgs$;

  getQuizRetakeCountdown = (destroy$: Subject<void>) => {
    return this.showQuizRetakeNotification$.pipe(
      switchMap((data) => {
        if (!data) {
          return of(null);
        }
        return interval(1000).pipe(
          startWith(-1),
          map(() => {
            const quizRetakeable = data.usedAttempts > 0 && Date.now() > data.retakeTime;
            const showQuizAttempts = quizRetakeable && data.remainAttempts > 0;
            const showRetakeQuiz = showQuizAttempts && data.isQuizCompleted;
            const showQuizHeader =
              !data.isQuizSuccessful &&
              (data.showFinishQuiz ||
                showRetakeQuiz ||
                showQuizAttempts ||
                (data.hasQuizRetakeCountdown && data.isQuizCompleted));
            return {
              ...data,
              showQuizAttempts,
              showRetakeQuiz,
              showQuizHeader,
            };
          }),
        );
      }),
      takeUntil(destroy$),
    );
  };

  navigateTo(path: unknown[]) {
    this.routerFacade.navigateTo(path);
  }

  dispatchGenericToastAction(action: Action, payload: any) {
    // this.genericToastService.dispatchGenericToastAction(action, payload);
  }

  finishQuiz() {
    onceWithLatest(
      this.viewerCoreFacade.pageId$,
      this.messageQuestionnaireLatestPageIndex$,
      (currentPage, pageIndex) => {
        // If not in the current page, switch to that page
        if (pageIndex !== currentPage - 1) {
          this.goToPage(pageIndex);
        } else {
          this.scrollToUnanswered();
        }
      },
    );
  }

  showCompletedSurveyNotification$ = combineLatest([
    this.messageCompletedSurveyNotification$,
    this.routerFacade.routeDataPage$,
  ]).pipe(map(([notification, page]) => (page === 'MESSAGE' ? notification : null)));

  // Auto translations
  autoTranslationLangs$: Observable<Language[]> = this.store.pipe(select(appSelectors.selectAutoTranslationLangs));
  showAutoTranslationModal$: Observable<boolean> = this.store.pipe(select(appSelectors.selectShowAutoTranslationModal));
  contactPreferredLanguage$: Observable<Language> = this.store.pipe(
    select(appSelectors.selectContactPreferredLanguage),
  );

  // Settings
  pushEnabledByClient$: Observable<boolean> = this.store.pipe(select(appSelectors.selectPushEnabledByClient));
  showUpdateContactInfoModal$: Observable<boolean> = this.store.pipe(
    select(appSelectors.selectUpdateContactInfoModalOpen),
  );

  messageByKeycode$ = (messageId: string) => this.store.select(appSelectors.getMessageByKeycode(messageId));
  moduleByModuleRef$ = (moduleRef: string): Observable<any> =>
    this.store.pipe(select(appSelectors.getModuleByModuleRef({ moduleRef })));
  messageModuleQuestionnaireDataByModule$ = (module: MsgModule): Observable<QuestionnaireValue> =>
    this.store.pipe(select(appSelectors.getMessageModuleQuestionnaireData, { module }));
  messageButtonGroupContextByModule$ = (module: MsgModuleButtonGroup): Observable<any> =>
    this.store.pipe(select(appSelectors.getMessageButtonGroupContextByModule, { module }));

  moduleFeedbackData$ = (module: MsgModuleFeedback): Observable<MsgModuleFeedback['data']> =>
    this.store.pipe(select(appSelectors.getModuleFeedbackData(module)));

  constructor(
    private store: Store<fromApp.AppPartialState>,
    // private genericToastService: GenericToastActionHandlerService,
  ) {}

  loadOneMessage(origin: string, keycode: string, queryParams: Record<string, string>): void {
    this.store.dispatch(appActions.LoadOneMessage({ origin, keycode, queryParams }));
  }

  trackEvent(event: AoTrackEvent) {
    this.store.dispatch(appActions.TrackEvent({ event }));
  }

  trackBulkEvents(events: AoTrackEvent[]) {
    this.store.dispatch(appActions.TrackBulkEvents({ events }));
  }

  loadChartData(module: MsgModuleChart) {
    this.store.dispatch(appActions.LoadChartData({ module }));
  }

  updateQuestionnaireValue({
    module,
    value,
    errors,
    confirmed,
    keycode,
    optional,
  }: {
    module: MsgModule;
    value: any;
    errors?: { [key: string]: true };
    confirmed?: true;
    keycode: string;
    optional?: boolean;
  }) {
    this.store.dispatch(
      appActions.UpdateQuestionnaireValue({
        module,
        value,
        errors,
        confirmed,
        keycode,
        optional,
      }),
    );
  }

  feedbackUpdate({ module, comment }: { module: MsgModuleFeedback; comment: MsgModuleFeedbackItem }) {
    this.store.dispatch(appActions.FeedbackUpdate({ module, comment }));
  }

  likeUpdate({ module, contact }: { module: MsgModuleLike; contact: Contact }) {
    this.store.dispatch(appActions.LikeUpdate({ module, contact }));
  }

  submitQuestionnaire() {
    this.store.dispatch(appActions.SubmitQuestionnaire());
  }

  scrollToUnanswered() {
    this.store.dispatch(appActions.ScrollToUnanswered());
  }

  completeQuizQuestion({ module, value, keycode }: { module: MsgModule; value: any; keycode: string }) {
    this.store.dispatch(appActions.CompleteQuizQuestion({ module, value, keycode }));
  }

  retakeQuiz() {
    this.store.dispatch(appActions.RetakeQuiz());
  }

  uploadRemoveFile({ module, fileId }: { module: MsgModuleUpload; fileId: number }) {
    this.store.dispatch(appActions.UploadRemoveFile({ module, fileId }));
  }

  uploadChangeTitle({ module, fileId, title }: { module: MsgModuleUpload; fileId: number; title: string }) {
    this.store.dispatch(appActions.UploadChangeTitle({ module, fileId, title }));
  }

  uploadFile({ module, file }: { module: MsgModuleUpload; file: File }) {
    this.store.dispatch(appActions.UploadFile({ module, file }));
  }

  like(module: MsgModuleLike, keycode: string, contact: Contact) {
    this.store.dispatch(appActions.Like({ module, keycode, contact }));
  }

  smsVerifySubmit({ module, data }: { module: MsgModuleSmsVerify; data: MsgModuleSmsVerifyData }) {
    this.store.dispatch(appActions.SmsVerifySubmit({ module, data }));
  }

  smsVerifyReset({ module }: { module: MsgModuleSmsVerify }) {
    this.store.dispatch(appActions.SmsVerifyReset({ module }));
  }

  startQuestionnaireRetake(keycode: string) {
    this.store.dispatch(appActions.StartQuestionnaireRetake({ keycode }));
  }

  goToPreviousPage() {
    this.store.dispatch(appActions.GoToPreviousPage());
  }

  goToNextPage() {
    this.store.dispatch(appActions.GoToNextPage());
  }

  goToPage(pageIndex: number) {
    this.store.dispatch(appActions.GoToPage({ pageIndex }));
  }

  loadMessageListData({
    module,
    params,
    appendMessages,
    debounce,
  }: {
    module: MsgModuleMessageList;
    params?: MessageListRequestParams;
    appendMessages?: boolean;
    debounce?: number;
  }) {
    this.store.dispatch(
      appActions.LoadMessageListData({
        module,
        params,
        appendMessages,
        debounce,
      }),
    );
  }

  searchStarted() {
    this.store.dispatch(appActions.SearchStarted({ searchContext: 'feed' }));
  }

  clearMessageListMessages({
    module,
    params,
    appendMessages,
  }: {
    module: MsgModuleMessageList;
    params?: MessageListRequestParams;
    appendMessages?: boolean;
  }) {
    this.store.dispatch(appActions.ClearMessageListMessages({ module, params, appendMessages }));
  }

  retakeAndRedirectToQuizMessage({
    messageId,
    queryParams,
  }: {
    messageId: number;
    queryParams?: Record<string, string>;
  }) {
    this.store.dispatch(appActions.RetakeAndRedirectToQuizMessage({ messageId, queryParams }));
  }

  redirectToMessage({
    messageId,
    queryParams,
    extraState,
  }: {
    messageId: number;
    queryParams?: Record<string, string>;
    extraState?: Record<string, string>;
  }) {
    this.store.dispatch(appActions.RedirectToMessage({ messageId, queryParams, extraState }));
  }

  redirectToMessageComment({
    messageId,
    messagePage,
    commentId,
  }: {
    messageId: number;
    messagePage: number;
    commentId: number;
  }) {
    this.store.dispatch(appActions.RedirectToMessageComment({ messageId, messagePage, commentId }));
  }

  redirectToInsight({ insight }: { insight: string }) {
    this.store.dispatch(appActions.RedirectToInsight({ insight }));
  }

  redirectToTaskManagement(taskId?: number) {
    this.store.dispatch(appActions.RedirectToTaskManagement({ taskId }));
  }

  redirectToAcademy({
    academyId,
    queryParams,
    openNewWindow,
  }: {
    academyId: number;
    queryParams?: Record<string, string>;
    openNewWindow?: boolean;
  }) {
    this.store.dispatch(appActions.RedirectToAcademy({ academyId, queryParams, openNewWindow }));
  }

  loadFeedback(module: MsgModuleFeedback) {
    this.store.dispatch(appActions.LoadFeedback({ module }));
  }

  feedbackSubmit({ module, content }: { module: MsgModuleFeedback; content: string }) {
    this.store.dispatch(appActions.FeedbackSubmit({ module, content }));
  }

  dataPickerDataSelected({ module, data }: { module: MsgModuleDataPicker; data: Record<string, string> }) {
    this.store.dispatch(appActions.DataPickerDataSelected({ module, data }));
  }

  contactInfoData({
    module,
    result,
  }: {
    module: MsgModuleContactInfoViewer;
    result: {
      fields?: any[];
      data?: any;
      field_info?: any;
      optionsData?: any[];
      preparedFields?: boolean;
    };
  }) {
    this.store.dispatch(appActions.ContactInfoData({ module, result }));
  }

  contactInfoSubmit({
    module,
    contactData,
    messageData,
  }: {
    module: MsgModuleContactInfoViewer;
    contactData: {
      data: any;
      optionsData: any[];
      checkedOptions: Set<number>;
      contactId: number;
    };
    messageData: { messageId: number; origin: string };
  }) {
    this.store.dispatch(appActions.ContactInfoSubmit({ module, contactData, messageData }));
  }

  confirm(module: MsgModuleConfirm) {
    this.store.dispatch(appActions.Confirm({ module }));
  }

  messagePageChanged(pageId: number) {
    this.store.dispatch(appActions.MessagePageChanged({ pageId }));
  }

  toggleAutoTranslationModal() {
    this.store.dispatch(appActions.ToggleAutoTranslationModal());
  }

  loadAutoTranslationLangs() {
    this.store.dispatch(appActions.LoadAutoTranslationLangs());
  }

  loadMessageTranslation(messageId: number) {
    this.store.dispatch(appActions.LoadMessageTranslation({ messageId }));
  }

  revertMessageTranslation() {
    this.store.dispatch(appActions.RevertMessageTranslation());
  }

  toggleMenuSidebar(isOpen: boolean) {
    this.store.dispatch(appActions.ToggleMenuSidebar({ isOpen }));
  }

  toggleSocialSidebar(isOpen: boolean) {
    this.store.dispatch(appActions.ToggleSocialSidebar({ isOpen }));
  }

  toggleUpdateContactInfoModal(isOpen: boolean) {
    this.store.dispatch(appActions.ToggleUpdateContactInfoModal({ isOpen }));
  }
  closePreviewTopHeaderIsOpened() {
    this.previewTopHeaderIsOpenedSubject$.next(false);
  }

  nativeAppDownloadFile(fileUrl: string) {
    combineLatest([
      this.viewerCoreFacade.featureFlagByKey$('allow_insecure_links'),
      this.messageInterpolationContext$,
      this.messageContentLanguage$,
    ])
      .pipe(first())
      .subscribe(([allowInsecure, interpolationContext, contentLanguage]) => {
        const param = interpolate(fileUrl, interpolationContext, contentLanguage);
        this.nativeBridge.downloadFile(param, !!allowInsecure);
      });
  }

  // region tasks feature
  private viewerTaskFacade = inject(ViewerTaskFacade);

  newTasksCount$ = this.viewerTaskFacade.newTasksCount$;
  firstCheckForTasksCount = false;

  showTasksInNavBar$ = combineLatest([
    this.viewerCoreFacade.taskManagementEnabled$,
    this.viewerCoreFacade.viewerSettings$,
    this.isTest$,
  ]).pipe(
    map(([enabled, viewerSettings, isTest]) => {
      // On preview mode, we always show the task icon if the feature is enabled
      const hasTasks = isTest ? true : viewerSettings?.hasTasks;
      const showTasks = hasTasks && enabled;
      if (viewerSettings?.hasTasks && enabled && !isTest && !this.firstCheckForTasksCount) {
        this.firstCheckForTasksCount = true;
        this.viewerTaskFacade.getNewTaskCount();
      }
      return showTasks;
    }),
  );
  tasksLink$ = combineLatest([
    this.showTasksInNavBar$,
    this.viewerCoreFacade.keycode$,
    this.viewerCoreFacade.origin$,
  ]).pipe(
    map(([taskFFActive, keycode, origin]) => {
      return taskFFActive ? ['/', origin, keycode, 'task'] : null;
    }),
  );

  // endregion
}
