import { Page } from '@ao/data-models';
import { getModuleRef } from '@ao/utilities';
import { createSelector } from '@ngrx/store';
import {
  selectCurrentMessagePages,
  selectMessageQuestionnaire,
  selectMessageQuestionnaireRetake,
  selectMessageQuizData,
  selectMessageQuizRetakeInterval,
  selectMessageQuizRetakeMax,
  selectMessageState,
  selectMessageTraining,
  selectMessageType,
  selectModuleEntities,
} from './app-store.selectors';
import { selectIfCurrentPageQuestionnaireIsSubmitted, selectMessageQuizQuestions } from './questionnaire.selectors';
import {
  isQuizQuestionAnswered,
  isQuizQuestionCorrect,
  pointsEarnedInQuizQuestion,
  pointsPossibleInQuizQuestion,
} from './utils';

export const selectIfCurrentRatingIsEditable = createSelector(
  selectMessageQuestionnaireRetake,
  selectMessageType,
  selectIfCurrentPageQuestionnaireIsSubmitted,
  (questionnaireRetake, messageType, questionnaireIsSubmitted) => {
    return !questionnaireRetake && messageType === 'rating' && questionnaireIsSubmitted;
  },
);

export const selectQuizRetakeNotification = createSelector(
  selectMessageQuizQuestions,
  selectMessageQuizRetakeMax,
  selectMessageQuizRetakeInterval,
  selectMessageQuestionnaire,
  selectMessageQuizData,
  selectMessageState,
  selectMessageTraining,
  (questions, maxAttempts, retakeInterval, questionnaire, quizData, messageState, trainingSettings) => {
    const questionsTotal = questions.length;
    if (questionsTotal === 0 || !quizData) {
      return null;
    }
    const answeredQuestions = questions.filter((m) => isQuizQuestionAnswered(m, questionnaire[getModuleRef(m)])).length;
    // note: .mostRecent is not updated during the session, only on initial load (describes the most previously passed attempt).
    // the only exception is updated_at for calculating the retake interval
    const usedAttempts =
      trainingSettings?.attempts ||
      quizData.mostRecent?.attempt_number ||
      (answeredQuestions >= questionsTotal ? 1 : 0) ||
      0;
    // -1 is unlimited retakes, set to 999 as an easy work-around
    const remainAttempts = maxAttempts === -1 ? 999 : Math.max(maxAttempts - usedAttempts, 0);
    const questionsCorrect = questions.filter((m) => isQuizQuestionCorrect(m, questionnaire[getModuleRef(m)])).length;
    const pointsPossible = questions.reduce((sum, m) => sum + pointsPossibleInQuizQuestion(m), 0);
    const pointsEarned = questions.reduce(
      (sum, m) => sum + pointsEarnedInQuizQuestion(m, questionnaire[getModuleRef(m)]),
      0,
    );
    let isQuizCompleted = false;
    let isQuizStarted = false;
    let isQuizSuccessful = false;
    let hasQuizRetakeCountdown = false;
    let retakeTime = 0;
    if (questionsTotal && retakeInterval) {
      isQuizCompleted = answeredQuestions >= questionsTotal;
      isQuizStarted = answeredQuestions > 0;
      if (isQuizCompleted) {
        isQuizSuccessful = trainingSettings
          ? trainingSettings.academyTarget <= questionsCorrect / answeredQuestions
          : questionsCorrect >= answeredQuestions;
      }
    }

    // note: trainingSettings exists only when opened from within an academy
    if (
      (questionsTotal && retakeInterval && remainAttempts && quizData.mostRecent?.updated_at) ||
      trainingSettings?.date
    ) {
      /*
       BUGS-2334:
       note: added "quizData.mostRecent?.updated_at) || trainingSettings?.date" to above if statement
       this is to mitigate a bug where quiz_result table is not updated/in sync with messateState in backend, making it impossible to retake a quiz
       because the retakeTime is not updated correctly, and will alwasy starte at refresh-time when reloading because quizData.mostRecent?.updated_at is null
       - 
       The trainingSettings?.date is there not to change the flow for training...
      */
      hasQuizRetakeCountdown = Boolean(messageState && Object.keys(messageState).length);
      const now = new Date();
      retakeTime =
        new Date(quizData.mostRecent?.updated_at || trainingSettings?.date || now).getTime() + retakeInterval * 1000;
    }
    const showFinishQuiz = !isQuizCompleted && isQuizStarted;

    return {
      isQuizCompleted,
      showFinishQuiz,
      usedAttempts,
      isQuizStarted,
      isQuizSuccessful,
      retakeTime,
      hasQuizRetakeCountdown,
      context: {
        questionsTotal: 'number:' + questionsTotal,
        answeredQuestions: 'number:' + answeredQuestions,
        questionsCorrect: 'number:' + questionsCorrect,
        pointsPossible: 'number:' + pointsPossible,
        pointsEarned: 'number:' + pointsEarned,
        quizzesAttempted: 'number:' + quizData.totals?.quizzes_attempted,
        quizzesCompleted: 'number:' + quizData.totals?.quizzes_completed,
        quizzesCorrect: 'number:' + quizData.totals?.quizzes_correct,
        quizzesCorrectPercent: 'number:' + Math.round(quizData.totals?.quizzes_correct_percent),
      },
      remainAttempts,
    };
  },
);

export const selectCompletedSurveyNotification = createSelector(
  selectMessageType,
  selectCurrentMessagePages,
  selectModuleEntities,
  selectMessageState,
  (messageType: string, pages: Page[], moduleEntities, messageState) => {
    if (messageType === 'survey') {
      return !pages.some((p) => {
        return p.modules
          .map((moduleRef) => moduleEntities[moduleRef])
          .some(
            (m) =>
              m.type === 'buttongroup' &&
              m.mode === 'autogenerated_submit' &&
              !messageState?.[`buttongroup.${m.id}.click`],
          );
      });
    }
    return false;
  },
);
