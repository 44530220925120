import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { getKeyToKeyObj, selectableRecipientFieldsObj } from './common';

export type SocialWallReportParams = {
  workspaceId?: number;
  fields: {
    companyGroups:
      | (keyof typeof multipleSocialWallGroupsFieldsObj)[]
      | (keyof typeof singleSocialWallGroupsFieldsObj)[];
    privateGroups:
      | (keyof typeof multipleSocialWallPrivateGroupsFieldsObj)[]
      | (keyof typeof singleSocialWallPrivateGroupsFieldsObj)[];
    contacts: (keyof typeof contactsFieldsObj)[];
  };
  interval: {
    from: string;
    to: string;
  };
  timezone: string;
};

export interface SocialWallExportColumns {
  id: keyof SocialWallReportParams['fields'];
  label: string;
  checked: boolean;
  expanded: boolean;
  disabled?: boolean;
  columns: Array<{ id: string; name: string; checked: boolean }>;
}

export const multipleSocialWallGroupsFieldsObj = {
  activity_id: i18n('Activity ID'),
  activity_type: i18n('Activity type'),
  timestamp: i18n('Timestamp'),
  parent_id: i18n('Parent ID'),
  post_id: i18n('Post ID'),
  contact_id: i18n('Contact ID'),
  workspace: i18n('Workspace'),
  group_id: i18n('Group ID'),
  group_name: i18n('Group name'),
} as const;
export const singleSocialWallGroupsFieldsObj = {
  activity_id: i18n('Activity ID'),
  activity_type: i18n('Activity type'),
  timestamp: i18n('Timestamp'),
  parent_id: i18n('Parent ID'),
  post_id: i18n('Post ID'),
  contact_id: i18n('Contact ID'),
  group_id: i18n('Group ID'),
  group_name: i18n('Group name'),
} as const;
export const multipleSocialWallPrivateGroupsFieldsObj = {
  activity_id: i18n('Activity ID'),
  activity_type: i18n('Activity type'),
  timestamp: i18n('Timestamp'),
  parent_id: i18n('Parent ID'),
  post_id: i18n('Post ID'),
} as const;
export const singleSocialWallPrivateGroupsFieldsObj = {
  activity_id: i18n('Activity ID'),
  activity_type: i18n('Activity type'),
  timestamp: i18n('Timestamp'),
  parent_id: i18n('Parent ID'),
  post_id: i18n('Post ID'),
} as const;
export const contactsFieldsObj = {
  ...selectableRecipientFieldsObj,
} as const;

export const multipleSocialWallGroupsObjKeys = getKeyToKeyObj(multipleSocialWallGroupsFieldsObj) as Record<
  keyof typeof multipleSocialWallGroupsFieldsObj,
  keyof typeof multipleSocialWallGroupsFieldsObj
>;
export const singleSocialWallGroupsObjKeys = getKeyToKeyObj(singleSocialWallGroupsFieldsObj) as Record<
  keyof typeof singleSocialWallGroupsFieldsObj,
  keyof typeof singleSocialWallGroupsFieldsObj
>;
export const multipleSocialWallPrivateGroupsObjKeys = getKeyToKeyObj(
  multipleSocialWallPrivateGroupsFieldsObj,
) as Record<
  keyof typeof multipleSocialWallPrivateGroupsFieldsObj,
  keyof typeof multipleSocialWallPrivateGroupsFieldsObj
>;
export const singleSocialWallPrivateGroupsObjKeys = getKeyToKeyObj(singleSocialWallPrivateGroupsFieldsObj) as Record<
  keyof typeof singleSocialWallPrivateGroupsFieldsObj,
  keyof typeof singleSocialWallPrivateGroupsFieldsObj
>;
export const contactsObjKeys = getKeyToKeyObj(contactsFieldsObj) as Record<
  keyof typeof contactsFieldsObj,
  keyof typeof contactsFieldsObj
>;

const checkedSelectableContact = {
  [contactsObjKeys.id]: true,
  [contactsObjKeys.name]: true,
};

// MULTIPLE
const multipleCheckedSelectableSocialWallGroup = {
  [multipleSocialWallGroupsObjKeys.activity_id]: true,
  [multipleSocialWallGroupsObjKeys.activity_type]: true,
  [multipleSocialWallGroupsObjKeys.timestamp]: true,
  [multipleSocialWallGroupsObjKeys.parent_id]: true,
  [multipleSocialWallGroupsObjKeys.post_id]: true,
  [multipleSocialWallGroupsObjKeys.contact_id]: true,
  [multipleSocialWallGroupsObjKeys.workspace]: true,
};
// SINGLE
const singleCheckedSelectableSocialWallGroup = {
  [multipleSocialWallGroupsObjKeys.activity_id]: true,
  [multipleSocialWallGroupsObjKeys.activity_type]: true,
  [multipleSocialWallGroupsObjKeys.timestamp]: true,
  [multipleSocialWallGroupsObjKeys.parent_id]: true,
  [multipleSocialWallGroupsObjKeys.post_id]: true,
  [multipleSocialWallGroupsObjKeys.contact_id]: true,
};

// MULTIPLE
const multipleSocialWallGroup: SocialWallExportColumns = {
  id: 'companyGroups',
  label: i18n('Company groups data'),
  checked: false,
  expanded: true,
  columns: Object.keys(multipleSocialWallGroupsObjKeys).map((key) => ({
    id: key,
    name: multipleSocialWallGroupsFieldsObj[key],
    checked: !!multipleCheckedSelectableSocialWallGroup[key],
  })),
};
// SINGLE
const singleSocialWallGroup: SocialWallExportColumns = {
  id: 'companyGroups',
  label: i18n('Company groups data'),
  checked: false,
  expanded: true,
  columns: Object.keys(singleSocialWallGroupsObjKeys).map((key) => ({
    id: key,
    name: singleSocialWallGroupsFieldsObj[key],
    checked: !!singleCheckedSelectableSocialWallGroup[key],
  })),
};

// MULTIPLE
const multipleSocialWallPrivateGroup: SocialWallExportColumns = {
  id: 'privateGroups',
  label: i18n('Private groups data'),
  checked: false,
  expanded: true,
  columns: Object.keys(multipleSocialWallPrivateGroupsObjKeys).map((key) => ({
    id: key,
    name: multipleSocialWallGroupsFieldsObj[key],
    checked: false,
  })),
};
// SINGLE
const singleSocialWallPrivateGroup: SocialWallExportColumns = {
  id: 'privateGroups',
  label: i18n('Private groups data'),
  checked: false,
  expanded: false,
  disabled: true,
  columns: Object.keys(singleSocialWallPrivateGroupsObjKeys).map((key) => ({
    id: key,
    name: multipleSocialWallGroupsFieldsObj[key],
    checked: false,
  })),
};

const contacts: SocialWallExportColumns = {
  id: 'contacts',
  label: i18n('Contacts data'),
  checked: false,
  expanded: true,
  columns: Object.keys(contactsObjKeys).map((key) => ({
    id: key,
    name: contactsFieldsObj[key],
    checked: !!checkedSelectableContact[key],
  })),
};

export const exportMultipleColumnSections: SocialWallExportColumns[] = [
  multipleSocialWallGroup,
  multipleSocialWallPrivateGroup,
  contacts,
];
export const exportSingleColumnSections: SocialWallExportColumns[] = [
  singleSocialWallGroup,
  singleSocialWallPrivateGroup,
  contacts,
];

export interface GetCommentOptions {
  interval?: {
    from: string | null;
    to: string | null;
  };
  fields?: {
    comments: string[];
  };
}
