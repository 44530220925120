import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, ViewContainerRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SocialContact } from '@ao/data-models';
import { BrowserService } from '@ao/utilities';
import { combineLatest, merge, Observable, Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { PeerViewComponent } from '../../components/peer-view/peer-view.component';

@Injectable()
export class PeerViewService implements OnDestroy {
  contactSubscription: Subscription;
  _overlayRef: OverlayRef;
  private close$ = new Subject<void>();

  get mode() {
    return this.browser.isMobileOS() ? 'MOBILE' : 'DESKTOP';
  }

  constructor(
    private router: Router,
    private overlay: Overlay,
    private browser: BrowserService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnDestroy() {
    this.close$.next();
    this.close$.complete();
    this.close();
  }

  open(
    originKeycode: [string, string],
    contact$: Observable<SocialContact>,
    showChat$: Observable<boolean>,
    viewContainerRef?: ViewContainerRef,
    options: { hasRemoveButton?: boolean } = {},
  ) {
    const { hasRemoveButton } = {
      hasRemoveButton: false,
      ...options,
    };
    const portal = new ComponentPortal(PeerViewComponent, viewContainerRef);

    const config = new OverlayConfig();
    config.width = '432px';
    config.height = '80vh';
    config.positionStrategy = this.overlay.position().global().bottom().centerHorizontally();
    config.scrollStrategy = this.overlay.scrollStrategies.block();
    config.scrollStrategy.enable();
    config.hasBackdrop = true;

    this._overlayRef = this.overlay.create(config);
    let instance: PeerViewComponent;
    const result = new Subject<string>();

    combineLatest([contact$, showChat$])
      .pipe(takeUntil(this.close$))
      .subscribe(([contact, showChat]) => {
        if (!instance) {
          const ref = this._overlayRef.attach(portal);
          instance = ref.instance;
          instance.mode = this.mode;
          instance.originKeycode = originKeycode;
          instance.hasRemoveButton = hasRemoveButton;
          instance.showChat = showChat;

          this.document.body.classList.add('ao-peer-view--open');
          if (instance.hasRemoveButton) {
            instance.remove.pipe(takeUntil(this.close$)).subscribe(() => {
              result.next('REMOVE');
              result.complete();
              this.close();
            });
          }
          merge(
            this._overlayRef.backdropClick(),
            this.router.events.pipe(first((e) => e instanceof NavigationStart)),
            instance.dismiss,
          )
            .pipe(takeUntil(this.close$))
            .subscribe(() => {
              result.next('DISMISS');
              result.complete();
              this.close();
            });
        }

        instance.contact = contact;
      });
    return result.asObservable();
  }

  close() {
    this.document.body.classList.remove('ao-peer-view--open');
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }
  }
}
