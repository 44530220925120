import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ao-notification-list-skeleton',
  templateUrl: './notification-list-skeleton.component.html',
  styleUrls: ['./notification-list-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListSkeletonComponent {
  @HostBinding('class.ao-notification-list') className = true;
}
