<div class="viewer-redirect__wrapper ao-font-max-medium">
  <div class="viewer-redirect__canvas">
    <div class="viewer-redirect__content">
      <div *ngIf="!_clicked && _openNewWindow" class="viewer-redirect__button" id="myButton" (click)="openMessage()">
        <img src="/assets/tap_icon.png" />
        <h1 class="ao-font-body2-bold" translate>Please click the button to open the login window</h1>
        <button class="ao-font-body2-bold" translate>Go to login</button>
      </div>
      <div *ngIf="_clicked">
        <img src="/assets/icon_redirect.png" />
        <h1 class="ao-font-body2" translate>You have been sent to the login page</h1>
      </div>
    </div>
  </div>
</div>
