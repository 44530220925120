<ao-messagelist-item
  [item]="mappedItem()"
  [title]="mappedItem().titlePreview ?? mappedItem().title"
  [description]="mappedItem().oneLiner"
  [media]="mappedItem().mediaPreview ?? null"
  (clicked)="onClick(mappedItem().id)"
  [categoryLimit]="2"
  [themedContent]="false"
  [hasBoxShadow]="false"
></ao-messagelist-item>
