import { Pipe, PipeTransform, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'replaceTemplate',
})
export class ReplaceTemplatePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html = '', ...args: Array<string | TemplateRef<any>>): any {
    const replacements: [string, TemplateRef<any>][] = [];
    for (let i = 0; i < args.length; i += 2) {
      replacements.push([<string>args[i], <TemplateRef<any>>args[i + 1]]);
    }
    for (const [text, template] of replacements) {
      const view = template.createEmbeddedView({});
      view.detectChanges();
      html = html.replace(text, view.rootNodes.map((node) => node.outerHTML).join(''));
      view.detach();
      view.destroy();
    }
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
