<h3 class="ao-font-title4 ao-support-modal__title">
  {{ supportSettings?.name ? supportSettings.name : ('Contact support' | translate) }}
</h3>

<ao-generic-list-item
  *ngIf="supportSettings?.phone?.country_code && supportSettings?.phone?.phone_number"
  class="ao-support-modal__item"
  [listItemType]="'icon'"
  [iconName]="'phone'"
>
  <a
    class="ao-font-body2-bold ao-support-modal__number"
    [href]="'tel:' + supportSettings.phone.country_code + supportSettings.phone.phone_number"
  >
    {{ supportSettings.phone.country_code }}{{ supportSettings.phone.phone_number }}
  </a>
  <ng-template aoListItemSubTitleTemplate>
    <span translate>Phone number</span>
  </ng-template>
</ao-generic-list-item>

<ao-generic-list-item
  *ngIf="supportSettings?.email"
  class="ao-support-modal__item"
  [listItemType]="'icon'"
  [iconName]="'email'"
>
  <a class="ao-support-modal__link" [href]="'mailto:' + supportSettings.email">{{ supportSettings.email }}</a>
  <ng-template aoListItemSubTitleTemplate>
    <span translate>Email</span>
  </ng-template>
</ao-generic-list-item>

<ao-generic-list-item
  *ngIf="supportSettings?.url"
  class="ao-support-modal__item"
  [listItemType]="'icon'"
  [iconName]="'link'"
>
  <a class="ao-support-modal__link" [href]="supportUrl" [target]="openSupportLinkInNewTab ? '_blank' : '_self'">{{
    supportSettings.url
  }}</a>
  <ng-template aoListItemSubTitleTemplate>
    <span translate>Website</span>
  </ng-template>
</ao-generic-list-item>

<button
  type="button"
  ao-button
  class="ao-support-modal__button"
  [outline]="true"
  buttonSize="medium"
  [buttonColor]="loginTheme ? null : 'blue'"
  (click)="close.emit()"
  translate
>
  Close
</button>
