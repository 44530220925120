<ng-container *ngIf="errorMessage">
  <div class="viewer-error-page__shell">
    <!-- header -->
    <div class="viewer-error-page__header" *ngIf="theme">
      <img *ngIf="theme.headerLogo" class="viewer-error-page__header-logo" [src]="theme.headerLogo" />
    </div>

    <!-- content / center aligned block -->
    <div class="viewer-error-page__pages">
      <div class="viewer-error-page__page">
        <div class="viewer-error-page__wrapper">
          <div class="viewer-error-page__top-spacing"></div>
          <div class="viewer-error-page__container">
            <div class="viewer-error-page__content">
              <ao-generic-viewer-error
                [title]="errorMessage.header | translate"
                [subTitle]="errorMessage.subHeader"
                [text]="errorMessage.text | translate"
                [errorType]="errorMessage.errorType || 'error'"
                [homeButton]="errorCode !== 'NO_HOMEPAGE'"
                [errorCode]="errorCode"
                [domain]="domain"
                [destination]="destination"
                [supportName]="support?.name || null"
                (openSupport)="toggleSupportModal()"
                (logout)="logout.emit()"
              >
              </ao-generic-viewer-error>
            </div>
          </div>
          <div class="viewer-error-page__bottom-spacing"></div>
        </div>
      </div>
    </div>

    <!-- Support modal -->
    <ng-container *ngIf="support">
      <ao-message-modal-card
        [open]="supportModalOpen"
        [loginPage]="true"
        [autoWidth]="true"
        maxWidth="400px"
        (close)="toggleSupportModal()"
      >
        <ao-support-modal [loginTheme]="true" [supportSettings]="support" (close)="toggleSupportModal()">
        </ao-support-modal>
      </ao-message-modal-card>
    </ng-container>
  </div>
</ng-container>
