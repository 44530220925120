import { QuillDelta } from '@ao/data-models';
import * as linkifyIt from 'linkify-it';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

export function isQuillEditorEmpty(value: any) {
  if (!value || (value instanceof Object && Object.keys(value).length === 0)) {
    return true;
  }
  if (value.ops.length === 1 && typeof value.ops[0].insert === 'string' && value.ops[0].insert.trim().length === 0) {
    return true;
  }
  return false;
}

const urlMatcher = linkifyIt();

export function getQuillConverterWithLinkify(model: QuillDelta | string, options = {}) {
  if (!model) {
    return;
  }
  if (typeof model === 'string') {
    try {
      model = JSON.parse(model) as QuillDelta;
    } catch (e) {
      model = { ops: [{ insert: model }] } as QuillDelta;
    }
  }

  const ops = model.ops?.length
    ? [].concat(
        ...model.ops.map((op) => {
          const insert = op.insert;
          if (typeof insert !== 'string') {
            return [op];
          }
          const matches = urlMatcher.match(insert);
          const length = insert.length;

          // automatic link detection from plain text
          if (matches) {
            const parts = [{ start: 0, end: matches[0].index, match: null }];
            matches.forEach((match, i) => {
              parts.push(
                { start: match.index, end: match.lastIndex, match },
                {
                  start: match.lastIndex,
                  end: i === matches.length - 1 ? length : matches[i + 1].index,
                  match: null,
                },
              );
            });
            return parts
              .filter((p) => p.end - p.start > 0)
              .map((p) => {
                if (p.match) {
                  return {
                    insert: insert.slice(p.start, p.end),
                    attributes: {
                      ...op.attributes,
                      link: p.match.url,
                      linkTarget: '_blank',
                    },
                  };
                } else {
                  return {
                    insert: insert.slice(p.start, p.end),
                    attributes: op.attributes,
                  };
                }
              });
            // user-edited links need to match with custom target options in QuillExtendService
          } else if (op.attributes?.link) {
            let link = op.attributes?.link;
            let target = '_blank';
            if (typeof op.attributes.link !== 'string') {
              link = op.attributes.link.url;
              target = op.attributes.link.target || 'blank';
            }
            return [{ ...op, attributes: { ...op.attributes, link, target } }];
          }
          return [op];
        }),
      )
    : [];

  const converter = new QuillDeltaToHtmlConverter(ops, options);
  return converter;
}
