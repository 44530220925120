import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  input,
  Output,
} from '@angular/core';
import { CommonUiModule } from '@ao/common-ui';
import { MessageListMessage, SearchResultItem } from '@ao/data-models';
import { SearchFacade } from '../../store/search-facade';
@Component({
  selector: 'ao-search-result',
  styleUrl: './search-result.component.scss',
  templateUrl: './search-result.component.html',
  standalone: true,
  imports: [CommonModule, CommonUiModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultComponent {
  private searchFacade = inject(SearchFacade);

  @Input() query = '';
  @Output() itemClicked = new EventEmitter<number>();

  item = input.required<SearchResultItem>();

  mappedItem = computed<MessageListMessage>(() => {
    const itemValue = this.item();
    return this.searchFacade.mapSearchResultItemToMessageListMessage(itemValue, this.query);
  });

  onClick(id: number) {
    this.itemClicked.emit(id);
  }
}
