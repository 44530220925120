<div class="ao-sso-login__top-spacing"></div>
<div class="ao-sso-login__content">
  <ao-generic-page-header
    [type]="'text'"
    [title]="'Log in to continue' | translate"
    [subTitle]="'Welcome to #companyName#' | translate: { companyName: loginSettings.appName }"
  >
  </ao-generic-page-header>

  <ul class="ao-sso-login__providers">
    <li *ngFor="let provider of providers">
      <a
        class="ao-sso-login__provider"
        ao-button
        buttonSize="medium"
        [href]="isAdminPreview ? 'javascript:void(0)' : loginUrl(provider)"
      >
        <div class="ao-sso-login__provider-link">
          <span class="ao-sso-login__provider-icon" *ngIf="!isCustom(provider)">
            <ao-icon [name]="getIconPath(provider)"></ao-icon>
          </span>
          <span translate [translateParams]="{ provider: provider.name }"
            >Continue with #provider# [fx: Google, Microsoft, etc.]</span
          >
        </div>
      </a>
    </li>
  </ul>

  <p
    class="ao-font-tiny ao-sso-login__privacy-policy"
    [innerHTML]="
      'By logging in, you confirm to have read and understood our #!privacy policy#.'
        | translate
        | replaceTemplate: '#!privacy policy#':t1
    "
  ></p>
  <ng-template #t1
    ><a
      class="ao-font-tiny-bold ao-sso-login__privacy-policy__link"
      href="https://www.actimo.com/privacy-policy/"
      target="_blank"
      translate
      >privacy policy</a
    ></ng-template
  >

  <!-- native allows switching apps -->
  <button
    ao-button
    buttonSize="medium"
    [outline]="true"
    (click)="findDifferentAppClick.emit()"
    *ngIf="nativeApp"
    translate
  >
    Find a different app
  </button>
</div>
<div class="ao-sso-login__bottom-spacing"></div>
