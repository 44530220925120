import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, Self, SimpleChanges } from '@angular/core';
import { ACTIMO_COLORS } from '@ao/data-models';

@Component({
  selector: 'ao-inline-progress',
  templateUrl: './inline-progress.component.html',
  styleUrls: ['./inline-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgClass],
})
export class InlineProgressComponent implements OnChanges {
  @Input() size = 'small';
  @Input() color: string = ACTIMO_COLORS.green.base;
  @Input() value = 0;
  @Input() label?: string;
  @Input() reversed?: boolean = false;
  @Input() showPercentage?: boolean = true;
  @Input() showPercentageBar?: boolean = true;

  constructor(@Self() private ngClass: NgClass) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngClass.ngClass = {
      'ao-inline-progress': true,
      [`ao-inline-progress--size-${this.size}`]: true,
    };
    this.ngClass.ngDoCheck();
  }
}
