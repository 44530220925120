import { ElementRef } from '@angular/core';

export class ChartHTMLTooltip {
  private tooltipDOMElement: ElementRef;

  public constructor(el: ElementRef) {
    this.tooltipDOMElement = el;
  }

  public setPosition(x: number, y: number): void {
    this.tooltipDOMElement.nativeElement.style.left = `${x}px`;
    this.tooltipDOMElement.nativeElement.style.top = `${y}px`;
  }

  public getDOMElement(): ElementRef {
    return this.tooltipDOMElement;
  }
}
