<a class="ao-complete-training-footer__exit" (click)="exit.emit()" translate>Exit</a>
<button *ngIf="showComplete" class="ao-complete-training-footer__complete" (click)="complete.emit()">
  <ao-icon *ngIf="!training?.passed" name="check_circle" [size]="16"></ao-icon>
  <span *ngIf="!training?.passed; else nextTraining" class="ao-complete-training-footer__complete-text" translate
    >Complete</span
  >
  <ng-template #nextTraining>
    <span class="ao-font-small ao-complete-training-footer__complete-text" translate>Next</span>
  </ng-template>
</button>
