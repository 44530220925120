import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ACTIMO_COLORS, SocialDynamicGroup, SocialGroup, SocialNormalGroup, SocialPrivateGroup } from '@ao/data-models';
import { getImageSourceMinSize } from '@ao/utilities';
@Component({
  selector: 'ao-viewer-contact-groups-list',
  templateUrl: './contact-groups-list.component.html',
  styleUrls: ['./contact-groups-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactGroupsListComponent {
  @Input() groups: (SocialNormalGroup | SocialPrivateGroup)[] = [];
  @Input() dynamicGroups: SocialDynamicGroup[] = [];

  @Input() socialName?: string;
  @Input() allowCreate = false;
  @Input() showEmployeeDirectory = false;
  @Input() showMyTeamDirectory = false;
  @Input() showSocial = false;

  @Output() groupClick = new EventEmitter<SocialGroup>();
  @Output() createClick = new EventEmitter<void>();
  @Output() directoryClick = new EventEmitter<boolean>();

  groupImage(group: SocialGroup) {
    const colors = [
      ACTIMO_COLORS.blue.base,
      ACTIMO_COLORS.green.base,
      ACTIMO_COLORS.yellow.base,
      ACTIMO_COLORS.red.base,
    ];
    const imgUrl = getImageSourceMinSize(group.backgroundImages || [], 300);
    if (imgUrl) {
      return `url("${imgUrl}")`;
    }

    if (group.type === 'GROUP') {
      const color = colors[<number>(<unknown>group.id) % colors.length];
      return `linear-gradient(to top, ${color}, ${color})`;
    }

    return undefined;
  }

  onGroupClick(group: SocialGroup) {
    this.groupClick.emit(group);
  }

  onCreateClick() {
    this.createClick.emit();
  }

  onDirectoryClick(onlyTeam: boolean) {
    this.directoryClick.emit(onlyTeam);
  }
}
