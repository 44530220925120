// To be updated with new modals
export enum ModalKeynames {
  TextEnhancerToggle = 'text-enhancer-toggle',
}

export type ModalStatus = {
  keyName: `${ModalKeynames}`;
};

// Include all keys that are considered admin keys
export const ADMIN_KEYS = new Set([ModalKeynames.TextEnhancerToggle]);
