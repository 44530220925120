<div class="ao-insights-value">
  <ng-container *ngIf="item.type === 'action' || item.type === 'link'; else regular">
    <div class="ao-insights-value__value">
      <span class="ao-insights-value__link">
        <ao-icon name="arrow_forward" [size]="24"></ao-icon>
      </span>
    </div>
  </ng-container>
  <ng-template #regular>
    <div class="ao-insights-value__value">
      <span
        *ngIf="hasValue && hasThreshold"
        class="ao-insights-value__threshold"
        [style.background-color]="item.status?.color"
      ></span>
      <span class="ao-font-body2 ao-insights-value__display-value">
        <ng-container *ngIf="isNumeric">
          <ao-animated-number [value]="item.value || 0" [animateOnLoad]="false"></ao-animated-number>
        </ng-container>
        <ng-container *ngIf="!isNumeric">
          {{ item.value || '-' | slice: 0 : 9 }}
        </ng-container>
        <ng-container *ngIf="hasValue && item.suffix">{{ item.suffix }}</ng-container>
      </span>
    </div>
  </ng-template>
  <ao-insights-title
    class="ao-font-tiny ao-insights-value__title"
    [type]="item.type"
    [title]="item.title"
    [key]="item.key"
  ></ao-insights-title>
</div>
