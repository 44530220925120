<div class="ao-custom-icon__container">
  <!--
    IE11 has no support for the css prop `filter`, so to do grayscaling w/fade
    we render two icons on top of each other inside an svg where the svg filter
    CAN be applied.
  -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 100 100"
    *ngIf="_icon"
    class="ao-custom-icon__svg"
  >
    <defs *ngIf="!active">
      <filter id="grayscale">
        <feColorMatrix
          type="matrix"
          values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0"
        />
      </filter>
    </defs>
    <image
      class="ao-custom-icon__colored"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      width="100%"
      height="100%"
      [attr.xlink:href]="_icon"
    />
    <image
      *ngIf="!active"
      filter="url(#grayscale)"
      class="ao-custom-icon__grayscaled"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      width="100%"
      height="100%"
      [attr.xlink:href]="_icon"
    />
  </svg>
</div>
