import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageQuizRetakeNotification, PendingMessage } from '@ao/data-models';
// import { AppFacade } from '@ao/viewer-app-store';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'ao-pending-assignment-banner[appFacade]',
  templateUrl: './pending-assignment-banner.component.html',
  styleUrls: ['./pending-assignment-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingAssignmentBannerComponent implements OnDestroy, OnInit {
  @HostBinding('class.ao-message-notifications') className = true;

  // should be AppFacade but causes circular dependency (and runtime error in admin) to import/inject;
  @Input()
  appFacade: {
    pendingRecurringMsgs$: Observable<PendingMessage[]>;
    showCompletedSurveyNotification$: Observable<boolean>;
    getQuizRetakeCountdown: (s: Subject<void>) => Observable<MessageQuizRetakeNotification>;
    finishQuiz: () => void;
    retakeQuiz: () => void;
    navigateTo: (s: unknown[]) => void;
  };

  showCompletedSurveyNotification$: Observable<boolean>;
  pendingRecurringMsgs$: Observable<PendingMessage[]>;
  data$: Observable<MessageQuizRetakeNotification>;

  destroy$ = new Subject<void>();
  retakeClicked = false;

  ngOnInit() {
    this.showCompletedSurveyNotification$ = this.appFacade.showCompletedSurveyNotification$;
    this.pendingRecurringMsgs$ = this.appFacade.pendingRecurringMsgs$;
    this.data$ = this.appFacade.getQuizRetakeCountdown(this.destroy$);
  }

  onClickFinishQuiz() {
    this.appFacade.finishQuiz();
  }

  onClickRetakeQuiz() {
    this.retakeClicked = true;
    this.appFacade.retakeQuiz();
  }

  onClickAnswerMessage(keycode: string) {
    this.appFacade.navigateTo(['/', 'c', keycode]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  pendingTotalCount(pending?: PendingMessage['pending']): number {
    return parseInt(pending.split('/')[1]) || 0;
  }
}
