import { createAction, props } from '@ngrx/store';

export const searchInitiated = createAction(
  '[Search] Search Initiated',
  props<{ query: string; filters?: any; sorting?: string; pageSize?: number }>(),
);

export const searchSuccess = createAction('[Search] Search Success', props<{ results: any[]; nextPage: number }>());

export const saveRecentSearch = createAction('[Search] Save recent search', props<{ query: string }>());

export const searchFailure = createAction('[Search] Search Failure', props<{ error: string }>());

export const updateQuery = createAction('[Search] Update Query', props<{ query: string }>());

export const updateFilters = createAction('[Search] Update Filters', props<{ filters: any }>());

export const updateSorting = createAction('[Search] Update Sorting', props<{ sorting: string }>());

export const resetSearch = createAction('[Search] Reset Search');

// for pagination
export const nextPage = createAction('[Search] Next Page');

export const deleteRecentSearch = createAction('[Search] Delete Recent Search', props<{ query: string }>());

export const setLastVisitedItem = createAction(
  '[Search] Set Last Visited Item',
  props<{ lastVisitedItemIndex: number }>(),
);
