<ul class="items">
  <ng-container *ngIf="showEmployeeDirectory">
    <div class="item-header">
      <div class="ao-font-tiny-bold item-header__title" translate>Directory</div>
    </div>
    <li class="ao-font-body2 item-option" role="button" (click)="onDirectoryClick(false)">
      <div class="item-option__name" translate>All contacts</div>
    </li>
    <li *ngIf="showMyTeamDirectory" class="ao-font-body2 item-option" role="button" (click)="onDirectoryClick(true)">
      <div class="item-option__name" translate>My team</div>
    </li>
  </ng-container>

  <ng-container *ngIf="showSocial">
    <div class="item-header" *ngIf="dynamicGroups.length > 0">
      <div class="ao-font-tiny-bold item-header__title" translate>Social</div>
    </div>
    <li
      *ngFor="let group of dynamicGroups; index as i"
      class="ao-font-body2 item-option"
      (click)="onGroupClick(group)"
      [ngSwitch]="group.id"
    >
      <div *ngSwitchCase="'ALL_POSTS'" class="item-option__name">
        {{ socialName || (group.name | translate) }}
      </div>
      <div *ngSwitchDefault class="item-option__name">
        {{ group.name | translate }}
      </div>
    </li>
    <div class="item-header">
      <div class="ao-font-tiny-bold item-header__title" translate>My groups</div>
    </div>
    <div *ngIf="allowCreate" class="item-action" (click)="onCreateClick()">
      <div class="item-action__icon">
        <ao-icon name="group" [size]="24"></ao-icon>
      </div>
      <div class="ao-font-body2-bold" translate="">Create group</div>
    </div>
    <li *ngFor="let group of groups" class="ao-font-body2 item-option" role="button" (click)="onGroupClick(group)">
      <div class="item-option__group-picture">
        <div
          *ngIf="group.type === 'GROUP' || groupImage(group); else avatars"
          class="item-option__group-picture-image"
          [style.background-image]="groupImage(group) || '' | trustStyle"
        ></div>
        <ng-template #avatars>
          <ng-container *ngIf="group.type !== 'GROUP'">
            <div class="item-option__group-picture-avatars" *ngIf="group.sampleMembers.length === 2">
              <ao-avatar
                *ngFor="let member of group.sampleMembers"
                [initials]="member.initials"
                [images]="member.avatarImages"
                size="smaller"
              ></ao-avatar>
            </div>
            <div *ngIf="group.sampleMembers.length === 1">
              <ao-avatar
                [initials]="group.sampleMembers[0].initials"
                [images]="group.sampleMembers[0].avatarImages"
                size="small"
              ></ao-avatar>
            </div>
          </ng-container>
        </ng-template>
      </div>
      <div class="item-option__info">
        <div class="item-option__name">
          {{ group.name }}
        </div>
        <div class="item-option__name-subtext" *ngIf="group.newPosts && group.newPosts > 0">
          {{ '#count# new posts' | translate: { count: group.newPosts } }}
        </div>
      </div>
    </li>
  </ng-container>
</ul>
