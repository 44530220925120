import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  inject,
} from '@angular/core';
import { getMimeCategory, getMimeIconUrl } from '@ao/utilities';
import { fileTypeBgClasses } from '../../utils/file-type-colors';

@Directive({
  selector: '[aoFileTypeBg]',
})
export class FileTypeBackgroundDirective implements OnInit, OnChanges {
  @HostBinding('class.ao-file-type-bg') className = true;

  renderer = inject(Renderer2);
  el = inject(ElementRef);

  @Input() aoFileTypeBg: string;

  get parsedFileType() {
    return this.aoFileTypeBg ? getMimeCategory(this.aoFileTypeBg) || this.aoFileTypeBg || 'other' : 'other';
  }

  ngOnInit(): void {
    this.addClasses();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileType) {
      this.addClasses();
    }
  }

  addClasses() {
    fileTypeBgClasses.forEach((classConfig) => {
      if (classConfig.typeMatchers.includes(this.parsedFileType)) {
        this.renderer.addClass(this.el.nativeElement, classConfig.className);
      } else {
        this.renderer.removeClass(this.el.nativeElement, classConfig.className);
      }
    });
  }

  get fileIcon() {
    const iconUrl = this.aoFileTypeBg ? getMimeIconUrl(getMimeCategory(this.aoFileTypeBg)) : null;
    return iconUrl ? `url('${iconUrl}')` : '';
  }
}
