import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[aoModalHeader]' })
export class ModalHeaderDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[aoModalContent]' })
export class ModalContentDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[aoModalFooter]' })
export class ModalFooterDirective {
  constructor(public template: TemplateRef<any>) {}
}
