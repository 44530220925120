<ng-container *ngIf="isVideo">
  <ao-media-attachment
    [label]="uploaded ? (!processed ? _media.name : undefined) : ('Uploading' | translate)"
    [labelSuffix]="uploaded && !processed && _media.type ? '.' + _media.type.split('/')[1] : undefined"
    [spinner]="!uploaded"
    [videoIcon]="uploaded ? true : undefined"
    [overlay]="uploaded ? true : undefined"
    [blackBg]="uploaded && !processed ? true : undefined"
    [images]="_media && _media.images"
    [thumbnailUrl]="_media?.thumbnailUrl?.includes('.png') ? _media.thumbnailUrl : undefined"
    (remove)="onAttachmentRemove()"
  ></ao-media-attachment>
</ng-container>
<ng-container *ngIf="!isVideo">
  <ao-media-attachment
    [label]="uploaded ? (!processed ? _media.name : undefined) : ('Uploading' | translate)"
    [spinner]="!uploaded || !processed"
    [images]="processed && _media?.images?.length > 0 ? _media.images : undefined"
    (remove)="onAttachmentRemove()"
  >
  </ao-media-attachment>
</ng-container>
