import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class OpenGraphService {
  constructor(private http: HttpClient) {}

  getOpenGraphData(url: string, contactId: number): Observable<any> {
    return this.http.get<any>(`/api/v1/openGraph/contacts/${contactId}?url=${url}`).pipe(
      map((result) => result.data),
      catchError((error: any) => throwError(error)),
    );
  }
}
