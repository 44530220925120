import { prodConfig } from '@ao/shared-config';
import { VERSION } from './version';

export const environment = {
  production: true,
  version: VERSION,
  envName: 'production',
  apiBaseUrl: '',
  emitterConfig: {
    host: 'emitter.actimo.io',
    port: window.location.protocol === 'https:' ? 443 : 8099,
  },
  intercomAppId: 'vsjdg75c',
  datadog: {
    adminClientToken: 'pub89d3b659999666f797b49f9c4f23742b',
    adminApplicationId: '26de89ec-88d0-48dc-8acc-2c8c1a006b7d',
    adminServiceName: 'actimo-admin',
    viewerClientToken: 'pub376140a2dcf448695563b0f1ddded173',
    viewerApplicationId: '11fbd377-a1a0-41f6-9cdc-dcfb06f454c4',
    viewerServiceName: 'actimo-viewer',
  },
  firebase: {
    apiKey: 'AIzaSyAWD1Qf04GboGkYqJ6tWwkWJ_PvH9AUqI0',
    authDomain: 'actimo-app-notifications.firebaseapp.com',
    databaseURL: 'https://actimo-app-notifications.firebaseio.com',
    projectId: 'actimo-app-notifications',
    storageBucket: 'actimo-app-notifications.appspot.com',
    messagingSenderId: '1086993841729',
    appId: '1:1086993841729:web:b70a608af165c2c16ad0c0',
  },
  ...prodConfig,
};
