import { Injectable } from '@angular/core';
import { getModuleRef, withLatestFromLazy } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacade } from '../../app-store.facade';
import { AppService } from '../../services/app-store.service';
import { MessageActionHandler } from '../../services/message-action-handler.service';
import * as appActions from '../actions';

@Injectable()
export class ModuleFeedbackEffects {
  constructor(
    private actions$: Actions,
    private appService: AppService,
    private appFacade: AppFacade,
    private viewerCoreFacade: ViewerCoreFacade,
    private messageActionHandler: MessageActionHandler,
  ) {}

  loadFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LoadFeedback),
      withLatestFromLazy(
        this.viewerCoreFacade.keycode$,
        this.appFacade.messageType$,
        this.appFacade.messageQuestionnaire$,
      ),
      mergeMap(([{ module }, keycode, messageType, questionnaire]) => {
        const moduleId = messageType === 'pulse' ? `${module.id}-${module.managerId}` : `${module.id}`;
        return this.appService.loadFeedback(keycode, moduleId).pipe(
          switchMap((data) => {
            const isQuestionnaire = ['survey', 'pulse', 'rating'].includes(messageType);
            if (isQuestionnaire) {
              const value = data.previousByContact;
              const current = questionnaire[getModuleRef(module)];

              if (!current || !current.value) {
                return [
                  appActions.UpdateQuestionnaireValue({
                    module,
                    value,
                    keycode,
                    errors: value || module.optional_answer ? undefined : { required: true },
                  }),
                  appActions.LoadFeedbackSuccess({ module, data }),
                ];
              }
            }
            return of(appActions.LoadFeedbackSuccess({ module, data }));
          }),
          catchError((error) => of(appActions.LoadFeedbackFail(error))),
        );
      }),
    ),
  );

  submitFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.FeedbackSubmit),
      withLatestFromLazy(this.viewerCoreFacade.keycode$, this.appFacade.messageType$),
      mergeMap(([{ module, content }, keycode, messageType]) => {
        const moduleId = messageType === 'pulse' ? `${module.id}-${module.managerId}` : `${module.id}`;
        return this.appService.submitFeedback(keycode, moduleId, content).pipe(
          map((comment) => appActions.FeedbackSubmitSuccess({ module, comment })),
          catchError((error) => of(appActions.FeedbackSubmitFail(error))),
        );
      }),
    ),
  );

  deleteFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.FeedbackDelete),
      withLatestFromLazy(this.viewerCoreFacade.keycode$),
      mergeMap(([{ module }, keycode]) => {
        return this.appService.deleteFeedback(keycode, module.id).pipe(
          map(() => appActions.FeedbackDeleteSuccess({ module })),
          catchError((error) => of(appActions.FeedbackDeleteFail(error))),
        );
      }),
    ),
  );

  triggerButtonAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.FeedbackSubmit, appActions.FeedbackSubmitSuccess),
        tap(({ type, module }) => {
          const hasTrackedAction = module.actions.some((action) => {
            // 'go_to_message' in a new window needs to happen in a new window
            const openNewWindow = <any>action.open_in_new_window === '0' ? false : Boolean(action.open_in_new_window);
            return action.type === 'go_to_message' && openNewWindow;
          });
          const isTriggered = hasTrackedAction
            ? type === appActions.FeedbackSubmit.type
            : type === appActions.FeedbackSubmitSuccess.type;

          if (isTriggered) {
            this.messageActionHandler.trigger(module, 'feedback');
          }
        }),
      ),
    { dispatch: false },
  );
}
