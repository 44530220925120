import { TRANSLATION_STATUS } from '@ao/data-models';
import { on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, moduleTranslationsAdapter } from './app-store.state';

export const autoTranslationOns = [
  on(appActions.ToggleAutoTranslationModal, (state: AppState): AppState => {
    return { ...state, showAutoTranslationModal: !state.showAutoTranslationModal };
  }),
  on(appActions.LoadAutoTranslationLangsSuccess, (state: AppState, { autoTranslateLangs }): AppState => {
    return {
      ...state,
      autoTranslateLangs,
    };
  }),
  on(appActions.LoadMessageTranslation, (state: AppState): AppState => {
    return {
      ...state,
      autoTranslationStatus: TRANSLATION_STATUS.requesting,
    };
  }),
  on(appActions.LoadMessageTranslationSuccess, (state: AppState, { data }): AppState => {
    const mappedModules = Object.entries(data).map(([id, value]) => {
      return {
        id,
        value,
      };
    });
    return {
      ...state,
      autoTranslationStatus: TRANSLATION_STATUS.translated,
      moduleTranslations: moduleTranslationsAdapter.upsertMany(mappedModules, { ...state.moduleTranslations }),
    };
  }),
  on(appActions.LoadMessageTranslationFail, (state: AppState): AppState => {
    return {
      ...state,
      autoTranslationStatus: TRANSLATION_STATUS.error,
    };
  }),
  on(appActions.RevertMessageTranslation, appActions.LoadOneMessageSuccess, (state: AppState): AppState => {
    return {
      ...state,
      autoTranslationStatus: TRANSLATION_STATUS.original,
    };
  }),
];
