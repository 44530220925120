import { Autolinker, AutolinkerConfig } from 'autolinker';

const AUTOLINKER_CFGS: AutolinkerConfig = {
  urls: {
    schemeMatches: true,
    tldMatches: true,
  },
  email: true,
  phone: true,
  sanitizeHtml: true,
  stripPrefix: false,
  stripTrailingSlash: false,
  newWindow: true,
  truncate: {
    length: 0,
    location: 'end',
  },
  decodePercentEncoding: true,
  className: 'linkify',
};

export class Linkifier {
  private autolinker: Autolinker;

  constructor() {
    this.autolinker = new Autolinker(AUTOLINKER_CFGS);
  }

  public link(textOrHtml: string): string {
    return this.autolinker.link(textOrHtml);
  }
}
