<ng-container>
  <div *ngIf="type !== 'text'" class="ao-generic-page-header__avatar">
    <ng-container [ngSwitch]="type">
      <!-- avatar -->
      <ng-container *ngSwitchCase="'avatar'">
        <ao-avatar
          [initials]="initials"
          [image]="image"
          [images]="images"
          [size]="avatarSize"
          (click)="imageClick.emit()"
        ></ao-avatar>
      </ng-container>

      <!-- icon -->
      <ng-container *ngSwitchCase="'icon'">
        <ao-icon-avatar
          [iconName]="iconName"
          [avatarColor]="'reverseBlue'"
          [size]="avatarSize"
          (click)="imageClick.emit()"
        ></ao-icon-avatar>
      </ng-container>

      <!-- default -->
      <ng-container *ngSwitchDefault>
        <ao-icon-avatar
          [iconName]="_iconTypes[type].icon || 'info'"
          [avatarColor]="_iconTypes[type] ? _iconTypes[type].color : 'blue'"
          [size]="avatarSize"
          (click)="imageClick.emit()"
        ></ao-icon-avatar>
      </ng-container>
    </ng-container>
  </div>
  <h6
    *ngIf="_subTitle"
    class="ao-font-tiny-bold ao-generic-page-header__subtitle"
    [style.color]="subtitleColor ? subtitleColor : null"
  >
    {{ _subTitle | translate }}
  </h6>
  <h3 *ngIf="title" class="ao-font-title4 ao-generic-page-header__title" [style.color]="titleColor ? titleColor : null">
    {{ title | translate }}
  </h3>
  <p *ngIf="text" class="ao-font-body2 ao-generic-page-header__text" [style.color]="textColor ? textColor : null">
    {{ text | translate }}
  </p>
</ng-container>
