<svg *ngIf="!fastRender" class="ao-spinner__svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <g class="ao-spinner__path-wrapper">
    <circle
      class="ao-spinner__path"
      [attr.stroke]="_color"
      [attr.stroke-dasharray]="dashArray"
      fill="none"
      stroke-width="3"
      stroke-linecap="round"
      cx="12"
      cy="12"
      r="10.5"
    ></circle>
  </g>
</svg>

<svg
  *ngIf="fastRender"
  class="ao-spinner__fast-path"
  viewBox="0 0 100 100"
  preserveAspectRatio="xMidYMid"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle
    cx="50"
    cy="50"
    fill="none"
    [attr.stroke]="_color"
    stroke-width="10"
    r="35"
    stroke-dasharray="144.93361431346415 76.97787143782138"
    stroke-linecap="round"
  ></circle>
</svg>
