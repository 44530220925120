import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AoTrackEvent } from '@ao/data-models';
import { environment } from '@ao/environments';
import { EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AppFacade } from '../app-store.facade';

interface TrackerEventBulk {
  events: { key: string; event: string; statpack: string }[];
  retry: number;
  subject: Subject<void>;
}

@Injectable({ providedIn: 'root' })
export class TrackerService {
  statpack$ = this.appFacade.messageStatpack$;

  // create redux store observables
  private queueBulk = new Subject<TrackerEventBulk>();
  // maz retries
  private maxRetries = 3;
  // create a storage for logging events by id
  private seenBulk = new Set<string>();

  constructor(private http: HttpClient, private appFacade: AppFacade) {
    // keep watching and executing on the event gueue
    this.queueBulk
      .pipe(
        mergeMap((bulkEvent) => {
          // create a unique key per event
          const key = bulkEvent.events.reduce((acc, event) => {
            return acc + `${event.key}:${event.event}:${event.statpack}:${bulkEvent.retry}`;
          }, '');
          // check if its already triggered
          if (this.seenBulk.has(key)) {
            bulkEvent.subject.error('REPEATED');
            return EMPTY;
          }
          // store a log of the event
          this.seenBulk.add(key);
          return of(bulkEvent);
        }),
        mergeMap((bulkEvent) => {
          // call the api
          return this.dispatchBulk(bulkEvent).pipe(
            // the provided Observer is called to perform a side effect for every value,
            //  error, and completion emitted by the source.
            tap(() => {
              bulkEvent.subject.next();
              bulkEvent.subject.complete();
            }),
            catchError(() => {
              if (bulkEvent.retry < this.maxRetries) {
                this.queueBulk.next({ ...bulkEvent, retry: bulkEvent.retry + 1 });
              } else {
                bulkEvent.subject.error('MAX_RETRIES');
              }
              return EMPTY;
            }),
          );
        }, 3),
      )
      .subscribe();
  }

  // function to track user events in bulk
  track(events: AoTrackEvent[]): Observable<Record<string, any>> {
    let filteredEvents;

    return this.statpack$.pipe(
      withLatestFrom(this.appFacade.messageQuestionnaire$),
      take(1),
      map(([statpack, questionnaire]) => {
        // Get the corresponding statpack.
        // Statpacks are encoded payloads generated by the backend that
        // identify the module and the event. This adds an integrity check
        // that prevents stats being faked by a malicious client.
        // Optional events without a value are filtered out

        filteredEvents = events.filter(
          (eventData) =>
            questionnaire[eventData.key]?.value ||
            (!questionnaire[eventData.key]?.value && !questionnaire[eventData.key]?.optional),
        );

        return filteredEvents.map((eventData) => ({
          key: eventData.key,
          event: eventData.event,
          statpack: (statpack && statpack[eventData.key] && statpack[eventData.key][eventData.event]) || null,
        }));
      }),
      switchMap((eventsData) => {
        if (
          !eventsData ||
          !eventsData.length ||
          eventsData.length !== filteredEvents.length ||
          eventsData.find((ev) => ev.statpack === null)
        ) {
          return throwError(() => 'NO_STATPACK');
        } else {
          // send the event into the queue for dispatching to the api
          const subject = new Subject<any>();
          this.queueBulk.next({
            events: eventsData,
            retry: 0,
            subject: subject,
          });
          return subject.asObservable();
        }
      }),
    );
  }

  // method to send events to the server
  private dispatchBulk(bulkEvent: TrackerEventBulk): Observable<any> {
    const data = bulkEvent.events.map((event) => event.statpack);
    const url = `${environment.apiBaseUrl}/api/v1/event/registerBulk`;
    return this.http.post(url, data);
  }
}
