import { MsgModuleMessageList } from '@ao/data-models';
import { getModuleRef } from '@ao/utilities';
import { on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, moduleAdapter, moduleTranslationsAdapter } from './app-store.state';

export const moduleMessageListOns = [
  on(appActions.LoadMessageListData, (state: AppState, { module, appendMessages, params = {} }) => {
    const moduleRef = getModuleRef(module);
    const currentModule = state.modules.entities[moduleRef] as MsgModuleMessageList;
    return {
      ...state,

      modules: moduleAdapter.updateOne(
        {
          id: moduleRef,
          changes: {
            ...params,
            messages: appendMessages ? currentModule.messages : [],
            loading: true,
            loaded: false,
            loadError: null,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.LoadMessageListDataSuccess, (state: AppState, { module, data, appendMessages }) => {
    const { options, messages, translatedText } = data;
    const moduleRef = getModuleRef(module);
    const currentModule = state.modules.entities[moduleRef] as MsgModuleMessageList;
    const mappedModules = translatedText
      ? Object.entries(translatedText).map(([id, value]) => {
          return {
            id,
            value,
          };
        })
      : null;

    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: moduleRef,
          changes: {
            ...options,

            messages: appendMessages ? updateOrAppend(currentModule.messages, messages) : messages,
            loaded: true,
            loading: false,
            loadError: null,
            initialLoaded: true,
          },
        },
        { ...state.modules },
      ),
      moduleTranslations: mappedModules
        ? moduleTranslationsAdapter.upsertMany(mappedModules, { ...state.moduleTranslations })
        : { ...state.moduleTranslations },
    };
  }),
  on(appActions.LoadMessageListDataFail, (state: AppState, { module, error }) => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            loaded: false,
            loading: false,
            loadError: error,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.ClearMessageListMessages, (state: AppState, { module }) => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            messages: [],
            loaded: false,
            loading: false,
          },
        },
        { ...state.modules },
      ),
    };
  }),
];

function updateOrAppend(current = [], messagesToAppend) {
  const currentMessages = [...current];
  for (const message of messagesToAppend) {
    const existing = currentMessages.find((e) => e.id === message.id);
    if (existing) {
      const index = currentMessages.indexOf(existing);
      currentMessages[index] = { ...existing, ...message };
    } else {
      currentMessages.push(message);
    }
  }
  return currentMessages;
}
