// function debounce to limit the number of times a function is called
export function simpleDebounce(fn, time) {
  let timeoutFlag;
  return (...args) => {
    if (timeoutFlag) {
      clearTimeout(timeoutFlag);
    }
    timeoutFlag = setTimeout(() => {
      timeoutFlag = null;
      fn(...args);
    }, time);
  };
}

// function to throttle the number of times a function is called
export function simpleThrottle(fn, delay) {
  let timerFlag = null;
  return (...args) => {
    if (timerFlag === null) {
      fn(...args); //
      timerFlag = setTimeout(() => {
        timerFlag = null;
      }, delay);
    }
  };
}
