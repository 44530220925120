import { Component, EventEmitter, Inject, Input, Output, TrackByFunction } from '@angular/core';
import { AcademyMessageSettings, AcademySection, InsightsSettings } from '@ao/data-models';
import { STORAGE } from '@ao/utilities';

@Component({
  selector: 'ao-academy-message',
  templateUrl: './academy-message.component.html',
  styleUrls: ['./academy-message.component.scss'],
})
export class AcademyMessageComponent {
  @Input() academy: AcademyMessageSettings;
  @Input() progressInsightsSettings?: InsightsSettings;
  @Input() set localStorageId(val) {
    this._localstorageId = val;
    this.setCollapseState();
  }
  @Input() isTest = false;
  @Output() saveAcademy = new EventEmitter<void>();
  @Output() unsaveAcademy = new EventEmitter<void>();

  sectionStatus: { [id: string]: boolean } = {};
  private sectionLocalStorage: { [id: string]: { [id: number]: boolean } } = {};
  private _localstorageId: string = null;

  constructor(@Inject(STORAGE) private localStorage: Storage) {}

  trackById: TrackByFunction<any> = (index, item) => item.id;

  // set the section blocks collapsed / expanded according to our default logic and user(contact) overrides
  setCollapseState() {
    if (this._localstorageId) {
      // fetch the local storage and store it on the controller for ease of use
      const raw = this.localStorage.getItem(`academy__preferences__sections`);
      try {
        this.sectionLocalStorage = JSON.parse(raw) || {};
      } catch {
        this.sectionLocalStorage = {};
      }
      // make sure theres an object for this academy user
      if (!this.sectionLocalStorage[this._localstorageId]) {
        this.sectionLocalStorage[this._localstorageId] = {};
      }
    }

    // apply the state to the UI
    [...(this.academy?.sections || [])].forEach((section: AcademySection, i) => {
      const localStorageValue = this._localstorageId && this.sectionLocalStorage[this._localstorageId][section.id];
      // local storage always overrides
      if (localStorageValue !== undefined) {
        this.sectionStatus[section.id] = localStorageValue;
      } else {
        // default state - first section any others with progress expanded
        this.sectionStatus[section.id] = i === 0 ? false : section.progress <= 0;
      }
    });
  }

  // toggle academy sections collapsed/expaded
  onToggleCollapsed(id: string) {
    const newValue = !this.sectionStatus[id];
    this.sectionStatus[id] = newValue;

    // set state in local storage
    if (this._localstorageId) {
      this.sectionLocalStorage[this._localstorageId][id] = newValue;
      this.localStorage.setItem('academy__preferences__sections', JSON.stringify(this.sectionLocalStorage));
    }
  }
}
