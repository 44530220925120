import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { AcademySection, MessageListAcademyData, MessageListMessageCategory } from '@ao/data-models';
import { MediaItem } from '@ao/shared-data-models';

@Component({
  selector: 'ao-academy-header',
  templateUrl: './academy-header.component.html',
  styleUrls: ['./academy-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgClass],
})
export class AcademyHeaderComponent {
  @HostBinding('class.ao-academy-header') className = true;

  _image: MediaItem = null;

  @Input() set image(value: MediaItem | string) {
    // temporary fallback until the backend always returns mediaItem instead of a string
    this._image = value ? (typeof value === 'string' ? <MediaItem>{ url: value } : value) : null;
  }
  @Input() title = '';
  @Input() progress = 0;
  @Input() sections: AcademySection[] = [];
  @Input() description?: string = '';
  @Input() categories?: MessageListMessageCategory[] = [];
  @Input() date = '';
  @Input() customProgressLabel?: string = '';
  @Input() academyIsSaved = false;
  @Input() readOnly = false;
  @Output() saveAcademy = new EventEmitter<void>();
  @Output() unsaveAcademy = new EventEmitter<void>();

  get academyData(): MessageListAcademyData {
    const trainings = this.sections.reduce((acc, section) => acc + section.trainings.length, 0);
    return {
      progress: this.progress,
      trainingsPassed: Math.round(trainings * this.progress),
      trainings: trainings,
    };
  }

  handleSaveClick() {
    if (this.readOnly) {
      return; // Do nothing if readOnly is true
    }

    if (this.academyIsSaved) {
      this.unsaveAcademy.emit();
    } else {
      this.saveAcademy.emit();
    }
    this.academyIsSaved = !this.academyIsSaved;
  }
}
