import { createReducer, on } from '@ngrx/store';
import * as SearchActions from '../actions/search.actions';
export const SEARCH_STATE_KEY = 'search';

export interface SearchState {
  isLoading: boolean;
  query: string;
  recentSearches: string[];
  results: any[];
  error: string | null;
  filters: {
    [key: string]: any; // Dynamic object to hold different filters (e.g., category, date range) TO IMPLEMENT
  };
  sorting: string;
  pagination?:
    | {
        currentPage: number;
        pageSize: number; // Keep if you need to change it dynamically
      }
    | object;
  lastVisitedItemIndex: number;
  paginationAvailable: boolean;
}

export const initialSearchState: SearchState = {
  isLoading: false,
  query: '',
  results: [],
  error: null,
  filters: {},
  sorting: 'relevance',
  pagination: {
    currentPage: 0,
    pageSize: 20, // Default page size
  },
  recentSearches: [],
  lastVisitedItemIndex: 0,
  paginationAvailable: false,
};

export const searchReducer = createReducer(
  initialSearchState,
  on(SearchActions.setLastVisitedItem, (state, { lastVisitedItemIndex }) => ({
    ...state,
    lastVisitedItemIndex,
  })),
  on(SearchActions.searchInitiated, (state, { query, filters, sorting, pageSize }) => ({
    ...state,
    query,
    filters: filters || state.filters,
    sorting: sorting || state.sorting,
    pagination: {
      ...state.pagination,
      pageSize,
    },
    isLoading: true,
    error: null,
    results: [],
    lastVisitedItemIndex: 0,
  })),
  on(SearchActions.searchSuccess, (state, { results, nextPage }) => ({
    ...state,
    results: results.length ? [...state.results, ...results] : nextPage > 1 ? state.results : results, // Append results if not the first page
    isLoading: false,
    pagination: {
      ...state.pagination,
      currentPage: nextPage || 1, // Update the current page
    },
    paginationAvailable: !!results.length,
  })),
  on(SearchActions.saveRecentSearch, (state, { query }) => ({
    ...state,
    recentSearches: [query, ...state.recentSearches.filter((q) => q !== query)].slice(0, 10), // Update recent searches
  })),
  on(SearchActions.nextPage, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    pagination: {
      ...state.pagination,
    },
  })),
  on(SearchActions.deleteRecentSearch, (state, { query }) => ({
    ...state,
    recentSearches: state.recentSearches.filter((q) => q !== query), // Remove the query from recent searches
  })),
  on(SearchActions.searchFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(SearchActions.updateQuery, (state, { query }) => ({
    ...state,
    query,
  })),
  on(SearchActions.updateFilters, (state, { filters }) => ({
    ...state,
    filters,
  })),
  on(SearchActions.updateSorting, (state, { sorting }) => ({
    ...state,
    sorting,
  })),
  on(SearchActions.resetSearch, (state) => ({
    ...state,
    results: [],
    filters: {},
    sorting: 'relevance',
    error: null,
    query: '',
    pagination: {
      ...state.pagination,

      currentPage: 0,
    },
    lastVisitedItemIndex: 0,
  })),
);
