import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  HostBinding,
  Input,
  TemplateRef,
} from '@angular/core';
import { expandInOut } from '@ao/utilities';

@Directive({ selector: '[aoViewerInputWrapperTooltipTemplate]' })
export class ViewerInputWrapperTooltipTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[aoViewerInputWrapperErrorTemplate]' })
export class ViewerInputWrapperErrorTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[aoViewerInputWrapperDisabledTemplate]' })
export class ViewerInputWrapperDisabledTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[aoViewerInputWrapperLabelTemplate]' })
export class ViewerInputWrapperLabelTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'ao-viewer-input-wrapper',
  templateUrl: './viewer-input-wrapper.component.html',
  styleUrls: ['./viewer-input-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandInOut()],
})
export class ViewerInputWrapperComponent {
  @HostBinding('class.ao-viewer-input-wrapper') className = true;
  @HostBinding('class.ao-viewer-input-wrapper--inline') get displayInline() {
    return this.inlineInput || this.reverseOrder;
  }
  @HostBinding('class.ao-viewer-input-wrapper--disabled')
  @Input()
  isDisabled = false;

  @Input() label?: string = '';
  @Input() boldLabel?: boolean = false;
  @Input() subHeader?: string = '';
  @Input() optional?: boolean = false;
  @Input() required?: boolean = false;
  @Input() reverseOrder?: boolean = false;
  @Input() fullWidthLabel?: boolean = false; // forcing full width on non-reverse (mainly for toggles)
  @Input() inlineInput?: boolean = false;
  @Input() displayErrors?: boolean = false;

  @ContentChild(ViewerInputWrapperErrorTemplateDirective, { read: TemplateRef }) errorTemplate: TemplateRef<any>;
  @ContentChild(ViewerInputWrapperDisabledTemplateDirective, { read: TemplateRef }) disabledTemplate: TemplateRef<any>;
  @ContentChild(ViewerInputWrapperLabelTemplateDirective, { read: TemplateRef }) labelTemplate: TemplateRef<any>;
}
