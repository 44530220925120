import { z } from 'zod';
import { ImageHandlerMedia, ImageSource, MediaItem } from '../media';

export const SaveContentSchema = z.object({
  id: z.string().pipe(z.coerce.number().min(1)).optional(),
  type: z.enum(['academies', 'messages', 'posts']),
});

export const GetSavedContentSchema = SaveContentSchema.pick({ type: true });

export type ItemTypesEnum = z.infer<typeof SaveContentSchema>['type'];

export interface ExtendedSavedMessage {
  id: number;
  title: string;
  text: string;
  textPreview?: string;
  titlePreview?: string;
  mediaPreview: ImageHandlerMedia;
  media?: ImageHandlerMedia;
  categories?: { categoryId: number; name: string }[];
  savedAt: Date;
}
export interface ExtendedSavedAcademy {
  id: number;
  messageId: number;
  title: string;
  description: string;
  categories: { categoryId: number; name: string }[];
  academyData: {
    progress: number;
    trainings: number;
    trainingsPassed: number;
  };
  image: string;
  savedAt: Date;
}

export interface ExtendedSavedPost {
  id: number;
  author: { id: number; initials: string; name: string; department: string; avatarImages: ImageSource[] };
  attachments: Record<string, any>;
  content: Record<string, any>;
  groupInfo: { id: number; name: string };
  media: MediaItem;
  linkPreview: {
    title: string;
    imageUrl: string;
    url: string;
  };
  createdAt: Date;
  savedAt: Date;
}
