// for tracking unsplash image views from within controllers
// https://gitlab.actimo.io/development/actimo/-/wikis/Unsplash%20image%20view%20tracking
// https://paper.dropbox.com/published/Partners-Photo-View-Tracking-LB4ssLsthfBoZfK5n9zYNbq

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@ao/environments';

@Injectable()
export class UnsplashTrackingService {
  private readonly BASE_URL = 'https://views.unsplash.com/v';

  constructor(private http: HttpClient) {}

  registerView(unsplashId) {
    const url = `${this.BASE_URL}?appId=${environment.unsplash.appId}&photo_id=${unsplashId}`;
    this.http.get(url).subscribe();
  }
}
