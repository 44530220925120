import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonUiModule } from '@ao/common-ui';
import { ViewerCoreModule } from '@ao/viewer-core';
import { ViewerUiModule } from '@ao/viewer-ui';
import { TranslateModule } from '@ngx-translate/core';
import { TaskDetailsPageComponent } from './containers/task-details-page/task-details-page.component';
import { TaskListPageComponent } from './containers/task-list-page/task-list-page.component';
import { TaskPageGuardComponent } from './containers/task-page-guard/task-page-guard.component';
import { TaskUncompletePromptComponent } from './containers/task-uncomplete-prompt/task-uncomplete-prompt.component';
import { viewerTaskManagementRoutes } from './viewer-task-management.routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(viewerTaskManagementRoutes),
    TranslateModule,
    ViewerCoreModule,
    CommonUiModule,
    ViewerUiModule,
  ],
  // providers: [{ provide: ROUTER_CONFIGURATION, useFactory: () => ({ initialNavigation: 'enabled' }) }],
  exports: [RouterModule],
  declarations: [
    TaskListPageComponent,
    TaskDetailsPageComponent,
    TaskPageGuardComponent,
    TaskUncompletePromptComponent,
  ],
})
export class ViewerTaskManagementModule {}
