<div class="ao-default-error__content">
  <div style="flex: 1"></div>
  <img [ngStyle]="{ height: errorImageHeight }" [src]="errorImageSrc" />
  <div class="ao-font-body2 ao-default-error__content-text">
    <p class="ao-default-error__title ao-font-title4" *ngIf="title">{{ title }}</p>
    <p class="ao-font-body2" *ngIf="description">{{ description }}</p>
    <ng-content></ng-content>
  </div>
  <div style="flex: 3"></div>
</div>
