import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TrackByFunction,
} from '@angular/core';
import { InsightsValue, Theme } from '@ao/data-models';
import { blackOrWhiteText, hexToRgb } from '@ao/utilities';

@Component({
  selector: 'ao-module-insights',
  templateUrl: './module-insights.component.html',
  styleUrls: ['./module-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleInsightsComponent {
  @Input() insights: InsightsValue[];
  @Input() contactId: number;
  @Input() demoMode = false;
  @Input() set theme(theme: Theme) {
    const matchInsightsColor = theme?.match_insights_bar_bg_color;
    this.backgroundColor = matchInsightsColor ? theme.nav_bg_color : 'FFFFFF';
    this.color = blackOrWhiteText(hexToRgb(this.backgroundColor));
  }

  private backgroundColor: string;
  color: string;

  @Output() itemClick: EventEmitter<InsightsValue> = new EventEmitter();

  @HostBinding('style.backgroundColor') get bgColor() {
    return this.backgroundColor?.startsWith('#') ? this.backgroundColor : `#${this.backgroundColor}`;
  }

  @HostBinding('class.--demo-mode') get isDemoMode() {
    return this.demoMode;
  }

  itemTrackBy: TrackByFunction<InsightsValue> = (index, item) => item.key;

  onInsightClick(insight: InsightsValue) {
    this.itemClick.emit(insight);
  }
}
