import { inject, Injectable, computed } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as SearchActions from './+state/actions/search.actions';
import * as fromSearch from './+state/selectors/search.selectors';
import { SearchState } from './+state/reducers/search.reducers';
import { MessageListMessage, SearchResultItem } from '@ao/data-models';
import { mapSearchResultItemToMessageListMessage } from './+state/services/utils';
import { RouterHistoryFacade } from '@ao/viewer-core';
import { Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { appActions } from '@ao/viewer-app-store';

@Injectable({
  providedIn: 'root',
})
export class SearchFacade {
  private navigationHistory = inject(RouterHistoryFacade);
  private router = inject(Router);

  searchQuery$: Observable<string> = this.store.select(fromSearch.selectQuery);
  searchResults$: Observable<any[]> = this.store.select(fromSearch.selectResults);
  searchResults = toSignal(this.searchResults$);
  searchLoading$: Observable<boolean> = this.store.select(fromSearch.selectLoading);
  searchError$: Observable<any> = this.store.select(fromSearch.selectError);
  recentSearches$: Observable<string[]> = this.store.select(fromSearch.selectRecentSearches);
  pagination$ = this.store.select(fromSearch.selectPagination);
  selectLastRoute = toSignal(this.navigationHistory.selectLastRoute$);
  lastVisitedItem$: Observable<number> = this.store.select(fromSearch.selectLastVisitedItem);
  searchResultsLength = computed(() => {
    return (this.searchResults()?.length ?? 0) > 0;
  });

  constructor(private store: Store<SearchState>) {}

  search(query: string): void {
    this.store.dispatch(SearchActions.searchInitiated({ query }));
    this.dispatchAmplitudeSearch();
  }

  private dispatchAmplitudeSearch() {
    this.store.dispatch(appActions.SearchStarted({ searchContext: 'homeTab' }));
  }

  nextPage() {
    this.store.dispatch(SearchActions.nextPage());
  }
  clearResults(): void {
    this.store.dispatch(SearchActions.resetSearch());
  }
  deleteRecentSearch(query: string): void {
    this.store.dispatch(SearchActions.deleteRecentSearch({ query }));
  }

  mapSearchResultItemToMessageListMessage(item: SearchResultItem, query: string): MessageListMessage {
    return mapSearchResultItemToMessageListMessage(item, query);
  }

  navigateToPreviousRoute() {
    // Remove the last "SEARCH" route from the history so that it is not navigated to search again from origin route we accessed search the first time
    this.navigationHistory.popRoute();
    this.router.navigateByUrl(this.selectLastRoute()?.path ?? '/');
  }

  setLastVisitedItem(lastVisitedItemIndex: number): void {
    this.store.dispatch(SearchActions.setLastVisitedItem({ lastVisitedItemIndex }));
  }

  paginationAvailable() {
    return this.store.select(fromSearch.paginationAvailable);
  }

  saveRecentSearch(query: string) {
    this.store.dispatch(SearchActions.saveRecentSearch({ query }));
  }
}
