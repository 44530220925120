<ng-template #templateRef>
  <div
    [ngClass]="{
      'ao-context-menu': true,
      'ao-context-menu--desktop': mode === 'DESKTOP',
      'ao-context-menu--mobile': mode === 'MOBILE'
    }"
  >
    <ul class="ao-context-menu__list">
      <li class="ao-context-menu__list-item" *ngIf="mode === 'MOBILE'">
        <button class="ao-font-body2-bold ao-context-menu__action" (click)="onDismissClick()" translate>Cancel</button>
      </li>
      <li *ngFor="let item of actions" class="ao-context-menu__list-item">
        <ul class="ao-context-menu__item-group">
          <li *ngFor="let subitem of item" class="ao-context-menu__list-subitem">
            <button
              [aoTooltip]="tooltipText"
              [tooltipEnabled]="!!subitem.tooltipText"
              class="ao-context-menu__action"
              [class.ao-context-menu__action--disabled]="subitem.disabled"
              [class.ao-context-menu__action--danger]="subitem.danger"
              (click)="onActionClick(subitem)"
            >
              <span class="ao-context-menu__action__content">
                <ao-icon
                  *ngIf="mode === 'DESKTOP' && subitem.icon"
                  class="ao-context-menu__action__icon"
                  [name]="subitem.icon"
                  [size]="20"
                ></ao-icon>
                {{ subitem.label | translate }}
              </span>
            </button>
            <ao-tooltip #tooltipText>
              <span>{{ subitem.tooltipText | translate }}</span>
            </ao-tooltip>
          </li>
          <li *ngIf="hasExtraContent" class="ao-context-menu__list-subitem">
            <ng-content></ng-content>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</ng-template>
