import { Injectable } from '@angular/core';
import { MsgModuleDataPicker } from '@ao/data-models';
import { withLatestFromLazy } from '@ao/utilities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacade } from '../../app-store.facade';
import { AppService } from '../../services/app-store.service';
import { MessageActionHandler } from '../../services/message-action-handler.service';
import * as appActions from '../actions';

@Injectable()
export class ModuleContactInfoEffects {
  constructor(
    private actions$: Actions,
    private appService: AppService,
    private appFacade: AppFacade,
    private messageActionHandler: MessageActionHandler,
  ) {}

  submitContactInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.ContactInfoSubmit),
      switchMap(({ module, contactData, messageData }) => {
        const moduleRef = typeof module.datapicker === 'number' ? `datapicker:${module.datapicker}` : null;
        return of({ module, contactData, messageData }).pipe(
          withLatestFromLazy(
            this.appFacade.moduleByModuleRef$(moduleRef) as Observable<MsgModuleDataPicker>,
            this.appFacade.isTest$,
          ),
        );
      }),
      mergeMap(([{ module, contactData, messageData }, datapicker, isTest]) => {
        const newContactData = { ...contactData, data: { ...contactData.data } };
        if (datapicker) {
          // If the datapicker is required and nothing selected, get out
          if (datapicker.required && !datapicker._selected) {
            return of(appActions.DataPickerMarkSubmitted({ module: datapicker }));
          }
          // If there is a field mapping and selected values, map it to the values being sent over
          if (datapicker._selected && datapicker.data_set && datapicker.data_set.fieldMappings) {
            for (const field of datapicker.data_set.fieldMappings) {
              if (datapicker._selected[field.from]) {
                newContactData.data[field.to] = datapicker._selected[field.from];
              }
            }
          }
        }
        const eventType = newContactData.contactId ? 'update' : 'create';

        // if it's a test we just get out, simulating a successful call
        if (isTest) {
          return of(appActions.ContactInfoSubmitSuccess({ module, contactData: newContactData, eventType }));
        }

        return this.appService.submitContactInfo(module, newContactData, messageData, eventType).pipe(
          map(() => appActions.ContactInfoSubmitSuccess({ module, contactData: newContactData, eventType })),
          catchError((error) => {
            return of(appActions.ContactInfoSubmitFail({ module, error }));
          }),
        );
      }),
    ),
  );

  submitContactInfoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.ContactInfoSubmitSuccess),
      map(({ module, eventType }) => {
        return appActions.TrackEvent({ event: { key: `contactinfo:${module.id}`, event: eventType, data: module } });
      }),
    ),
  );

  triggerButtonAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.ContactInfoSubmit, appActions.ContactInfoSubmitSuccess),
        tap(({ type, module }) => {
          const hasTrackedAction = module.actions.some((action) => {
            // 'go_to_message' in a new window needs to happen in a new window
            const openNewWindow = <any>action.open_in_new_window === '0' ? false : Boolean(action.open_in_new_window);
            return action.type === 'go_to_message' && openNewWindow;
          });
          const isTriggered = hasTrackedAction
            ? type === appActions.ContactInfoSubmit.type
            : type === appActions.ContactInfoSubmitSuccess.type;

          if (isTriggered) {
            this.messageActionHandler.trigger(module, 'contactinfo');
          }
        }),
      ),
    { dispatch: false },
  );
}
