<div
  *ngIf="listItemType !== 'none'"
  [style.background-color]="backgroundColor"
  [style.min-height]="graphicSize + 'px'"
  [style.min-width]="graphicSize + 'px'"
  class="ao-generic-list-item__graphic"
  [ngClass]="{ 'ao-generic-list-item__graphic--left-round-corners': graphicWithLeftRoundCorners }"
>
  <ng-container [ngSwitch]="listItemType">
    <ng-container *ngSwitchCase="'image'">
      <img *ngIf="image; else placeholder" [style.max-width]="graphicSize + 'px'" [src]="image" />
      <ng-template #placeholder>
        <div
          class="ao-generic-list-item__placeholder-image"
          [style.height]="graphicSize + 'px'"
          [style.width]="graphicSize + 'px'"
        >
          <ao-icon name="image"></ao-icon>
        </div>
      </ng-template>
    </ng-container>

    <div
      *ngSwitchCase="'coverImage'"
      class="ao-generic-list-item__cover-image"
      [ngClass]="{ 'ao-generic-list-item__cover-image--left-round-corners': graphicWithLeftRoundCorners }"
      [style.background-image]="image ? 'url(' + image + ')' : undefined"
      [style.height]="graphicSize + 'px'"
      [style.width]="graphicSize + 'px'"
    ></div>

    <ao-avatar *ngSwitchCase="'avatar'" [size]="'medium'" [initials]="initials" [image]="image" [images]="images">
    </ao-avatar>

    <ao-icon *ngSwitchCase="'icon'" [size]="iconSize" [style.color]="color" [name]="iconName"> </ao-icon>

    <ao-icon-avatar
      *ngSwitchCase="'iconAvatar'"
      [size]="avatarSize"
      [avatarColor]="iconColor === 'blue' ? 'reverseBlue' : iconColor"
      [iconName]="iconName"
    >
    </ao-icon-avatar>
  </ng-container>
</div>
<div class="ao-generic-list-item__list">
  <div class="ao-font-body2-bold ao-generic-list-item__list-title">
    <ng-content></ng-content>
  </div>
  <div class="ao-font-small ao-generic-list-item__list-subtitle" *ngIf="genericListItemSubTitleTemplate">
    <ng-template [ngTemplateOutlet]="genericListItemSubTitleTemplate"></ng-template>
  </div>
</div>
