import { FactoryProvider, Injectable } from '@angular/core';
import { RouterAction, routerReducer as routerStoreReducer, RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { RouterStateUrl } from './routerStateUrl';

export type RouterState = RouterReducerState<RouterStateUrl>;

export const routerReducer: (state: RouterState, action: RouterAction<any>) => RouterState = <any>routerStoreReducer;

@Injectable()
export class RouterStore extends Store<RouterState> {}

export function routerStoreFactory(store: Store<any>) {
  return store.select('router');
}

export const routerStoreProvider: FactoryProvider = {
  provide: RouterStore,
  useFactory: routerStoreFactory,
  deps: [Store],
};
