<ao-generic-page-header
  class="ao-generic-viewer-error__header"
  [type]="errorType"
  [title]="title || ('An unknown error occured' | translate)"
  [subTitle]="subTitle || _defaultSubtitle[errorType] || null"
  [text]="
    text ||
    ('We’re not sure exactly why this is happening but for more help contact #supportName# support.'
      | translate: { supportName: supportName })
  "
>
</ao-generic-page-header>

<div class="ao-generic-viewer-error__details" *ngIf="errorCode || domain || destination || sessionId">
  <span class="ao-font-tiny-bold ao-generic-viewer-error__details__title" translate> error details </span>
  <span
    class="ao-font-small ao-generic-viewer-error__details__text"
    *ngIf="errorCode"
    translate
    [translateParams]="{ errorCode: errorCode }"
  >
    Error code: #errorCode#
  </span>
  <span
    class="ao-font-small ao-generic-viewer-error__details__text"
    *ngIf="domain"
    translate
    [translateParams]="{ domain: domain }"
  >
    Domain: #domain#
  </span>
  <span
    class="ao-font-small ao-generic-viewer-error__details__text"
    *ngIf="destination"
    translate
    [translateParams]="{ destination: destination }"
  >
    Destination: #destination#
  </span>
  <span class="ao-font-small ao-generic-viewer-error__details__text" *ngIf="sessionId"> ID: {{ sessionId }} </span>
</div>

<button
  *ngIf="homeButton"
  class="ao-generic-viewer-error__button"
  type="button"
  ao-button
  buttonSize="medium"
  buttonColor="blue"
  (click)="backToHome()"
  translate
>
  Back to homepage
</button>
<button
  *ngIf="refreshButton"
  class="ao-generic-viewer-error__button"
  type="button"
  ao-button
  buttonSize="medium"
  buttonColor="blue"
  (click)="refreshPage()"
  translate
>
  Refresh the browser window
</button>
<button
  *ngIf="supportName"
  class="ao-generic-viewer-error__button"
  type="button"
  ao-button
  [outline]="true"
  buttonSize="medium"
  buttonColor="blue"
  (click)="openSupport.emit()"
  translate
>
  Contact support
</button>
<button
  class="ao-generic-viewer-error__button"
  type="button"
  ao-button
  [outline]="true"
  buttonSize="medium"
  buttonColor="blue"
  (click)="onLogout()"
  translate
>
  Logout
</button>
