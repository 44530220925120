import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, Self, SimpleChanges } from '@angular/core';
import { IconSize } from '@ao/data-models';

type StatusCircleType = 'green' | 'yellow' | 'red' | 'gray' | null;

const iconNames = {
  green: 'done',
  yellow: 'priority_high',
  red: 'close',
  gray: 'lock',
};

@Component({
  selector: 'ao-status-circle',
  templateUrl: './status-circle.component.html',
  styleUrls: ['./status-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgClass],
})
export class StatusCircleComponent implements OnChanges {
  @Input() status: StatusCircleType = null;
  @Input() size?: IconSize = 20;

  constructor(@Self() private ngClass: NgClass) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngClass.ngClass = {
      'ao-status-circle': true,
      [`ao-status-circle--type-${this.status}`]: true,
    };
    this.ngClass.ngDoCheck();
  }

  get iconName() {
    return iconNames[this.status] || 'close';
  }
}
