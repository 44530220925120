<div class="ao-login__header" [class.ao-login__header--back-shown]="showBack">
  <button class="ao-login__header-back" *ngIf="showBack">
    <ao-icon (click)="onBackClick()" [size]="30" name="arrow_back_ios"></ao-icon>
  </button>
  <img *ngIf="theme.headerLogo" class="ao-login__header-logo" [src]="theme.headerLogo" />
  <div *ngIf="showBack" class="ao-login__header-alignment-element"></div>
</div>
<div class="ao-login__pages" [ngSwitch]="state" [@pageTransitions]="chooseTransition(state)">
  <!-- Standard Login -->
  <div class="ao-login__page" *ngSwitchCase="'login'">
    <ao-login-auth
      [contact]="contact"
      [state]="loginAuthState"
      [authInProgress]="authInProgress"
      [remainingAttempts]="remainingLoginAuthAttempts"
      [isPWA]="isPWA"
      [defaultCountryCode]="loginSettings.geoIpCountryCode"
      [loginSettings]="loginSettings"
      [clientList]="clientList"
      (openSupport)="openSupport.emit()"
      (inputComplete)="loginAuthComplete.emit($event)"
      (resetRequest)="loginAuthResetRequest.emit($event)"
      (showErrorToast)="onShowErrorToast($event)"
      (pickClient)="pickClient.emit($event)"
      (logout)="logout.emit()"
    >
    </ao-login-auth>
  </div>

  <!-- SSO Login -->
  <div class="ao-login__page" *ngSwitchCase="'ssoLogin'">
    <ao-sso-login
      [providers]="providers"
      [loginSettings]="loginSettings"
      [error]="errorCode"
      [redirectUrl]="redirectUrl"
      [nativeApp]="nativeApp"
      [isAdminPreview]="isAdminPreview"
      (troubleClick)="openSupport.emit()"
      (findDifferentAppClick)="onFindDifferentAppClick()"
    >
    </ao-sso-login>
  </div>

  <!-- SSO Errors -->
  <div class="ao-login__page" *ngSwitchCase="'error'">
    <div class="ao-login__top-spacing"></div>
    <ao-generic-viewer-error
      class="ao-login__content"
      [title]="errorMessage.header | translate"
      [text]="errorMessage.text | translate"
      [refreshButton]="true"
      [errorCode]="errorCode || loginErrorCode"
      [supportName]="supportSettings.name"
      (openSupport)="openSupport.emit()"
      (logout)="logout.emit()"
    >
    </ao-generic-viewer-error>
    <div class="ao-login__bottom-spacing"></div>
  </div>

  <!-- loading -->
  <div class="ao-login__page" *ngSwitchCase="'loading'">
    <ao-loader class="ao-login__loader" size="huge"></ao-loader>
  </div>
</div>

<div class="ao-font-body2 ao-login__footer">
  <div class="ao-login__footer-block" *ngIf="showSupportInfo; else noSupportInfo">
    <span translate>Need help?</span>
    <a class="ao-font-body2-bold ao-login__footer-link" (click)="openSupport.emit()" translate>Contact your support</a>
  </div>

  <ng-template #noSupportInfo>
    <div class="ao-login__footer-block">
      <span translate>Trouble logging in?</span>
      <a class="ao-font-body2-bold ao-login__footer-link" [href]="helpLoginTroubleUrl" target="_blank" translate
        >Get help</a
      >
    </div>
  </ng-template>
  <a
    class="ao-font-body2-bold ao-login__footer-link"
    *ngIf="showAdminLoginLink"
    [href]="isAdminPreview ? 'javascript:void(0)' : '/admin/login'"
    translate
    >Log in as an admin instead</a
  >
</div>
