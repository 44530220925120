<ng-container *ngIf="zoomableImages?.[currentImage]; else singleImage">
  <img
    (load)="onImageLoaded(zoomableImages[currentImage].media_file_id)"
    (error)="onImageError()"
    (click)="onClick()"
    [aoSrc]="zoomableImages[currentImage].src"
    [sizes]="imgSizes"
    [srcset]="zoomableImages[currentImage].srcset"
    [aoUnsplashTrackViews]="trackUnsplash ? zoomableImages[currentImage].unsplash_image_id : null"
    [ngClass]="{ 'ao-zoomable-image__img--cover-image': !!coverImage }"
    class="ao-zoomable-image__img"
  />
</ng-container>

<ng-template #singleImage>
  <img
    *ngIf="imageByUrl"
    [aoSrc]="imageByUrl"
    alt=""
    class="ao-zoomable-image__img"
    [ngClass]="{ 'ao-zoomable-image__img--cover-image': !!coverImage }"
    loading="lazy"
    (error)="onImageError()"
    (click)="onClick()"
  />
  <img
    *ngIf="zoomableImage?.src"
    (load)="onImageLoaded(zoomableImage.media_file_id)"
    (error)="onImageError()"
    (click)="onClick()"
    [aoSrc]="zoomableImage.src"
    [sizes]="imgSizes"
    [srcset]="zoomableImage.srcset"
    [aoUnsplashTrackViews]="trackUnsplash ? unsplashImageId || zoomableImage?.unsplash_image_id : null"
    class="ao-zoomable-image__img"
    [ngClass]="{ 'ao-zoomable-image__img--cover-image': !!coverImage }"
  />
</ng-template>
<ng-content></ng-content>
