import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ClientListItem } from '@ao/data-models';

@Component({
  selector: 'ao-login-client-picker',
  templateUrl: './login-client-picker.component.html',
  styleUrls: ['./login-client-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginClientPickerComponent {
  @HostBinding('class.ao-login-client-picker') className = true;

  @Input() clientList: ClientListItem[];
  @Input() firstName: string;
  @Input() initials: string;
  @Input() avatar: string;

  @Output() pickClient = new EventEmitter<ClientListItem>();

  clientTrackBy = (index, homepage: any) => homepage.clientId;
}
