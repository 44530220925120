import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSize } from '@ao/data-models';
import { getMimeCategory, getMimeIconUrl } from '@ao/utilities';

@Component({
  selector: 'ao-file-type-avatar',
  templateUrl: './file-type-avatar.component.html',
  styleUrls: ['./file-type-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileTypeAvatarComponent {
  @Input() fileType: string;

  @Input() size?: IconSize;
  @Input() boxSize?: number;

  get fileIcon() {
    const iconUrl = this.fileType ? getMimeIconUrl(getMimeCategory(this.fileType)) : null;
    return iconUrl ? `url('${iconUrl}')` : '';
  }

  get parsedFileType() {
    return this.fileType ? getMimeCategory(this.fileType) || this.fileType || '' : '';
  }
}
