import { Modal, ModalResult, ModalResultConfirm, ModalResultDismiss } from './modal.interface';
import { EMPTY, from, merge, Observable, of } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { filter, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

export class ModalRef<T extends Modal<U>, U = any> {
  readonly result: Observable<ModalResult<U>>;
  readonly confirmed: Observable<U>;
  readonly dismissed: Observable<string>;

  get isOpen() {
    return this.overlayRef && this.overlayRef.hasAttached();
  }

  constructor(public overlayRef: OverlayRef, public instance: T, destroy$: Observable<void>, disposeOnClose: boolean) {
    this.result = merge(
      destroy$.pipe(mapTo(<ModalResult<U>>{ type: 'DESTROY' })),
      from(this.instance.confirm).pipe(map((value) => <ModalResult<U>>{ type: 'CONFIRM', value })),
      from(this.instance.dismiss).pipe(map((reason) => <ModalResult<U>>{ type: 'DISMISS', reason })),
    ).pipe(
      switchMap((res) => {
        if (res.type === 'DESTROY' || !this.instance.cancelClose) {
          return of(res);
        } else {
          return this.instance.cancelClose(res).pipe(
            switchMap((cancelled) => {
              if (cancelled) {
                return EMPTY;
              } else {
                return of(res);
              }
            }),
          );
        }
      }),
      take(1),
      tap((result) => {
        // do not clean up the modal before the cancel/confirm subscribe has had time to emit
        setTimeout(() => {
          if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
            if (result.type === 'DESTROY' || disposeOnClose) {
              this.overlayRef.dispose();
            }
          }
        }, 0);
      }),
    );
    this.confirmed = this.result.pipe(
      filter<ModalResultConfirm<U>>((res) => res.type === 'CONFIRM'),
      map((res) => res.value),
    );
    this.dismissed = this.result.pipe(
      filter<ModalResultDismiss>((res) => res.type === 'DISMISS'),
      map((res) => res.reason),
    );
  }

  close(reason?: string) {
    this.instance.dismiss.emit(reason);
  }
}
