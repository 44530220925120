import { selectRouteNestedParam } from '@ao/utilities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfile from './profile-store.reducer';

export const getProfileState = createFeatureSelector<fromProfile.ProfileState>(fromProfile.PROFILE_FEATURE_KEY);

// params selectors

// root level selectors
export const selectProfileEntities = createSelector(getProfileState, (state) =>
  fromProfile.profileAdapter.getSelectors().selectEntities(state),
);
export const getAllProfiles = createSelector(getProfileState, (state) =>
  fromProfile.profileAdapter.getSelectors().selectAll(state),
);
export const getLoadingStates = createSelector(getProfileState, (state) => state.loadingStateById);

export const getCurrentProfileId = createSelector(selectRouteNestedParam('profileId'), (id) => id);

export const getCurrentProfile = createSelector(
  getCurrentProfileId,
  selectProfileEntities,
  (id, profiles) => profiles[id],
);

export const getCurrentProfileLoading = createSelector(
  getCurrentProfile,
  getLoadingStates,
  (profile, loadingStates) => loadingStates?.[profile.id],
);
export const getCurrentProfileMessageList = createSelector(
  getCurrentProfile,
  (profile) => profile?.data && profile.data.messagelist,
);

export const selectSubordinatesPage = createSelector(getCurrentProfile, (profile) => profile?.subordinates);
