import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AttachmentImage,
  AttachmentInfoTypeExcludingImageAndVideo,
  AttachmentVideo,
  isImageAttachment,
  isVideoAttachment,
} from '@ao/shared-data-models';
import { SocialFacade } from '@ao/social-store';
import { defined, onceWithLatest, WINDOW } from '@ao/utilities';
import { AppFacade } from '@ao/viewer-app-store';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { TaskDetailsEntity, ViewerTaskFacade } from '@ao/viewer-task-management-store';
import { ViewerTrackingService } from '@ao/viewer-tracking-store';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, map, switchMap } from 'rxjs';

@Component({
  selector: 'ao-task-details-page',
  templateUrl: './task-details-page.component.html',
  styleUrls: ['./task-details-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDetailsPageComponent implements OnInit {
  viewerCore = inject(ViewerCoreFacade);
  viewerApp = inject(AppFacade);
  window = inject(WINDOW);
  router = inject(Router);
  routes = inject(ActivatedRoute);
  viewerTaskFacade = inject(ViewerTaskFacade);
  viewerTrackingService = inject(ViewerTrackingService);
  translate = inject(TranslateService);
  socialFacade = inject(SocialFacade);

  id = this.routes.snapshot.params['id'];
  task$ = this.viewerTaskFacade.selectTaskDetailsById$(this.id);
  taskError$ = this.viewerTaskFacade.selectTaskDetailsErrorById$(this.id);
  isLoading$ = this.viewerTaskFacade.selectTaskLoadingById$(this.id);
  isUpdating$ = (id: number) => this.viewerTaskFacade.selectTaskUpdatingById$(id);

  _showConfirmReopen = false;
  _redirectOnComplete = false;

  _fullScreenViewImage: string | false = false;
  _fullScreenViewVideo: string | false = false;

  get isIsolatedRoute() {
    return this.router.url.startsWith('/task');
  }

  contactId$ = this.viewerCore.contactId$;
  contactIdSignal = toSignal(this.contactId$);

  completedByContactName$ = this.task$.pipe(
    defined(),
    switchMap((task) => {
      if (task?.completedByContactId) {
        this.socialFacade.loadOneContact(task.completedByContactId);
        return this.socialFacade.oneContactById$({ contactId: task.completedByContactId }).pipe(
          defined(),
          map((contact) => {
            return contact.name;
          }),
        );
      }
      return EMPTY;
    }),
  );

  goToTaskList() {
    if (this.isIsolatedRoute) {
      this.router.navigate(['/task']);
    } else {
      onceWithLatest(this.viewerCore.origin$, this.viewerCore.keycode$, (origin, keycode) => {
        this.router.navigate(['/', origin, keycode, 'task']);
      });
    }
  }

  ngOnInit(): void {
    this.viewerTaskFacade.fetchTaskById(this.id);
  }

  getPrioColor(task: TaskDetailsEntity) {
    switch (task.priority) {
      case 'PRIORITY_1':
        return 'red';
      case 'PRIORITY_2':
        return 'yellow';
      case 'PRIORITY_3':
        return 'blue';
    }
    return undefined;
  }
  isComplete(task: TaskDetailsEntity) {
    return task.status === 'completed';
  }

  uncompleteTask(task: TaskDetailsEntity) {
    this.viewerTaskFacade.uncompleteTask(task.id);
    this._showConfirmReopen = false;
  }

  confirmUncompleteState(_task: TaskDetailsEntity) {
    this._showConfirmReopen = true;
  }
  dismissUncomplete() {
    this._showConfirmReopen = false;
  }

  taskCompletionToggle(event: Event, task: TaskDetailsEntity, taskCompletionContext: 'topCheckbox' | 'bottomButton') {
    event.preventDefault();

    if (task.status === 'completed') {
      this.confirmUncompleteState(task);
    } else {
      this.viewerTrackingService.taskCompleted(task.id, taskCompletionContext);
      let redirectPath = ['/task'];
      if (!this.isIsolatedRoute) {
        onceWithLatest(this.viewerCore.origin$, this.viewerCore.keycode$, (origin, keycode) => {
          redirectPath = ['/', origin, keycode, 'task'];
        });
      }
      this.viewerTaskFacade.completeTask(task.id, redirectPath);
    }
  }

  isVideo(file: AttachmentImage | AttachmentVideo): file is AttachmentVideo {
    return isVideoAttachment(file);
  }
  isImage(file: AttachmentImage | AttachmentVideo): file is AttachmentImage {
    return isImageAttachment(file);
  }

  getImageOrVideoAttachments(task: TaskDetailsEntity): (AttachmentVideo | AttachmentImage)[] {
    const items = task?.mediaFiles?.filter((file) => this.isImage(file) || this.isVideo(file)) || [];
    return [...items];
  }

  getNonImageOrVideoAttachments(task: TaskDetailsEntity): AttachmentInfoTypeExcludingImageAndVideo[] {
    const others = task?.mediaFiles?.filter((file) => !this.isImage(file) && !this.isVideo(file)) || [];
    return others as AttachmentInfoTypeExcludingImageAndVideo[];
  }

  openMediaViewer(mediaImage: AttachmentImage) {
    this._fullScreenViewImage = mediaImage.imageVersions[mediaImage.imageVersions.length - 1].url || false;
  }

  openVideoViewer(url: string) {
    this._fullScreenViewVideo = url;
  }

  closeViewers() {
    this._fullScreenViewImage = false;
    this._fullScreenViewVideo = false;
  }

  dismissToast() {
    this.viewerCore.dismissToast();
  }
  nativeAppDownloadFile(url: string) {
    this.viewerApp.nativeAppDownloadFile(url);
  }

  getFrequencyLabel(frequency: string | null) {
    switch (frequency) {
      case 'daily':
        return this.translate.instant(i18n('daily'));
      case 'weekly':
        return this.translate.instant(i18n('weekly'));
      case 'monthly':
        return this.translate.instant(i18n('monthly'));
      default:
        return '';
    }
  }

  completedByOthers(task: TaskDetailsEntity) {
    return task.completedByContactId && task.completedByContactId !== this.contactIdSignal();
  }
}
