<ao-message-modal-card
  [modalInsideModal]="true"
  [open]="isOpen"
  [autoWidth]="true"
  (close)="onDismiss($event)"
  [headerText]="'Mark task as ongoing?' | translate"
>
  <ng-container *ngIf="completionType === 'oneToOne'">
    <p>
      {{ 'This task has been marked as complete. Are you sure you want to mark this task as ongoing?' | translate }}
    </p>
  </ng-container>
  <ng-container *ngIf="completionType === 'oneToMany'">
    <p>
      {{
        'You have marked this task as complete. Marking it as ongoing will make it visible for all the task recipients.'
          | translate
      }}
    </p>
    <br />
    <p>{{ 'Are you sure you want to mark this task as ongoing?' | translate }}</p>
  </ng-container>
  <br />
  <ng-template aoMessageModalCardFooter
    ><ao-flex-block direction="column" gap="10px" style="margin-top: 0.5em">
      <button ao-button (click)="onConfirm($event)" buttonColor="blue" class="task-button">
        {{ 'Mark as ongoing' | translate }}
      </button>
      <button ao-button (click)="onDismiss($event)" buttonColor="snow" class="task-button">
        {{ 'Cancel' | translate }}
      </button>
    </ao-flex-block></ng-template
  >
</ao-message-modal-card>
