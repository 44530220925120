import { MsgModuleDataPicker } from '@ao/data-models';
import { createAction, props } from '@ngrx/store';

export const DataPickerDataSelected = createAction(
  '[Message/Module DataPicker] Data Selected',
  props<{ module: MsgModuleDataPicker; data: Record<string, string> }>(),
);
export const DataPickerMarkSubmitted = createAction(
  '[Message/Module DataPicker] DataPicker Mark Submitted',
  props<{ module: MsgModuleDataPicker }>(),
);
