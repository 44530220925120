import { getModuleRef } from '@ao/utilities';
import { on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, moduleAdapter } from './app-store.state';

export const moduleLikeOns = [
  on(appActions.Like, (state: AppState, { module, keycode, contact }): AppState => {
    const currentMessage = state.messages.entities[keycode];
    const noContact = currentMessage.anonymous || !contact || !contact.id;
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            liked: true,
            likeCount: module.liked ? module.likeCount : module.likeCount + 1,
            contacts:
              module.liked || module.contacts.length >= 3 || noContact
                ? module.contacts
                : [...module.contacts, contact.name],
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.LikeUpdateSuccess, (state: AppState, { module, contact, currentContactId }): AppState => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            liked: module.liked || contact.id === currentContactId,
            contacts: module.contacts.length >= 3 ? module.contacts : [...module.contacts, contact.name],
            likeCount: module.likeCount + 1,
          },
        },
        { ...state.modules },
      ),
    };
  }),
];
