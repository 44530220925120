import { LOADING_STATE_PAGINATED } from '@ao/data-models';
import { Notification, NotificationSettingsGroup, NotificationTab } from '@ao/shared-data-models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as NotificationsActions from './notifications-store.actions';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

export interface NotificationsState extends EntityState<Notification> {
  hasUnread: boolean;
  filterUnread: boolean;
  loadedAll?: boolean;
  loadingState: LOADING_STATE_PAGINATED;
  settingsGroups: NotificationSettingsGroup[];
  activeTab: NotificationTab;
}

export interface NotificationsPartialState {
  readonly [NOTIFICATIONS_FEATURE_KEY]: NotificationsState;
}

const sortByCreatedAt = (a: Notification, b: Notification): number => {
  return b.createdAt.toString().localeCompare(a.createdAt.toString());
};

export const notificationsAdapter: EntityAdapter<Notification> = createEntityAdapter({
  sortComparer: sortByCreatedAt,
});

export const initialState: NotificationsState = notificationsAdapter.getInitialState({
  filterUnread: null,
  loadedAll: false,
  loadingState: LOADING_STATE_PAGINATED.unloaded,
  settingsGroups: [],
  hasUnread: false,
  activeTab: 'all',
});

const notificationsReducer = createReducer(
  initialState,
  on(NotificationsActions.SetNotificationUnreadCount, (state, { unreadCount }): NotificationsState => {
    return {
      ...state,
      hasUnread: unreadCount > 0,
    };
  }),
  on(NotificationsActions.LoadFreshNotifications, (state) => {
    return {
      ...state,
      loadingState: LOADING_STATE_PAGINATED.loading,
    };
  }),
  on(NotificationsActions.LoadMoreNotifications, (state) => {
    return {
      ...state,
      loadingState: LOADING_STATE_PAGINATED.loadingMore,
    };
  }),
  on(
    NotificationsActions.LoadMoreNotificationsSuccess,
    (state, { notifications, filterUnread }): NotificationsState => {
      return {
        ...state,
        ...notificationsAdapter.upsertMany(notifications, state),
        loadedAll: notifications?.length === 0,
        filterUnread,
        loadingState: LOADING_STATE_PAGINATED.loadedMore,
      };
    },
  ),
  on(
    NotificationsActions.LoadFreshNotificationsSuccess,
    (state, { notifications, filterUnread }): NotificationsState => {
      return {
        ...state,
        ...notificationsAdapter.upsertMany(notifications, state),
        loadedAll: notifications?.length === 0,
        filterUnread,
        loadingState: LOADING_STATE_PAGINATED.loaded,
        hasUnread: false, // clear the unread notifications dot
      };
    },
  ),
  on(NotificationsActions.LoadUserNotificationSettingsSuccess, (state, { settingsGroups }): NotificationsState => {
    const groups = settingsGroups.map((group) => {
      // Filter email settings from tasks for now
      if (group.settingType === 'tasks') {
        return {
          ...group,
          settings: group.settings.filter((setting) => setting.channelSettingType !== 'email'),
        };
      }
      return group;
    });
    return {
      ...state,
      settingsGroups: groups,
    };
  }),
  on(NotificationsActions.MarkNotificationAsReadSuccess, (state, { notification, isRead }): NotificationsState => {
    return {
      ...state,
      ...notificationsAdapter.upsertOne({ ...notification, read: isRead }, state),
    };
  }),
  on(NotificationsActions.MarkNotificationAsUnreadSuccess, (state, { notification, isRead }): NotificationsState => {
    return {
      ...state,
      ...notificationsAdapter.upsertOne({ ...notification, read: isRead }, state),
    };
  }),
  on(NotificationsActions.MarkAllNotificationsAsReadSuccess, (state): NotificationsState => {
    return notificationsAdapter.map(
      (notification) => {
        return { ...notification, read: true };
      },
      { ...state },
    );
  }),
  on(
    NotificationsActions.ToggleNotificationSettingSuccess,
    (state, { settingType, channelSettingType, newValue }): NotificationsState => {
      const settingsGroups = state.settingsGroups.map((sg) => {
        if (sg.settingType === settingType) {
          return {
            ...sg,
            settings: sg.settings.map((setting) => {
              return channelSettingType === setting.channelSettingType ? { ...setting, value: newValue } : setting;
            }),
            [channelSettingType]: newValue,
          };
        }
        return sg;
      });

      return { ...state, settingsGroups };
    },
  ),
  on(NotificationsActions.ToggleGroupNotificationSettingSuccess, (state, { groupId }): NotificationsState => {
    const settingsGroups = state.settingsGroups.map((sg) => {
      if (sg.settingType === 'groups') {
        const disabledGroups = sg.disabledGroups.includes(groupId)
          ? sg.disabledGroups.filter((g) => g !== groupId)
          : [...sg.disabledGroups, groupId];
        return {
          ...sg,
          disabledGroups,
        };
      }
      return sg;
    });

    return { ...state, settingsGroups };
  }),
  on(NotificationsActions.SwitchActiveTab, (state, { activeTab }): NotificationsState => {
    return { ...state, activeTab, loadedAll: false };
  }),
);

export function reducer(state: NotificationsState | undefined, action: Action) {
  return notificationsReducer(state, action);
}
