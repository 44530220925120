import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, Self, SimpleChanges } from '@angular/core';
import { AvatarSize, IconSize } from '@ao/data-models';

@Component({
  selector: 'ao-icon-avatar',
  templateUrl: './icon-avatar.component.html',
  styleUrls: ['./icon-avatar.component.scss'],
  providers: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconAvatarComponent implements OnChanges {
  @Input() size: AvatarSize = 'big';

  @Input() avatarColor: 'red' | 'green' | 'yellow' | 'blue' | 'reverseBlue' | 'reverseInk' | string = 'blue';

  @Input() iconName: string;
  @Input() iconSize: IconSize = 'full';
  @Input() borderRadius = '50%';
  @Input() outline?: boolean = false;

  constructor(@Self() private ngClass: NgClass) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.checkClasses();
  }

  private checkClasses() {
    this.ngClass.ngClass = [
      'ao-icon-avatar',
      `ao-icon-avatar--size-${this.size}`,
      `ao-icon-avatar--color-${this.avatarColor}`,
      ...(this.outline ? [`ao-icon-avatar--outline`] : []),
    ];
    this.ngClass.ngDoCheck();
  }
}
