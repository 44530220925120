import { Injectable, inject } from '@angular/core';
import { SavedContentType } from '@ao/data-models';
import { SocialActions } from '@ao/social-store';
import { appActions } from '@ao/viewer-app-store';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { SavedContentService } from '../../services/saved-content.service';
import * as SavedContentActions from '../actions/saved-content.actions';
import { ampliViewerFrontend as ampli } from '@ao-amplitude/viewer-frontend';

@Injectable()
export class SavedContentEffects {
  private actions$ = inject(Actions);
  private viewerCoreFacade = inject(ViewerCoreFacade);
  private savedContentService = inject(SavedContentService);
  private store = inject(Store);
  private translate = inject(TranslateService);

  displayErrorToast() {
    this.viewerCoreFacade.showToast({
      listItemType: 'iconAvatar',
      iconName: 'warning',
      iconColor: 'red',
      title: i18n('Content could not be saved'),
      text: i18n('Please try again'),
      displayDuration: 5,
    });
  }
  displayFetchErrorToast() {
    this.viewerCoreFacade.showToast({
      listItemType: 'iconAvatar',
      iconName: 'warning',
      iconColor: 'red',
      title: i18n('Content could not be loaded'),
      text: i18n('Please try again'),
      displayDuration: 5,
    });
  }
  displaySuccessToast() {
    this.viewerCoreFacade.showToast({
      listItemType: 'iconAvatar',
      iconName: 'bookmark',
      iconColor: 'blue',
      title: this.translate.instant(i18n('Message saved')),
      link: this.translate.instant(i18n('savedContentMessage #profileLink#'), {
        profileLink: this.createProfileLink(),
      }),
      linkAction: appActions.NavigateToProfile({}),
      displayDuration: 5,
    });
  }

  // Method to create the HTML content for the profile link
  createProfileLink() {
    return `<span class='ao-viewer-toast-snackbar-link-click'>${this.translate.instant(i18n('profile'))}</span>`;
  }
  savedContentIsOpened$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SavedContentActions.savedContentOpened),
        tap(() => {
          ampli.savedContentOpened();
        }),
      ),
    { dispatch: false },
  );

  fetchSavedContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedContentActions.loadContent),
      switchMap(({ contentType }) => {
        if (contentType === SavedContentType.messages) {
          return this.savedContentService.fetchMessages().pipe(
            map((messages) => {
              return SavedContentActions.loadMessagesSuccess({
                messages,
              });
            }),
            catchError((error) => {
              this.displayFetchErrorToast();
              return of(SavedContentActions.loadContentFail({ error }));
            }),
          );
        }
        if (contentType === SavedContentType.academies) {
          return this.savedContentService.fetchAcademies().pipe(
            map((academies) => {
              return SavedContentActions.loadAcademiesSuccess({
                academies,
              });
            }),
            catchError((error) => {
              this.displayFetchErrorToast();
              return of(SavedContentActions.loadContentFail({ error }));
            }),
          );
        }
        if (contentType === SavedContentType.posts) {
          return this.savedContentService.fetchPosts().pipe(
            map((posts) => {
              return SavedContentActions.loadPostsSuccess({
                posts,
              });
            }),
            catchError((error) => {
              this.displayFetchErrorToast();
              return of(SavedContentActions.loadContentFail({ error }));
            }),
          );
        }
        return EMPTY;
      }),
    ),
  );

  saveItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedContentActions.saveItem),
      tap(({ contentType, itemId, itemKeycode }) =>
        this.store.dispatch(
          SavedContentActions.setItemUpdating({
            itemId,
            isUpdating: true,
          }),
        ),
      ),
      mergeMap(({ contentType, itemId, itemKeycode }) => {
        if (contentType === SavedContentType.messages) {
          return this.savedContentService.saveMessage(itemId).pipe(
            map(() => {
              this.displaySuccessToast();
              this.store.dispatch(
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
              return appActions.SavedMessageUpdate({ itemKeycode });
            }),
            catchError((error) => {
              this.displayErrorToast();
              return of(
                SavedContentActions.saveItemFail({ itemId, error }),
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
            }),
          );
        }
        if (contentType === SavedContentType.posts) {
          return this.savedContentService.savePost(itemId).pipe(
            map(() => {
              this.displaySuccessToast();
              this.store.dispatch(
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
              return SocialActions.SavedPostUpdate({ itemId });
            }),
            catchError((error) => {
              this.displayErrorToast();
              return of(
                SavedContentActions.saveItemFail({ itemId, error }),
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
            }),
          );
        }
        if (contentType === SavedContentType.academies) {
          return this.savedContentService.saveAcademy(itemId).pipe(
            map((response) => {
              this.displaySuccessToast();
              this.store.dispatch(
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
              return appActions.SavedMessageAcademyUpdate({ academyId: itemId, messageKeycode: itemKeycode });
            }),
            catchError((error) => {
              this.displayErrorToast();
              return of(
                SavedContentActions.saveItemFail({ itemId, error }),
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
            }),
          );
        }
        return EMPTY;
      }),
    ),
  );

  unsaveItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedContentActions.unsaveItem),
      tap(({ contentType, itemId, itemKeycode }) =>
        this.store.dispatch(
          SavedContentActions.setItemUpdating({
            itemId,
            isUpdating: true,
          }),
        ),
      ),
      mergeMap(({ contentType, itemId, itemKeycode }) => {
        if (contentType === SavedContentType.messages) {
          return this.savedContentService.unsaveMessage(itemId).pipe(
            map(() => {
              this.store.dispatch(
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
              return appActions.UnsavedMessageUpdate({ itemKeycode });
            }),
            catchError((error) => {
              this.displayErrorToast();
              return of(
                SavedContentActions.unsaveItemFail({ itemId, error }),
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
            }),
          );
        }
        if (contentType === SavedContentType.posts) {
          return this.savedContentService.unsavePost(itemId).pipe(
            map(() => {
              this.store.dispatch(
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
              return SocialActions.UnsavedPostUpdate({ itemId });
            }),
            catchError((error) => {
              this.displayErrorToast();
              return of(
                SavedContentActions.unsaveItemFail({ itemId, error }),
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
            }),
          );
        }
        if (contentType === SavedContentType.academies) {
          return this.savedContentService.unsaveAcademy(itemId).pipe(
            map(() => {
              this.store.dispatch(
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
              return appActions.UnsavedMessageAcademyUpdate({ academyId: itemId, messageKeycode: itemKeycode });
            }),
            catchError((error) => {
              this.displayErrorToast();
              return of(
                SavedContentActions.unsaveItemFail({ itemId, error }),
                SavedContentActions.setItemUpdating({
                  itemId,
                  isUpdating: false,
                }),
              );
            }),
          );
        }
        return EMPTY;
      }),
    ),
  );
}
