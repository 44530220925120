<article class="ao-homepagelist-item__header" (click)="this.pickClient.emit()">
  <ao-card class="ao-homepagelist-item__card" [viewerStyling]="true">
    <ao-generic-list-item [listItemType]="'image'" [image]="client.messageFaviconUrl">
      {{ client.clientName }}
      <ng-template aoListItemSubTitleTemplate>
        {{ 'Last logged in #loggedInTime#' | translate: { loggedInTime: (client.contactLastActive | aoDateFormat) } }}
      </ng-template>
    </ao-generic-list-item>
  </ao-card>
</article>
