import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ao-not-enough-data',
  templateUrl: './not-enough-data.component.html',
  styleUrls: ['./not-enough-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotEnoughDataComponent {
  @HostBinding('class.ao-not-enough-data') readonly className = true;
  @Input() notificationText?: string;
  @Input() dataIcon?: string = null;
}
