import { createReducer, on } from '@ngrx/store';
import * as TasksActions from '../actions/viewer-tasks.actions';
import { adapter, initialState } from '../viewer-tasks.state';

export const reducer = createReducer(
  initialState,

  on(TasksActions.addTask, (state, action) => adapter.addOne(action.task, state)),
  on(TasksActions.upsertTask, (state, action) => adapter.upsertOne(action.task, state)),
  on(TasksActions.addTasks, (state, action) => adapter.addMany(action.tasks, state)),
  on(TasksActions.upsertTasks, (state, action) => adapter.upsertMany(action.tasks, state)),
  on(TasksActions.updateTask, (state, action) => adapter.updateOne(action.task, state)),
  on(TasksActions.updateTasks, (state, action) => adapter.updateMany(action.tasks, state)),
  on(TasksActions.deleteTask, (state, action) => adapter.removeOne(action.id, state)),
  on(TasksActions.deleteTasks, (state, action) => adapter.removeMany(action.ids, state)),
  on(TasksActions.loadTasks, (state, action) => adapter.setAll(action.tasks, state)),
  on(TasksActions.clearTasks, (state) => adapter.removeAll(state)),

  on(TasksActions.fetchTasks, (state, action) => {
    return { ...state, isLoading: true };
  }),
  on(TasksActions.fetchTasksSuccess, (state, action) => {
    return {
      ...adapter.setAll(action.tasks, { ...state, isLoading: false }),
      pagination: { ...state.pagination, totalCount: action.totalCount },
    };
  }),
  on(TasksActions.fetchMoreTasksSuccess, (state, action) => {
    return {
      ...adapter.upsertMany(action.tasks, { ...state, isLoading: false }),
      pagination: { ...state.pagination, totalCount: action.totalCount },
    };
  }),
  on(TasksActions.fetchTasksError, (state, action) => {
    return { ...state, isLoading: false, pagination: {} };
  }),

  on(TasksActions.fetchTaskById, (state, { taskId }) => {
    return {
      ...state,
      taskDetails: {
        ...state.taskDetails,
        [taskId]: {
          loading: true,
          data: undefined,
          error: undefined,
        },
      },
    };
  }),
  on(TasksActions.fetchTaskByIdSuccess, (state, { task }) => {
    return {
      ...state,
      taskDetails: {
        ...state.taskDetails,
        [task.id]: {
          loading: false,
          data: task,
          error: undefined,
        },
      },
    };
  }),
  on(TasksActions.fetchTaskByIdError, (state, { taskId, error }) => {
    return {
      ...state,
      taskDetails: {
        ...state.taskDetails,
        [taskId]: {
          loading: false,
          data: state.taskDetails?.[taskId].data || undefined,
          error,
        },
      },
    };
  }),
  on(TasksActions.setTaskUpdating, (state, { taskId, isUpdating }) => {
    return {
      ...state,
      taskIdsUpdating: isUpdating
        ? [...(state.taskIdsUpdating || []), taskId]
        : [...(state.taskIdsUpdating || []).filter((id) => Number(id) !== Number(taskId))],
    };
  }),

  on(TasksActions.getNewTaskCountSuccess, (state, action) => {
    return { ...state, newTasksCount: action.count };
  }),
);
