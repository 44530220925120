import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AVAILABLE_LANGUAGES } from '@ao/data-models';
import { environment } from '@ao/environments';
import { removeObjectEmptyStrings } from '@ao/utilities';
import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { catchError, map, mergeMap, reduce } from 'rxjs/operators';

@Injectable()
export class ViewerUiTranslateLoaderService implements TranslateLoader {
  constructor(private http: HttpClient) {}
  getTranslation(locale: string) {
    const data = AVAILABLE_LANGUAGES[locale] || AVAILABLE_LANGUAGES['en-US'];
    return combineLatest([this.getI18nData(data.translations), this.getCountryData(data.countryData)]).pipe(
      map(([t1, t2]) => ({ ...t1, ...t2 })),
    );
  }

  private getI18nData(codes: string[]) {
    return of(...codes).pipe(
      mergeMap((code) =>
        this.http
          .get(`/assets/i18n-viewer/${code}.json`, {
            params: { _: environment.version },
          })
          .pipe(
            catchError(() => of({})),
            map((res) => removeObjectEmptyStrings(res)),
          ),
      ),
      reduce((acc, curr) => ({ ...curr, ...acc })),
    );
  }

  private getCountryData(codes: string[]) {
    return of(...codes).pipe(
      mergeMap((code) =>
        this.http
          .get(`/assets/country-data/${code}.json`, {
            params: { _: environment.version },
          })
          .pipe(catchError(() => of({}))),
      ),
      reduce((acc, curr) => ({ ...curr, ...acc })),
      map((res) => {
        const org = Object(res);
        const cKeys = {};
        Object.keys(org).forEach((key) => {
          cKeys[`COUNTRY_${key}`] = org[key];
        });
        return cKeys;
      }),
    );
  }
}
