import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageModalService } from '../../services/message-modal/message-modal.service';

@Component({
  selector: 'ao-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MessageModalComponent implements OnInit, OnDestroy {
  @ViewChild('content', { static: true }) contentRef: TemplateRef<any>;

  @Output() backdropClick = new EventEmitter<Event>();
  @Output() overlayClick = new EventEmitter<Event>();

  private destroy$ = new Subject<void>();

  private _open = true;
  @Input() set open(value: boolean) {
    this._open = value;
    if (this.initialized) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    }
  }
  get open() {
    return this._open;
  }

  @Input() width = '600px';

  private initialized = false;

  constructor(private messageModalService: MessageModalService, private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.initialized = true;
    if (this.open) {
      this.openModal();
    }
  }

  ngOnDestroy() {
    this.closeModal();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private openModal() {
    const portal = new TemplatePortal(this.contentRef, this.viewContainerRef);
    const { backdropClick, overlayClick } = this.messageModalService.openModal(portal, this.width, true);
    backdropClick.pipe(takeUntil(this.destroy$)).subscribe((e) => this.backdropClick.emit(e));
    overlayClick.pipe(takeUntil(this.destroy$)).subscribe((e) => this.overlayClick.emit(e));
  }

  private closeModal() {
    this.messageModalService.closeModal();
  }
}
