import { Injectable } from '@angular/core';
import { getRouteData, getRouteParams } from '@ao/utilities';
import { ViewerCoreFacade, viewerCoreActions } from '@ao/viewer-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of, timer } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProfileService } from '../profile-store.service';
import * as profileActions from './profile-store.actions';

@Injectable()
export class ProfileEffects {
  constructor(
    private viewerCoreFacade: ViewerCoreFacade,
    private actions$: Actions,
    private routerStore: Store,
    private profileService: ProfileService,
  ) {}

  updateContactInfoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.UpdateContactInfoSuccess),
      mergeMap(({ id, newValue, avatarUrl, avatarImages, sendHomePage }) => {
        return [
          profileActions.ProfileUpdateContactInfoSuccess({
            targetId: id,
            newValue,
            avatarUrl,
            avatarImages,
          }),
          // trigger send homepage after (potentially) updating the contact info
          ...(sendHomePage
            ? [
                profileActions.SendHomepage({
                  profileId: id,
                }),
              ]
            : []),
        ];
      }),
    ),
  );

  sendHomepage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.SendHomepage),
      withLatestFrom(
        this.viewerCoreFacade.contact$,
        this.viewerCoreFacade.contactAuthCode$,
        this.viewerCoreFacade.keycode$,
      ),
      mergeMap(([{ profileId }, contact, contactAuthCode, keycode]) => {
        const sourceId = contact && contact.id ? contact.id : null;
        return this.profileService.sendHomepage(keycode, profileId, sourceId, contactAuthCode).pipe(
          map((result) => profileActions.SendHomepageSuccess({ profileId })),
          catchError((error) => of(profileActions.SendHomepageFail({ profileId, error }))),
        );
      }),
    ),
  );

  sendHomepageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.SendHomepageSuccess),
      delay(5000),
      map(({ profileId }) => {
        return profileActions.SendHomepageReset({ profileId });
      }),
    ),
  );

  updateContactSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.UpdateProfileSettings),
      withLatestFrom(this.viewerCoreFacade.contact$, this.viewerCoreFacade.contactAuthCode$),
      mergeMap(([{ targetId, newValue, oldValue }, contact, contactAuthCode]) => {
        return this.profileService.updateContactSettings(targetId, contact.id, contactAuthCode, newValue).pipe(
          map((comment) => profileActions.UpdateProfileSettingsSuccess({ targetId, newValue })),
          catchError((error) => of(profileActions.UpdateProfileSettingsFail({ targetId, oldValue, error }))),
        );
      }),
    ),
  );

  updateContactAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.UploadContactAvatar),
      withLatestFrom(
        this.viewerCoreFacade.contact$,
        this.viewerCoreFacade.contactAuthCode$,
        this.viewerCoreFacade.keycode$,
        this.viewerCoreFacade.isS3DirectUploadEnabled$,
      ),
      mergeMap(([{ newValue, oldValue }, contact, contactAuthCode, keycode, isS3DirectUploadEnabled]) => {
        return this.profileService
          .uploadContactAvatar(contact.id, contactAuthCode, newValue, keycode, isS3DirectUploadEnabled)
          .pipe(
            mergeMap((response) => {
              return [
                profileActions.UploadContactAvatarPoll({
                  targetId: contact.id,
                  mediaId: response.id,
                }),
              ];
            }),
            catchError((error) =>
              of(profileActions.UploadContactAvatarFail({ targetId: contact.id, oldValue, error })),
            ),
          );
      }),
    ),
  );

  updateContactAvatarPoll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.UploadContactAvatarPoll),
      withLatestFrom(this.viewerCoreFacade.contact$, this.viewerCoreFacade.contactAuthCode$),
      switchMap(([{ mediaId, attempt, targetId }, contact, authCode]) =>
        this.profileService.getAvatarMedia(mediaId).pipe(
          mergeMap((res) => {
            if (res.data.status === 'Ready') {
              return [
                profileActions.SaveContactAvatarMedia({
                  contactId: contact.id,
                  mediaId: res.data?.id,
                  authCode: authCode,
                }),
              ];
            }
            if (attempt > 10) {
              return of(
                profileActions.UploadContactAvatarFail({
                  targetId: contact.id,
                  oldValue: {},
                  error: 'Failed to upload avatar',
                }),
              );
            }
            return timer(3000).pipe(
              map(() =>
                profileActions.UploadContactAvatarPoll({
                  mediaId,
                  attempt: attempt + 1,
                  targetId,
                }),
              ),
            );
          }),
          catchError((error) =>
            of(profileActions.UploadContactAvatarFail({ targetId: contact.id, oldValue: {}, error })),
          ),
        ),
      ),
    );
  });

  saveContactAvatarMedia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(profileActions.SaveContactAvatarMedia),
        mergeMap(({ mediaId, contactId, authCode }) => {
          return this.profileService.saveContactAvatar(contactId, authCode, mediaId).pipe(
            mergeMap((response) => {
              return [
                profileActions.SaveContactAvatarMediaSuccess({ mediaId: response.id }),
                profileActions.UploadContactAvatarSuccess({ targetId: contactId, newValue: response }),
                viewerCoreActions.UploadContactAvatarSuccess({ targetId: contactId, newValue: response }),
              ];
            }),
            catchError((error) => of(profileActions.SaveContactAvatarMediaFail({ contactId, mediaId, error }))),
          );
        }),
      ),
    {},
  );

  updateProfileInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.UpdateProfileInsights),
      withLatestFrom(this.routerStore.select(getRouteData), this.routerStore.select(getRouteParams)),
      map(([{ contactId }, data, params]) => {
        const page: string = (data.page || '').toLowerCase();
        const profileId = Number(params?.profileId) || null;
        return { contactId, page, profileId };
      }),
      // Only load data if on a insights page and contact id equals the profile id
      filter(({ contactId, page, profileId }) => this.isInsightsPage(page) && profileId === contactId),
      switchMap(({ page }) => {
        const hasHistory = ['rating', 'pulse'].includes(page);
        return from([
          profileActions.LoadInsightHistory({ insightType: page }),
          ...(hasHistory ? [profileActions.LoadInsightData({ insightType: page })] : []),
        ]);
      }),
    ),
  );

  createContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.CreateContact),
      withLatestFrom(
        this.viewerCoreFacade.keycode$,
        this.viewerCoreFacade.contact$,
        this.viewerCoreFacade.profileSettings$,
      ),
      mergeMap(([{ formData }, keycode, contact, profileSettings]) => {
        const creatorId = contact && contact.id ? contact.id : null;
        const createContactAuthcode =
          profileSettings && profileSettings.createContactSettings
            ? profileSettings.createContactSettings.authCode
            : null;

        return this.profileService.createContact(keycode, creatorId, createContactAuthcode, formData).pipe(
          map((res) => viewerCoreActions.CreateContactSuccess({ ...res, creatorId })),
          catchError((error) => of(viewerCoreActions.CreateContactFail({ error }))),
        );
      }),
    ),
  );

  // creation endpoint success does not provide the insights object so re-fetch the current contact

  createContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.CreateContactSuccess),
      withLatestFrom(this.viewerCoreFacade.contact$),
      mergeMap(([_, contact]) => {
        return of(profileActions.LoadProfile({ profileId: contact.id }));
      }),
    ),
  );

  private isInsightsPage(pageId = '') {
    const pages = ['engagement', 'knowledge', 'rating', 'pulse', 'points'];
    const page = pageId.toLowerCase();
    return pages.includes(page);
  }
}
