import { selectRoutePath } from '@ao/utilities';
import { createSelector } from '@ngrx/store';
import { selectViewerCoreState } from './viewer-core-store.selectors';

export const selectRouterHistory = createSelector(selectViewerCoreState, (state) => state.routerHistory);
export const selectHasNavigated = createSelector(selectRouterHistory, (state) => state.length > 1);

export const selectHasNavigatedToMessage = createSelector(selectRouterHistory, selectRoutePath, (state, path) => {
  return state.filter((route) => route.pageType === 'MESSAGE').some((route) => route.path !== path);
});

export const selectLastNavigatedMessage = createSelector(selectRouterHistory, selectRoutePath, (state, path) => {
  const messageRoutes = state.filter((route) => route.pageType === 'MESSAGE' && route.path !== path);
  return messageRoutes[messageRoutes.length - 1];
});

export const selectPreviousRoute = createSelector(selectRouterHistory, (state) =>
  state?.length ? state[state?.length - 2] : null,
);
export const selectLastRoute = createSelector(selectRouterHistory, (state) =>
  state?.length ? state[state?.length - 1] : null,
);

export const selectLastNavigateFromSavedContent = createSelector(
  selectRouterHistory,
  selectRoutePath,
  (routerHistory, currentPath) => {
    const savedContentRoutes = routerHistory.filter(
      (route) => route.pageType === 'SAVED_CONTENT' && route.path !== currentPath,
    );

    // Check if there are at least two routes and if the second to last is 'SAVED_CONTENT'
    const secondToLastRoute = routerHistory[routerHistory.length - 2];

    if (!secondToLastRoute || secondToLastRoute.pageType !== 'SAVED_CONTENT') {
      return null;
    }

    // Return the last matching route from savedContentRoutes
    return savedContentRoutes[savedContentRoutes.length - 1] || null;
  },
);

export const selectLastNavigateFromSearchPage = createSelector(
  selectRouterHistory,
  selectRoutePath,
  (routerHistory, currentPath) => {
    const searchRoutes = routerHistory.filter((route) => route.pageType === 'SEARCH' && route.path !== currentPath);

    // Check if there are at least two routes and if the second to last is 'SEARCH'
    const secondToLastRoute = routerHistory[routerHistory.length - 2];

    if (!secondToLastRoute || secondToLastRoute.pageType !== 'SEARCH') {
      return null;
    }

    // Return the last matching route from searchRoutes
    return searchRoutes[searchRoutes.length - 1] || null;
  },
);
