import { Rgb, Rgba } from '@ao/data-models';

//  apply rgb() color directly, prefix the rest with # or use default if no value
export function color(text: string, defaults?: string): string {
  text = text && (text.charAt(0) === '#' ? text.substr(1) : text);
  return (text && (text.startsWith('rgb') ? text : `#${text}`)) || defaults;
}

// increase the brightness of a color by power of x(value)
export function highlightColor(col: Rgb | string, opacity = 1, value = 1): string {
  const rgb = typeof col === 'string' ? hexToRgb(col) : col;
  return (
    'rgb' +
    (opacity !== 1 ? 'a' : '') +
    '(' +
    Math.min(Math.round(rgb[0] * value), 255) +
    ',' +
    Math.min(Math.round(rgb[1] * value), 255) +
    ',' +
    Math.min(Math.round(rgb[2] * value), 255) +
    (opacity !== 1 ? ',' + opacity : '') +
    ')'
  );
}

// change shade of the color by passing a negative or positive value e.g. -0.30
export function shadeColor(col: Rgb | string, percent: number): string {
  const rgb = typeof col === 'string' ? hexToRgb(col) : col;
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  return (
    'rgb(' +
    (Math.round((t - rgb[0]) * p) + rgb[0]) +
    ',' +
    (Math.round((t - rgb[1]) * p) + rgb[1]) +
    ',' +
    (Math.round((t - rgb[2]) * p) + rgb[2]) +
    ')'
  );
}

export function alphaColor(col: Rgb | string, percent: number): string {
  const rgb = typeof col === 'string' ? hexToRgb(col) : col;
  const [r, g, b] = rgb;
  const a = percent > 0 ? percent / 100 : 0;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function isHexColorStringReady(value?: string): string | false {
  if (!value) return false;
  const hexString = value[0] === '#' ? value : `#${value}`;
  const reg = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');
  return reg.test(hexString) ? hexString : false;
}
export function isRgbaColorStringReady(value?: string): string | false {
  // todo: add more checks and test
  if (!value) return false;
  const rgbString = value.split(',').map((v) => v.trim());
  if (rgbString.length !== 4) return false;
  return value;
}

export function hexToRgb(hex: string): Rgb {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  const rgb = /^(?:rgba?\()?(\d+), *(\d+), *(\d+)(?:\))?$/i.exec(hex);
  if (rgb) {
    return [parseInt(rgb[1], 10), parseInt(rgb[2], 10), parseInt(rgb[3], 10)];
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

export function rgbStringToHex(color) {
  return (
    '#' +
    color
      .split(',')
      .map((value) => {
        return (parseInt(value.trim(), 10) || 0).toString(16).padStart(2, '0');
      })
      .join('')
  );
}
export function hexWithAlphaToRgba(hex: string): Rgba {
  if (hex[0] === '#') {
    hex = hex.slice(1);
  }
  if (hex.length === 6) {
    return [...hexToRgb(hex), 1];
  }
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])$/;
  hex = hex.replace(shorthandRegex, function (m, r, g, b, a) {
    return r + r + g + g + b + b + a + a;
  });

  const toPercent = (value: string) => {
    const num = parseInt(value, 16);
    return Number((num / 255).toFixed(2));
  };

  const rgba = /^(?:rgba?\()?(\d+), *(\d+), *(\d+), *(\d+)(?:\))?$/i.exec(hex);
  if (rgba) {
    return [parseInt(rgba[1], 10), parseInt(rgba[2], 10), parseInt(rgba[3], 10), toPercent(rgba[4] as string)];
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), toPercent(result[4])]
    : null;
}

export function brightness(col: Rgb | string): number {
  const rgb = typeof col === 'string' ? hexToRgb(col) : col;
  return (0.299 * rgb[0]) / 255 + (0.587 * rgb[1]) / 255 + (0.114 * rgb[2]) / 255;
}

export function invert(hex: string): Rgb {
  let rgb = hexToRgb(hex);
  // eslint-disable-next-line no-useless-escape
  rgb = Array.prototype.join.call(rgb).match(/(-?[0-9\.]+)/g);
  for (let i = 0; i < rgb.length; i++) {
    rgb[i] = (i === 3 ? 1 : 255) - rgb[i];
  }
  return rgb;
}

/**
 * @see https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors/9733420#9733420
 * @param {number} r
 * @param {number} g
 * @param {number} b
 * @return {number}
 */
export function luminance(r: number, g: number, b: number): number {
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

/**
 * @see https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors/9733420#9733420
 * @param {number} rgb1
 * @param {number} rgb2
 * @return {number}
 */
export function contrast(rgb1: number[], rgb2: number[]): number {
  return (luminance(rgb1[0], rgb1[1], rgb1[2]) + 0.05) / (luminance(rgb2[0], rgb2[1], rgb2[2]) + 0.05);
}

/**
 * @see http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
 *  Color brightness is determined by the following formula:
 ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
 * @param {number} rgb
 * @param {number} threshold
 * @return {'#000000' | '#ffffff'}
 */
export function blackOrWhiteText(rgb: number[], threshold?: number): '#000000' | '#ffffff' {
  threshold = threshold || 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

  const cBrightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return cBrightness > threshold ? '#000000' : '#ffffff';
}

export function isColorDark(rgb: number[], threshold?: number): boolean {
  threshold = threshold || 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

  const cBrightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return cBrightness < threshold;
}

export function getRgbaColor(rgb: Rgb, opacity = 1, value = 1) {
  return (
    'rgb' +
    (opacity !== 1 ? 'a' : '') +
    '(' +
    Math.min(Math.round(rgb[0] * value), 255) +
    ',' +
    Math.min(Math.round(rgb[1] * value), 255) +
    ',' +
    Math.min(Math.round(rgb[2] * value), 255) +
    (opacity !== 1 ? ',' + opacity : '') +
    ')'
  );
}

export function shadeRGBColor(color: string, percent: number) {
  const f = color.split(',');
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = parseInt(f[0].slice(4));
  const G = parseInt(f[1]);
  const B = parseInt(f[2]);
  return (
    'rgb(' +
    (Math.round((t - R) * p) + R) +
    ',' +
    (Math.round((t - G) * p) + G) +
    ',' +
    (Math.round((t - B) * p) + B) +
    ')'
  );
}

export function rgbAndOpacityFromRgbaString(rgbaString?: string): [string, number] | undefined[] {
  const result = rgbaString ? rgbaString.replace('rgba(', '').replace(')', '') : undefined;
  const arr = result?.split(',').map((v) => v.trim()) || [];
  if (!result || arr.length < 3) return [undefined, undefined];
  const [r, g, b, a] = arr;
  return [`${r},${g},${b}`, a ? Number(a) * 100 : 100];
}

export function rgbStringAndOpacityToRgbaString(rgb: string, opacity = 100): string | undefined {
  const rgbNumbers = rgb?.split(',').map((v) => v.trim());
  if (!rgbNumbers || rgbNumbers.length < 3) return undefined;
  return `rgba(${rgbNumbers.join(',')},${opacity / 100})`;
}

/**
 *
 * @param rgb '255,255,255'
 * @param opacity 0 (0 - 100)
 * @returns #FFFFFF00
 */
export function rgbStringAndOpacityToHexAlphaString(rgb: string, opacity = 100): string | undefined {
  const hex = rgbStringToHex(rgb);
  const alpha = Math.round((opacity * 255) / 100)
    .toString(16)
    .padStart(2, '0')
    .toUpperCase();
  return `${hex}${alpha}`;
}
