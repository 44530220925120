<div
  class="ao-file-type-avatar"
  [aoFileTypeBg]="fileType"
  [ngStyle]="boxSize ? { width: boxSize + 'px', height: boxSize + 'px' } : undefined"
>
  <span
    *ngIf="fileType !== 'video'; else videoIcon"
    class="ao-file-type-avatar__icon"
    [style.background-image]="fileIcon"
    [ngStyle]="size ? { width: size + 'px', height: size + 'px' } : undefined"
  >
  </span>
  <ng-template #videoIcon>
    <span class="ao-file-type-avatar__video">
      <ao-icon class="ao-file-type-avatar__video-icon" name="videocam" [size]="size"></ao-icon>
    </span>
  </ng-template>

  <ng-content></ng-content>
</div>
