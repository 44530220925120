<input
  [type]="'number'"
  #input
  pattern="[0-9]*"
  *ngFor="let item of _passcode; let index = index; trackBy: trackByIndex"
  [(ngModel)]="_passcode[index]"
  (keydown)="onInputKeyDown(index, $event)"
  (keyup)="onInputKeyUp(index)"
  (keypress)="onInputKeyPress(index, $event)"
  (ngModelChange)="onInputChange()"
  [disabled]="disabled"
/>
