import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'ao-lightbox-modal',
  templateUrl: './lightbox-modal.component.html',
  styleUrls: ['./lightbox-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightboxModalComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class.lightbox-modal') classes = true;

  @Input() open: boolean;

  @Output() closeHandler = new EventEmitter<void>();

  @ViewChild('templatePortal') private template: TemplateRef<unknown>;

  private host: DomPortalOutlet;
  private portal: TemplatePortal;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngAfterViewInit(): void {
    this.host = new DomPortalOutlet(document.body);
    this.portal = new TemplatePortal(this.template, this.viewContainerRef);
    this.host.attach(this.portal);
  }

  onClose() {
    this.closeHandler.emit();
  }

  ngOnDestroy() {
    this.host.detach();
  }
}
