import {
  CompanyContext,
  GenericMessageToast,
  LOADING_STATE,
  MessageError,
  UserContext,
  ViewerSettingsFE,
} from '@ao/data-models';
import { NavPages } from '@ao/utilities';

export const VIEWER_CORE_FEATURE_KEY = 'viewerCore';

export interface RouterHistoryItem {
  path: string;
  pageType: NavPages;
}

export interface ViewerCoreState {
  routerHistory: RouterHistoryItem[];
  loadingState: LOADING_STATE;
  error: MessageError;
  toast: GenericMessageToast;
  origin: string;
  keycode: string;
  pageId: number;
  previousKeycode: string;
  queryParams: Record<string, string>;
  extraState: Record<string, string>;
  userContext: UserContext;
  companyContext: CompanyContext;
  viewerSettings: ViewerSettingsFE;
  navbarHidden: boolean;
  statusBarTheme: 'dark' | 'light';
}

export interface ViewerCorePartialState {
  readonly [VIEWER_CORE_FEATURE_KEY]: ViewerCoreState;
}

export const initialState: ViewerCoreState = {
  routerHistory: [],
  loadingState: LOADING_STATE.unloaded,
  error: {},
  toast: null,
  origin: '',
  keycode: '',
  pageId: 0,
  previousKeycode: '',
  queryParams: {},
  extraState: {},
  companyContext: {
    chatEnabled: false,
    searchEnabled: false,
    socialEnabled: false,
    socialName: '',
    directoryEnabled: false,
    autoTranslationEnabled: false,
    termsOfUse: {
      enabled: false,
      customText: null,
    },
    customFields: [],
    menuExpandedDesktop: false,
    defaultLanguage: null,
    trackingAttributes: null,
    taskManagementEnabled: false,
  },
  userContext: {
    contact: {
      msisdn: null,
      country_code: '',
      phone_number: null,
      name: '',
      initials: '',
      active: false,
      id: null,
      client_id: null,
      user_id: null,
      first_name: '',
      last_name: '',
      email: '',
      allow_contact_info: true,
      avatar_url: '',
      avatar_media_file_id: null,
      phoneIntegration: false,
      emailIntegration: false,
      modal_status: {},
      contact_status: {},
      tags: [],
    },
    unreadCounts: {},
    insightsData: [],
    contactAuthCode: '',
    profileSettings: {},
    country: 'DK',
  },
  viewerSettings: null,
  navbarHidden: false,
  statusBarTheme: null,
};
