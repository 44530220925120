<div class="ao-audio-waveform__aspect-ratio-wrap">
  <div
    *ngFor="let bar of audioBar; let i = index; trackBy: trackByIndex"
    class="ao-audio-waveform__line-wrapper"
    (click)="setPlayhead(i)"
  >
    <div
      class="ao-audio-waveform__line"
      [ngClass]="{ 'ao-audio-waveform__line--blue': !!recordAnimation, 'ao-audio-waveform__line--black': playHead > i }"
      [style.height.%]="bar"
    ></div>
  </div>
</div>
