import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as SavedContentActions from './+state/actions/saved-content.actions';
import * as SavedContentSelectors from './+state/selectors/saved-content.selectors';
import { SavedContentState } from './+state/reducers/saved-content.reducer';
import { ExtendedSavedPost } from '@ao/shared-data-models';
import { ActivatedRoute } from '@angular/router';
import { SavedContentType, SocialPost } from '@ao/data-models';
import { NavigationService } from './services/navigation.service';
import { mapExtendedSavedPostToPost } from './services/utils';

@Injectable({ providedIn: 'root' })
export class SavedContentFacade {
  constructor(
    private store: Store<SavedContentState>,
    private navigationService: NavigationService,
  ) {}

  loaded$ = this.store.pipe(select(SavedContentSelectors.selectSavedContentLoaded));

  allSavedMessages$ = this.store.pipe(select(SavedContentSelectors.selectAllSavedMessages));
  allSavedAcademies$ = this.store.pipe(select(SavedContentSelectors.selectAllSavedAcademies));
  allSavedPosts$ = this.store.pipe(select(SavedContentSelectors.selectAllSavedPosts));

  loadSavedContent(contentType: SavedContentType): void {
    this.store.dispatch(SavedContentActions.loadContent({ contentType }));
  }

  navigateToPost(post: ExtendedSavedPost, route: ActivatedRoute) {
    this.navigationService.navigateToPost(post, route);
  }
  setActiveTab(activeTab: string, route: ActivatedRoute) {
    this.navigationService.setActiveTab(activeTab, route);
  }
  navigateToProfile(contactId: number, route: ActivatedRoute) {
    this.navigationService.navigateToProfile(contactId, route);
  }
  saveItem(contentType: SavedContentType, itemId: number, itemKeycode = ''): void {
    this.store.dispatch(SavedContentActions.saveItem({ contentType, itemId, itemKeycode }));
  }
  unSaveItem(contentType: SavedContentType, itemId: number, itemKeycode = ''): void {
    this.store.dispatch(SavedContentActions.unsaveItem({ contentType, itemId, itemKeycode }));
  }
  isSavedContentLoaded$ = this.store.pipe(select(SavedContentSelectors.isLoaded));

  mapExtendedSavedPostToPost(post: ExtendedSavedPost): Partial<SocialPost> {
    return mapExtendedSavedPostToPost(post);
  }
  savedContentOpened() {
    this.store.dispatch(SavedContentActions.savedContentOpened());
  }
}
