import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@ao/environments';
import { ExtendedSavedAcademy, ExtendedSavedMessage, ExtendedSavedPost } from '@ao/shared-data-models';
import { Observable, catchError, map, throwError } from 'rxjs';

// import { academies, messages, posts } from './savedContent.mock';
import { SavedContentType } from '@ao/data-models';

@Injectable({
  providedIn: 'root',
})
export class SavedContentService {
  constructor(private http: HttpClient) {}

  // Messages
  fetchMessages(): Observable<ExtendedSavedMessage[]> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/save/messages`;

    return this.http.get<{ data: ExtendedSavedMessage[] }>(url).pipe(
      map(({ data }) => data),
      map((messages) => messages.sort((a, b) => new Date(b.savedAt).getTime() - new Date(a.savedAt).getTime())),
      catchError(customErrorCatcher),
    );
  }

  saveMessage(itemId: number): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/save/${SavedContentType.messages}/${itemId.toString()}`;
    return this.http.post(url, {});
  }

  unsaveMessage(itemId: number): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/save/${SavedContentType.messages}/${itemId.toString()}`;
    return this.http.delete(url, {});
  }

  // Academies
  fetchAcademies(): Observable<ExtendedSavedAcademy[]> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/save/academies`;

    return this.http.get<{ data: ExtendedSavedAcademy[] }>(url).pipe(
      map(({ data }) => data),
      map((academies) => academies.sort((a, b) => new Date(b.savedAt).getTime() - new Date(a.savedAt).getTime())),
      catchError(customErrorCatcher),
    );
  }

  saveAcademy(itemId: number): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/save/${SavedContentType.academies}/${itemId.toString()}`;
    return this.http.post(url, {});
  }

  unsaveAcademy(itemId: number): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/save/${SavedContentType.academies}/${itemId.toString()}`;
    return this.http.delete(url, {});
  }

  fetchPosts(): Observable<ExtendedSavedPost[]> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/save/posts`;

    return this.http.get<{ data: ExtendedSavedPost[] }>(url).pipe(
      map(({ data }) => data),
      map((posts) => posts.sort((a, b) => new Date(b.savedAt).getTime() - new Date(a.savedAt).getTime())),
      catchError(customErrorCatcher),
    );
  }
  savePost(itemId: number): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/save/${SavedContentType.posts}/${itemId.toString()}`;
    return this.http.post(url, {});
  }

  unsavePost(itemId: number): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/save/${SavedContentType.posts}/${itemId.toString()}`;
    return this.http.delete(url, {});
  }
}

export function customErrorCatcher(err?: unknown) {
  return throwError(() => {
    return (err as { error: string }).error || err;
  });
}
