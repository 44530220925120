import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SearchService } from '../services/search.service';
import * as SearchActions from '../actions/search.actions';
import * as fromSearch from '../selectors/search.selectors';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable()
export class SearchEffects {
  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.searchInitiated),
      mergeMap(({ query, filters, sorting }) =>
        this.searchService.search(query, filters, sorting).pipe(
          map((results) =>
            SearchActions.searchSuccess({
              results,
              nextPage: 1,
            }),
          ),
          catchError((error) => of(SearchActions.searchFailure({ error: error.message }))),
        ),
      ),
    ),
  );

  nextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.nextPage),
      withLatestFrom(
        this.store.select(fromSearch.selectQuery),
        this.store.select(fromSearch.selectFilters),
        this.store.select(fromSearch.selectSorting),
        this.store.select(fromSearch.selectPagination),
      ),
      mergeMap(([action, query, filters, sorting, pagination]) => {
        const nextPage = (pagination as { currentPage: number }).currentPage + 1;
        return this.searchService.search(query, filters, sorting, nextPage).pipe(
          map((results) =>
            SearchActions.searchSuccess({
              results,
              nextPage,
            }),
          ),
          catchError((error) => of(SearchActions.searchFailure({ error: error.message }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private searchService: SearchService,
    private store: Store,
  ) {}
}
