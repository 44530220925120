import { Contact, MsgModuleLike } from '@ao/data-models';
import { createAction, props } from '@ngrx/store';

export const Like = createAction('[Message/Module Like] Like', props<{ module: MsgModuleLike; keycode: string; contact: Contact }>());
export const LikeUpdate = createAction(
  '[Message/Module Like] Like Update',
  props<{ module: MsgModuleLike; contact: Contact }>(),
);
export const LikeUpdateSuccess = createAction(
  '[Message/Module Like] Like Update Success',
  props<{ module: MsgModuleLike; contact: Contact; currentContactId: number }>(),
);
