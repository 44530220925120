<div *ngIf="group && ['GROUP', 'PRIVATE'].includes(group.type)" class="ao-social-group-item__picture">
  <div
    *ngIf="group?.type === 'GROUP' || group?.backgroundImages.length > 0; else avatars"
    class="ao-social-group-item__picture-image"
    [style.background-image]="groupImage(group) | trustStyle"
  ></div>
  <ng-template #avatars>
    <div
      class="ao-social-group-item__picture-avatars"
      *ngIf="group.type === 'PRIVATE' && group?.sampleMembers?.length === 2"
    >
      <ao-avatar
        *ngFor="let member of group.sampleMembers"
        [initials]="member.initials"
        [images]="member.avatarImages"
        size="tiny"
      ></ao-avatar>
    </div>
    <div
      class="ao-social-group-item__picture-avatar"
      *ngIf="group.type === 'PRIVATE' && group?.sampleMembers?.length === 1"
    >
      <ao-avatar
        [initials]="group.sampleMembers[0].initials"
        [images]="group.sampleMembers[0].avatarImages"
        size="small"
      ></ao-avatar>
    </div>
  </ng-template>
</div>
<div class="ao-social-group-item__name">
  {{ groupName }}
</div>
