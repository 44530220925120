<ng-container *ngIf="theme$ | async; let theme">
  <ao-shell class="ao-font-max-medium" [loginTheme]="theme">
    <ao-viewer-error-page
      [theme]="theme"
      [domain]="_domain"
      [destination]="destination"
      [support]="_support"
      [errorMessage]="errorMessage"
      (logout)="logout()"
    >
    </ao-viewer-error-page>
  </ao-shell>
</ng-container>
