import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Directive({ selector: '[aoHeaderLeft]' })
export class BaseHeaderLeftDirective {}

@Directive({ selector: '[aoHeaderRight]' })
export class BaseHeaderRightDirective {}

@Component({
  selector: 'ao-base-header',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseHeaderComponent {
  @Input() closeIcon = 'arrow_back_ios';
  @Input() layout: 'center' | 'stack' = 'center';
  @Input() grow = false;
  @Input() showBackButton = true;
  @Input() showClose = false;
  @Input() navBarDisplay = false;
  @Input() bottomBorder = false;

  @HostBinding('class.ao-base-header--sticky') @Input() sticky = false;

  @Output() dismiss = new EventEmitter();

  @ContentChild(BaseHeaderRightDirective, { read: TemplateRef }) right: TemplateRef<any>;
  @ContentChild(BaseHeaderLeftDirective, { read: TemplateRef }) left: TemplateRef<any>;
}
