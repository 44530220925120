declare let google: any;

import { EventEmitter, Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable()
export class GoogleChartsLoaderService {
  private chartPackage: { [id: string]: string } = {
    AreaChart: 'corechart',
    BarChart: 'corechart',
    Table: 'table',
    PieChart: 'corechart',
    LineChart: 'corechart',
  };

  private googleScriptLoadingNotifier: EventEmitter<boolean>;
  private googleScriptIsLoading: boolean;
  private localeId: string;

  public constructor(@Inject(LOCALE_ID) localeId: string) {
    this.googleScriptLoadingNotifier = new EventEmitter();
    this.googleScriptIsLoading = false;
    this.localeId = localeId;
  }

  public load(chartType: string, apiKey?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadGoogleChartsScript()
        .then(() => {
          const initializer: any = {
            packages: [this.chartPackage[chartType]],
            language: this.localeId,
            callback: resolve,
          };
          if (apiKey) {
            initializer.mapsApiKey = apiKey;
          }
          google.charts.load('45.2', initializer);
        })
        .catch((err) => reject(err));
    });
  }

  private loadGoogleChartsScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (typeof google !== 'undefined' && google.charts) {
        resolve();
      } else if (!this.googleScriptIsLoading) {
        this.googleScriptIsLoading = true;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.gstatic.com/charts/loader.js';
        script.async = true;
        script.defer = true;
        script.onload = () => {
          this.googleScriptIsLoading = false;
          this.googleScriptLoadingNotifier.emit(true);
          resolve();
        };
        script.onerror = (err) => {
          this.googleScriptIsLoading = false;
          this.googleScriptLoadingNotifier.emit(false);
          reject(err);
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        // FYI. not a ngrx subscribe but emitter library method
        this.googleScriptLoadingNotifier.subscribe((loaded: boolean) => {
          if (loaded) {
            resolve();
          } else {
            reject(new Error());
          }
        });
      }
    });
  }
}
