import { Injectable } from '@angular/core';
import {
  AcademyViewedProperties,
  ampliViewerFrontend as ampli,
  MessageViewedProperties,
} from '@ao-amplitude/viewer-frontend';
import { ChatMessageSent, ChatOpened } from '@ao/chat-store';
import { environment } from '@ao/environments';
import { NotificationType } from '@ao/shared-data-models';
import { SocialFacade } from '@ao/social-store';
import { BrowserService, DatadogService, OneTrustConsentService, RouterStateUrl, waitForInit } from '@ao/utilities';
import { appActions, AppFacade } from '@ao/viewer-app-store';
import { viewerCoreActions, ViewerCoreFacade } from '@ao/viewer-core';
import { NotificationActions } from '@ao/viewer/notification-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ROUTER_NAVIGATED, RouterNavigationPayload } from '@ngrx/router-store';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs/operators';

enum AmplitudeEventTypes {
  NOTIFICATION_SETTINGS = 'summary',
  NOTIFICATION_SETTINGS_PUBLISHED_CONTENT = 'contentPublishedToMe',
  NOTIFICATION_SETTINGS_MENTIONS = 'mentions',
  NOTIFICATION_SETTINGS_GROUPS = 'socialGroups',
  NOTIFICATION_SETTINGS_COMMENTS = 'interestingComments',
}

@Injectable({ providedIn: 'root' })
export class ViewerTrackingStoreEffects {
  private trackingInit$ = new ReplaySubject<boolean>(1);
  private trackingConsent: boolean;
  private ampliInitialized = false;

  constructor(
    private appFacade: AppFacade,
    private viewerCoreFacade: ViewerCoreFacade,
    private socialFacade: SocialFacade,
    private actions$: Actions,
    private datadogService: DatadogService,
    private browserService: BrowserService,
    private oneTrustConsentService: OneTrustConsentService,
  ) {
    this.trackingConsent = this.oneTrustConsentService.performanceAllowed;

    this.oneTrustConsentService.performanceAllowedUpdate
      .pipe(withLatestFrom(this.viewerCoreFacade.viewerSettings$, this.viewerCoreFacade.featureFlagByKey$('tracking')))
      .subscribe(([performanceAllowed, settings, shouldTrack]) => {
        this.trackingConsent = performanceAllowed;

        if (!this.ampliInitialized && settings) {
          this.initializeAmplitude(settings, shouldTrack);
        } else {
          if (this.ampliInitialized) {
            if (performanceAllowed && shouldTrack) {
              ampli.client.setOptOut(false);
            } else {
              ampli.client.setOptOut(true);
            }
          }
        }
      });
  }

  initializeAmplitude(settings: { clientId: number; contactId: number; employees?: unknown[] }, shouldTrack: boolean) {
    const clientId = settings.clientId;
    const contactId = settings.contactId;
    const isManager = settings.employees?.length > 0;
    const isDevClient = [1].includes(clientId);

    ampli.load({
      disabled: !shouldTrack,
      environment: environment.production && !isDevClient ? 'production' : 'development',
    });
    ampli.client.setOptOut(!this.trackingConsent);

    ampli.setGroup('clientId', `${clientId}`);

    if (contactId) {
      ampli.identify(`${contactId}`, { isManager });
    }
    this.ampliInitialized = true;
    this.trackingInit$.next(true);
  }

  contextReady$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(viewerCoreActions.LoadViewerSettingsSuccess),
        withLatestFrom(this.viewerCoreFacade.featureFlagByKey$('tracking')),
        tap(([{ settings }, shouldTrack]) => {
          if (!this.browserService.isNativeAppCookieSet() && !this.trackingConsent) {
            return;
          }
          this.initializeAmplitude(settings, shouldTrack);

          if (this.browserService.isNativeAppCookieSet() && localStorage?.getItem) {
            // added localStorage?.getItem check because some samsung/android devices throw an error when trying to access localStorage
            // todo: abstract localStorage into a service for alignment all places
            ampli.nativeAppLoaded({
              mobileDeviceManufacturer: localStorage.getItem('X-Device-Manufacturer'),
              mobileDeviceType: localStorage.getItem('X-Device-Type'),
              mobileOS: localStorage.getItem('X-Mobile-OS'),
              mobileVersion: localStorage.getItem('X-Mobile-Version'),
            });
          }
        }),
      );
    },
    { dispatch: false },
  );

  // EVENTS AS A RESULT OF ACTIONS --------------------------

  menuOpened$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.ToggleMenuSidebar),
        waitForInit(this.trackingInit$),
        withLatestFrom(this.appFacade.currentSidebar$, this.appFacade.hasSidebarUnreads$),
        filter(([{ isOpen }, sidebar]) => isOpen && !!sidebar?.id),
        tap(([_, sidebar, hasNotificationBadge]) => {
          ampli.menuOpened({ hasNotificationBadge, menuId: `${sidebar.id}` });
        }),
      ),
    { dispatch: false },
  );

  socialMenuOpened$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.ToggleSocialSidebar),
        waitForInit(this.trackingInit$),
        filter(({ isOpen }) => isOpen),
        tap(() => ampli.socialMenuOpened()),
      ),
    { dispatch: false },
  );

  notificationClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.RegisterNotificationClick),
        waitForInit(this.trackingInit$),
        tap(({ notification }) => {
          ampli.notificationClicked({
            notificationType: this.mapToReadableName(notification.type),
          });
        }),
      ),
    { dispatch: false },
  );

  notificationTabClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.SwitchActiveTab),
        waitForInit(this.trackingInit$),
        tap(({ activeTab }) => {
          ampli.notificationCenterTabClicked({ activeTab });
        }),
      ),
    { dispatch: false },
  );

  datadogView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        withLatestFrom(this.appFacade.currentMessageId$),
        tap(([{ payload }, messageId]: [{ payload: RouterNavigationPayload<RouterStateUrl> }, number]) => {
          this.datadogService.startView(payload.event.url);
          this.datadogService.setMessage(payload?.routerState?.data?.page === 'MESSAGE' ? messageId : null);
        }),
      ),
    { dispatch: false },
  );

  taskOverviewOpened$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => payload),
        filter((payload) => {
          const routerState = payload?.routerState;
          // We only track opening the task overview from the navbar icon
          return routerState?.data?.page === 'TASK' && !routerState?.params?.id && routerState?.extraState?.fromNavbar;
        }),
        tap(() => {
          ampli.taskOverviewOpened();
        }),
      ),
    { dispatch: false },
  );

  taskOpened$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => payload),
        filter((payload) => payload.routerState?.data?.page === 'TASK' && payload.routerState?.params?.id),
        tap((payload) => {
          const taskId = Number(payload.routerState.params.id);
          ampli.taskOpened({ taskId });
        }),
      ),
    { dispatch: false },
  );

  socialGroupViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => payload),
        filter(
          (payload) =>
            payload.routerState?.data?.page === 'SOCIAL' &&
            !payload.routerState.outlets?.post &&
            !!payload.routerState.params?.groupId,
        ),
        concatLatestFrom((payload) => [
          this.socialFacade.oneGroupById$({
            groupId: payload.routerState.params.groupId.replace(/^.*-/, ''),
          }),
        ]),
        tap(([_, group]) => {
          const socialGroupId = `${group.id}`;
          const socialGroupType = group.type;
          ampli.socialGroupViewed({ socialGroupId, socialGroupType });
        }),
      ),
    { dispatch: false },
  );

  socialPostViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => payload),
        filter(
          (payload) =>
            payload.routerState?.data?.page === 'SOCIAL' &&
            payload.routerState.outlets?.post?.postId &&
            !!payload.routerState.params?.groupId,
        ),
        concatLatestFrom((payload) => [
          this.socialFacade.oneGroupById$({
            groupId: payload.routerState.params.groupId.replace(/^.*-/, ''),
          }),
        ]),
        tap(([payload, group]) => {
          const postId = Number(payload.routerState.outlets.post.postId);
          const socialGroupId = `${group.id}`;
          const socialGroupType = group.type;
          ampli.socialPostViewed({ postId, socialGroupId, socialGroupType });
        }),
      ),
    { dispatch: false },
  );

  profileViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => payload),
        withLatestFrom(this.viewerCoreFacade.contactId$),
        filter(
          ([payload]) => payload.routerState?.data?.page === 'PROFILE' && !!payload.routerState?.params?.profileId,
        ),
        tap(([payload, contactId]) => {
          const profileId = payload.routerState.params.profileId;
          const isManagersView = profileId !== `${contactId}`;
          ampli.profileViewed({ isManagersView });
        }),
      ),
    { dispatch: false },
  );

  notificationSettingsViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => payload),
        filter((payload) => payload.routerState?.data?.page?.includes('NOTIFICATION_SETTINGS')),
        tap((payload) => {
          ampli.notificationSettingsViewed({
            pageType: AmplitudeEventTypes[payload.routerState?.data?.page],
          });
        }),
      ),
    { dispatch: false },
  );

  insightsViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => payload),
        withLatestFrom(this.viewerCoreFacade.contactId$),
        filter(
          ([payload]) =>
            ['engagement', 'progress', 'knowledge', 'points', 'pulse', 'rating'].includes(
              payload.routerState?.data?.page,
            ) && !!payload.routerState?.params?.profileId,
        ),
        tap(([payload, contactId]) => {
          const insightsType = payload.routerState.data.page;
          const profileId = payload.routerState.params.profileId;
          const isReport = profileId !== `${contactId}`;
          ampli.insightsPageViewed({ insightsType, isReport });
        }),
      ),
    { dispatch: false },
  );

  messageViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => payload),
        withLatestFrom(this.appFacade.currentMessage$, this.viewerCoreFacade.trackingAttributes$),
        distinctUntilChanged(([prevPayload, prevMessage], [currPayload, currMessage]) => {
          if (prevPayload.routerState.data.page === 'MESSAGE' && currPayload.routerState.data.page === 'MESSAGE') {
            return prevMessage.id === currMessage.id;
          } else {
            return false;
          }
        }),
        tap(([{ routerState }, message, trackingAttributes]) => {
          if (routerState?.data?.page === 'MESSAGE' && !message.academy) {
            const props: MessageViewedProperties = {
              isHomepage: trackingAttributes?.isHomepage || false,
              isTraining: !!message.training,
              contentLanguage: message.content_language,
              messageId: message.id,
              messageType: message.type as 'normal' | 'pulse' | 'rating' | 'survey' | 'presentation',
              currentPage: Number(routerState?.params?.pageId || 0),
              navSource: routerState?.extraState?.navSource || 'directLink',
            };
            ampli.messageViewed(props);
          }
        }),
      ),
    { dispatch: false },
  );

  feedSearchStarted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.SearchStarted),
        waitForInit(this.trackingInit$),
        tap(({ searchContext }) => {
          ampli.searchStarted({ searchContext });
        }),
      ),
    { dispatch: false },
  );

  academyViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => payload),
        withLatestFrom(this.appFacade.currentMessage$),
        filter(([payload, message]) => payload.routerState?.data?.page === 'MESSAGE' && !!message.academy),
        tap(([_, message]) => {
          const props: AcademyViewedProperties = {
            academyId: message.academy.id,
            progress: message.academy.progress,
            sectionsCount: message.academy.sections.length,
            trainingsCount: (message.academy.sections || []).reduce(
              (acc, section) => acc + section.trainings.length,
              0,
            ),
            contentLanguage: message.content_language,
          };
          ampli.academyViewed(props);
        }),
      ),
    { dispatch: false },
  );

  notificationCenterViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        waitForInit(this.trackingInit$),
        map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => payload),
        filter((payload) => payload.routerState?.data?.page === 'NOTIFICATIONS'),
        tap(() => {
          ampli.notificationCenterViewed({
            hasNotificationBadge: true, // TODO: use real data once it's available
          });
        }),
      ),
    { dispatch: false },
  );

  chatOpened$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatOpened),
        waitForInit(this.trackingInit$),
        tap(() => {
          ampli.chatOpened();
        }),
      ),
    { dispatch: false },
  );

  chatMessageSent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatMessageSent),
        waitForInit(this.trackingInit$),
        tap(({ chatId, chatMediaUploaded, chatType, chatIsReply }) => {
          ampli.chatMessageSent({ chatId, chatMediaUploaded, chatType, chatIsReply });
        }),
      ),
    { dispatch: false },
  );

  // EVENTS ON NAVIGATION WITH DATA --------------------------
  // example$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ROUTER_NAVIGATED),
  //        waitForInit(this.trackingInit$),
  //       map(({ payload }: { payload: RouterNavigationPayload<RouterStateUrl> }) => {
  //         return payload.routerState?.extraState?.[TRACKING_CONSTANTS.TRACKING_STATE_KEY] as AoTrackingEventBase<SocialGroup>; // MAP TO EXTRA_STATE AND ENFORCE DATA'S TYPE
  //       }),
  //       filter((event) => !!event && event.type === ampli.socialGroupViewed.name), // FILTER TO MATCH ONLY ONE EVENT
  //       tap(({ data: group }) => {
  //         const socialGroupId = `${group.id}`;
  //         const socialGroupType = group.type === 'PRIVATE' ? 'private' : 'company';
  //         const socialGroupSize = group.type === 'DYNAMIC' ? group.count : group.memberCount;
  //         ampli.socialGroupViewed({ socialGroupId, socialGroupType, socialGroupSize }); // SEND TO ITLY
  //       })
  //     ),
  //   { dispatch: false }
  // );

  // HELPERS --------------------------

  private mapToReadableName = (t: NotificationType) => {
    switch (t) {
      case NotificationType.SocialWallPostCreated: {
        return 'PostCreated';
      }
      case NotificationType.MessagePostCommentCreated:
      case NotificationType.SocialWallCommentCreated: {
        return 'CommentCreated';
      }
      case NotificationType.MessagePostReplyCreated: {
        return 'PostReplyCreated';
      }
      case NotificationType.SocialWallCommentReplyCreated: {
        return 'CommentReplyCreated';
      }
      case NotificationType.SocialWallPostMentionCreated: {
        return 'PostWallMentionCreated';
      }
      case NotificationType.MessagePostMentionCreated: {
        return 'PostMessageMentionCreated';
      }
      case NotificationType.ContactUpdated: {
        return 'ContactUpdated';
      }
      case NotificationType.MessagePublished: {
        return 'MessagePublished';
      }
    }
  };
}
