import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { RouterStateUrl } from './routerStateUrl';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  constructor(private router: Router) {}
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    let child;
    const data = {};
    while ((child = route.children.find((r) => r.outlet === 'primary'))) {
      route = child;
      Object.assign(data, route.data || {});
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params, fragment } = route;
    const extraState = this.router.getCurrentNavigation()?.extras?.state || null;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return {
      url,
      path: url.replace(/\?.*/, ''),
      params,
      queryParams,
      fragment,
      outlets: this.outlets(routerState.root),
      data,
      ...(extraState ? { extraState } : {}),
    };
  }

  private outlets(route: ActivatedRouteSnapshot) {
    const outlets: { [name: string]: Params } = {};
    const pending = [route];
    while (pending.length) {
      const current = pending.shift();
      pending.push(...current.children);
      outlets[current.outlet] = current.params;
    }
    return outlets;
  }
}
