<ng-container [ngSwitch]="type">
  <div *ngSwitchCase="'optional'">
    {{ 'Optional [not required]' | translate }}
  </div>
  <div *ngSwitchCase="'required'">
    {{ 'Required' | translate }}
  </div>
  <div *ngSwitchCase="'custom'">
    <ng-content></ng-content>
  </div>
</ng-container>
