import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChatEffects } from './+state/chat-store.effects';
import * as fromChat from './+state/chat-store.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(fromChat.CHAT_FEATURE_KEY, fromChat.reducer),
    EffectsModule.forFeature([ChatEffects]),
  ],
})
export class ChatStoreModule {}
