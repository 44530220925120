import { getModuleRef } from '@ao/utilities';
import { on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, moduleAdapter } from './app-store.state';

export const moduleConfirmOns = [
  on(appActions.Confirm, (state: AppState, { module }) => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            confirmed: true,
          },
        },
        { ...state.modules },
      ),
    };
  }),
];
