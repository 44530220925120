import { Injectable } from '@angular/core';
import { ACTIMO_COLORS } from '@ao/data-models';
import { Options } from 'highcharts';
// service to provide standard configuration for highcharts instances

const base: Options = {
  chart: {
    style: { fontFamily: '"Effra", "Monserrat", "Helvetica Neue", Helvectica, Arial', fontSize: '12px' },
  },
  title: {
    style: { fontSize: '20px' },
    text: null,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    borderColor: null,
    borderWidth: 0,
    backgroundColor: 'rgba(255,255,255,1)',
  },
};

// ***************  circle / gauge kpi ***************
const solidgauge: Options = {
  chart: {
    ...base.chart,
    type: 'solidgauge',
    renderTo: 'container',
    margin: 0,
  },
  tooltip: {
    enabled: false,
  },
  pane: {
    size: '100%',
    background: [
      {
        borderWidth: 0,
        backgroundColor: '#E8E8EA',
        innerRadius: '90%',
        outerRadius: '100%',
        shape: 'arc',
      },
    ],
  },
  // the value axis
  yAxis: {
    min: 0,
    max: 100,
    stops: [
      [0.1, ACTIMO_COLORS.green.base], // green
    ],
    tickLength: 0,
    lineWidth: 0,
    minorTickInterval: null,
    tickAmount: 0,
    labels: {
      enabled: false,
    },
  },
  plotOptions: {
    solidgauge: {
      // innerRadius: '90%',
      dataLabels: {
        y: -40,
        borderWidth: 0,
        useHTML: true,
      },
      rounded: true,
    },
  },
  series: [
    {
      type: 'solidgauge',
      name: 'Speed',
      data: [0],
      dataLabels: {
        format: '<div style="text-align:center"><span style="font-size:40px;color: #353A46">{y}%</span></div>',
      },
      tooltip: {
        ...base.tooltip,
        valueSuffix: ' %',
      },
    },
  ],
};

// ***************  spline chart ***************
const spline: Options = {
  chart: {
    ...base.chart,
    type: 'spline',
    height: 200,
  },
  xAxis: {
    labels: {},
    gridLineWidth: 1,
    gridLineColor: '#f6f6f6',
    tickInterval: 1,
    categories: [],
  },
  yAxis: {
    allowDecimals: false,
    labels: {},
    title: {
      text: null,
    },
    gridLineWidth: 0,
    min: 0,
    minRange: 1,
  },
  series: [],
};

// *************** column (vertical) ***************
const column: Options = {
  chart: {
    ...base.chart,
    type: 'column',
    height: 200,
  },
  xAxis: {
    title: {},
    gridLineWidth: 1,
    gridLineColor: '#f6f6f6',
    tickInterval: 1,
  },
  yAxis: {
    allowDecimals: false,
    labels: {},
    title: {
      text: null,
    },
    gridLineWidth: 0,
    softMin: 0,
    softMax: 2,
  },
  series: [],
};

// ***************  bar chart (horizontal) ***************
const bar: Options = {
  chart: {
    ...base.chart,
    type: 'bar',
    marginBottom: 100,
  },
  xAxis: {
    gridLineWidth: 1,
    gridLineColor: '#f6f6f6',
    tickInterval: 1,
    labels: {
      useHTML: true,
      style: { wordBreak: 'break-all', width: 200 },
      format: '{value} ',
    },
  },
  yAxis: {
    allowDecimals: false,
    title: {
      text: null,
    },
    gridLineWidth: 0,
    min: 0,
    minRange: 1,
  },
  series: [],
};

// ***************  Pie chart ***************
const pie: Options = {
  chart: {
    ...base.chart,
    type: 'pie',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      showInLegend: true,
    },
    series: {
      dataLabels: {
        enabled: true,
        format: '{point.percentage:.1f} %',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        distance: -20, // Individual distance.
        style: {
          color: '#FFFFFF',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          textDecoration: 'none',
          textOutline: '0px',
          fontWeight: 'normal',
        },
      },
    },
  },
  series: [],
  drilldown: {},
};

@Injectable()
export class HighchartsConfigsService {
  private types = { solidgauge, spline, column, bar, pie };

  getConfig(type) {
    return {
      ...base,
      ...(this.types[type] || {}),
    };
  }
}
