import { viewerCoreSelectors } from '@ao/viewer-core';
import { createSelector } from '@ngrx/store';
import { selectInsights } from './app-store.selectors';

export const selectInsightsSettings = createSelector(selectInsights, (state) => state?.settings);
export const selectRatingSettings = createSelector(selectInsightsSettings, (state) => state?.rating);
export const selectInsightsData = createSelector(viewerCoreSelectors.selectUserContext, (state) => state.insightsData);

export const selectFilteredInsightsData = createSelector(
  selectInsightsSettings,
  selectInsightsData,
  (settings, data) => {
    return data
      .filter((insight) => {
        const setting = settings[insight.key];
        if (setting?.insightsBar <= 0 || !setting?.insightsBar) {
          return false;
        }
        if (setting && Object.prototype.hasOwnProperty.call(setting, 'active') && !setting.active) {
          return false;
        }
        // Hide points when there are no possible points
        if (insight?.type === 'points' && insight?.possible === 0) {
          return false;
        }
        return true;
      })
      .sort((n1, n2) => n1.insightsBar - n2.insightsBar);
  },
);
