import { createAction, props } from '@ngrx/store';
import { RouterHistoryItem } from '../reducers/viewer-core-store.state';

export const pushRoute = createAction('[Router History] Push Route', props<RouterHistoryItem>());
export const popRoute = createAction('[Router History] Pop Route');

export const toPreviousMessage = createAction('[Router History] To Previous Message');
export const toPreviousMessageSuccess = createAction(
  '[Router History] To Previous Message SUCCESS',
  props<RouterHistoryItem>(),
);

export const clearRoute = createAction(
  '[Router History] Clear history related to specific route',
  props<RouterHistoryItem>(),
);
export const clearHistory = createAction('[Router History] Clear history');
