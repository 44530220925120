import { MsgModuleSmsVerify, MsgModuleSmsVerifyData } from '@ao/data-models';
import { createAction, props } from '@ngrx/store';

export const SmsVerifySubmit = createAction(
  '[Message/Module SmsVerify] Submit',
  props<{ module: MsgModuleSmsVerify; data: MsgModuleSmsVerifyData }>(),
);
export const SmsVerifySubmitSuccess = createAction(
  '[Message/Module SmsVerify] Submit Success',
  props<{ module: MsgModuleSmsVerify }>(),
);
export const SmsVerifySubmitFail = createAction(
  '[Message/Module SmsVerify] Submit Fail',
  props<{ module: MsgModuleSmsVerify; error: any }>(),
);
export const SmsVerifyReset = createAction('[Message/Module SmsVerify] Reset', props<{ module: MsgModuleSmsVerify }>());
