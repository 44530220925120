import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, Self, SimpleChanges } from '@angular/core';
import { BrandingColor, IconSize } from '@ao/data-models';
import { environment } from '@ao/environments';

@Component({
  selector: 'ao-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  providers: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  _material: string;
  @Input() size: IconSize = 24;
  @Input() assetsFolder = 'material-icons';
  @Input() set name(value: string) {
    this._material = value && (this.isCustom(value) ? value : `${value.replace(/[ -]/g, '_')}`);
  }
  @Input() color?: BrandingColor | 'inkLighter' | 'currentColor';

  get classNames() {
    return {
      'ao-icon': true,
      [`ao-icon--${this.color}`]: this.color,
      [`ao-icon--size-${this.size}`]: this.size,
    };
  }
  isCustom(value?: string) {
    return (value || '').includes('/');
  }

  constructor(@Self() private ngClass: NgClass) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngClass.ngClass = this.classNames;
    this.ngClass.ngDoCheck();
  }

  get urlstring() {
    const path = this.isCustom(this._material) ? this._material : `${this.assetsFolder}/${this._material}`;
    return `url(/assets/${path}.svg?_=${environment.version})`;
  }
}
