// this util ensures that the language files are imported and provides a type safe fn to apply a global dayJs locale
import { AVAILABLE_LANGUAGES } from '@ao/data-models';
import * as dayjs from 'dayjs';
import './dayjs-locale-bundle';

export function setDayJsLocale(actimoLocaleId: string) {
  // we always store english as "en-us" so automatically assign the correct english version from the browser.
  let englishLocale = null;
  if (actimoLocaleId.toLowerCase() === 'en-us') {
    const browserLocale = window.navigator.language;
    // set custom english variation.
    englishLocale = browserLocale.substr(0, 2) === 'en' && browserLocale !== 'en' && browserLocale.toLowerCase();
  }

  dayjs.locale(englishLocale || AVAILABLE_LANGUAGES[actimoLocaleId]?.dayJsId || 'en-gb');
}
