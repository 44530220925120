<header class="ao-peer-view-main-info__header">
  <ao-avatar
    [size]="avatarSize"
    [initials]="contact?.initials"
    [image]="contact?.avatarUrl"
    [images]="contact?.avatarImages"
    class="ao-peer-view-main-info__avatar"
  >
  </ao-avatar>
</header>
<div class="ao-peer-view-main-info__content">
  <div class="ao-peer-view-main-info__contact-info">
    <h1 *ngIf="contact?.name; else nameMissing" class="ao-font-title3 ao-peer-view-main-info__name">
      {{ contact?.name }}
    </h1>
    <ng-template #nameMissing> - </ng-template>
    <div *ngIf="contact?.title || contact?.department || contact?.addrCountry">
      {{ contactInfo }}
    </div>
    <div class="ao-font-small ao-peer-view-main-info__last-active--light" *ngIf="contact?.lastActive && showLastActive">
      {{ 'Last active: #lastActive#' | translate: { lastActive: (contact?.lastActive | aoDateFormat: 'social':true) } }}
    </div>
  </div>
</div>
