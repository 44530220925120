<ao-base-header
  class="chat-creation-header__header-wrapper"
  layout="stack"
  [showBackButton]="showBackButton"
  (dismiss)="backClicked.emit()"
>
  <ao-social-wall-search-input
    [ngModel]="searchText()"
    (ngModelChange)="searched.emit($event)"
  ></ao-social-wall-search-input>
</ao-base-header>
