<div class="task-item" [ngClass]="stateClasses">
  <button type="button" class="task-item__checkbox" (click)="onClickCheckbox($event)">
    <ao-icon-box
      class="task-item__checkmark"
      [iconName]="isComplete ? 'check' : undefined"
      [bgColor]="isComplete ? 'blue' : undefined"
      [isLoading]="loadingSkeleton"
    >
    </ao-icon-box>
  </button>
  <button type="button" class="task-item__content" (click)="onClick()">
    <div class="task-item__body">
      <div class="task-item__title">
        <ao-icon class="task-item__recurrent" name="repeat" color="blue" [size]="16" *ngIf="isRecurrent"></ao-icon>
        <ao-icon class="task-item__recurrent" name="people" color="blue" [size]="16" *ngIf="isToMany"></ao-icon>
        {{ title }}
      </div>
      <div class="task-item__sub" *ngIf="subTitle">
        <ao-icon name="date_range" [size]="16"></ao-icon>
        <span>{{ subTitle }}</span>
      </div>
    </div>
    <div class="task-item__end">
      <span class="task-item__badge" *ngIf="priority"> P{{ priority }} </span>
    </div>
  </button>
</div>
