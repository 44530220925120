<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="skeletonItemType.Button">
    <ao-tag class="ao-skeleton-item--button"></ao-tag>
  </ng-container>

  <ng-container *ngSwitchCase="skeletonItemType.Statistic">
    <div class="ao-skeleton-item--statistic">
      <ao-tag class="ao-skeleton-item--statistic--label">&nbsp;</ao-tag>
      <ao-tag class="ao-skeleton-item--statistic--item">&nbsp;</ao-tag>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="skeletonItemType.MultiTags">
    <div class="ao-skeleton-item--multi-tags">
      <div class="ao-skeleton-item--multi-tags--group">
        <ao-tag class="ao-skeleton-item--multi-tags--label">&nbsp;</ao-tag>
        <ao-tag class="ao-skeleton-item--multi-tags--item">&nbsp;</ao-tag>
      </div>
      <ao-tag class="ao-skeleton-item--multi-tags--item">&nbsp;</ao-tag>
      <ao-tag class="ao-skeleton-item--multi-tags--item">&nbsp;</ao-tag>
      <ao-tag class="ao-skeleton-item--multi-tags--item">&nbsp;</ao-tag>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="skeletonItemType.Preview">
    <div class="ao-skeleton-item--preview">
      <div class="ao-skeleton-item--preview--group">
        <ao-tag class="ao-skeleton-item--preview--label">&nbsp;</ao-tag>
        <ao-tag class="ao-skeleton-item--preview--item">&nbsp;</ao-tag>
      </div>
      <ao-tag class="ao-skeleton-item--preview--image">&nbsp;</ao-tag>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="skeletonItemType.DirectoryItem">
    <div class="ao-skeleton-item--directory-item">
      <div class="ao-skeleton-item--directory-item__avatar-wrapper">
        <ao-avatar
          class="ao-skeleton-item--directory-item--avatar"
          [size]="'medium'"
          [skeleton]="true"
          [bgColor]="ACTIMO_COLORS.snow.base"
        ></ao-avatar>
      </div>
      <div class="ao-skeleton-item--directory-item__contact-details">
        <ao-tag class="ao-skeleton-item--directory-item__contact-details--name"></ao-tag>
        <ao-tag class="ao-skeleton-item--directory-item__contact-details--credentials"></ao-tag>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="skeletonItemType.SubordinateDirectoryItem">
    <div class="ao-skeleton-item--subordinate-directory-item">
      <ao-tag class="ao-skeleton-item--subordinate-directory-item--label"></ao-tag>
      <ao-tag class="ao-skeleton-item--subordinate-directory-item--activity"></ao-tag>
      <div class="ao-skeleton-item--subordinate-directory-item__contact-details">
        <div class="ao-skeleton-item--subordinate-directory-item__avatar-wrapper">
          <ao-avatar
            class="ao-skeleton-item--subordinate-directory-item--avatar"
            [size]="'medium'"
            [skeleton]="true"
            [bgColor]="ACTIMO_COLORS.snow.base"
          ></ao-avatar>
        </div>
        <div class="ao-skeleton-item--subordinate-directory-item__contact-details--stat">
          <ao-tag class="ao-skeleton-item--subordinate-directory-item__contact-details--stat--name"></ao-tag>
          <ao-tag class="ao-skeleton-item--subordinate-directory-item__contact-details--stat--credentials"></ao-tag>
        </div>
        <div class="ao-skeleton-item--subordinate-directory-item__contact-details--stat">
          <ao-tag class="ao-skeleton-item--subordinate-directory-item__contact-details--stat--name"></ao-tag>
          <ao-tag class="ao-skeleton-item--subordinate-directory-item__contact-details--stat--credentials"></ao-tag>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
