import { localizeNumber } from './digits-info';

const symbols = ['', 'K', 'M'];

export function friendlyNumber(value: number, lang: string, limit?: number): string {
  let i = 0;
  while (value > (limit || 1000) && i < symbols.length - 1) {
    i += 1;
    value = value / 1000;
  }
  return localizeNumber(value, lang, '1.0-1') + symbols[i];
}
