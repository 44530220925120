export enum LOADING_STATE {
  unloaded = 'unloaded',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}
export type LOADING_STATE_VALUE = keyof typeof LOADING_STATE;

export enum TRANSLATION_STATUS {
  'unneccesary',
  'original',
  'requesting',
  'translated',
  'error',
}

export enum LOADING_STATE_PAGINATED {
  'unloaded',
  'loading',
  'loaded',
  'error',
  'loadingMore',
  'loadedMore',
  'errorMore',
}

export interface Paginated<T> {
  totalItems: number;
  items: T[];
}

export enum EMPTY_STATE {
  'loading' = 'loading',
  'empty' = 'empty',
  'notEmpty' = 'notEmpty',
  'noSearchResults' = 'noSearchResults',
  'failedToLoad' = 'failedToLoad',
}

export interface DataLoading<T> {
  data?: T;
  loading: boolean;
  error?: unknown;
}

export interface ByPeriod<T> {
  [period: string]: T;
}
export interface ByCategoryId<T> {
  [categoryId: string]: T;
}
export interface ByDepartmentId<T> {
  [departmentId: string]: T;
}
