import { transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
} from '@angular/core';
import { addLoginStyles, ThemeHandlerService } from '@ao/common-ui';
import {
  AuthInProgress,
  ClientListItem,
  ERROR_CONSTANT,
  GenericMessageToast,
  HELPDESK_URLS,
  LoginAuthState,
  LoginContact,
  LoginTheme,
  SupportSettings,
  ViewerLoginErrorCode,
  ViewerLoginProvider,
  ViewerLoginSettings,
  ViewerLoginState,
} from '@ao/data-models';
import { BrowserService, fade, fadeInOut, slideToLeft, slideToRight } from '@ao/utilities';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'ao-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    fadeInOut('fadeInOut', 600, 600),
    trigger('pageTransitions', [
      transition('* => loading', fade()),
      transition('loading => *', fade()),
      transition('* => isLeft', slideToLeft()),
      transition('* => isRight', slideToRight()),
      transition('isRight => *', slideToLeft()),
      transition('isLeft => *', slideToRight()),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnDestroy {
  @HostBinding('class.ao-login') className = true;

  @Input() state: ViewerLoginState;
  @Input() loginAuthState: LoginAuthState;
  @Input() remainingLoginAuthAttempts: number;

  // native app flag
  @Input() nativeApp = false;
  @Input() ssoEnabled = false;
  @Input() theme: LoginTheme;
  @Input() isPWA = false;
  @Input() isAdminPreview?: boolean = false;
  @Input() authInProgress: AuthInProgress = null;
  @Input() contact: LoginContact;
  @Input() errorCode: ViewerLoginErrorCode;
  @Input() loginErrorCode: string;
  @Input() providers: ViewerLoginProvider[] = [] as ViewerLoginProvider[];
  @Input() loginSettings: ViewerLoginSettings = {} as ViewerLoginSettings;
  @Input() redirectUrl = '';
  @Input() clientList: ClientListItem[];
  @Input() supportSettings: SupportSettings;

  @Output() findDifferentClick = new EventEmitter();
  @Output() openSupport = new EventEmitter();
  @Output() loginAuthComplete = new EventEmitter<string>();
  @Output() loginAuthResetRequest = new EventEmitter<{
    type: string;
    value: string;
  }>();
  @Output() stateChange = new EventEmitter<ViewerLoginState>();
  @Output() pickClient = new EventEmitter<ClientListItem>();
  @Output() logout = new EventEmitter();
  @Output() errorToast = new EventEmitter<GenericMessageToast>();

  helpLoginTroubleUrl = HELPDESK_URLS.loginTrouble;

  codes = ViewerLoginErrorCode;

  errorTexts = {
    'provider-rejected': {
      header: i18n('Incorrect login'),
      text: i18n(
        'The login credentials you entered seem to be incorrect. If this error persists, please contact support.',
      ),
      button: '',
    },
    'configuration-error': {
      header: i18n('A configuration error has occured'),
      text: i18n(
        'There is a configuration error in the setup for this SSO provider. Please contact support if this error persists.',
      ),
      button: '',
    },
    'provider-expired': {
      header: i18n('Your session has expired'),
      text: i18n('You’ve been logged out. Please log in again.'),
      button: '',
    },
    'no-contact-found': {
      header: i18n('No contact'),
      text: i18n('Contact not found with provided federated linked value'),
      button: '',
    },
    unknown: {
      header: i18n('An unknown error occured'),
      text: i18n('We couldn’t log you in through this SSO provider. If this error persists, please contact support'),
      button: '',
    },
  };

  constructor(
    @Optional() private themeHandler: ThemeHandlerService,
    private browser: BrowserService,
  ) {
    if (themeHandler) {
      themeHandler.registerLoginStyle('LoginComponent', this.addStyles);
    }
  }

  ngOnDestroy(): void {
    this.themeHandler.unregisterLoginStyle('LoginComponent');
  }

  onShowErrorToast(type) {
    const toast: GenericMessageToast = {
      title: '',
      listItemType: 'iconAvatar',
      iconName: 'warning',
      iconColor: 'yellow',
    };

    switch (type) {
      case 'incognito':
        toast.title = i18n('Browsing in incognito?');
        toast.text = i18n(
          "It looks like your're in incoqnito mode. this means you may have to log in again every time you use the app.",
        );
        break;
      default:
        toast.title = i18n('Sorry, something went wrong');
        toast.text = '';
        break;
    }
    this.errorToast.emit(toast);
  }

  // sso app switching
  onFindDifferentAppClick() {
    this.findDifferentClick.emit();
  }

  // return to first slide
  onBackClick() {
    this.stateChange.emit(this.ssoEnabled ? 'ssoLogin' : 'login');
  }

  // which pages to display the back arrow on
  get showBack() {
    return ['error'].includes(this.state);
  }

  get showSupportInfo() {
    return (
      this.loginSettings &&
      this.loginSettings.support &&
      (this.loginSettings.support.email ||
        this.loginSettings.support.url ||
        (this.loginSettings.support.phone &&
          this.loginSettings.support.phone.country_code &&
          this.loginSettings.support.phone.phone_number))
    );
  }

  get showAdminLoginLink() {
    return !this.nativeApp && !this.browser.isMobileOrTabletOS() && !this.isAdminPreview;
  }

  get errorMessage() {
    return this.errorTexts[this.errorCode] || ERROR_CONSTANT[this.loginErrorCode] || ERROR_CONSTANT['UNKNOWN'];
  }

  // adjust left <--> right slide animation direction
  chooseTransition(state: ViewerLoginState) {
    if (state === 'loading') return state;
    return ['ssoLogin', 'login'].includes(state) ? 'isRight' : 'isLeft';
  }

  // using some parts of the message theme
  addStyles = (theme: LoginTheme) => {
    return addLoginStyles(theme, null, this.isAdminPreview);
  };
}
