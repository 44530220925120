import { MsgModuleContactInfoViewer } from '@ao/data-models';
import { createAction, props } from '@ngrx/store';

export const ContactInfoOptionClicked = createAction(
  '[Message/Module Contact Info] Option Clicked',
  props<{ module: MsgModuleContactInfoViewer; optionData: { index: number; value: boolean } }>(),
);
export const ContactInfoData = createAction(
  '[Message/Module Contact Info] Contact Info Data',
  props<{
    module: MsgModuleContactInfoViewer;
    result: { fields?: any[]; data?: any; field_info?: any; optionsData?: any[]; preparedFields?: boolean };
  }>(),
);
export const ContactInfoSubmit = createAction(
  '[Message/Module Contact Info] Contact Info Submit',
  props<{
    module: MsgModuleContactInfoViewer;
    contactData: {
      data: any;
      optionsData: any[];
      checkedOptions: Set<number>;
      contactId: number;
    };
    messageData: { messageId: number; origin: string };
  }>(),
);
export const ContactInfoSubmitSuccess = createAction(
  '[Message/Module Contact Info] Contact Info Submit Success',
  props<{
    module: MsgModuleContactInfoViewer;
    contactData: { data: any; optionsData: any[] };
    eventType: string;
  }>(),
);
export const ContactInfoSubmitFail = createAction(
  '[Message/Module Contact Info] Contact Info Submit Fail',
  props<{
    module: MsgModuleContactInfoViewer;
    error: any;
  }>(),
);
