import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as routerActions from './router.actions';
import * as routerSelectors from './router.selectors';

export type NavPages =
  | 'MESSAGE'
  | 'SOCIAL'
  | 'NOTIFICATIONS'
  | 'PROFILE'
  | 'TASK'
  | 'CHAT'
  | 'SAVED_CONTENT'
  | 'SEARCH';

@Injectable({ providedIn: 'root' })
export class RouterFacade {
  routeState$ = this.store.pipe(select(routerSelectors.getRouteState));
  routePath$ = this.store.pipe(select(routerSelectors.selectRoutePath));
  routeParams$ = this.store.pipe(select(routerSelectors.getRouteParams));
  routeOutlets$ = this.store.pipe(select(routerSelectors.getRouteOutlets));
  routeQuery$ = this.store.pipe(select(routerSelectors.getRouteQuery));
  routeData$ = this.store.pipe(select(routerSelectors.getRouteData));
  routeDataPage$: Observable<NavPages> = this.store.pipe(select(routerSelectors.getRouteDataPage));
  routeFragment$ = this.store.pipe(select(routerSelectors.getRouteFragment));
  routeUrl$ = this.store.pipe(select(routerSelectors.getRouteUrl));
  routeNestedParams$ = this.store.pipe(select(routerSelectors.selectRouteNestedParams));

  constructor(private store: Store) {}

  navigateTo(path: any[], query?: Record<string, unknown>, extras?: NavigationExtras): void {
    this.store.dispatch(routerActions.RouterGo({ path, query, extras }));
  }

  navigateBack(): void {
    this.store.dispatch(routerActions.RouterBack());
  }

  navigateForward(): void {
    this.store.dispatch(routerActions.RouterForward());
  }
}
