import { ImageHandlerMedia } from '../media';

export const messageTypeMap = {
  communicate: ['normal', 'presentation', 'survey', 'simpleSMS'],
  normal: ['normal'],
  presentation: ['presentation'],
  survey: ['survey'],
  sms: ['simpleSMS'],
  oneonone: ['rating', 'rating_template'],
  pulse: ['pulse', 'pulse_template'],
};

export enum messageTypeMapReversed {
  normal = 'normal',
  presentation = 'presentation',
  survey = 'survey',
  simpleSMS = 'sms',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  rating = 'oneonone',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  rating_template = 'oneonone',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  pulse_template = 'pulse',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  pulse = 'pulse',
}
// #endregion

// #region both frontend & backend
// messageTypes used in GET /folder/message?messageType=oneonone
export const messageTypesMap: { [K in keyof typeof messageTypeMap]: K } = {
  communicate: 'communicate',
  normal: 'normal',
  presentation: 'presentation',
  survey: 'survey',
  sms: 'sms',
  oneonone: 'oneonone',
  pulse: 'pulse',
} as const;
// #endregion

// #region frontend

export type AdminEditorType =
  | 'communicate'
  | 'normal'
  | 'presentation'
  | 'survey'
  | 'sms'
  | 'oneonone'
  | 'pulse'
  | 'academy';

export type MessageDuplicationOptions = {
  user_id?: number;
  client_id?: number;
  selected_project?: number;
  copy_theme?: boolean;
  users?: any[];
  subject?: string;
  type?: string;
  active?: boolean;
  recurring_message_id?: number;
  recurring_message_sequence_date?: number;
  send_reminders?: number;
  reminder_rules?: any;
  reminder_items?: any;
  reminder_type?: string;
  shouldApplyDefaultTheme?: boolean;
  addToFolder?: boolean;
  path?: string;
  shouldRenameMessage?: boolean;
  isDuplicateAcademy?: boolean;
  isCopyToDifferentClient?: boolean;
};

export type MultipleMessageDuplicationOptions = {
  isApi: boolean;
  messageDuplicationOptions: MessageDuplicationOptions;
};

export type OriginalToDuplicatedMessageId = {
  [oldMessageId: number]: number;
};

export const editorRouterToTypeMap: { [key: string]: AdminEditorType } = {
  communication: 'communicate',
  messages: 'normal',
  presentations: 'presentation',
  surveys: 'survey',
  sms: 'sms',
  oneonone: 'oneonone',
  pulse: 'pulse',
  academies: 'academy',
} as const;

export const editorTypeToRouterMap: { [key: string]: AdminEditorType } = Object.keys(editorRouterToTypeMap).reduce(
  (ret, key) => {
    ret[editorRouterToTypeMap[key]] = key;
    return ret;
  },
  {},
);

export type MessagePreview = {
  title?: string;
  titlePreview?: string;
  text?: string;
  textPreview?: string;
  media?: ImageHandlerMedia;
  mediaPreview?: ImageHandlerMedia;
};

// Define constants for use with special message types
export const messageTypes = {
  survey: 'survey',
  smsverification: 'smsverification',
  pulse: 'pulse',
  rating: 'rating',
  normal: 'normal',
  presentation: 'presentation',
  pulse_template: 'pulse_template',
  rating_template: 'rating_template',
  academy: 'academy',
  simpleSMS: 'simpleSMS',
} as const; // read-only properties

export type RawMessagePreview = {
  title?: string;
  titlePreview?: string;
  text?: string;
  textPreview?: string;
  media?: {
    mediaFileId?: number;
    videoMediaFileId?: number;
    youtubeKey?: string;
    thumbnailSource?: string;
    url?: string;
  };
  mediaPreview?: {
    mediaFileId?: number;
  };
};
