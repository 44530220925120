<div
  class="ao-notification-list-item__container"
  (click)="notificationClicked.emit()"
  [ngClass]="{ 'ao-notification-list-item__container--read': notification.read }"
>
  <ng-template [ngTemplateOutlet]="graphicTemplate"></ng-template>
  <div class="ao-notification-list-item__body">
    <ng-container [ngSwitch]="notification.type">
      <span
        *ngSwitchCase="notificationType.SocialWallPostMentionCreated"
        [innerHTML]="
          '<strong>(#name#)</strong> mentioned you in <strong>(#group#)</strong>'
            | translate: { name: authorFullName, group: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <span
        *ngSwitchCase="notificationType.MessagePostMentionCreated"
        [innerHTML]="
          '<strong>(#name#)</strong> mentioned you in a comment in <strong>(#message#)</strong>'
            | translate: { name: authorFullName, message: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <span
        *ngSwitchCase="notificationType.SocialWallPostCreated"
        [innerHTML]="
          '<strong>(#name#)</strong> has posted in <strong>(#group#)</strong>'
            | translate: { name: authorFullName, group: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <ng-container *ngSwitchCase="notificationType.MessagePublished">
        <span
          *ngIf="!messageCategoryName"
          [innerHTML]="'<strong>(#message#)</strong> has been published to you' | translate: { message: messageTitle }"
        ></span>
        <span
          *ngIf="messageCategoryName"
          [innerHTML]="
            '<strong>(#message#)</strong> has been published to you in <strong>(#category#)</strong>'
              | translate: { message: messageTitle, category: messageCategoryName }
          "
        ></span>
      </ng-container>
      <ng-container *ngSwitchCase="notificationType.SocialWallCommentCreated">
        <span
          *ngIf="!rootPostAuthor"
          [innerHTML]="
            '<strong>(#name#)</strong> commented on your post in <strong>(#group#)</strong>'
              | translate: { name: authorFullName, group: getNotificationPostGroupName(notification.data) }
          "
        ></span>
        <span
          *ngIf="rootPostAuthor"
          [innerHTML]="
            '<strong>(#name#)</strong> commented on <strong>(#rootPostAuthor#)</strong> post in <strong>(#group#)</strong>'
              | translate
                : {
                    name: authorFullName,
                    group: getNotificationPostGroupName(notification.data),
                    rootPostAuthor: rootPostAuthor
                  }
          "
        ></span>
      </ng-container>
      <span
        *ngSwitchCase="notificationType.MessagePostCommentCreated"
        [innerHTML]="
          '<strong>(#name#)</strong> commented on <strong>(#message#)</strong>'
            | translate: { name: authorFullName, message: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <span
        *ngSwitchCase="notificationType.MessagePostReplyCreated"
        [innerHTML]="
          '<strong>(#name#)</strong> replied to your comment in <strong>(#message#)</strong>'
            | translate: { name: authorFullName, message: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <span
        *ngSwitchCase="notificationType.SocialWallCommentReplyCreated"
        [innerHTML]="
          '<strong>(#name#)</strong> replied to your comment in <strong>(#message#)</strong>'
            | translate: { name: authorFullName, message: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <span
        *ngSwitchCase="notificationType.TaskPublished"
        [innerHTML]="
          'A new task has been assigned to you: <strong>#headline#</strong>'
            | translate: { headline: getNotificationTaskDataHeadline(notification.data) }
        "
      ></span>
    </ng-container>
    <time class="ao-notification-list-item__date" [aoTime]="notification.createdAt" format="messagelist"></time>
  </div>
  <div class="ao-notification-list-item__menu-container" (click)="onClickMenuButton($event)">
    <ao-icon class="ao-notification-list-item__menu" [size]="24" name="more_horiz" [aoContextMenu]="menu"></ao-icon>
  </div>
</div>

<ao-context-menu
  #menu
  [mode]="contextMenuMode"
  [hasExtraContent]="contextMenuMode === 'MOBILE'"
  [actions]="contextMenuActions"
  (actionClick)="onSelectMenuAction($event)"
>
  <div class="ao-notification-list-item__context-menu">
    <div class="ao-notification-list-item__context-menu__graphic">
      <ng-template [ngTemplateOutlet]="graphicTemplate"></ng-template>
    </div>
    <ng-container [ngSwitch]="notification.type">
      <span
        *ngSwitchCase="notificationType.SocialWallPostMentionCreated"
        [innerHTML]="
          '#name# mentioned you in #group#'
            | translate: { name: authorFullName, group: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <span
        *ngSwitchCase="notificationType.MessagePostMentionCreated"
        [innerHTML]="
          '#name# mentioned you in a comment in #message#'
            | translate: { name: authorFullName, message: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <span
        *ngSwitchCase="notificationType.SocialWallPostCreated"
        [innerHTML]="
          '#name# has posted in #group#'
            | translate: { name: authorFullName, group: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <ng-container *ngSwitchCase="notificationType.MessagePublished">
        <span
          *ngIf="!messageCategoryName"
          [innerHTML]="'#message# has been published to you' | translate: { message: messageTitle }"
        ></span>
        <span
          *ngIf="messageCategoryName"
          [innerHTML]="
            '#message# has been published to you in #category#'
              | translate: { message: messageTitle, category: messageCategoryName }
          "
        ></span>
      </ng-container>
      <ng-container *ngSwitchCase="notificationType.SocialWallCommentCreated">
        <span
          *ngIf="!rootPostAuthor"
          [innerHTML]="
            '#name# commented on your post in #group#'
              | translate: { name: authorFullName, group: getNotificationPostGroupName(notification.data) }
          "
        ></span>
        <span
          *ngIf="rootPostAuthor"
          [innerHTML]="
            '#name# commented on #rootPostAuthor# post in #group#'
              | translate
                : {
                    name: authorFullName,
                    group: getNotificationPostGroupName(notification.data),
                    rootPostAuthor: rootPostAuthor
                  }
          "
        ></span>
      </ng-container>
      <span
        *ngSwitchCase="notificationType.MessagePostCommentCreated"
        [innerHTML]="
          '#name# commented on #message#'
            | translate: { name: authorFullName, message: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <span
        *ngSwitchCase="notificationType.MessagePostReplyCreated"
        [innerHTML]="
          '#name# replied to your comment in #message#'
            | translate: { name: authorFullName, message: getNotificationPostGroupName(notification.data) }
        "
      ></span>
      <span
        *ngSwitchCase="notificationType.SocialWallCommentReplyCreated"
        [innerHTML]="
          '#name# replied to your comment in #message#'
            | translate: { name: authorFullName, message: getNotificationPostGroupName(notification.data) }
        "
      ></span>
    </ng-container>
  </div>
</ao-context-menu>

<ng-template #graphicTemplate>
  <ng-container [ngSwitch]="notification.type">
    <!-- Publish notification -->
    <ng-container *ngSwitchCase="notificationType.TaskPublished">
      <ao-icon-avatar
        iconName="check_box"
        [size]="avatarSize"
        [borderRadius]="'6px'"
        avatarColor="red"
      ></ao-icon-avatar>
    </ng-container>
    <ng-container *ngSwitchCase="notificationType.MessagePublished">
      <ao-thumbnail
        *ngIf="messageMedia; else messageDefault"
        size="bigger"
        [media]="messageMedia"
        [borderRadius]="6"
      ></ao-thumbnail>
      <ng-template #messageDefault>
        <ao-icon-avatar
          [iconName]="defaultIcon?.name"
          [size]="avatarSize"
          [borderRadius]="'6px'"
          [avatarColor]="defaultIcon?.color"
        ></ao-icon-avatar>
      </ng-template>
    </ng-container>

    <!--  Else: Mention notification -->
    <ng-container *ngSwitchDefault>
      <ao-avatar
        size="medium"
        [initials]="initials"
        [image]="getNotificationPostData(notification.data).author?.avatarUrl"
      ></ao-avatar>
    </ng-container>
  </ng-container>
</ng-template>
