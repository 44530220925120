import { animate, state, style, transition, trigger } from '@angular/animations';

export function expandInOut(name = 'expandInOut', timings = '200ms ease-in-out') {
  return trigger(name, [
    transition(':enter', [
      style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px',
      }),
      animate(
        timings,
        style({
          opacity: '1',
          overflow: 'visible',
          height: '*',
        }),
      ),
    ]),
    transition(':leave', [
      animate(
        timings,
        style({
          opacity: '0',
          overflow: 'hidden',
          height: '0px',
        }),
      ),
    ]),
  ]);
}

export function expandInOutByState(name = 'expandInOutByState', timings = '200ms ease-in-out') {
  return trigger(name, [
    state(
      'open',
      style({
        opacity: '1',
        overflow: 'visible',
        height: '*',
      }),
    ),
    state(
      'closed',
      style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
      }),
    ),
    transition('open => closed', [animate(timings)]),
    transition('closed => open', [animate(timings)]),
  ]);
}
