import { z } from 'zod';

/**
 * Input schema of POST /academy/:id/trainings
 */
export const AddAcademyTrainingsBodySchema = z.object({
  sectionId: z.string().min(1),
  trainings: z.array(z.number()),
});
export type AddAcademyTrainingsBodyInput = z.infer<typeof AddAcademyTrainingsBodySchema>;
