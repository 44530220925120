import { Component, Input } from '@angular/core';
import { AvatarSize, SocialContact } from '@ao/data-models';

@Component({
  selector: 'ao-peer-view-main-info',
  templateUrl: './peer-view-main-info.component.html',
  styleUrls: ['./peer-view-main-info.component.scss'],
})
export class PeerViewMainInfoComponent {
  @Input() contact: SocialContact;
  @Input() avatarSize: AvatarSize = 'big';
  @Input() showLastActive = true;

  get contactInfo() {
    return [this.contact?.title, this.contact?.department, this.contact?.addrCountry]
      .filter((existing) => existing)
      .join(', ');
  }
}
