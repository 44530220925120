import { Action, ActionReducer } from '@ngrx/store';
import { getDeep, updateDeep } from '../utils';

export const localStorageSync = <S, A extends Action = Action>(stateKeys: string[]) => {
  const lastKey = stateKeys[stateKeys.length - 1];

  const localStorageKey = `__ao-${lastKey}__`;

  const setSavedState = (state: S[keyof S]) => {
    if (window.localStorage) {
      window.localStorage.setItem(localStorageKey, JSON.stringify({ [lastKey]: state }));
    }
  };

  const getSavedState = (): S[keyof S] | undefined => {
    if (window.localStorage) {
      const savedState = window.localStorage.getItem(localStorageKey);
      return savedState ? JSON.parse(savedState) : undefined;
    }
  };

  let onInit = true;

  return (reducer: ActionReducer<S, A>): ActionReducer<S, A> => {
    return (state: S | undefined, action: A): S => {
      const nextState = reducer(state, action);

      if (onInit) {
        onInit = false;
        const savedState = getSavedState();
        if (savedState !== undefined) {
          return updateDeep(nextState, stateKeys as any, () => savedState[lastKey]);
        }
      } else {
        const stateToSave = getDeep(nextState, stateKeys as any);
        setSavedState(stateToSave);
      }

      return nextState;
    };
  };
};
