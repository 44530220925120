export function mapToObject<T, U = T>(
  array: T[],
  keySelector: (val: T) => string,
  valueSelector?: (val: T) => U,
): Record<string, U>;
export function mapToObject<T, U = T>(
  array: T[],
  keySelector: (val: T) => number,
  valueSelector?: (val: T) => U,
): Record<number, U>;
export function mapToObject<T, U = T>(
  array: T[],
  keySelector: (val: T) => string | number,
  valueSelector?: (val: T) => U,
): Record<string | number, U> {
  const result = {};
  for (let i = 0; i < array.length; i++) {
    result[keySelector(array[i])] = valueSelector ? valueSelector(array[i]) : array[i];
  }
  return result;
}
