import { ChatMessageReactions, CometChatUser, UnreadStatus } from '@ao/data-models';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { createAction, props } from '@ngrx/store';

export const UpdateChatHasUnread = createAction(
  '[Core] Update chat Unread notification',
  props<{ updateStatus: UnreadStatus }>(),
);
export const UpdateChatHasUnreadSuccess = createAction(
  '[Core] Update chat Unread Count notification',
  props<{ hasUnread: boolean }>(),
);
export const UpdateChatHasUnreadFail = createAction(
  '[Core] Update chat Unread notification fail',
  props<{ error: any }>(),
);

// Chat init
export const ChatInitSuccess = createAction('[Core] Chat init Success');
export const ChatInitFail = createAction('[Core] Chat init Fail', props<{ error: any }>());

export const ChatOpened = createAction('[Core] Chat Opened');

export const ChatMessageSent = createAction(
  '[Core] Chat Message Sent',
  props<{
    chatId: string;
    chatMediaUploaded: boolean;
    chatType: 'group' | 'one-to-one';
    chatMediaUploadType?: 'file' | 'video' | 'image' | 'audio';
    chatIsReply: boolean;
  }>(),
);

// logged in user
export const fetchLoggedInUser = createAction('[Chat] fetch logged in user');
export const fetchLoggedInUserSuccess = createAction(
  '[Chat] fetch logged in user success',
  props<{ loggedInUser: CometChatUser }>(),
);
export const fetchLoggedInUserFail = createAction('[Chat] fetch logged in user failed');

// Reactions
export const setSelectedMessageReations = createAction(
  '[CHAT] Chat set selected message reactions',
  props<{ selectedMessageReactions: ChatMessageReactions }>(),
);
export const resetSelectedMessageReactions = createAction('[CHAT] Chat reset selected message reactions');
export const openMessageReactions = createAction('[CHAT] Chat open message reactions');
export const closeMessageReactions = createAction('[CHAT] Chat close message reactions');
export const fetchCurrentConversationAdmin = createAction(
  '[Chat] fetch conversation admins',
  props<{ groupID: string }>(),
);

export const fetchCurrentConversationAdminsSuccess = createAction(
  '[Chat] fetch conversation admins success',
  props<{ currentConversationAdmins: CometChat.GroupMember[] }>(),
);
export const fetchCurrentConversationAdminsFail = createAction(
  '[Chat] fetch conversation admins fail',
  props<{ error: any }>(),
);

export const resetCurrentConversationAdmin = createAction('[Chat] reset conversation admins');
