<div>
  <div class="ao-login-client-picker__header">
    <ao-generic-page-header
      [type]="'avatar'"
      [initials]="initials"
      [image]="avatar"
      [title]="'We found #count# of your apps' | translate: { count: clientList?.length }"
      [text]="'Please select which one you want to log in to:' | translate"
      [subTitle]="firstName ? ('Hey there, #name#!' | translate: { name: firstName }) : ('Hey there!' | translate)"
    >
    </ao-generic-page-header>
  </div>
  <ul class="ao-login-client-picker__homepages" *ngIf="clientList?.length">
    <li *ngFor="let client of clientList; trackBy: clientTrackBy">
      <ao-homepagelist-item [client]="client" (pickClient)="pickClient.emit(client)"></ao-homepagelist-item>
    </li>
  </ul>
</div>
