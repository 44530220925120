import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonUiModule } from '@ao/common-ui';
import { ViewerCoreModule } from '@ao/viewer-core';
import { ViewerUiModule } from '@ao/viewer-ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ViewerTaskEffects } from './+state/effects/viewer-task.effects';
import { reducer } from './+state/reducers/viewer-tasks.reducer';
import { VIEWER_TASK_STATE_KEY } from './+state/viewer-tasks.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(VIEWER_TASK_STATE_KEY, reducer),
    EffectsModule.forFeature([ViewerTaskEffects]),
    TranslateModule,
    ViewerCoreModule,
    CommonUiModule,
    ViewerUiModule,
  ],
  // providers: [{ provide: ROUTER_CONFIGURATION, useFactory: () => ({ initialNavigation: 'enabled' }) }],
  exports: [RouterModule],
  declarations: [],
})
export class ViewerTaskManagementStoreModule {}
