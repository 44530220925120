import { on } from '@ngrx/store';
import * as routerHistoryActions from '../actions/router-history.actions';
import { ViewerCoreState } from './viewer-core-store.state';

export const routerHistoryOns = [
  on(routerHistoryActions.pushRoute, (state: ViewerCoreState, payload): ViewerCoreState => {
    const lastRoute = state.routerHistory[state.routerHistory.length - 1];
    const isSamePath = lastRoute?.path.split('?')[0] === payload.path.split('?')[0];

    if (isSamePath) {
      // Replace the last route with the new one
      return {
        ...state,
        routerHistory: [...state.routerHistory.slice(0, -1), { path: payload.path, pageType: payload.pageType }],
      };
    } else {
      // Push the new route to the history
      return {
        ...state,
        routerHistory: [...state.routerHistory, { path: payload.path, pageType: payload.pageType }],
      };
    }
  }),
  on(
    routerHistoryActions.popRoute,
    (state: ViewerCoreState): ViewerCoreState => ({
      ...state,
      routerHistory: [...state.routerHistory.slice(0, -1)],
    }),
  ),
  on(
    routerHistoryActions.clearHistory,
    (state: ViewerCoreState): ViewerCoreState => ({
      ...state,
      routerHistory: [],
    }),
  ),
  on(
    routerHistoryActions.clearRoute,
    (state: ViewerCoreState, { path }): ViewerCoreState => ({
      ...state,
      routerHistory: state.routerHistory.filter((item) => item.path !== path && !item.path.startsWith(`${path}?`)),
    }),
  ),
  on(routerHistoryActions.toPreviousMessageSuccess, (state: ViewerCoreState, { path }): ViewerCoreState => {
    const index = state.routerHistory.map((item) => item.path).lastIndexOf(path);
    return {
      ...state,
      routerHistory: state.routerHistory.slice(0, index),
    };
  }),
];
