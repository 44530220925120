import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  Output,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { Notification, NotificationSettingsGroup } from '@ao/shared-data-models';
import { LOADING_STATE, LOADING_STATE_PAGINATED } from '@ao/data-models';
import { mapToSkeletonLoader } from '@ao/utilities';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'ao-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class.ao-notification-list') className = true;
  @HostBinding('class.ao-notification-list--padding-bottom') addPaddingBottom = false;

  @Input() set loadingState(value: LOADING_STATE_PAGINATED) {
    this._loadingState = value;
    this.skeletonState = mapToSkeletonLoader(value);
  }
  get loadingState() {
    return this._loadingState;
  }

  @Input() notifications: Notification[];
  @Input() groupsUserSettings: NotificationSettingsGroup;
  @Input() loadedAll = false;

  @Output() loadMore = new EventEmitter();
  @Output() notificationClick = new EventEmitter<Notification>();
  @Output() notificationActionClick = new EventEmitter<{ actionType: string; notification: Notification }>();
  @Output() hideNavBar = new EventEmitter<boolean>();

  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;

  NAVBAR_HEIGHT = 52;

  private destroy$ = new Subject<void>();
  _loadingState = LOADING_STATE_PAGINATED.unloaded;
  skeletonState = LOADING_STATE.unloaded;

  trackById: TrackByFunction<Notification> = (index, notification) => notification.id;

  constructor(private ngZone: NgZone) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.viewport.renderedRangeStream
      .pipe(
        takeUntil(this.destroy$),
        map(({ end }) => {
          const isLoading = [LOADING_STATE_PAGINATED.loading, LOADING_STATE_PAGINATED.loadingMore].includes(
            this.loadingState,
          );
          return !isLoading && !this.loadedAll && end > this.notifications.length * 0.9;
        }),
      )
      .subscribe((loadMore) => {
        if (loadMore) {
          this.ngZone.run(() => {
            this.loadMore.emit();
          });
        }
      });

    // bottom nav bar hide animation
    let startPosition = this.viewport.measureScrollOffset();
    this.viewport
      .elementScrolled()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        event.stopPropagation();
        if (startPosition < this.viewport.measureScrollOffset()) {
          this.hideNavBar.emit(true);
          this.addPaddingBottom = false;
        } else {
          this.hideNavBar.emit(false);
          this.addPaddingBottom = true;
        }
        if (this.viewport.measureScrollOffset('bottom') < this.NAVBAR_HEIGHT) {
          this.hideNavBar.emit(false);
          this.addPaddingBottom = true;
        }
        startPosition = this.viewport.measureScrollOffset();
      });
  }

  onNotificationActionClick(actionType: string, notification: Notification) {
    this.notificationActionClick.emit({ actionType, notification });
  }

  onNotificationClick(notification: Notification) {
    this.notificationClick.emit(notification);
  }
}
