<div
  class="preview-mode-top-header__wrapper"
  [ngClass]="{ 'preview-mode-top-header__wrapper--is-desktop': !isSmallScreen }"
>
  <div class="preview-mode-top-header" [ngClass]="{ 'preview-mode-top-header--is-desktop': !isSmallScreen }">
    <ao-icon class="preview-mode-top-header__icon" name="warning"></ao-icon>
    <div
      class="preview-mode-top-header__info ao-font-small"
      [ngClass]="{ 'preview-mode-top-header__info--is-desktop': !isSmallScreen }"
    >
      <p>
        <strong>{{ 'You are viewing this content in test mode.' | translate }}</strong>
      </p>
      {{
        'No statistics or data will be collected while in test mode. This banner will not show when you publish the content.'
          | translate
      }}
    </div>
    <ao-icon class="preview-mode-top-header__close" name="close" (click)="closePreviewTopHeader.emit()"></ao-icon>
  </div>
</div>
