<div
  class="ao-base-header ao-base-header--navbar"
  [class.ao-base-header--main-center]="layout === 'center'"
  [class.ao-base-header--stack]="layout === 'stack'"
  [class.ao-base-header--can-grow]="grow && layout === 'stack'"
  [class.ao-base-header--bottomBorder]="bottomBorder"
>
  <div class="ao-base-header__container">
    <div class="ao-base-header__left">
      <div class="ao-base-header__wrapper">
        <button class="ao-base-header__close" *ngIf="showBackButton" (click)="dismiss.emit()">
          <ao-icon [size]="30" [name]="closeIcon"></ao-icon>
        </button>
        <ng-template *ngIf="left" [ngTemplateOutlet]="left"></ng-template>
      </div>
    </div>
    <div class="ao-base-header__main">
      <div class="ao-base-header__wrapper">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="ao-base-header__right">
      <div class="ao-base-header__wrapper">
        <button class="ao-base-header__close" *ngIf="showClose" (click)="dismiss.emit()">
          <ao-icon [size]="30" [name]="closeIcon"></ao-icon>
        </button>
        <ng-template *ngIf="right" [ngTemplateOutlet]="right"></ng-template>
      </div>
    </div>
  </div>
</div>
