import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateContactInfoModel } from '@ao/data-models';
import { environment } from '@ao/environments';
import { ImageSource } from '@ao/shared-data-models';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewerCoreService {
  constructor(private http: HttpClient) {}

  getUnreadCount(keycode: string): Observable<Record<number, number>> {
    const url = `${environment.apiBaseUrl}/api/v1/messages/${keycode}/unreadCount`;
    return this.http.get<{ data: Record<number, number> }>(url).pipe(catchError((error: any) => throwError(error)));
  }

  getContactData(keycode: string, targetContactId: number): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/${keycode}/contact/${targetContactId}`;
    return this.http.get(url).pipe(catchError((error: any) => throwError(error)));
  }

  updateContact({ id, keycode, avatar, ...payload }: UpdateContactInfoModel): Observable<{
    data: { avatarUrl?: string; avatarImages?: ImageSource[]; contactAuthCode?: string };
  }> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/${keycode}/contact/${id}`;

    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      const value = typeof payload[key] === 'object' ? JSON.stringify(payload[key]) : payload[key];
      formData.append(key, value);
    });
    if (avatar) {
      formData.append('avatar', avatar, 'image.png');
    }

    return this.http
      .put<{ data: { avatarUrl?: string; contactAuthCode?: string } }>(url, formData)
      .pipe(catchError((error: any) => throwError(error)));
  }

  updateModalStatus(data: any, contactId: number, contactAuthCode: string) {
    const url = `${environment.apiBaseUrl}/api/v1/contacts/${contactId}/modalStatus?authCode=${contactAuthCode}`;
    return this.http.put(url, { ...data });
  }

  updateContactStatus(data: any, contactId: number) {
    const url = `${environment.apiBaseUrl}/api/v1/contacts/${contactId}/contactStatus`;
    return this.http.put(url, { ...data });
  }

  setNativeAndroidOffSetProperty(property: string, value: string) {
    document.documentElement.style.setProperty(property, value);
  }

  extractOffsetDataFromMessage(message: string) {
    const bottomOffset = message.match(/bottomOffset:([+-]?\d+(\.\d+)?)/);
    const topOffset = message.match(/topOffset:([+-]?\d+(\.\d+)?)/);
    const keyboardHeight = message.match(/keyboardHeight:([+-]?\d+(\.\d+)?)/);

    return {
      bottomOffset: bottomOffset ? Number(bottomOffset[1]) : 0,
      topOffset: topOffset ? Number(topOffset[1]) : 0,
      keyboardHeight: keyboardHeight ? Number(keyboardHeight[1]) : 0,
    };
  }
  login(path: string) {
    const apiPath =
      path === '/login/test' ? '/api/v1/identityProvider/app/login/test' : '/api/v1/identityProvider/app/login';
    return this.http.get(apiPath);
  }
}
