import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MULTIPART_FILE_CHUNK_SIZE, MultiUploadParams, UploadPartProgressEvent } from '@ao/data-models';
import { MediaItem } from '@ao/shared-data-models';
import { Observable, Subject, forkJoin } from 'rxjs';
import { last, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MultipartUploadService {
  constructor(private http: HttpClient) {}

  multiPartUploadStart(
    file: File,
    width: number,
    height: number,
    url: string,
    folderPath?: string,
    customFileName?: string,
    dontSaveFolderItem?: boolean,
  ) {
    const parts = Math.ceil(file.size / MULTIPART_FILE_CHUNK_SIZE);
    return this.http
      .post<any>(url, {
        parts,
        fileName: file.name || customFileName,
        type: file.type,
        size: file.size,
        width,
        height,
        folderPath,
        dontSaveFolderItem,
      })
      .pipe(map((res) => res.data));
  }

  multiPartUploadParts(
    { parts, uploadId, fileName },
    file,
    mediaFileId: number,
    progressSubject?: Subject<UploadPartProgressEvent>,
  ): Observable<{
    parts: { ETag: string; PartNumber: number }[];
    uploadId: string;
    fileName: string;
    mediaFileId: number;
  }> {
    const keys = Object.keys(parts);
    const totalParts = parts.length;
    const progressedParts = {};

    const pieces = keys.map((indexStr) => {
      const index = parseInt(indexStr, 10);
      const start = index * MULTIPART_FILE_CHUNK_SIZE;
      const end = (index + 1) * MULTIPART_FILE_CHUNK_SIZE;
      const blob = index < keys.length ? file.slice(start, end) : file.slice(start);

      const request = new HttpRequest('PUT', parts[index], blob, {
        reportProgress: true,
      });
      return this.http.request(request).pipe(
        tap((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            progressedParts[parts[index].toString()] = true;
            const partsDone = Object.keys(progressedParts).length;

            if (progressSubject) {
              progressSubject.next({
                loaded: partsDone,
                total: totalParts,
                percent: Math.round((partsDone / totalParts) * 100),
              });
              if (partsDone === totalParts) {
                progressSubject.complete();
              }
            }
          }
        }),
        last(),
        tap(() => {
          if (progressSubject) {
            return progressSubject.complete();
          }
        }),
      );
    });

    return forkJoin(pieces).pipe(
      map((resParts) => {
        const partsData: { ETag: string; PartNumber: number }[] = resParts.map((part, index) => ({
          // ETag is getting returned as a string like "abcde" so we need to strip the "" out and any other unwanted chars as well (not entirely sure why though)
          // Reference https://stackoverflow.com/questions/31799982/aws-sdk-presigned-url-multipart-upload
          ETag: (part as any).headers.get('ETag')?.replace(/[|&;$%@"<>()+,]/g, ''),
          PartNumber: index + 1,
        }));

        return { parts: partsData, uploadId, fileName, mediaFileId };
      }),
    );
  }

  multiPartUploadComplete(
    body: { parts: { ETag: string; PartNumber: number }[]; uploadId: string; fileName: string; mediaFileId: number },
    url: string,
  ): Observable<MediaItem> {
    return this.http.post<{ data: MediaItem }>(url, body).pipe(map((res) => res.data));
  }

  multipartUpload({
    file,
    width,
    height,
    startUrl,
    completeUrl,
    folderPath,
    customFileName,
    dontSaveFolderItem,
    progressSubject,
  }: MultiUploadParams): Observable<MediaItem> {
    return this.multiPartUploadStart(
      file,
      width,
      height,
      startUrl,
      folderPath,
      customFileName,
      dontSaveFolderItem,
    ).pipe(
      switchMap(({ parts, uploadId, fileName, mediaFileId }) =>
        this.multiPartUploadParts(
          {
            parts,
            uploadId,
            fileName,
          },
          file,
          mediaFileId,
          progressSubject,
        ),
      ),
      switchMap((resParts) => {
        return this.multiPartUploadComplete({ ...resParts }, completeUrl);
      }),
    );
  }
}
