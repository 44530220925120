import { Component, ElementRef, HostBinding, ViewChild } from '@angular/core';

@Component({
  selector: 'ao-zoomable-image-lightbox',
  templateUrl: './zoomable-image-lightbox.component.html',
  styleUrls: ['./zoomable-image-lightbox.component.scss'],
})
export class ZoomableImageLightboxComponent {
  @HostBinding('class.ao-zoomable-image-lightbox') className = true;

  @ViewChild('lightbox', { static: true }) lightboxElem: ElementRef;
}
