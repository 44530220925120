<!--Backdrop-->
<ng-template #templatePortal>
  <div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <ao-viewer-backdrop (click)="onClose()"></ao-viewer-backdrop>
    <div class="lightbox-modal__content">
      <ng-content>
        <ao-zoomable-image
          [zoomableImage]="{
            src: 'https://data-eu.cometchat.io/22426578f9c18cc1/media/1715175755_509126243_896d07ea277646dc3aa12e6e12fc2f81.png',
            srcset:
              'https://data-eu.cometchat.io/22426578f9c18cc1/media/1715175755_509126243_896d07ea277646dc3aa12e6e12fc2f81.png 480w'
          }"
        ></ao-zoomable-image>
      </ng-content>
    </div>
    <button type="button" (click)="onClose()" class="lightbox-modal__close">
      <ao-icon name="close" class="close" [size]="32"></ao-icon>
    </button>
  </div>
</ng-template>
