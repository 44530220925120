<span
  class="ao-toggle-slider"
  [ngClass]="{
    'ao-toggle-slider--checked': _checked,
    'ao-toggle-slider--disabled': disabled,
    'ao-toggle-slider--small': smallSize
  }"
>
  <input type="checkbox" [id]="_toggleId" [checked]="_checked" class="ao-toggle-slider__input" aria-invalid="false" />
  <span class="ao-toggle-slider__label">
    {{ trueLabel ? (_checked ? trueLabel : falseLabel) : '' }}
  </span>
  <small class="ao-toggle-slider__handle"></small>
</span>
