import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import { Observable, of, Subject } from 'rxjs';

dayjs.extend(duration);

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

interface AudioRecording {
  recorder: any;
  stream: any;
  interval: any;
  startTime: any;
  recorded: Subject<RecordedAudioOutput>;
  recordingTime: Subject<{ time: string; seconds: number }>;
  recordingFailed: Subject<void | string>;
}

@Injectable()
export class AudioRecordingService {
  private RecordRTC: any;
  private recordings: { [id: string]: AudioRecording } = {};

  prepareRecording(id: string) {
    this.recordings[id] = {
      recorder: undefined,
      stream: undefined,
      interval: undefined,
      startTime: undefined,
      recorded: new Subject<RecordedAudioOutput>(),
      recordingTime: new Subject<{ time: string; seconds: number }>(),
      recordingFailed: new Subject<void | string>(),
    };
  }
  clearRecording(id) {
    delete this.recordings[id];
  }

  getRecordedBlob(id: string): Observable<RecordedAudioOutput> {
    return this.recordings[id] ? this.recordings[id].recorded.asObservable() : of();
  }

  getRecordedTime(id: string): Observable<{ time: string; seconds: number }> {
    return this.recordings[id] ? this.recordings[id].recordingTime.asObservable() : of(null);
  }

  recordingFailed(id: string): Observable<string> {
    return this.recordings[id] ? (this.recordings[id].recordingFailed as Subject<string>).asObservable() : of();
  }

  startRecording(id: string) {
    if (this.recordings[id]?.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    if (!this.recordings[id]) {
      this.prepareRecording(id);
    }

    return navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((s) => {
        this.recordings[id].recordingTime.next({ time: '00:00', seconds: 0 });
        this.recordings[id].stream = s;
        this.record(id);
        return true;
      })
      .catch((error) => {
        this.recordings[id].recordingFailed.next();
        return false;
      });
  }

  abortRecording(id: string) {
    this.stopMedia(id);
  }

  private async record(id: string) {
    if (!this.RecordRTC) {
      const record = await import('recordrtc');
      this.RecordRTC = record.default;
    }

    this.recordings[id].recorder = new this.RecordRTC.StereoAudioRecorder(this.recordings[id].stream.clone(), {
      type: 'audio',
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      bufferSize: 16384,
      desiredSampRate: 24000,
    });

    this.recordings[id].recorder.record();
    this.recordings[id].startTime = dayjs();
    this.recordings[id].interval = setInterval(() => {
      const currentTime = dayjs();
      const diffTime = dayjs.duration(currentTime.diff(this.recordings[id].startTime, 'milliseconds'));
      const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
      this.recordings[id].recordingTime.next({ time, seconds: diffTime.asSeconds() });

      // safety override to keep the recordings length somewhat sane
      if (diffTime.minutes() >= 20) {
        this.stopRecording(id);
      }
    }, 1000);
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording(id: string) {
    if (this.recordings[id].recorder) {
      this.recordings[id].recorder.stop(
        (blob) => {
          if (this.recordings[id].startTime) {
            const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.mp3');
            this.stopMedia(id);
            this.recordings[id].recorded.next({ blob: blob, title: mp3Name });
          }
        },
        () => {
          this.stopMedia(id);
          this.recordings[id].recordingFailed.next();
        },
      );
    }
  }

  private stopMedia(id: string) {
    if (this.recordings[id].recorder) {
      this.recordings[id].recorder = null;
      clearInterval(this.recordings[id].interval);
      this.recordings[id].startTime = null;
      if (this.recordings[id].stream) {
        this.recordings[id].stream.getAudioTracks().forEach((track) => track.stop());
        this.recordings[id].stream = null;
      }
    }
  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    // Cast to a File() type
    return <File>theBlob;
  };
}
