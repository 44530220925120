import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DisableScrolling } from '@ao/utilities';
import { Subject } from 'rxjs';

import { ModalConfig } from '@ao/data-models';
import {
  AppConfirmationConfig,
  AppConfirmationModalComponent,
} from '../../components/app-confirmation-modal/app-confirmation-modal.component';
import { ModalService } from '../modal/modal.service';

interface ModalState {
  overlayRef: OverlayRef;
  backdropClick: Subject<MouseEvent>;
  overlayClick: Subject<MouseEvent>;
}

@Injectable({
  providedIn: 'root',
})
export class MessageModalService {
  private stack: ModalState[] = [];

  constructor(
    private overlay: Overlay,
    private disableScrolling: DisableScrolling,
    private modalService: ModalService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  private get empty() {
    return this.stack.length === 0;
  }

  private get current() {
    return this.stack[this.stack.length - 1];
  }

  private containerClick = (e: MouseEvent) => {
    if (e.target === this.current.overlayRef.hostElement) {
      this.current.backdropClick.next(e);
    }
  };

  private overlayClick = (e: MouseEvent) => {
    if (e.target === this.current.overlayRef.overlayElement) {
      this.current.overlayClick.next(e);
    }
  };

  openModal<T>(portal: TemplatePortal | ComponentPortal<T>, width: string | number = '100%', showBackdrop?: boolean) {
    if (this.empty) {
      this.document.body.classList.add('ao-message-modal--open');
    }

    const overlayRef = this.overlay.create({
      width,
      minHeight: '100%',
      hasBackdrop: typeof showBackdrop === 'undefined' ? this.empty : showBackdrop,
    });
    const ref = overlayRef.attach(portal);
    overlayRef.hostElement.classList.add('ao-cdk-overlay');

    this.disableScrolling.onExcept(overlayRef.hostElement);
    overlayRef.hostElement.addEventListener('click', this.containerClick);
    overlayRef.overlayElement.addEventListener('click', this.overlayClick);
    const backdropClick = new Subject<MouseEvent>();
    const overlayClick = new Subject<MouseEvent>();
    this.stack.push({ overlayRef, backdropClick, overlayClick });
    return {
      backdropClick: backdropClick.asObservable(),
      overlayClick: overlayClick.asObservable(),
      instance: ref.instance ? <T>ref.instance : null,
    };
  }

  closeModal() {
    if (this.stack.length) {
      const { backdropClick, overlayClick, overlayRef } = this.stack.pop();
      backdropClick.complete();
      overlayClick.complete();
      this.disableScrolling.off();
      if (overlayRef && overlayRef.hostElement) {
        overlayRef.hostElement.removeEventListener('click', this.containerClick);
      }
      if (overlayRef && overlayRef.overlayElement) {
        overlayRef.overlayElement.removeEventListener('click', this.overlayClick);
      }
      if (overlayRef) {
        overlayRef.dispose();
      }
      if (this.empty) {
        this.document.body.classList.remove('ao-message-modal--open');
      }
    }
  }

  confirm(config: AppConfirmationConfig, modal: ModalConfig = {}) {
    return this.modalService.openWithConfig(AppConfirmationModalComponent, config, {
      ...modal,
    });
  }
}
