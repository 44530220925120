<mat-sidenav-container
  class="ao-shell__wrapper"
  [class.ao-shell__wrapper--menu-open]="expandedMenu"
  [class.ao-shell__wrapper--social-open]="!smallScreen"
  (backdropClick)="toggleSidebar(expandedMenu)"
>
  <mat-sidenav-content
    class="ao-shell__content ao-shell__content--centered"
    [ngClass]="{
      'ao-shell__content--safearea': layoutHasHeader
    }"
  >
    <div
      class="ao-shell__canvas-wrapper"
      [ngClass]="{
        'ao-shell__canvas-wrapper--show-navbar': layoutHasHeader
      }"
    >
      <div
        class="ao-shell__canvas"
        [ngClass]="{
          'ao-shell__canvas--has-pagination': hasPagination
        }"
      >
        <ng-content select="[slot=banner]"></ng-content>

        <div class="ao-shell__fixed-header" [ngClass]="{ 'ao-shell__fixed-header__admin': adminPreview }">
          <ng-content select="[slot=header]"></ng-content>
          <ng-content select="[slot=insights]"></ng-content>
          <ng-container cdkPortalOutlet #socialHeader="cdkPortalOutlet"></ng-container>
        </div>

        <!-- Add fake padding beneath the header -->
        <div
          *ngIf="hasHeader"
          class="ao-shell__empty-header"
          [ngClass]="{ 'ao-shell__empty-header__admin': adminPreview }"
        ></div>
        <div *ngIf="hasInsights" class="ao-shell__empty-insights"></div>

        <!-- Notifications -->
        <ng-content select="[slot=message-notifications]"></ng-content>
        <ng-container cdkPortalOutlet #socialNotifications="cdkPortalOutlet"></ng-container>
        <div class="ao-shell__content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
  <mat-sidenav
    #messageSidebar
    [ngClass]="classList"
    position="start"
    [fixedInViewport]="true"
    [opened]="expandedMenu"
    [mode]="menuExpandedDesktop && !smallScreen ? 'side' : 'over'"
    (keydown.escape)="toggleSidebar(true)"
    disableClose
  >
    <ng-content select="[slot=main-sidebar]"></ng-content>
  </mat-sidenav>
</mat-sidenav-container>
