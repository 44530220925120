<ao-viewer-toast-display></ao-viewer-toast-display>
<ao-shell class="ao-font-max-medium" [loginTheme]="theme">
  <ao-login
    [state]="state$ | async"
    [loginAuthState]="loginAuthState$ | async"
    [loginSettings]="settings"
    [providers]="providers"
    [errorCode]="error$ | async"
    [loginErrorCode]="loginErrorCode"
    [redirectUrl]="redirectUrl$ | async"
    [nativeApp]="isNativeApp"
    [ssoEnabled]="ssoEnabled"
    [theme]="theme"
    [remainingLoginAuthAttempts]="remainingLoginAuthAttempts$ | async"
    [isPWA]="isPWA"
    [authInProgress]="authInProgress"
    [contact]="contact"
    [clientList]="clientList"
    [supportSettings]="support"
    (stateChange)="onStateChange($event)"
    (findDifferentClick)="onFindDifferentClick()"
    (openSupport)="toggleSupportModal()"
    (loginAuthComplete)="onLoginAuthComplete($event)"
    (loginAuthResetRequest)="onLoginAuthResetRequest($event)"
    (pickClient)="onPickClient($event)"
    (logout)="logout()"
    (errorToast)="onShowErrorToast($event)"
  ></ao-login>
  <ao-message-modal-card
    [open]="supportModalOpen"
    [loginPage]="true"
    [autoWidth]="true"
    maxWidth="400px"
    (close)="toggleSupportModal()"
  >
    <ao-support-modal [loginTheme]="true" [supportSettings]="support" (close)="toggleSupportModal()">
    </ao-support-modal>
  </ao-message-modal-card>
</ao-shell>
