import { viewerCoreSelectors } from '@ao/viewer-core';
import { createSelector } from '@ngrx/store';
import { selectIsTest, selectMessageTraining, selectMessageType } from './app-store.selectors';
import {
  selectIfCurrentPageLastSubmit,
  selectIfCurrentPageQuestionnaireIsSubmitted,
  selectMessageQuizStatus,
} from './questionnaire.selectors';

export const selectShowCompleteTrainingFooter = createSelector(
  selectMessageTraining,
  selectIsTest,
  selectMessageType,
  viewerCoreSelectors.selectContact,
  selectMessageQuizStatus,
  selectIfCurrentPageQuestionnaireIsSubmitted,
  selectIfCurrentPageLastSubmit,
  (training, isTest, messageType, contact, messageQuizStatus, questionnaireSubmitted, lastQuestionnairePage) => {
    // Don't show if: insights overlay, not a training,  test message, or no contact

    const notEnabled = !training || isTest || !contact?.id;
    const quizCompleted = messageType !== 'survey' && (messageQuizStatus === null || messageQuizStatus?.completed);
    const surveyAndVisible = messageType === 'survey' && questionnaireSubmitted && lastQuestionnairePage;

    const result = notEnabled ? false : quizCompleted || surveyAndVisible;
    return result;
  },
);
