import { ACTIMO_COLORS, BrandingColor } from '@ao/data-models';

export interface StatusOption {
  name: BrandingColor;
  color: string;
  invertedColor: string;
}

const SNOW: StatusOption = {
  name: 'snow',
  color: ACTIMO_COLORS.snow.lightest,
  invertedColor: ACTIMO_COLORS.ink.lightest,
};
const RED: StatusOption = {
  name: 'red',
  color: ACTIMO_COLORS.red.base,
  invertedColor: ACTIMO_COLORS.snow.lightest,
};
const YELLOW: StatusOption = {
  name: 'yellow',
  color: ACTIMO_COLORS.yellow.base,
  invertedColor: ACTIMO_COLORS.snow.lightest,
};
const GREEN: StatusOption = {
  name: 'green',
  color: ACTIMO_COLORS.green.base,
  invertedColor: ACTIMO_COLORS.snow.lightest,
};

export function getThresholdStatus(value: number, upperThreshold: number, lowerThreshold: number): StatusOption {
  if (value || Number(value) === 0) {
    const lowest = lowerThreshold || upperThreshold;

    if (value < lowest) {
      return { ...RED };
    }

    if (value >= lowest && value < upperThreshold) {
      return { ...YELLOW };
    }
    return { ...GREEN };
  }

  return { ...SNOW };
}
