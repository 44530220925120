export const NUMBER_FORMAT_REGEXP = /^(\d+)?\.((\d+)(-(\d+))?)?$/;

export type ParsedDigitsInfo = Pick<
  Intl.NumberFormatOptions,
  'minimumIntegerDigits' | 'minimumFractionDigits' | 'maximumFractionDigits'
>;

export type LocalizeNumberOptions = {
  [P in Exclude<keyof Intl.NumberFormatOptions, keyof ParsedDigitsInfo>]?: Intl.NumberFormatOptions[P];
};

export function localizeNumber(
  value: number,
  lang: string,
  digitsInfo: string,
  extraOptions: LocalizeNumberOptions = {},
) {
  if (typeof value !== 'number' || Number.isNaN(value)) {
    return '-';
  }
  return value.toLocaleString(lang, {
    ...parseDigitsInfo(digitsInfo || '1.0-0'),
    ...extraOptions,
  });
}

/**
 * Converts Angular `digitsinfo` into options compliant with `Number.toLocaleString`
 * @param {string} digitsInfo Decimal representation options, as defined here: https://angular.io/api/common/DecimalPipe#parameters
 * @return {void} the converted options
 */
export function parseDigitsInfo(digitsInfo: string): ParsedDigitsInfo {
  const parts = digitsInfo.match(NUMBER_FORMAT_REGEXP);
  if (parts === null) {
    throw new Error(`${digitsInfo} is not a valid digit info`);
  }
  const minIntPart = parts[1];
  const minFractionPart = parts[3];
  const maxFractionPart = parts[5];

  // defaults
  let minimumIntegerDigits = 1;
  let minimumFractionDigits = 0;
  let maximumFractionDigits = 0;

  if (minIntPart != null) {
    minimumIntegerDigits = safeParseInt(minIntPart);
  }
  if (minFractionPart != null) {
    minimumFractionDigits = safeParseInt(minFractionPart);
  }
  if (maxFractionPart != null) {
    maximumFractionDigits = safeParseInt(maxFractionPart);
  } else if (minFractionPart != null && minimumFractionDigits > maximumFractionDigits) {
    maximumFractionDigits = minimumFractionDigits;
  }

  return {
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
  };
}

function safeParseInt(text: string): number {
  const result: number = parseInt(text, 10);
  if (isNaN(result)) {
    throw new Error('Invalid integer literal when parsing ' + text);
  }
  return result;
}
