import { MsgModuleUpload } from '@ao/data-models';
import { getModuleRef } from '@ao/utilities';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, moduleAdapter } from './app-store.state';

export const moduleUploadOns = [
  on(appActions.UploadFile, (state: AppState, { module, file }): AppState => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            _files: [
              ...(module._files || []),
              { progress: 0, uploaded: false, processed: false, name: file.name, type: file.type },
            ],
            _uploading: true,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.UploadFileProgress, (state: AppState, { module, data, file }): AppState => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            _files: [...(module._files || []), { progress: Math.min(data, 90), name: file.name, type: file.type }],
            _uploading: true,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.PollUploadFileSuccess, (state: AppState, { module, mediaItem }): AppState => {
    const entityId = getModuleRef(module);

    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            _files: (state.modules.entities[entityId] as MsgModuleUpload)._files.reduce((acc, curr) => {
              return curr.media_file_id === mediaItem.id
                ? [
                    ...acc,
                    {
                      ...curr,
                      url: mediaItem.url,
                      thumbnailUrl: mediaItem.thumbnailUrl,
                      images: mediaItem.images,
                      processed: true,
                      progress: 100,
                    },
                  ]
                : [...acc, curr];
            }, []),
            _processing: false,
            _uploading: false,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.PollUploadFileUpdate, (state: AppState, { module, progress, fileId }): AppState => {
    const entityId = getModuleRef(module);

    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            _files: (state.modules.entities[entityId] as MsgModuleUpload)._files.reduce((acc, curr) => {
              return curr.id === fileId ? [...acc, { ...curr, progress: Math.trunc(progress) }] : [...acc, curr];
            }, []),
            _processing: true,
            _uploading: true,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.UploadFileSuccess, (state: AppState, { module, data, file, hasDelayedProcessing }): AppState => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            _files: [
              ...(module._files || []),
              {
                uploaded: true,
                ...data,
                name: file.name,
                type: file.type,
                progress: 100,
                ...(hasDelayedProcessing
                  ? {
                      progress: 0,
                      processed: false,
                    }
                  : {
                      progress: 100,
                      processed: true,
                    }),
              },
            ],
            _processing: hasDelayedProcessing,
            _uploading: false,
            _uploadedFile: file.name,
            _uploadedMessage: i18n('Upload successful: #fileName#'),
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.UploadFileError, (state: AppState, { module, data }): AppState => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            _uploadedFile: '',
            _uploadedMessage: data.error.message,
            _uploading: false,
            _processing: false,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.UploadChangeTitleError, (state: AppState, { module, data }): AppState => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            _uploadedMessage: data.message,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.UploadRemoveFile, (state: AppState, { module, fileId }): AppState => {
    const currentFiles = module._files.filter((file) => file.id !== fileId);

    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            _files: currentFiles,
            _uploading: false,
            _processing: false,
            _uploadedFile: currentFiles.length ? module._uploadedFile : '',
            _uploadedMessage: currentFiles.length ? module._uploadedMessage : null,
          },
        },
        { ...state.modules },
      ),
    };
  }),
];
