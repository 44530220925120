import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AdminSupportSettings, ERROR_CONSTANT, LoginTheme } from '@ao/data-models';
import { onceWithLatest, RouterStore, setDayJsLocale, WINDOW, withLatestFromLazy } from '@ao/utilities';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { map, mergeMap, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ViewerCoreFacade } from '../../store/viewer-core-store.facade';

@Component({
  selector: 'viewer-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit, OnDestroy {
  @HostBinding('class.viewer-error') className = true;
  _queryParams$: Observable<Record<string, unknown>>;
  private destroy$ = new Subject<void>();

  errorCode$ = this.viewerCoreFacade.errorCode$;
  language$ = this.viewerCoreFacade.errorLanguage$;
  support$ = this.viewerCoreFacade.errorSupport$;

  _support: AdminSupportSettings;

  theme$ = this.viewerCoreFacade.errorTheme$;

  _origin = '';
  _keycode = '';
  _domain = '';
  _previousPath = '';

  _errorCode: string;
  _theme: LoginTheme;

  get errorMessage() {
    return ERROR_CONSTANT[this._errorCode] || ERROR_CONSTANT['UNKNOWN'];
  }

  get destination() {
    return this._previousPath || (this._origin && this._keycode ? `${this._origin}/${this._keycode}` : null);
  }

  constructor(
    private routerStore: RouterStore,
    private viewerCoreFacade: ViewerCoreFacade,
    private translate: TranslateService,
    private meta: Meta,
    @Inject(WINDOW) private window: Window,
  ) {
    this._queryParams$ = this.routerStore.pipe(map((route) => route.state.queryParams));

    this.meta.updateTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, minimal-ui',
    });
  }

  ngOnInit() {
    this.theme$
      .pipe(
        skip(1),
        takeUntil(this.destroy$),
        withLatestFromLazy(this.language$),
        withLatestFromLazy(this.support$),
        switchMap(([[theme, language], support]) => {
          this._theme = theme;
          this._support = support && support.name ? support : null;
          this.setLanguage(language || 'en-US');

          return of(false);
        }),
      )
      .subscribe();

    // set the right code and text
    this.errorCode$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.viewerCoreFacade.setAppReady();
        }),
        withLatestFromLazy(this._queryParams$),
        mergeMap(([storeErrorCode, queryParams]: [string, any]) => {
          const { origin, keycode, customMessage, previousPath, errorCode } = queryParams;

          // fetch theme, language, etc
          const redirectUrl = origin && keycode ? origin + '/' + keycode : null;
          this.viewerCoreFacade.loadClientBasicConfig(redirectUrl);

          // set the message
          this._errorCode = customMessage || errorCode || storeErrorCode;

          // store details for extra context
          this._previousPath = previousPath || '';
          this._origin = origin;
          this._keycode = keycode;
          this._domain = this.window.origin;
          return of(false);
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setLanguage(lang) {
    const defaultLocale = 'en-US';
    setDayJsLocale(lang);
    this.translate.use(lang || defaultLocale);
  }

  logout() {
    onceWithLatest(this.viewerCoreFacade.keycode$, this.viewerCoreFacade.origin$, (keycode, origin) => {
      this.viewerCoreFacade.logout(keycode, origin);
    });
  }
}
