import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { ContactCustomFields, getKeyToKeyObj, selectableRecipientFieldsObj } from './common';

export interface AcademyExportColumns {
  id: keyof AcademyListReportFields | keyof AcademySingleReportFields;
  label: string;
  checked: boolean;
  expanded: boolean;
  columns: Array<{ id: string; name: string; checked: boolean }>;
}
// #region for single academy
/*
  database contact field -> spreadsheet header name

  It contains the optional contact standard fields that can be selected in the frontend when exporting academy analytics (all academy analytics or just for one) to a spreadsheet
*/

export const selectableRecipientFieldsObjSingle = {
  ...selectableRecipientFieldsObj,
  // #region academy fields
  progress: i18n('Progress'),
  published: i18n('First published'),
  // created: i18n'Created', // only when exporting list of academies
  completed: i18n('Completed'),
  // #endregion academy fields
} as const;
export const selectableGroupFieldsObjSingle = {
  id: i18n('ID'),
  name: i18n('Group'),
  progress: i18n('Average progress'),
  nEmployeesStartedAcademy: i18n('# of Started Academies'),
  size: i18n('# of Employees'),
  published: i18n('First published'),
  completed: i18n('Completed'), // different in selectableGroupFieldsObjAll
} as const;
export const selectableDepartmentFieldsObjSingle = {
  department: i18n('Department'),
  progress: i18n('Average progress'),
  size: i18n('# of Employees'),
} as const;
export const selectableContentFieldsObjSingle = {
  trainingId: i18n('Training ID'),
  type: i18n('Type'),
  title: i18n('Name'),
  progress: i18n('Average progress'),
  openedRate: i18n('Opened'),
  passedRate: i18n('Passed'),
  published: i18n('First published'),
} as const;

export const selectableGroupFieldsObjKeysSingle = getKeyToKeyObj(selectableGroupFieldsObjSingle) as Record<
  keyof typeof selectableGroupFieldsObjSingle,
  keyof typeof selectableGroupFieldsObjSingle
>;

export const selectableRecipientFieldsObjKeysSingle = getKeyToKeyObj(selectableRecipientFieldsObjSingle) as Record<
  keyof typeof selectableRecipientFieldsObjSingle,
  keyof typeof selectableRecipientFieldsObjSingle
>;

export const selectableDepartmentFieldsObjKeysSingle = getKeyToKeyObj(selectableDepartmentFieldsObjSingle) as Record<
  keyof typeof selectableDepartmentFieldsObjSingle,
  keyof typeof selectableDepartmentFieldsObjSingle
>;

export const selectableContentFieldsObjKeysSingle = getKeyToKeyObj(selectableContentFieldsObjSingle) as Record<
  keyof typeof selectableContentFieldsObjSingle,
  keyof typeof selectableContentFieldsObjSingle
>;

export type AcademySingleReportFields = {
  content: (keyof typeof selectableContentFieldsObjSingle)[];
  recipient: (keyof typeof selectableRecipientFieldsObjSingle | ContactCustomFields)[];
  group: (keyof typeof selectableGroupFieldsObjSingle)[];
  department: (keyof typeof selectableDepartmentFieldsObjSingle)[];
};

const checkedSelectableRecipientSingle = {
  [selectableRecipientFieldsObjKeysSingle.id]: true,
  [selectableRecipientFieldsObjKeysSingle.employeeId]: true,
  [selectableRecipientFieldsObjKeysSingle.name]: true,
  [selectableRecipientFieldsObjKeysSingle.email]: true,
  [selectableRecipientFieldsObjKeysSingle.addr_country]: true,
  [selectableRecipientFieldsObjKeysSingle.department]: true,
  [selectableRecipientFieldsObjKeysSingle.group_names]: true,
  [selectableRecipientFieldsObjKeysSingle.progress]: true,
  [selectableRecipientFieldsObjKeysSingle.published]: true,
  [selectableRecipientFieldsObjKeysSingle.completed]: true,
};
const selectableGroupsSingle: AcademyExportColumns = {
  id: 'group',
  label: i18n('Groups data'),
  checked: false,
  expanded: false,
  columns: Object.keys(selectableGroupFieldsObjKeysSingle).map((key) => ({
    id: key,
    name: selectableGroupFieldsObjSingle[key],
    checked: false,
  })),
};
const selectableRecipientSingle: AcademyExportColumns = {
  id: 'recipient',
  label: i18n('Employees data'),
  checked: false,
  expanded: false,
  columns: Object.keys(selectableRecipientFieldsObjKeysSingle).map((key) => ({
    id: key,
    name: selectableRecipientFieldsObjSingle[key],
    checked: !!checkedSelectableRecipientSingle[key],
  })),
};

const selectableDepartmentFieldsSingle: AcademyExportColumns = {
  id: 'department',
  label: i18n('Department data'),
  checked: false,
  expanded: false,
  columns: Object.keys(selectableDepartmentFieldsObjKeysSingle).map((key) => ({
    id: key,
    name: selectableDepartmentFieldsObjSingle[key],
    checked: false,
  })),
};

const selectableContentFieldsSingle: AcademyExportColumns = {
  id: 'content',
  label: i18n('Content data'),
  checked: false,
  expanded: false,
  columns: Object.keys(selectableContentFieldsObjKeysSingle).map((key) => ({
    id: key,
    name: selectableContentFieldsObjSingle[key],
    checked: false,
  })),
};

export const exportColumnSections_exportSingle: AcademyExportColumns[] = [
  selectableContentFieldsSingle,
  selectableRecipientSingle,
  selectableGroupsSingle,
  selectableDepartmentFieldsSingle,
];
// #endregion

// #region for all academies
export const selectableRecipientFieldsObjAll = {
  ...selectableRecipientFieldsObj,
  // #region academy fields
  progress: i18n('Progress'),
  published: i18n('Available academies'),
  created: i18n('Employee created'),
  // #endregion academy fields
} as const;
export const selectableAllDataFieldsObjAll = {
  ...selectableRecipientFieldsObjAll,
  published: i18n('First published'),
  academy: i18n('Academy'),
  completed: i18n('Completed'), // only when exporting one academy and all-data
  categories: i18n('Categories'),
} as const;
export const selectableGroupFieldsObjAll = {
  id: i18n('ID'),
  name: i18n('Group'),
  progress: i18n('Average progress'),
  nEmployeesStartedAcademy: i18n('# of Started Academies'),
  employees: i18n('# of Employees'),
  published: i18n('Available academies'),
  created: i18n('Group created'), // different in selectableGroupFieldsObjSingle
} as const;
export const selectableDepartmentFieldsObjAll = {
  name: i18n('Department'),
  progress: i18n('Average progress'),
  published: i18n('Available academies'),
  nEmployeesStartedAcademy: i18n('# of Started Academies'),
  employees: i18n('# of Employees'),
} as const;
export const selectableCategoryFieldsObjAll = {
  id: i18n('ID'),
  name: i18n('Category'),
  progress: i18n('Average progress'),
  published: i18n('Available academies'),
} as const;

export const selectableRecipientFieldsObjKeysAll = getKeyToKeyObj(selectableRecipientFieldsObjAll) as Record<
  keyof typeof selectableRecipientFieldsObjAll,
  keyof typeof selectableRecipientFieldsObjAll
>;

export const selectableGroupFieldsObjKeysAll = getKeyToKeyObj(selectableGroupFieldsObjAll) as Record<
  keyof typeof selectableGroupFieldsObjAll,
  keyof typeof selectableGroupFieldsObjAll
>;

export const selectableCategoryFieldsObjKeysAll = getKeyToKeyObj(selectableCategoryFieldsObjAll) as Record<
  keyof typeof selectableCategoryFieldsObjAll,
  keyof typeof selectableCategoryFieldsObjAll
>;

export const selectableDepartmentFieldsObjKeysAll = getKeyToKeyObj(selectableDepartmentFieldsObjAll) as Record<
  keyof typeof selectableDepartmentFieldsObjAll,
  keyof typeof selectableDepartmentFieldsObjAll
>;

export const selectableAllDataFieldsObjKeysAll = getKeyToKeyObj(selectableAllDataFieldsObjAll) as Record<
  keyof typeof selectableAllDataFieldsObjAll,
  keyof typeof selectableAllDataFieldsObjAll
>;

export type AcademyListReportFields = {
  recipient: (keyof typeof selectableRecipientFieldsObjAll | ContactCustomFields)[];
  group: (keyof typeof selectableGroupFieldsObjAll)[];
  department: (keyof typeof selectableDepartmentFieldsObjAll)[];
  category: (keyof typeof selectableCategoryFieldsObjAll)[];
};
const checkedSelectableRecipientAll = {
  [selectableRecipientFieldsObjKeysAll.id]: true,
  [selectableRecipientFieldsObjKeysAll.employeeId]: true,
  [selectableRecipientFieldsObjKeysAll.name]: true,
  [selectableRecipientFieldsObjKeysAll.email]: true,
  [selectableRecipientFieldsObjKeysAll.addr_country]: true,
  [selectableRecipientFieldsObjKeysAll.department]: true,
  [selectableRecipientFieldsObjKeysAll.group_names]: true,
  [selectableRecipientFieldsObjKeysAll.progress]: true,
  [selectableRecipientFieldsObjKeysAll.published]: true,
  [selectableRecipientFieldsObjKeysAll.created]: true,
};
const selectableRecipientAll: AcademyExportColumns = {
  id: 'recipient',
  label: i18n('Employees data'),
  checked: false,
  expanded: true,
  columns: Object.keys(selectableRecipientFieldsObjKeysAll).map((key) => ({
    id: key,
    name: selectableRecipientFieldsObjAll[key],
    checked: !!checkedSelectableRecipientAll[key],
  })),
};

const selectableDepartmentsAll: AcademyExportColumns = {
  id: 'department',
  label: i18n('Departments data'),
  checked: false,
  expanded: false,
  columns: Object.keys(selectableDepartmentFieldsObjKeysAll).map((key) => ({
    id: key,
    name: selectableDepartmentFieldsObjAll[key],
    checked: false,
  })),
};

const selectableCategoriesAll: AcademyExportColumns = {
  id: 'category',
  label: i18n('Categories data'),
  checked: false,
  expanded: false,
  columns: Object.keys(selectableCategoryFieldsObjKeysAll).map((key) => ({
    id: key,
    name: selectableCategoryFieldsObjAll[key],
    checked: false,
  })),
};

const selectableGroupsAll: AcademyExportColumns = {
  id: 'group',
  label: i18n('Groups data'),
  checked: false,
  expanded: false,
  columns: Object.keys(selectableGroupFieldsObjKeysAll).map((key) => ({
    id: key,
    name: selectableGroupFieldsObjAll[key],
    checked: false,
  })),
};

export const exportColumnSections_exportAll = [
  selectableRecipientAll,
  selectableGroupsAll,
  selectableDepartmentsAll,
  selectableCategoriesAll,
];

// #endregion
