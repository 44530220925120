<div class="ao-progress" [ngClass]="{ 'ao-progress--has-target': target }">
  <div class="ao-progress__container">
    <div class="ao-progress__header">
      <div class="ao-font-tiny ao-progress__header__legend">{{ headerTitle }}</div>
      <div class="ao-font-body2 ao-progress__header__value">{{ headerText }}</div>
    </div>
    <ao-progress-linear
      *ngIf="showBar"
      [size]="size"
      [color]="color"
      [value]="value ? value : 0"
      [maxValue]="maxValue"
      [target]="target"
      [targetLabel]="targetLabel"
    ></ao-progress-linear>
  </div>
  <div *ngIf="showLink" class="ao-progress__link">
    <ao-icon class="ao-progress__link__icon" name="arrow_forward" [size]="18"></ao-icon>
  </div>
</div>
