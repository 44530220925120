import { Injectable } from '@angular/core';
import { interpolate, withLatestFromLazy } from '@ao/utilities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AppFacade } from '../../app-store.facade';
import { AppService } from '../../services/app-store.service';
import * as appActions from '../actions';

@Injectable()
export class ModuleChartEffects {
  constructor(private actions$: Actions, private appService: AppService, private appFacade: AppFacade) {}

  loadChartData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LoadChartData),
      withLatestFromLazy(this.appFacade.messageInterpolationContext$, this.appFacade.messageContentLanguage$),
      mergeMap(([{ module }, context, contentLanguage]) => {
        const dataUrl = interpolate(module.data_url, context, contentLanguage);
        return this.appService.getChartData(dataUrl).pipe(
          map((data) => appActions.LoadChartDataSuccess({ module, data })),
          catchError((error) => of(appActions.LoadChartDataFail(error))),
        );
      }),
    ),
  );
}
