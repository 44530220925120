import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floor',
})
export class FloorPipe implements PipeTransform {
  transform(value: number, decimals = 0): any {
    const aux = Math.pow(10, decimals);
    return Math.floor(value * aux) / aux;
  }
}
