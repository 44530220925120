import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ao-task-list-section',
  templateUrl: './task-list-section.component.html',
  styleUrls: ['./task-list-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListSectionComponent {
  @Input() title: string | undefined;
  @Input() count: string | undefined;
  @Input() isOpen = false;
  @Input() warn = false;
  @Input() loadingSkeleton = false;

  get classList() {
    return {
      'task-list-section--warn': this.warn,
      'task-list-section--loading': this.loadingSkeleton,
    };
  }
}
