export const fileTypeBgClasses = [
  {
    className: 'ao-bg--sheet',
    typeMatchers: ['sheet'],
  },
  {
    className: 'ao-bg--doc',
    typeMatchers: ['doc'],
  },
  {
    className: 'ao-bg--ppt',
    typeMatchers: ['presentation'],
  },
  {
    className: 'ao-bg--pdf',
    typeMatchers: ['pdf'],
  },
  {
    className: 'ao-bg--img',
    typeMatchers: ['image'],
  },
  {
    className: 'ao-bg--vid',
    typeMatchers: ['video'],
  },
  {
    className: 'ao-bg--aud',
    typeMatchers: ['audio'],
  },
  {
    className: 'ao-bg--font',
    typeMatchers: ['font'],
  },
  {
    className: 'ao-bg--other',
    typeMatchers: ['other'],
  },
];
