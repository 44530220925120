<a
  class="ao-academy-section-training__wrapper"
  *ngIf="!sectionDisabled && !training.disabled && training.keycode; else noLink"
  [routerLink]="['/', training.prefix, training.keycode]"
  [queryParams]="{ academyId: academyId }"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<ng-template #noLink>
  <a class="ao-academy-section-training__wrapper">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>

<ng-template #content>
  <div class="ao-academy-section-training__status">
    <div
      *ngIf="!isJustOneTraining"
      class="ao-academy-section-training__dashed-line"
      [style.background-image]="'url(/assets/images/dashed-gray-line.svg)'"
    ></div>
    <ao-status-circle class="ao-academy-section-training__status-circle" [status]="trainingStatus"> </ao-status-circle>
  </div>

  <div
    class="ao-academy-section-training__info"
    [ngClass]="{ 'ao-academy-section-training__info--disabled': sectionDisabled || training.disabled }"
  >
    <div class="ao-academy-section-training__content">
      <div class="ao-font-body2-bold ao-academy-section-training__title">
        {{ training.title }}
      </div>
      <div class="ao-font-small ao-academy-section-training__points" *ngIf="training.pointsPossible">
        {{
          '#receivedValue# / #possibleValue# Points [points earned from Actimo surveys]'
            | translate: { receivedValue: training.points, possibleValue: training.pointsPossible }
        }}
      </div>
      <div
        class="ao-font-small ao-academy-section-training__retake"
        *ngIf="
          !(sectionDisabled || training.disabled) &&
          (training.retakeMaxAttempts === -1 || training.retakeMaxAttempts > training.attempts) &&
          !training.passed &&
          training.completed
        "
      >
        {{
          training.retakeMaxAttempts === -1 || training.retakeMaxAttempts > 10
            ? ('Retake' | translate)
            : ('Retakes available: #count# [quiz]'
              | translate: { count: training.retakeMaxAttempts - training.attempts })
        }}
      </div>
    </div>

    <div
      class="ao-academy-section-training__image"
      [style.backgroundImage]="backgroundImage"
      [aoUnsplashTrackViews]="trackImageId"
    ></div>
  </div>
</ng-template>
