import { DOCUMENT } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { BrowserService } from '../../services/browser.service';
import { makeHttps } from '../../utils/make-https';

@Pipe({
  name: 'secureLinks',
})
export class SecureLinksPipe implements PipeTransform {
  constructor(@Inject(DOCUMENT) private document: Document, private browser: BrowserService) {}

  transform(html: string, allowInsecure = false): any {
    const el = this.createElement(html);
    if (allowInsecure) {
      return el.innerHTML;
    }
    const treeWalker = this.document.createTreeWalker(el, NodeFilter.SHOW_ELEMENT, null);
    while (treeWalker.nextNode()) {
      const node = treeWalker.currentNode;
      if (!this.isAnchorElement(node)) continue;

      if (node.hasAttribute('href')) {
        node.setAttribute('href', makeHttps(node.getAttribute('href')));
      }

      if (node.hasAttribute('target')) {
        const targetValue = this.setTargetValue(node.getAttribute('target'));
        node.setAttribute('target', targetValue);

        // secure outbound links, see: https://tinyurl.com/y52ruggl
        if (targetValue === '_blank') {
          node.setAttribute('rel', 'noreferrer noopener');
        }
      }
    }
    return el.innerHTML;
  }

  private isAnchorElement(node: Node): node is HTMLAnchorElement {
    return node.nodeName === 'A';
  }

  private setTargetValue(targetValue: string) {
    return this.browser.isStandaloneIOS ? '_self' : targetValue;
  }

  private createElement(value: string) {
    const el = this.document.createElement('div');
    el.innerHTML = value;
    return el;
  }
}
