import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchRoutingModule } from './search-routing.module';

@NgModule({
  imports: [CommonModule, SearchRoutingModule],
  exports: [],
  providers: [],
})
export class InternalDirectoryModule {}

@NgModule({
  imports: [InternalDirectoryModule],
})
export class ViewerSearchModule {}
