<!-- label (header) -->
<ng-container *ngIf="!reverseOrder">
  <ng-container *ngTemplateOutlet="labelElement"></ng-container>
</ng-container>

<!-- Input container -->
<div
  class="ao-viewer-input-wrapper__input-content"
  [class.ao-viewer-input-wrapper__input-content--minimal-width]="fullWidthLabel"
  [class.ao-viewer-input-wrapper__input-content--full-width]="!reverseOrder || fullWidthLabel"
>
  <ng-content></ng-content>
  <!-- error -->
  <div *ngIf="displayErrors && errorTemplate" class="ao-font-body2-bold ao-viewer-input-wrapper__error" [@expandInOut]>
    <ng-template [ngTemplateOutlet]="errorTemplate"></ng-template>
  </div>
  <!-- disabled explanation -->
  <div *ngIf="disabledTemplate" class="ao-font-body2-bold ao-viewer-input-wrapper__disabled">
    <ng-template [ngTemplateOutlet]="disabledTemplate"></ng-template>
  </div>
</div>

<!-- label (header) -> radio-buttons/toggles have labels after the input -->
<ng-container *ngIf="reverseOrder">
  <ng-container *ngTemplateOutlet="labelElement"></ng-container>
</ng-container>

<!-- label template -->
<ng-template #labelElement>
  <div
    *ngIf="labelTemplate; else defaultLabel"
    class="ao-viewer-input-wrapper__header"
    [class.ao-viewer-input-wrapper__header--full-width]="fullWidthLabel"
  >
    <ng-template [ngTemplateOutlet]="labelTemplate"></ng-template>
  </div>
  <ng-template #defaultLabel>
    <label
      *ngIf="label"
      class="ao-viewer-input-wrapper__header"
      [class.ao-viewer-input-wrapper__header--full-width]="fullWidthLabel"
    >
      <!-- name template -->
      <p class="ao-font-body2 ao-viewer-input-wrapper__label" [class.ao-viewer-input-wrapper__label--bold]="boldLabel">
        {{ label }}
      </p>

      <!-- optional indicator  -->
      <ao-input-tag
        *ngIf="optional"
        [boldTag]="true"
        class="ao-viewer-input-wrapper__optional"
        type="optional"
      ></ao-input-tag>

      <!-- required indicator -->
      <ao-input-tag
        *ngIf="required"
        [boldTag]="true"
        class="ao-viewer-input-wrapper__optional"
        tagColor="yellow"
        type="required"
      ></ao-input-tag>
    </label>
  </ng-template>
  <ng-container *ngIf="subHeader">
    <div class="ao-font-small ao-viewer-input-wrapper__sub-header">{{ subHeader }}</div>
  </ng-container>
</ng-template>
