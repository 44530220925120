import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSocial from './+state/social-store.reducer';
import { SocialEffects } from './+state/social-store.effects';
import { SocialFacade } from './social-store.facade';
import { SocialService } from './social-store.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSocial.SOCIAL_FEATURE_KEY, fromSocial.reducer),
    EffectsModule.forFeature([SocialEffects]),
  ],
  providers: [SocialFacade, SocialService],
})
export class SocialStoreModule {}
