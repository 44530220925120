import { getModuleRef } from '@ao/utilities';
import { on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, moduleAdapter } from './app-store.state';

export const moduleSmsVerifyOns = [
  on(appActions.SmsVerifyReset, (state: AppState, { module }) => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            response: undefined,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.SmsVerifySubmitSuccess, (state: AppState, { module }) => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            response: { success: true },
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.SmsVerifySubmitFail, (state: AppState, { module }) => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            response: { success: false },
          },
        },
        { ...state.modules },
      ),
    };
  }),
];
