import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ThemeHandlerService } from '@ao/common-ui';
import { ACTIMO_COLORS, Theme, TrainingMessageSettings } from '@ao/data-models';
import { color as c, highlightColor } from '@ao/utilities';

@Component({
  selector: 'ao-complete-training-footer',
  templateUrl: './complete-training-footer.component.html',
  styleUrls: ['./complete-training-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompleteTrainingFooterComponent {
  @HostBinding('class.ao-complete-training-footer') className = true;

  @Input() training: TrainingMessageSettings;
  @Input() isQuestionnaire = false;
  @Output() exit = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() complete = new EventEmitter();

  theme: Theme;

  constructor(themeHandler: ThemeHandlerService) {
    themeHandler.registerStyle('CompleteTrainingFooterComponent', (theme) => {
      this.theme = theme;
      return `
        .ao-complete-training-footer {
          background-color: ${c(theme.nav_bg_color, ACTIMO_COLORS.blue.base)};
          color: ${c(theme.nav_icon_color, '#ffffff')};
        }
        .ao-complete-training-footer .ao-complete-training-footer__complete {
          color: ${c(theme.nav_bg_color, ACTIMO_COLORS.blue.base)};
          background-color: ${c(theme.nav_icon_color, '#ffffff')};
          border-color: ${c(theme.nav_icon_color, '#ffffff')};
        }
        .ao-complete-training-footer .ao-complete-training-footer__complete:hover {
          background-color: ${c(highlightColor(theme.nav_icon_color, 1, 1.15))};
        }
      `;
    });
  }

  get showComplete() {
    // hide if not academy (training === null)
    if (!this.training) {
      return false;
    }

    // questionnaire
    if (this.isQuestionnaire) {
      if (this.training.passed) {
        // show "next training" if one is available
        return !!this.training.nextTrainingKeycode;
      } else {
        // show only if retakes are available
        return this.training.retakeMaxAttempts === -1 || this.training.retakeMaxAttempts > this.training.attempts;
      }
      // normal message
    } else {
      // show "next training" if one is available
      return !!this.training.nextTrainingKeycode;
    }
  }
}
