import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ao-country-flag',
  template: '',
  styleUrls: ['./country-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryFlagComponent {
  @Input() country: string;
  @HostBinding('class') get className() {
    return `flag flag__${this.country.toLowerCase()}`;
  }
}
