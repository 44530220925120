<button
  type="button"
  name="home"
  class="button button-icon"
  (click)="homePageClick.emit($event)"
  [disabled]="disabledIcons"
>
  <ao-icon
    [ngStyle]="{
      color: getColor('MESSAGE')
    }"
    [size]="iconSize"
    name="home"
  ></ao-icon>
</button>
<button
  [disabled]="disabledIcons"
  type="button"
  name="social"
  class="button button-icon"
  (click)="socialClick.emit(socialLink)"
  *ngIf="showSocialInNavbar"
>
  <ao-icon
    [ngStyle]="{
      color: getColor('SOCIAL', true)
    }"
    [size]="iconSize"
    name="group"
  ></ao-icon>
</button>
<button
  [disabled]="disabledIcons"
  type="button"
  name="task"
  class="button button-icon"
  (click)="tasksClick.emit(tasksLink)"
  *ngIf="showTasksInNavBar"
>
  <ao-badge-icon
    [ngStyle]="{
      color: getColor('TASK', true)
    }"
    [size]="iconSize"
    [hasUnread]="tasksBadge"
    iconName="check_box"
  ></ao-badge-icon>
</button>
<button
  [disabled]="disabledIcons"
  type="button"
  name="notifications"
  class="button button-icon"
  (click)="notificationsClick.emit(notificationsLink)"
>
  <ao-badge-icon
    [ngStyle]="{
      color: getColor('NOTIFICATIONS', true)
    }"
    [size]="iconSize"
    [hasUnread]="hasNotificationsUnread"
    iconName="notifications"
  ></ao-badge-icon>
</button>
<button
  [disabled]="disabledIcons"
  type="button"
  name="profile"
  class="button button-icon"
  (click)="profileClicked.emit(profileLink)"
  [class.has-avatar-images]="hasAvatarImages"
  [ngStyle]="hasAvatarImages ? { 'border-color': getColor('PROFILE', true) } : null"
>
  <ao-avatar
    size="smaller"
    [bgColor]="getColor('PROFILE', true)"
    [txtColor]="theme?.nav_bg_color"
    [images]="contact?.avatarImages"
  >
  </ao-avatar>
</button>
