<div class="ao-peer-view__dialogue">
  <div class="ao-peer-view__close-button">
    <ao-icon
      *ngIf="mode === 'DESKTOP'"
      role="button"
      class="ao-peer-view__close"
      name="close"
      (click)="onCloseDialog()"
      [size]="18"
    >
    </ao-icon>
  </div>
  <ao-peer-view-main-info [contact]="contact"></ao-peer-view-main-info>

  <footer class="ao-peer-view__footer">
    <div class="ao-peer-view__actions">
      <div class="ao-peer-view__action" *ngIf="showChat">
        <a class="ao-font-tiny" (click)="onActionClick('chat', contact?.id)">
          <div class="ao-peer-view__icon">
            <ao-icon name="chat" [size]="18"></ao-icon>
          </div>
          <span translate>Chat</span>
        </a>
      </div>
      <div class="ao-peer-view__action" [class.ao-peer-view__action--disabled]="!contact?.phone">
        <a class="ao-font-tiny" (click)="onActionClick('tel', contact?.phone)">
          <div class="ao-peer-view__icon">
            <ao-icon name="call" [size]="18"></ao-icon>
          </div>
          <span translate>Call</span>
        </a>
      </div>

      <div class="ao-peer-view__action" [class.ao-peer-view__action--disabled]="!contact?.phone">
        <a class="ao-font-tiny" (click)="onActionClick('sms', contact?.phone)">
          <div class="ao-peer-view__icon">
            <ao-icon name="message" [size]="18"></ao-icon>
          </div>
          <span translate>SMS</span>
        </a>
      </div>

      <div class="ao-peer-view__action" [class.ao-peer-view__action--disabled]="!contact?.email">
        <a class="ao-font-tiny" (click)="onActionClick('email', contact?.email)">
          <div class="ao-peer-view__icon">
            <ao-icon name="mail" [size]="18"></ao-icon>
          </div>
          <span translate>Email</span>
        </a>
      </div>
    </div>
  </footer>
  <button
    *ngIf="hasRemoveButton"
    ao-button
    [buttonColor]="'red'"
    class="ao-peer-view__main-action"
    (click)="onRemoveFromGroup()"
    translate
  >
    Remove from group
  </button>
  <button
    ao-button
    [buttonColor]="'transparent'"
    *ngIf="mode === 'MOBILE'"
    class="ao-peer-view__main-action"
    (click)="onDismiss()"
  >
    Cancel
  </button>
</div>
