import { QuillDelta, SocialContact, SocialPost } from '@ao/data-models';
import { ExtendedSavedPost, MediaItem } from '@ao/shared-data-models';
export function mapExtendedSavedPostToPost(post: ExtendedSavedPost): Partial<SocialPost> {
  const mappedPost = {
    id: post.id,
    groupId: post.groupInfo.id,
    groupName: post.groupInfo.name,
    author: {
      id: post.author.id,
      name: post.author.name,
      avatarImages: post.author.avatarImages && post.author.avatarImages.length ? post.author.avatarImages : null,
      initials: post.author.initials,
    } as SocialContact,
    media: post.media as MediaItem[],
    content: {
      ops: post.content,
    } as QuillDelta,
    linkPreview: post.linkPreview || {},
  };

  return mappedPost;
}
