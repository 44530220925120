import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@ao/environments';
import 'hammerjs';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    const applicationRef = moduleRef.injector.get(ApplicationRef);
    const componentRef = applicationRef.components[0];
    // allows to run `ng.profiler.timeChangeDetection();`
    if (!environment.production) {
      enableDebugTools(componentRef);
    }
  })
  .catch((err) => console.log(err));

// handle redirect to private message from external sites
(<any>window).redirectToMessage = (origin, keycode) => {
  window.location.replace(`/${origin}/${keycode}`);
};

// handle auth error redirect from external sites
(<any>window).handleError = (errorCode, supportEmail) => {
  window.location.replace(`/auth-error?code=${errorCode}&support=${supportEmail}`);
};
