import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZoomableImageService implements OnDestroy {
  private _lightboxOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get isLightboxOpen$() {
    return this._lightboxOpen$.asObservable();
  }

  openLightBox() {
    this._lightboxOpen$.next(true);
  }

  closeLightBox() {
    this._lightboxOpen$.next(false);
  }

  ngOnDestroy() {
    this._lightboxOpen$.complete();
  }
}
