import { NavigationExtras } from '@angular/router';
import { createAction, props } from '@ngrx/store';

export const RouterGo = createAction(
  '[Router] Go',
  props<{
    path: any[];
    query?: Record<string, unknown>;
    extras?: NavigationExtras;
  }>(),
);
export const RouterBack = createAction('[Router] Back');
export const RouterForward = createAction('[Router] Forward');
