import { Injectable } from '@angular/core';
import {
  Notification,
  NotificationChannelSettingType,
  NotificationRequestParams,
  NotificationSettingType,
  NotificationTab,
} from '@ao/shared-data-models';
import { Store } from '@ngrx/store';
import * as notificationsActions from './+state/notifications-store.actions';
import * as notificationsSelectors from './+state/notifications-store.selectors';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsFacade {
  allNotifications$ = this.store.select(notificationsSelectors.selectAllNotifications);
  hasUnread$ = this.store.select(notificationsSelectors.selectHasUnread);
  unreadNotifications$ = this.store.select(notificationsSelectors.selectUnreadNotifications);
  filterUnread$ = this.store.select(notificationsSelectors.selectFilterUnread);
  notificationsLoadingState$ = this.store.select(notificationsSelectors.selectNotificationsLoadingState);
  notificationsLoadedAll$ = this.store.select(notificationsSelectors.selectNotificationsLoadedAll);
  userSettingsGroups$ = this.store.select(notificationsSelectors.selectUserSettingsGroups);
  activeTab$ = this.store.select(notificationsSelectors.selectActiveTab);
  notificationsOffAndDisabled$ = this.userSettingsGroups$.pipe(
    switchMap((data) => {
      return of(!data.find((group) => !group?.hidden));
    }),
  );

  userSettingsGroupBySettingType$ = (settingType: NotificationSettingType) =>
    this.store.select(notificationsSelectors.selectUserSettingsGroupBySettingType(settingType));

  constructor(private store: Store) {}

  loadNotifications(params: NotificationRequestParams, filterUnread?: boolean, shouldLoadFresh?: boolean): void {
    return this.store.dispatch(notificationsActions.LoadNotifications({ params, filterUnread, shouldLoadFresh }));
  }

  markNotificationAsRead(notification: Notification): void {
    return this.store.dispatch(notificationsActions.MarkNotificationAsRead({ notification }));
  }

  loadUserNotificationSettings(): void {
    return this.store.dispatch(notificationsActions.LoadUserNotificationSettings());
  }

  markNotificationAsUnread(notification: Notification): void {
    return this.store.dispatch(notificationsActions.MarkNotificationAsUnread({ notification }));
  }

  markAllNotificationsAsRead(): void {
    return this.store.dispatch(notificationsActions.MarkAllNotificationsAsRead());
  }

  toggleNotificationSetting(
    settingType: NotificationSettingType,
    channelSettingType: NotificationChannelSettingType,
    newValue: boolean,
  ): void {
    return this.store.dispatch(
      notificationsActions.ToggleNotificationSetting({ settingType, channelSettingType, newValue }),
    );
  }

  toggleGroupNotificationSetting(groupId: string, newValue: boolean, source: string): void {
    return this.store.dispatch(notificationsActions.ToggleGroupNotificationSetting({ groupId, newValue, source }));
  }

  switchActiveTab(activeTab: NotificationTab): void {
    return this.store.dispatch(notificationsActions.SwitchActiveTab({ activeTab }));
  }

  registerNotificationClick(notification: Notification): void {
    return this.store.dispatch(notificationsActions.RegisterNotificationClick({ notification }));
  }

  loadNotificationHasUnread(hasUnread?: boolean) {
    return this.store.dispatch(notificationsActions.LoadNotificationHasUnread({ hasUnread }));
  }
}
