import { Component, Inject, OnDestroy } from '@angular/core';
import { WINDOW } from '@ao/utilities';

@Component({
  selector: 'viewer-ghost-mode',
  templateUrl: './ghost-mode.component.html',
  styleUrls: ['./ghost-mode.component.scss'],
})
export class GhostModeComponent implements OnDestroy {
  isGhostMode = false;

  constructor(@Inject(WINDOW) private window: Window) {
    const params = new URL(this.window.location.href).searchParams;
    const ghostMode = params.get('ghostLoginToken');
    const storedGhostStatus = this.window.sessionStorage.getItem('ghostMode');

    if (ghostMode?.length > 0 || storedGhostStatus) {
      this.isGhostMode = true;
      this.window.sessionStorage.setItem('ghostMode', 'true');
    }
  }

  ngOnDestroy() {
    this.window.sessionStorage.removeItem('ghostMode');
  }
}
