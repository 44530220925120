import { createAction, props } from '@ngrx/store';
import { ExtendedSavedAcademy, ExtendedSavedMessage, ExtendedSavedPost } from '@ao/shared-data-models';
import { SavedContentType } from '@ao/data-models';

export const loadContent = createAction('[SavedContent Page] Load content', props<{ contentType: SavedContentType }>());

export const loadContentFail = createAction('[SavedContent Page] Load messages failed', props<{ error: string }>());

export const loadMessagesSuccess = createAction(
  '[SavedContent Page] Load messages success',
  props<{ messages: ExtendedSavedMessage[] }>(),
);

export const loadAcademiesSuccess = createAction(
  '[SavedContent Page] Load academies success',
  props<{ academies: ExtendedSavedAcademy[] }>(),
);
export const loadPostsSuccess = createAction(
  '[SavedContent Page] Load posts success',
  props<{ posts: ExtendedSavedPost[] }>(),
);

export const saveItem = createAction(
  '[SavedContent Page] save item',
  props<{ contentType: SavedContentType; itemId: number; itemKeycode: string }>(),
);
export const saveItemSuccess = createAction('[SavedContent Page] save item success', props<{ itemId: number }>());
export const saveItemFail = createAction(
  '[SavedContent Page] save item fail',
  props<{ itemId: number; error: unknown }>(),
);

// genric updating status
export const setItemUpdating = createAction(
  '[SavedContent Page] Set saving item update status',
  props<{ itemId: number; isUpdating: boolean }>(),
);

export const unsaveItem = createAction(
  '[SavedContent Page] unsave item',
  props<{ contentType: SavedContentType; itemId: number; itemKeycode: string }>(),
);
export const unsaveItemSuccess = createAction('[SavedContent Page] unsave item success', props<{ itemId: number }>());
export const unsaveItemFail = createAction(
  '[SavedContent Page] unsave item fail',
  props<{ itemId: number; error: unknown }>(),
);

export const savedContentOpened = createAction('[SavedContent Page] Saved content opened');
