<div class="viewer-auth-error__wrapper ao-font-max-medium" *ngIf="error$ | async; let error">
  <div>
    <div class="viewer-auth-error__canvas">
      <div
        *ngIf="error.theme?.nav_logo_url"
        class="viewer-auth-error__header"
        [style.background]="error.theme.nav_bg_color ? '#' + error.theme.nav_bg_color : 'transparent'"
      >
        <img *ngIf="error.theme.nav_logo_url" [aoSrc]="error.theme.nav_logo_url" class="viewer-auth-error__logo" />
      </div>
      <div class="viewer-auth-error__content">
        <ng-container *ngIf="error">
          <div class="viewer-auth-error__ops">
            <img src="/assets/ops_icon.png" />
            <h1 class="ao-font-title3">Oops</h1>
          </div>

          <!-- Error description -->
          <ng-container [ngSwitch]="error.code">
            <!-- contact does not exist -->
            <h2 class="ao-font-body2" *ngSwitchCase="'AUTH_CONTACT_NOT_FOUND'">
              {{ 'We could not find anyone matching your user name and password.' | translate }}
            </h2>

            <!-- duplicate contact -->
            <h2 class="ao-font-body2" *ngSwitchCase="'AUTH_DUPLICATE_CONTACT'">
              {{ 'We found multiple contacts matching your credentials.' | translate }}
            </h2>

            <!-- generic auth error -->
            <h2 class="ao-font-body2" *ngSwitchDefault>
              {{ 'Something went wrong.' | translate }}
            </h2>
          </ng-container>

          <div class="viewer-auth-error__separator"></div>

          <!-- Contact info -->
          <ng-container>
            <h2 class="ao-font-body2">{{ 'Please login again or contact our support:' | translate }}</h2>
            <a class="ao-font-body2 viewer-auth-error__contact-link" [attr.href]="'mailto:' + _supportEmail | trustUrl">
              {{ _supportEmail }}
            </a>
          </ng-container>

          <!-- Future try again when multiple methods are defined
          <h3>{{ 'Try again' | translate }}</h3> */}}-->
        </ng-container>
      </div>
    </div>
  </div>
</div>
