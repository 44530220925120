<ao-message-modal-card [open]="open" (close)="onDismiss()" [overflowVisible]="true">
  <h3 class="ao-font-title5 ao-auto-translation-modal__title" translate>Translate settings</h3>
  <p class="ao-font-body2 ao-auto-translation-modal__explanation" translate>
    Translate allows you to auto-translate messages and comments into your preferred language.
  </p>
  <div class="ao-auto-translation-modal__select-language" *ngIf="languages">
    <p translate>Select a language</p>
    <button (click)="toggleSearch()" class="ao-font-body2 ao-auto-translation-modal__select-box">
      <span class="ao-auto-translation-modal__select-box--language" *ngIf="selectedLanguage; else placeholder">{{
        selectedLanguage.languageName
      }}</span>
      <ng-template #placeholder
        ><span class="ao-auto-translation-modal__select-box--placeholder">{{
          'E.g. Dutch' | translate
        }}</span></ng-template
      >
      <ao-icon name="keyboard_arrow_down" [size]="18"></ao-icon>
    </button>
    <div *ngIf="searchVisible" class="ao-auto-translation-modal__search">
      <ao-social-wall-search-input
        [placeholder]="'Search' | translate"
        [(ngModel)]="searchInput"
        (ngModelChange)="onSearch($event)"
      >
      </ao-social-wall-search-input>
      <ul class="ao-auto-translation-modal__list">
        <li class="ao-font-body2 ao-auto-translation-modal__list--item" (click)="resetLanguage()" translate>None</li>
        <li
          class="ao-font-body2 ao-auto-translation-modal__list--item"
          *ngFor="let language of filteredLanguages"
          (click)="selectLanguage(language)"
        >
          {{ language.languageName }}
        </li>
      </ul>
    </div>
  </div>
  <button class="ao-font-body2-bold ao-auto-translation-modal__save" ao-button buttonColor="blue" (click)="onConfirm()">
    Save
  </button>
</ao-message-modal-card>
