<!--Stop recording Button-->
<div class="ao-audio-recorder__main-button" *ngIf="!blobUrl">
  <div role="button" class="ao-audio-recorder__stop-button" *ngIf="!recordedTime" (click)="startRecording()">
    <ao-icon name="mic"></ao-icon>
  </div>
  <div
    role="button"
    class="ao-audio-recorder__stop-button ao-audio-recorder__stop-button--recording"
    *ngIf="recordedTime"
    (click)="stopRecording()"
  >
    <ao-icon [size]="30" name="stop_circle"></ao-icon>
  </div>
</div>

<!-- waveform recording animation -->
<div
  class="ao-audio-recorder__waveform"
  [ngClass]="{ 'ao-audio-recorder__waveform--fixed': fixedWidth }"
  *ngIf="!!recordedTime"
>
  <ao-audio-waveform [recording]="!!recordedTime"> </ao-audio-waveform>
</div>

<!-- duration -->
<div *ngIf="recordedTime && !blobUrl" class="ao-font-body2 ao-audio-recorder__time">{{ recordedTime }}</div>

<!--after recording stops -->
<ng-container *ngIf="!recordedTime && blobUrl">
  <!-- preview player -->
  <div
    class="ao-audio-recorder__preview-player"
    [ngClass]="{ 'ao-audio-recorder__preview-player--fixed': fixedWidth }"
    *ngIf="!recordedTime && blobUrl"
  >
    <ao-audio-waveform-player
      [customDuration]="latestRecordinDuration"
      [blobUrl]="blobUrl.changingThisBreaksApplicationSecurity"
    >
    </ao-audio-waveform-player>
  </div>

  <!-- controls -->
  <div class="ao-audio-recorder__stopped">
    <!--Delete Button-->
    <div role="button" class="ao-audio-recorder__cancel-button" (click)="clearRecordedData()">
      <ao-icon [size]="30" name="cancel"></ao-icon>
    </div>

    <!--Upload button-->
    <div role="button" class="ao-audio-recorder__send-button" (click)="uploadAudio()">
      <ao-icon [size]="30" name="arrow-circle-up"></ao-icon>
    </div>
  </div>
</ng-container>

<ao-message-modal-card
  [open]="microphoneDisabledModalOpen"
  [modalInsideModal]="modalInsideModal"
  (close)="microphoneDisabledModalOpen = false"
>
  <h2 class="ao-font-body2-bold ao-audio-recorder__mic-header">{{ 'Allow microphone access' | translate }}</h2>
  <div class="ao-font-body2 ao-audio-recorder__mic-warning" *ngIf="disabledMicrophoneText">
    <ao-icon name="info" [size]="20"></ao-icon>
    <div>{{ disabledMicrophoneText | translate }}</div>
  </div>
  <div class="ao-audio-recorder__mic-instruction" *ngIf="androidNative">
    {{ 'Please make sure that your Actimo app is fully updated first.' | translate }}
  </div>
  <div *ngIf="androidNative">{{ 'If that still doesnt work:' | translate }}</div>
  <div
    class="ao-audio-recorder__mic-instruction"
    [innerHTML]="
      'Please go to your <strong>device settings</strong> and enable microphone access for the Actimo app.' | translate
    "
  ></div>
  <div class="ao-audio-recorder__mic-footer">
    <button type="button" ao-button [buttonColor]="'blue'" (click)="microphoneDisabledModalOpen = false">
      {{ 'Done [complete action]' | translate }}
    </button>
  </div>
</ao-message-modal-card>
