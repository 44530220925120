import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { BrandingColor } from '@ao/data-models';

@Component({
  selector: 'ao-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressComponent {
  @Input() color: BrandingColor = 'green';
  @Input() headerTitle = '';
  @Input() headerText: string;
  @Input() size = 'small';
  @Input() value: number;
  @Input() target: number = null;
  @Input() maxValue = 100;
  @Input() targetLabel = 'Target';

  @HostBinding('class.ao-progress--bar')
  @Input()
  showBar = true;

  @HostBinding('class.ao-progress--link')
  @Input()
  showLink = false;
}
