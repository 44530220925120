/* eslint-disable camelcase */
import { Awaited } from '../common';
import { GetRecentRes, GetSearchPhotosRes } from './unsplash';

export interface AttachmentFile {
  url: string;
  name: string;
  mimeType: string;
  extension?: string;
  size?: number;
}

export type MediaTypeGroups = 'image' | 'video' | 'audio' | 'document' | 'presentation';

export interface AttachmentInfo {
  url?: string;
  mediaFileId: number;
  title?: string;
  fileType: `${MediaTypeGroups}/${string}` | string; // "| string" added for backend mapper compatability
}

export interface AttachmentVideo extends AttachmentInfo {
  duration?: number;
  videoThumbnailUrl?: string;
}

export interface AttachmentDocument extends AttachmentInfo {
  size?: number;
}

export interface AttachmentImage extends Omit<AttachmentInfo, 'url'>, ImageHandlerMedia {
  mediaFileId: number;
}

export interface AttachmentAudio extends AttachmentInfo {}

export interface AttachmentUnknown extends AttachmentInfo {}

export type AttachmentInfoType =
  | AttachmentVideo
  | AttachmentAudio
  | AttachmentDocument
  | AttachmentImage
  | AttachmentUnknown;

export type AttachmentInfoTypeExcludingImageAndVideo = AttachmentAudio | AttachmentDocument | AttachmentUnknown;

// attachment typeguards
export function isVideoAttachment(file: AttachmentImage | AttachmentVideo): file is AttachmentVideo {
  const group: MediaTypeGroups = 'video';
  return file.fileType.includes(group);
}
export function isImageAttachment(file: AttachmentImage | AttachmentVideo): file is AttachmentImage {
  const group: MediaTypeGroups = 'image';
  return file.fileType.includes(group);
}

export type ImageHandlerMedia = {
  mediaFileId?: number;
  unsplashImageId?: string;
  imageVersions: ImageSource[];
  thumbnailSource?: ThumbnailSource;
  duration?: number;
};

type ThumbnailSource = 'image' | 'media' | 'video' | 'youtube' | 'contentPreview' | 'academyCover';

export interface ImageSource {
  size: {
    height?: number;
    original: boolean;
    width?: number;
  };
  url?: string;
}

export enum MediaSourceOrigin {
  Unsplash = 'unsplash',
  Library = 'library',
  Upload = 'upload',
}

export interface MediaLibraryImage {
  uploadId?: string;
  // eslint-disable-next-line camelcase
  media_file_id: number;
  url: string;
  type: string;
  title: string;
  images?: ImageSource[];
  caption?: string;
  // eslint-disable-next-line camelcase
  unsplash_image_id?: string;
}

interface Source {
  type: string;
  url: string;
}

export interface MediaSource extends Source {
  all?: {
    '360p'?: Source;
    '720p'?: Source;
    '1080p'?: Source;
  };
}

export interface MediaItem {
  id?: number;
  type?: string;
  name?: string;
  sources?: MediaSource[];
  url?: string;
  thumbnailUrl?: string;
  height?: number;
  width?: number;
  size?: number;
  progress?: number;
  status?: string;
  images?: ImageSource[];
  url_x2?: string;
  caption?: string;
  media_file_id?: number;
  unsplash_image_id?: string;
  title?: string;
  thumbnails?: string[];
}

export type MediaFile = {
  mediaFileId?: number;
  id: number;
  media_file_id?: number;
  unsplash_image_id?: string;
  unsplash_download_url?: string;
  user_id: number;
  shared?: number;
  project_ref_count?: number;
  client_id: number;
  message_upload_id?: number;
  title?: string;
  description?: string;
  path?: string;
  file_name: string;
  file_type: string;
  public_url?: string;
  thumbnail_url?: string;
  thumbnail_sizes?: string;
  file_path: string;
  full_path: string;
  raw_name: string;
  orig_name: string;
  images: ImageSource[];
  file_ext: string;
  file_size: number;
  length?: number;
  duration?: number;
  width?: number;
  height?: number;
  trim_start?: string;
  trim_duration?: string;
  encode_request?: string;
  metadata?: Record<string, any>;
  tags?: string[];
  video_filters?: string;
  progress?: number;
  status?: string;
  youtube?: number;
  youtube_id?: string;
  date_created?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  ft?: string;
  qw_status?: string;
  qw_task?: string;
  qw_priority?: number;
  qw_delay_till?: number;
  qw_processor?: string;
  qw_processing_start_date?: Date;
  qw_processing_end_date?: Date;
  qw_status_info?: string;
};

// used in both  POST /media/ & POST /media/unsplash
export type PostUnsplashRes = {
  id: number;
  unsplash_image_id: string | null;
  media_file_id: number;
  title: string;
  file_name: string;
  file_type: string;
  file_size: number;
  url: string;
  metadata: Record<string, any> | null;
  images: ImageSource[];
};

// POST /media/unsplash
export type PostBodyUnsplashReq = {
  path?: string;
  // @ts-ignore
  image: Awaited<GetSearchPhotosRes>['response']['results'][number] | GetRecentRes[number];
};

// POST /media/unsplash
export type PostMediaUnsplashRequest = PostBodyUnsplashReq;
export type PostMediaUnsplashResponse = Awaited<PostUnsplashRes>;

export type StartMultipartMediaUpload = {
  mediaFileId: string;
  mediaFileType: string;
  media: SimpleMediaFile;
} & PresignedUrlData;

export type SimpleMediaFile = {
  id: number;
  title: string;
  file_name: string;
  orig_name: string;
  file_type: string;
  file_size: number;
  url: string;
  metadata: Record<string, any>;
  images: any[];
  width: number;
  height: number;
};

export type CompleteMultipartMediaUpload = {
  id: number;
  name: string;
  type: string;
  size;
  url: string;
  width: number;
  height: number;
  thumbnailUrl: string;
  images: ImageSource[];
  progress: number;
};

export type MultipartMediaUploadData = {
  fileName: string;
  parts: number;
  type: string;
  size: number;
  folderPath?: string;
  dontSaveFolderItem?: boolean;
};

export type PresignedUrlData = {
  parts: any[];
  uploadId: string;
  fileName: string;
  mediaFileId?: string | number;
};

export function getMediaElasticSearchFields(type: 'content' | 'sidebar'): string[] {
  if (type === 'content') {
    return ['media_file_id', 'title', 'description', 'file_type', 'orig_name', 'file_ext', 'tags'];
  } else if (type === 'sidebar') {
    return ['tags', 'header'];
  }

  return [];
}
