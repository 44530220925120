import {
  MsgModule,
  MsgModuleButtonGroup,
  MsgModuleConfirm,
  MsgModuleFeedback,
  MsgModuleSlider,
  QuestionnaireValue,
} from '@ao/data-models';

export function correctQuizAnswers(module: MsgModuleButtonGroup | MsgModuleConfirm): string[] {
  if (module.type === 'confirm') {
    return [String(module.id)];
  } else {
    return Array.isArray(module.mode_meta.correct_answer_id)
      ? module.mode_meta.correct_answer_id.map((id) => '' + id)
      : ['' + module.mode_meta.correct_answer_id];
  }
}

export function pointsEarnedInQuizQuestion(module: MsgModuleButtonGroup, data: QuestionnaireValue) {
  if (!data) {
    return 0;
  }
  if (module.mode_meta && module.mode_meta.partial_credit) {
    return module.mode_meta.points * correctQuizAnswers(module).filter((id) => data.value[id]).length;
  } else {
    return isQuizQuestionCorrect(module, data) && module.mode_meta ? module.mode_meta.points : 0;
  }
}

export function pointsPossibleInQuizQuestion(module: MsgModuleButtonGroup | MsgModuleConfirm) {
  if (module.type !== 'buttongroup' || !module.mode_meta) {
    return 0;
  }
  if (module.mode_meta.partial_credit) {
    return module.mode_meta.points * correctQuizAnswers(module).length;
  } else {
    return module.mode_meta.points;
  }
}

export function isQuizQuestionCorrect(module: MsgModuleButtonGroup | MsgModuleConfirm, data: QuestionnaireValue) {
  if (!data) {
    return false;
  }
  if (module.type === 'buttongroup') {
    let answers: string[] = Object.keys(data?.value || {}).filter((k) => data.value[k]);
    // legacy quiz modules without "confirm_choice" used a different format for
    if (!answers.length && !module.confirm_choice) {
      answers = Object.keys(data).filter((k) => data[k]);
    }
    const correct = correctQuizAnswers(module);
    return data.completed && answers.length === correct.length && correct.every((a) => answers.includes('' + a));
  } else if (module.type === 'confirm') {
    return data && data.value;
  }
}

export function isQuizQuestionAnswered(module: MsgModuleButtonGroup, data: QuestionnaireValue) {
  if (module.type === 'buttongroup') {
    return data && data.completed;
  } else if (module.type === 'confirm') {
    return data && data.value;
  }
}

export function isQuestionModule(
  module: MsgModule,
  messageType: string,
): module is MsgModuleSlider | MsgModuleFeedback | MsgModuleButtonGroup {
  return (
    (module.type === 'slider' && (module.surveyMode || messageType === 'rating' || messageType === 'pulse')) ||
    (module.type === 'feedback' && (module.surveyMode || messageType === 'rating' || messageType === 'pulse')) ||
    (module.type === 'buttongroup' && ['survey', 'quiz', 'autogenerated_submit'].includes(module.mode))
  );
}
