import { animate, style, transition, trigger, animation, useAnimation, state } from '@angular/animations';

export const slideInDownAnimation = animation(
  [
    style({ transform: 'translateY(-100%)' }),
    animate('{{duration}}ms {{easing}}', style({ transform: 'translateY(0%)' })),
  ],
  { params: { duration: 200, easing: 'ease-in' } },
);

export const slideOutDownAnimation = animation(
  [
    style({ transform: 'translateY(0%)' }),
    animate('{{duration}}ms {{easing}}', style({ transform: 'translateY(100%)' })),
  ],
  { params: { duration: 200, easing: 'ease-in' } },
);

export const slideInUpAnimation = animation(
  [
    style({ transform: 'translateY(100%)' }),
    animate('{{duration}}ms {{easing}}', style({ transform: 'translateY(0%)' })),
  ],
  { params: { duration: 200, easing: 'ease-in' } },
);

export const slideOutUpAnimation = animation(
  [
    style({ transform: 'translateY(0%)' }),
    animate('{{duration}}ms {{easing}}', style({ transform: 'translateY(-100%)' })),
  ],
  { params: { duration: 200, easing: 'ease-in' } },
);

// //////////

export function slideInOut(name = 'slideInOut', duration = 200, easing = 'ease-in') {
  return trigger(name, [
    state('*', style({ transform: 'translateY(0%)' })),
    transition(':enter', [useAnimation(slideInDownAnimation, { params: { duration, easing } })]),
    transition(':leave', [useAnimation(slideOutUpAnimation, { params: { duration, easing } })]),
  ]);
}

export function slideInOutDown(name = 'slideInOutDown', duration = 200, easing = 'ease-in') {
  return trigger(name, [
    state('*', style({ transform: 'translateY(0%)' })),
    transition(':enter', [useAnimation(slideInDownAnimation, { params: { duration, easing } })]),
    transition(':leave', [useAnimation(slideOutDownAnimation, { params: { duration, easing } })]),
  ]);
}

export function slideIn(name = 'slideIn', duration = 200, easing = 'ease-in') {
  return trigger(name, [
    state('*', style({ transform: 'translateY(0%)' })),
    transition(':enter', [useAnimation(slideInDownAnimation, { params: { duration, easing } })]),
  ]);
}
