import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'trustStyle',
})
export class TrustStylePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(style: string): SafeUrl {
    return this._sanitizer.bypassSecurityTrustStyle(style);
  }
}
