import {
  QuillDelta,
  SocialContact,
  SocialGroup,
  SocialGroupType,
  SocialPost,
  SocialPrivateGroup,
  SocialWallLinkPreview,
} from '@ao/data-models';
import { AttachmentInput, MediaItem, MessageAttachmentPreview, ReactionType } from '@ao/shared-data-models';
import { createAction, props } from '@ngrx/store';
import * as fromSocial from './social-store.reducer';

export const SetStateForTesting = createAction(
  '[Social] Set State For Testing',
  props<{ newState: fromSocial.State }>(),
);

export const LoadContact = createAction('[Social] Load Contact', props<{ contactId: number }>());
export const LoadContactSuccess = createAction('[Social] Load Contact Success', props<{ contact: SocialContact }>());
export const LoadContactFail = createAction(
  '[Social] Load Contact Fail',
  props<{ contactId: number; error: unknown }>(),
);

export const LoadPosts = createAction(
  '[Social] Load Posts',
  props<{ groupKey: string; limit?: number; append?: boolean; unread?: boolean }>(),
);
export const LoadPostsSuccess = createAction(
  '[Social] Load Posts Success',
  props<{ posts: SocialPost[]; append?: boolean; hasMore: boolean }>(),
);
export const LoadPostsFail = createAction(
  '[Social] Load Posts Fail',
  props<{ error: { error?: { code?: string } } }>(),
);

export const LoadOnePost = createAction(
  '[Social] Load One Post',
  props<{ postId: number; isMessageWithSocialFooter?: boolean }>(),
);
export const LoadOnePostSuccess = createAction(
  '[Social] Load One Post Success',
  props<{ post: SocialPost; isMessageWithSocialFooter?: boolean }>(),
);
export const LoadOnePostFail = createAction(
  '[Social] Load One Post Fail',
  props<{ postId: number; error: { status?: number } }>(),
);

export const LoadPostReactions = createAction(
  '[Social] Load Post Reactions',
  props<{ postId: number; reactionType?: ReactionType }>(),
);
export const LoadPostReactionsSuccess = createAction(
  '[Social] Load Post Reactions Success',
  props<{ postId: number; contacts: SocialContact[] }>(),
);
export const LoadPostReactionsFail = createAction('[Social] Load Post Reactions Fail', props<{ error: any }>());

export const CreatePostAttachment = createAction(
  '[Social] Create Post Attachment',
  props<{ ref: string; attachmentId: string; groupId: number; file: File; fromSocialWall?: boolean }>(),
);
export const CreatePostAttachmentProgress = createAction(
  '[Social] Create Post Attachment Progress',
  props<{ ref: string; attachmentId: string; progress: number }>(),
);
export const CreatePostAttachmentSuccess = createAction(
  '[Social] Create Post Attachment Success',
  props<{ ref: string; attachmentId: string; media: MediaItem; hasDelayedProcessing?: boolean }>(),
);
export const CreatePostAttachmentFail = createAction(
  '[Social] Create Post Attachment Fail',
  props<{ ref: string; attachmentId: string; error: any }>(),
);

export const PollPostAttachment = createAction(
  '[Social] Poll Post Attachment',
  props<{ ref: string; attachmentId: string; groupId: number; mediaId: number }>(),
);
export const PollPostAttachmentUpdate = createAction(
  '[Social] Poll Post Attachment Update',
  props<{ attachmentId: string; progress: number; media?: MediaItem }>(),
);
export const PollPostAttachmentSuccess = createAction(
  '[Social] Poll Post Attachment Success',
  props<{ ref: string; attachmentId: string; media: MediaItem }>(),
);
export const PollPostAttachmentFail = createAction(
  '[Social] Poll Post Attachment Fail',
  props<{ ref: string; attachmentId: string; media: MediaItem }>(),
);
export const RemovePostAttachment = createAction(
  '[Social] Remove Post Attachment',
  props<{ ref: string; attachmentId: string }>(),
);

export const ReactToPost = createAction(
  '[Social] React to Post',
  props<{ postId: number; reactionType: ReactionType }>(),
);
export const ReactToPostSuccess = createAction(
  '[Social] React to Post Success',
  props<{ postId: number; reactionType: ReactionType }>(),
);
export const ReactToPostFail = createAction('[Social] React to Post Fail', props<{ error: any }>());

export const UnreactPost = createAction('[Social] Unreact Post', props<{ postId: number }>());
export const UnreactPostSuccess = createAction('[Social] Unreact Post Success', props<{ postId: number }>());
export const UnreactPostFail = createAction('[Social] Unreact Post Fail', props<{ error: any }>());

export const CreatePost = createAction(
  '[Social] Create Post',
  props<{
    tempId: string;
    parentId?: number;
    groupId?: number;
    groupType?: SocialGroupType;
    dynamicGroupId?: string;
    content: QuillDelta;
    linkPreview?: SocialWallLinkPreview;
    shareAttachments?: AttachmentInput[];
    showToast?: boolean;
    isMessageWithSocialFooter?: boolean;
  }>(),
);
export const CreatePostSuccess = createAction(
  '[Social] Create Post Success',
  props<{ tempId: string; post: SocialPost; showToast: boolean }>(),
);
export const CreatePostFail = createAction(
  '[Social] Create Post Fail',
  props<{ error: unknown; tempId: string; hasAttachments: boolean }>(),
);
export const CancelCreatePost = createAction('[Social] Cancel Create Post', props<{ tempId: string }>());

export const StartEditingPost = createAction('[Social] Start Editing Post', props<{ postId: number }>());
export const EditPost = createAction(
  '[Social] Edit Post',
  props<{
    tempId: string;
    postId: number;
    content: QuillDelta;
    linkPreview?: SocialWallLinkPreview;
    media?: MediaItem[];
    shareAttachments?: AttachmentInput[];
    pinned?: boolean;
  }>(),
);
export const EditPostSuccess = createAction(
  '[Social] Edit Post Success',
  props<{ post: SocialPost; tempId: string; pinned?: boolean }>(),
);
export const EditPostFail = createAction('[Social] Edit Post Fail', props<{ postId: number; error: any }>());
export const CancelEditPost = createAction('[Social] Cancel Edit Post', props<{ tempId: string }>());

export const DeletePost = createAction(
  '[Social] Delete Post',
  props<{ postId: number; isMessageWithSocialFooter: boolean }>(),
);
export const DeletePostSuccess = createAction('[Social] Delete Post Success', props<{ postId: number }>());
export const DeletePostFail = createAction('[Social] Delete Post Fail', props<{ contentId: number; error: any }>());

export const PostViewed = createAction('[Social] Post Viewed', props<{ postId: number; moduleId?: string }>());
export const GroupViewed = createAction('[Social] Group Viewed', props<{ groupKey: string }>());

export const LoadPostViews = createAction('[Social] Load Post Views', props<{ postId: number; search: string }>());
export const LoadPostViewsSuccess = createAction(
  '[Social] Load Post Views Success',
  props<{ postId: number; contacts: SocialContact[]; messageId?: number }>(),
);
export const LoadPostViewsFail = createAction('[Social] Load Post Views Fail', props<{ error: any }>());

export const LoadMessageViews = createAction(
  '[Social] Load Message Views',
  props<{ postId: number; messageId: number; search: string }>(),
);
export const LoadMessageViewsSuccess = createAction(
  '[Social] Load Message Views Success',
  props<{ postId: number; messageId: number; contacts: SocialContact[] }>(),
);
export const LoadMessageViewsFail = createAction('[Social] Load Message Views Fail', props<{ error: any }>());

export const PollMediaItem = createAction(
  '[Social] Poll Media Item',
  props<{ postId: number; groupId: number; mediaId: number }>(),
);
export const PollMediaItemUpdate = createAction(
  '[Social] Poll Media Item Update',
  props<{ postId: number; progress: number; mediaId: number }>(),
);
export const PollMediaItemSuccess = createAction(
  '[Social] Poll Media Item Success',
  props<{ postId: number; media: MediaItem }>(),
);
export const PollMediaItemFail = createAction(
  '[Social] Poll Media Item Fail',
  props<{ postId: number; media: MediaItem }>(),
);

export const LoadPostTranslation = createAction('[Social] Load Post Translation', props<{ postId: number }>());
export const LoadPostTranslationSuccess = createAction(
  '[Social] Load Post Translation Success',
  props<{ postId: number; content: QuillDelta }>(),
);
export const LoadPostTranslationFail = createAction(
  '[Social] Load Post Translation Fail',
  props<{ error: any; postId: number }>(),
);
export const RevertPostTranslation = createAction('[Social] Revert Post Translation', props<{ postId: number }>());
export const RevertAllPostTranslations = createAction('[Social] Revert All Post Translations');

export const LoadSocialGroups = createAction('[Social] Load Social Groups');
export const LoadSocialGroupsSuccess = createAction(
  '[Social] Load Social Groups Success',
  props<{ groups: SocialGroup[] }>(),
);
export const LoadSocialGroupsFail = createAction('[Social] Load Social Groups Fail', props<{ error: any }>());

// load social group new post count
export const LoadSocialGroupsNewPostCount = createAction('[Social] Load Social Groups New Post Count');
export const LoadSocialGroupsNewPostCountSuccess = createAction(
  '[Social] Load Social Groups New Post Count Success',
  props<{ newPostsPerGroup: { [groupId: number]: number } }>(),
);
export const LoadSocialGroupsNewPostCountFail = createAction(
  '[Social] Load Social Groups New Post Count Fail',
  props<{ error: any }>(),
);

export const CreateGroup = createAction('[Social] Create Group', props<{ members: number[] }>());
export const CreateGroupSuccess = createAction('[Social] Create Group Success', props<{ group: SocialPrivateGroup }>());
export const CreateGroupFail = createAction('[Social] Create Group Fail', props<{ error: any }>());

export const EditGroup = createAction(
  '[Social] Edit Group',
  props<{
    groupId: number;
    name?: string;
    description?: string;
    add?: number[];
    remove?: number[];
    image?: Blob;
    mediaFileId?: number;
    navigate?: boolean;
  }>(),
);
export const EditGroupSuccess = createAction(
  '[Social] Edit Group Success',
  props<{ group: SocialPrivateGroup; navigate?: boolean }>(),
);
export const EditGroupFail = createAction('[Social] Edit Group Fail', props<{ error: any }>());

export const LeaveGroup = createAction('[Social] Leave Group', props<{ groupId: number }>());
export const LeaveGroupSuccess = createAction('[Social] Leave Group Success', props<{ groupId: number }>());
export const LeaveGroupFail = createAction('[Social] Leave Group Fail', props<{ error: any }>());

export const RedirectToPost = createAction(
  '[Social] Redirect To Post',
  props<{ postId: number; groupId: number; groupName: string }>(),
);
export const RedirectToPostComments = createAction(
  '[Social] Redirect To Post Comments',
  props<{ postId: number; groupId: number; groupName: string; commentId: number }>(),
);

export const LoadShareMessagePreview = createAction(
  '[Social] Load Share Message Preview',
  props<{ messageId: number }>(),
);
export const LoadShareMessagePreviewSuccess = createAction(
  '[Social] Load Share Message Preview Success',
  props<{ messageId: number; data: MessageAttachmentPreview }>(),
);
export const LoadShareMessagePreviewFail = createAction(
  '[Social] Load Share Message Preview Fail',
  props<{ messageId: number; error: any }>(),
);

export const CheckGroupMembersAccess = createAction(
  '[Social] Check Group Members Access',
  props<{ messageId: number; groupId: string }>(),
);
export const CheckGroupMembersAccessSuccess = createAction(
  '[Social] Check Group Members Access Success',
  props<{ messageId: number; allGroupMembersHaveAccess: boolean }>(),
);
export const CheckGroupMembersAccessFail = createAction(
  '[Social] Check Group Members Access Fail',
  props<{ error: any }>(),
);

export const SavedPostUpdate = createAction('[Social] Post update saved', props<{ itemId: number }>());
export const UnsavedPostUpdate = createAction('[Social] Post update unsaved', props<{ itemId: number }>());
