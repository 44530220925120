<div
  class="ao-tabs__nav ao-tabs__nav--prev"
  *ngIf="showNavigation"
  [ngClass]="{ 'ao-tabs__nav--disabled': offsetLeft === 0 }"
  role="button"
  (click)="previousPage()"
>
  <ao-icon name="keyboard_arrow_left" [size]="30"></ao-icon>
</div>
<div
  class="ao-tabs__nav ao-tabs__nav--next"
  *ngIf="showNavigation"
  [ngClass]="{ 'ao-tabs__nav--disabled': !canPageForward() }"
  role="button"
  (click)="nextPage()"
>
  <ao-icon name="keyboard_arrow_right" [size]="30"></ao-icon>
</div>
<div class="ao-tabs__viewport" 
[ngClass]="scrollableTabs ? 'ao-tabs__viewport--scrollable' : ''"
 #viewport>
  <div
    class="ao-tabs__wrapper"
    [ngClass]="scrollableTabs ? 'ao-tabs__wrapper--scrollable' : ''"
    (click)="onContentClick()"
    #wrapper
    role="tablist"
    [style.transform]="'translateX(' + -offsetLeft + 'px)'"
  >
    <ng-content></ng-content>
  </div>
</div>
