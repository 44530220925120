import { Contact, CustomField, InterpolateParams, MsgModuleButtonGroup } from '@ao/data-models';
import { getModuleRef, selectRouteNestedParam } from '@ao/utilities';
import { viewerCoreSelectors } from '@ao/viewer-core';
import { createSelector } from '@ngrx/store';
import {
  selectCurrentMessagePageContact,
  selectMessageContext,
  selectMessageQuestionnaire,
  selectMessageQuizData,
  selectMessageType,
} from './app-store.selectors';
import { selectMessageQuizQuestions } from './questionnaire.selectors';
import {
  isQuizQuestionAnswered,
  isQuizQuestionCorrect,
  pointsEarnedInQuizQuestion,
  pointsPossibleInQuizQuestion,
} from './utils';

const buildContextForContact = (customFields: CustomField[], contact: Contact) => {
  if (!contact) {
    return {};
  }
  const context: { [key: string]: [string, any] } = {
    id: ['text', contact.id],
    first_name: ['text', contact.first_name],
    firstname: ['text', contact.first_name],
    last_name: ['text', contact.last_name],
    lastname: ['text', contact.last_name],
    title: ['text', contact.title],
    department: ['text', contact.department],
    company: ['text', contact.company],
    company_reg: ['text', contact.company_reg],
    email: ['email', contact.email],
    addr_line_1: ['text', contact.addr_line_1],
    addr_line_2: ['text', contact.addr_line_2],
    addr_state: ['text', contact.addr_state],
    addr_zip: ['text', contact.addr_zip],
    addr_city: ['text', contact.addr_city],
    addr_country: ['text', contact.addr_country],
    country_code: ['text', contact.country_code],
    phone_number: ['phone_number', contact.phone_number],
    phone: ['phone_number', contact.phone_number],
    international_phone: ['phone_number', (contact.country_code || '') + (contact.phone_number + '')],
    employee_id: ['text', contact.employee_id],
    source_data: ['text', contact.source_data],
  };
  customFields.forEach(({ name, dataType, field }) => {
    const key = name.toLowerCase().replace(/[\s#]/g, '_');
    context[key] = [dataType, contact[field]];
  });
  return context;
};

const formatContext = (input: Record<string, [string, any]>) => {
  const context = {};
  for (const [key, [type, value]] of Object.entries(input)) {
    context[key] = `${type}:${typeof value === 'undefined' || value === null ? '' : value}`;
  }
  return context;
};

const mapRelationships = (relationships: { [type: string]: Contact }, customFields: CustomField[]) => {
  const result = {};
  for (const [type, contact] of Object.entries(relationships)) {
    // Special case for MANAGER needs to be turned to lower case
    result[type === 'MANAGER' ? 'manager' : type] = formatContext(buildContextForContact(customFields, contact));
  }
  return result;
};

const selectMessageQuizContext = createSelector(
  selectMessageQuizQuestions,
  selectMessageQuizData,
  selectMessageQuestionnaire,
  (quizQuestions, quizData, questionaire) => {
    const pointsPossible = quizQuestions.reduce((sum, m) => sum + pointsPossibleInQuizQuestion(m), 0);
    const questionsCorrect = quizQuestions.filter((m) =>
      isQuizQuestionCorrect(m, questionaire[getModuleRef(m)]),
    ).length;
    const pointsEarned = quizQuestions.reduce(
      (sum, m) => sum + pointsEarnedInQuizQuestion(m, questionaire[getModuleRef(m)]),
      0,
    );
    const averageScore = quizData?.averages?.avg_points || pointsPossible / 2;
    return formatContext({
      // message quiz
      questionsInMessage: ['number', quizQuestions.length],
      correctQuestionsInMessage: ['number', questionsCorrect],
      averageScore: ['number', averageScore],
      averageScorePercent: ['number', pointsPossible ? Math.round((averageScore / pointsPossible) * 100) : 0],
      pointsPossibleInMessage: ['number', pointsPossible],
      pointsEarnedInMessage: ['number', pointsEarned],

      // overall quiz
      totalPointsPossible: ['number', (quizData?.totals?.points_possible || 0) + pointsPossible],
      totalPointsEarned: ['number', (quizData?.totals?.points_earned || 0) + pointsEarned],

      // quizzes
      quizzesAttempted: ['number', quizData?.totals?.quizzes_attempted],
      quizzesCompleted: ['number', quizData?.totals?.quizzes_completed],
      quizzesCorrect: ['number', quizData?.totals?.quizzes_correct],
      quizzesCorrectPercent: ['number', Math.round(quizData?.totals?.quizzes_correct_percent)],

      // retake quiz
      answeredQuestions: [
        'number',
        quizQuestions.filter((m) => isQuizQuestionAnswered(m, questionaire[getModuleRef(m)])).length,
      ],
      questionsTotal: ['number', quizQuestions.length],
      questionsCorrect: ['number', questionsCorrect],
      pointsEarned: ['number', pointsEarned],
      pointsPossible: ['number', pointsPossible],
    });
  },
);

const selectMessageContactsContext = createSelector(
  viewerCoreSelectors.selectContact,
  viewerCoreSelectors.selectCustomFields,
  selectMessageType,
  selectCurrentMessagePageContact,
  selectMessageContext,
  viewerCoreSelectors.selectRelationships,
  (contact, customFields, type, pageContact, context, relationships) => {
    return {
      ...formatContext(buildContextForContact(customFields, contact)),
      // context for the report being evaluated in each page
      ...(type === 'rating' && pageContact
        ? {
            rating: formatContext(buildContextForContact(customFields, pageContact)),
          }
        : {}),
      // context for the manager being evaluated
      ...(context && context.cref
        ? {
            cref: formatContext(buildContextForContact(customFields, context.cref)),
          }
        : {}),
      ...(relationships ? mapRelationships(relationships, customFields) : {}),
    };
  },
);

export const selectMessageInterpolationContext = createSelector(
  selectRouteNestedParam('keycode'),
  selectMessageContactsContext,
  selectMessageQuizContext,
  (keycode, contactsContext, quizContext) => {
    return <InterpolateParams>{
      ...formatContext({
        keycode: ['text', keycode],
      }),
      ...contactsContext,
      ...quizContext,
    };
  },
);

export const getMessageButtonGroupContextByModule = createSelector(
  selectMessageQuizQuestions,
  selectMessageQuestionnaire,
  selectMessageQuizData,
  (questions, questionnaire, quizData, { module }: { module: MsgModuleButtonGroup }) => {
    if (questionnaire) {
      const data = questionnaire[getModuleRef(module)];
      const pointsPossibleInMessage = questions.reduce((sum, m) => sum + pointsPossibleInQuizQuestion(m), 0);
      const pointsEarnedInMessage = questions.reduce(
        (sum, m) => sum + pointsEarnedInQuizQuestion(m, questionnaire[getModuleRef(m)]),
        0,
      );
      const averageScore = quizData?.averages?.avg_points || pointsPossibleInMessage / 2;
      return formatContext({
        // message
        questionsInMessage: ['number', questions.length],
        correctQuestionsInMessage: [
          'number',
          questions.filter((m) => isQuizQuestionCorrect(m, questionnaire[getModuleRef(m)])).length,
        ],
        pointsPossibleInMessage: ['number', pointsPossibleInMessage],
        pointsEarnedInMessage: ['number', pointsEarnedInMessage],
        averageScore: ['number', averageScore],
        averageScorePercent: [
          'number',
          pointsPossibleInMessage ? Math.round((averageScore / pointsPossibleInMessage) * 100) : 0,
        ],

        // question
        pointsPossibleInQuestion: ['number', pointsPossibleInQuizQuestion(module)],
        pointsEarnedInQuestion: ['number', pointsEarnedInQuizQuestion(module, data)],

        // overall
        totalPointsPossible: ['number', (quizData?.totals?.points_possible || 0) + pointsPossibleInMessage],
        totalPointsEarned: ['number', (quizData?.totals?.points_earned || 0) + pointsEarnedInMessage],
      });
    }
    return null;
  },
);
