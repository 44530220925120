<!-- play / pause-->
<div class="ao-audio-waveform-player__main-button">
  <div role="button" class="ao-audio-waveform-player__stop-button" (click)="paused ? play() : pause()">
    <ao-icon [size]="30" [name]="paused ? 'play_circle' : 'pause_circle_outline'"></ao-icon>
  </div>
</div>

<!--waveform-->
<div class="ao-audio-waveform-player__waveform">
  <ao-audio-waveform
    [playHeadPercent]="playHeadPercent"
    [ngClass]="{ 'ao-audio-waveform-player__fixed-waveform': fixedWidth }"
    (setPlayHeadPercentage)="setPlayHead($event)"
  >
  </ao-audio-waveform>
</div>

<!--timestamp-->
<div class="ao-audio-waveform-player__stopped">
  <div class="ao-font-body2 ao-audio-waveform-player__time">
    <span>{{ (currentTime || customDuration || _sourceDuration) * 1000 | date: 'm:ss' }}</span>
  </div>
</div>
