import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

export type InfoBoxType = 'gray' | 'info' | 'warn' | 'danger' | 'success';

@Component({
  selector: 'ao-admin-info-box',
  templateUrl: './admin-info-box.component.html',
  styleUrls: ['./admin-info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminInfoBoxComponent {
  @HostBinding('class.ao-admin-info-box') className = true;

  @HostBinding('attr.type')
  @Input()
  type: InfoBoxType = 'info';

  @HostBinding('class.ao-admin-info-box--border')
  @Input()
  border = false;

  @HostBinding('class.ao-admin-info-box--radius')
  @Input()
  radius = true;

  @HostBinding('class.ao-admin-info-box--inline')
  @Input()
  inline = false;

  @Input() icon = 'info';
  @Input() showCloseButton = false;
  @Input() infoMessage?: string;

  @Input() customTemplate = true;

  @Output() closeInfoBox = new EventEmitter();
}
