export interface IconLink {
  rel: string;
  sizes?: string;
  type?: string;
  href: string;
}

export const ICON_LINKS: IconLink[] = [
  { rel: 'icon', href: 'favicon.ico', type: 'image/x-icon' },
  { rel: 'apple-touch-icon', sizes: '57x57', href: 'apple-touch-icon-57x57.png' },
  { rel: 'apple-touch-icon', sizes: '60x60', href: 'apple-touch-icon-60x60.png' },
  { rel: 'apple-touch-icon', sizes: '72x72', href: 'apple-touch-icon-72x72.png' },
  { rel: 'apple-touch-icon', sizes: '76x76', href: 'apple-touch-icon-76x76.png' },
  { rel: 'apple-touch-icon', sizes: '114x114', href: 'apple-touch-icon-114x114.png' },
  { rel: 'apple-touch-icon', sizes: '120x120', href: 'apple-touch-icon-120x120.png' },
  { rel: 'apple-touch-icon', sizes: '144x144', href: 'apple-touch-icon-144x144.png' },
  { rel: 'apple-touch-icon', sizes: '152x152', href: 'apple-touch-icon-152x152.png' },
  { rel: 'apple-touch-icon', sizes: '180x180', href: 'apple-touch-icon-180x180.png' },
  { rel: 'icon', type: 'image/png', sizes: '96x96', href: 'android-chrome-96x96.png' },
  { rel: 'icon', type: 'image/png', sizes: '192x192', href: 'android-chrome-192x192.png' },
  { rel: 'icon', type: 'image/png', sizes: '512x512', href: 'android-chrome-512x512.png' },
  { rel: 'icon', type: 'image/png', sizes: '32x32', href: 'favicon-32x32.png' },
  { rel: 'icon', type: 'image/png', sizes: '96x96', href: 'favicon-96x96.png' },
  { rel: 'icon', type: 'image/png', sizes: '16x16', href: 'favicon-16x16.png' },
];
