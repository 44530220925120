import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TrackByFunction,
} from '@angular/core';
import { AcademyMessageTraining, AcademyTraining } from '@ao/data-models';
import { expandInOut } from '@ao/utilities';

@Component({
  selector: 'ao-academy-section',
  templateUrl: './academy-section.component.html',
  styleUrls: ['./academy-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandInOut()],
})
export class AcademySectionComponent {
  @HostBinding('class.ao-academy-section') className = true;

  @Input() sectionTitle = '';
  @Input() disabled = false;
  @Input() progress = 0;
  @Input() academyId = 0;
  @Input() set trainings(value: AcademyMessageTraining[] | []) {
    this._trainings = value || [];
  }
  @Input() collapsed = false;

  @Output() toggleCollapsed = new EventEmitter();

  _trainings: AcademyTraining[] | [] = [];

  _toggleCollapsed() {
    this.toggleCollapsed.emit();
  }

  trackById: TrackByFunction<any> = (index, item) => item.id;

  get trainingsCompleted() {
    return this._trainings.length * this.progress;
  }
}
