import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ACTIMO_COLORS, LOADING_STATE, LOADING_STATE_VALUE, THEME_COLORS } from '@ao/data-models';
import { alphaColor, brightness } from '@ao/utilities';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ao-suspense',
  templateUrl: './suspense.component.html',
  styleUrls: ['./suspense.component.scss'],
  animations: [
    trigger('fadeIn', [transition(':enter', [style({ opacity: 0 }), animate('0.5s', style({ opacity: 1 }))])]),
    trigger('fadeOut', [
      transition(
        ':leave',
        [style({ position: 'absolute', top: 0 }), animate('0.5s {{delay}}', style({ opacity: 0 }))],
        {
          params: { delay: '0s' },
        },
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuspenseComponent implements OnInit {
  @ContentChild('aoSuspenseSkeleton', { read: TemplateRef }) skeleton: TemplateRef<unknown>;
  @ContentChild('aoSuspenseContent', { read: TemplateRef }) content: TemplateRef<unknown>;
  @ContentChild('aoSuspenseError', { read: TemplateRef }) error: TemplateRef<unknown>;
  @HostBinding('class.ao-suspense') readonly className = true;
  @HostBinding('class.ao-suspense--full-height')
  @Input()
  fullHeight?: boolean = false;
  @HostBinding('class.ao-suspense--full-width')
  @Input()
  fullWidth?: boolean = false;

  @Input() absolute? = false;
  @Input() moduleBackgroundColor?: string;
  @Input() noTheme?: boolean = false;
  @Input() preload = false;
  @Input() set state(value: LOADING_STATE_VALUE) {
    this.state$.next(value);
  }
  @Input() delay = '0s';
  @Input() set backgroundColor(value) {
    this._backgroundColor = value;
  }

  LOADING_STATE = LOADING_STATE;
  _backgroundColor: string;
  _textColor: string;
  state$ = new BehaviorSubject<LOADING_STATE_VALUE>(LOADING_STATE.unloaded);

  ngOnInit() {
    if (this.noTheme) {
      // Actimo theme
      this._textColor = ACTIMO_COLORS.snow.dark;
      this._backgroundColor = 'transparent';
    } else if (this.moduleBackgroundColor && brightness(this.moduleBackgroundColor) < 0.5) {
      // Dark theme
      this._textColor = THEME_COLORS.dark.text;
      this._backgroundColor = alphaColor(this._textColor, 30);
    } else {
      // Light theme (default)
      this._textColor = THEME_COLORS.light.text;
      if (this.moduleBackgroundColor) {
        this._backgroundColor = alphaColor(this._textColor, 20);
      }
    }
  }
}
