import { InjectionToken, Optional, Provider } from '@angular/core';

declare const emitter: any;

let emitterConnection = null;
export function emitterFactory(config?: { host: string; port: number }) {
  if (emitterConnection) {
    return emitterConnection;
  }

  emitterConnection = emitter.connect({ ...config, reconnectPeriod: 10000 } || {});

  return emitterConnection;
}

export const EMITTER = new InjectionToken('EMITTER');
export const EMITTER_CONFIG = new InjectionToken('EMITTER_CONFIG');

export interface Emitter {
  subscribe(channel: any): void;
  unsubscribe(channel: any): void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  on(event: string, cb: Function): void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  off(event: string, cb: Function): void;
}

export const EmitterProvider: Provider = {
  provide: EMITTER,
  deps: [[new Optional(), EMITTER_CONFIG]],
  useFactory: emitterFactory,
};
