import { Injectable } from '@angular/core';
import { UploadPartProgressEvent, isMsgModuleUpload } from '@ao/data-models';
import { MediaItem } from '@ao/shared-data-models';
import { withLatestFromLazy } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Subject, merge, of, timer } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppFacade } from '../../app-store.facade';
import { ModuleUploadService } from '../../services/module-upload.service';
import * as appActions from '../actions';

@Injectable()
export class ModuleUploadEffects {
  constructor(
    private actions$: Actions,
    private appFacade: AppFacade,
    private viewerCoreFacade: ViewerCoreFacade,
    private moduleUploadService: ModuleUploadService,
  ) {}

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.UploadFile),
      withLatestFromLazy(this.viewerCoreFacade.keycode$, this.viewerCoreFacade.isS3DirectUploadEnabled$),
      mergeMap(([{ module, file }, keycode, isS3DirectUploadEnabled]) => {
        const progressSubject = new Subject<UploadPartProgressEvent>();
        return merge(
          this.moduleUploadService
            .uploadModuleMediaByConfig({ module, keycode, file, progressSubject, isS3DirectUploadEnabled })
            .pipe(
              switchMap((data: MediaItem) => {
                const type = (data.type || '').split('/')[0];
                const hasDelayedProcessing =
                  (type === 'image' && !!isS3DirectUploadEnabled) || ['video', 'audio'].includes(type);

                if (hasDelayedProcessing) {
                  return merge(
                    of(appActions.UploadFileSuccess({ module, data, file, hasDelayedProcessing })),
                    timer(3000).pipe(map(() => appActions.PollUploadFile({ module, mediaItem: data }))),
                  );
                } else {
                  return of(appActions.UploadFileSuccess({ module, data, file }));
                }
              }),
              catchError((error) => of(appActions.UploadFileError({ module, data: error }))),
            ),
          progressSubject.pipe(
            map(({ loaded, total }) =>
              appActions.UploadFileProgress({ module, data: (loaded / total) * 100, file: file }),
            ),
          ),
        );
      }),
    ),
  );

  pollFileUpload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appActions.PollUploadFile),
      withLatestFrom(this.viewerCoreFacade.keycode$, this.appFacade.currentMessageModules$),
      mergeMap(([{ module, mediaItem }, keycode, modules]) => {
        const currentModule = modules.find((m) => m.id === module.id);

        if (!isMsgModuleUpload(currentModule)) {
          return EMPTY;
        }

        const file = currentModule._files.find((item) => item.id === mediaItem.id);

        if (!file) {
          // in case of the file has already been removed
          return EMPTY;
        }

        return this.moduleUploadService.getMedia(keycode, currentModule, mediaItem.id).pipe(
          mergeMap((mediaItems) => {
            const processedMediaItem = mediaItems.find((item) => item.id === mediaItem.media_file_id);

            if (processedMediaItem.status === 'Error') {
              return of(appActions.PollUploadFileFail({ fileId: mediaItem.id, module: currentModule }));
            } else if (processedMediaItem.status === 'Ready' && !processedMediaItem.url.includes('multipart-upload')) {
              return of(appActions.PollUploadFileSuccess({ mediaItem: processedMediaItem, module: currentModule }));
            } else {
              const progress = processedMediaItem.status === 'Ready' ? 100 : processedMediaItem.progress;

              return merge(
                of(appActions.PollUploadFileUpdate({ fileId: mediaItem.id, progress, module: currentModule })),
                of(appActions.PollUploadFile({ module: currentModule, mediaItem })).pipe(delay(3000)),
              );
            }
          }),
          catchError(() => of(appActions.PollUploadFileFail({ fileId: mediaItem.id, module }))),
        );
      }),
    );
  });

  removeFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.UploadRemoveFile),
      withLatestFromLazy(this.viewerCoreFacade.keycode$),
      mergeMap(([{ module, fileId }, keycode]) => {
        return this.moduleUploadService.removeFileUpload(module, keycode, fileId).pipe(
          map(() => appActions.UploadRemoveFileSuccess({ module: module })),
          catchError((error) => of(appActions.UploadRemoveFileError({ data: error, module }))),
        );
      }),
    ),
  );

  changeTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.UploadChangeTitle),
      withLatestFromLazy(this.viewerCoreFacade.keycode$),
      mergeMap(([{ module, fileId, title }, keycode]) => {
        return this.moduleUploadService.changeFileTitle(module, keycode, fileId, title).pipe(
          map(() => appActions.UploadChangeTitleSuccess({ module: module })),
          catchError((error) => of(appActions.UploadChangeTitleError({ data: error, module }))),
        );
      }),
    ),
  );
}
