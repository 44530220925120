import { Component, Inject, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RouterStore, WINDOW } from '@ao/utilities';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'viewer-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnDestroy {
  _queryParams$: Observable<Record<string, unknown>>;
  private destroy$ = new Subject<void>();
  private url = '';
  _openNewWindow = false;
  _clicked = false;

  constructor(private routerStore: RouterStore, @Inject(WINDOW) private window: Window, private meta: Meta) {
    this._queryParams$ = this.routerStore.pipe(map((route) => route.state.queryParams));

    this._queryParams$.pipe(take(1)).subscribe((queryParams: any) => {
      // if coming from a message, populate the messageStore
      const { url, openNewWindow } = queryParams;
      this.url = url;
      this._openNewWindow = openNewWindow === 'true';
      if (!this._openNewWindow) {
        this.window.location.href = this.url;
      }
    });

    this.meta.updateTag({
      name: 'viewport',
      content: ['width=device-width', 'initial-scale=1.0'].join(', '),
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openMessage() {
    this._clicked = true;
    this.window.open(this.url, '_blank');
  }
}
