<div class="ao-not-enough-data__content">
  <div class="ao-not-enough-data__vertical-align">
    <ng-container *ngIf="dataIcon; else statsIcon">
      <ao-icon class="ao-not-enough-data__icon" [name]="dataIcon" [size]="128"></ao-icon>
    </ng-container>
    <ng-template #statsIcon>
      <img class="ao-not-enough-data__icon" src="assets/custom-icons/empty_one_on_one.svg" />
    </ng-template>

    <div *ngIf="notificationText; else default">{{ notificationText }}</div>
    <ng-template #default>
      <div translate>There's not enough data to show in the time period selected</div>
    </ng-template>
  </div>
</div>
