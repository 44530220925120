import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { ActimoLanguageType, ActimoLocale } from '../common';

// locale: The locale code coming from the api
// translations: the name of the translation (from poeditor) files to use (+.json)
// countryData: the name of the country data files to use (+.json)
export const AVAILABLE_LANGUAGES: { [key in ActimoLanguageType]: ActimoLocale } = {
  'da-DK': {
    name: i18n('Danish'),
    locale: 'da-DK',
    dayJsId: 'da',
    translations: ['da'],
    countryData: ['da'],
    preferredCountries: ['DK', 'SE', 'NO', 'FI'],
    nativeName: 'Dansk',
    code: 'da-dk',
  },
  'en-US': {
    name: i18n('English (US)'),
    locale: 'en-US',
    dayJsId: 'en-gb',
    translations: ['en'],
    countryData: ['en_US', 'en'],
    preferredCountries: ['US', 'GB'],
    nativeName: 'English',
    code: 'en-us',
  }, // using en-gb since en-us uses a fairly rare mm-dd-yyyy format
  'es-ES': {
    name: i18n('Spanish'),
    locale: 'es-ES',
    dayJsId: 'es',
    translations: ['es'],
    countryData: ['es'],
    preferredCountries: ['GT', 'MX', 'ES'],
    nativeName: 'Español',
    code: 'es-es',
  },
  'et-EE': {
    name: i18n('Estonian'),
    locale: 'et-EE',
    dayJsId: 'et',
    translations: ['et'],
    countryData: ['et'],
    preferredCountries: ['EE'],
  },
  'nl-NL': {
    name: i18n('Dutch'),
    locale: 'nl-NL',
    dayJsId: 'nl',
    translations: ['nl'],
    countryData: ['nl'],
    preferredCountries: ['NL'],
  },
  'fr-FR': {
    name: i18n('French'),
    locale: 'fr-FR',
    dayJsId: 'fr',
    translations: ['fr'],
    countryData: ['fr'],
    preferredCountries: ['FR'],
  },
  'fi-FI': {
    name: i18n('Finnish'),
    locale: 'fi-FI',
    dayJsId: 'fi',
    translations: ['fi'],
    countryData: ['fi'],
    preferredCountries: ['FI', 'SE', 'NO', 'DK'],
    nativeName: 'Suomi',
    code: 'fi-fi',
  },
  'de-DE': {
    name: i18n('German'),
    locale: 'de-DE',
    dayJsId: 'de',
    translations: ['de'],
    countryData: ['de'],
    preferredCountries: ['DE'],
  },
  'hu-HU': {
    name: i18n('Hungarian'),
    locale: 'hu-HU',
    dayJsId: 'hu',
    translations: ['hu'],
    countryData: ['hu'],
    preferredCountries: ['HU'],
  },
  'it-IT': {
    name: i18n('Italian'),
    locale: 'it-IT',
    dayJsId: 'it',
    translations: ['it'],
    countryData: ['it'],
    preferredCountries: ['IT'],
  },
  'ja-JP': {
    name: i18n('Japanese'),
    locale: 'ja-JP',
    dayJsId: 'ja',
    translations: ['ja'],
    countryData: ['ja'],
    preferredCountries: ['JP'],
  },
  'nb-NO': {
    name: i18n('Norwegian'),
    locale: 'nb-NO',
    dayJsId: 'nb',
    translations: ['nb'],
    countryData: ['nb'],
    preferredCountries: ['NO', 'SE', 'DK', 'FI'],
  },
  'pt-BR': {
    name: i18n('Portuguese (BR)'),
    locale: 'pt-BR',
    dayJsId: 'pt-br',
    translations: ['pt-br'],
    countryData: ['pt_BR', 'pt'],
    preferredCountries: ['BR'],
  },
  'pl-PL': {
    name: i18n('Polish'),
    locale: 'pl-PL',
    dayJsId: 'pl',
    translations: ['pl'],
    countryData: ['pl'],
    preferredCountries: ['PL'],
  },
  'ro-RO': {
    name: i18n('Romanian'),
    locale: 'ro-RO',
    dayJsId: 'ro',
    translations: ['ro'],
    countryData: ['ro'],
    preferredCountries: ['RO'],
  },
  'sv-SE': {
    name: i18n('Swedish'),
    locale: 'sv-SE',
    dayJsId: 'sv',
    translations: ['sv'],
    countryData: ['sv'],
    preferredCountries: ['SE', 'DK', 'NO', 'FI'],
  },
  'ru-RU': {
    name: i18n('Russian'),
    locale: 'ru-RU',
    dayJsId: 'ru',
    translations: ['ru'],
    countryData: ['ru'],
    preferredCountries: ['RU'],
  },
  'tr-TR': {
    name: i18n('Turkish'),
    locale: 'tr-TR',
    dayJsId: 'tr',
    translations: ['tr'],
    countryData: ['tr'],
    preferredCountries: ['TR'],
  },
};

export const ADMIN_LANGUAGES: { [key in ActimoLanguageType]?: ActimoLocale } = {
  'en-US': AVAILABLE_LANGUAGES['en-US'],
  'da-DK': AVAILABLE_LANGUAGES['da-DK'],
  'es-ES': AVAILABLE_LANGUAGES['es-ES'],
  'fi-FI': AVAILABLE_LANGUAGES['fi-FI'],
};

export const VIEWER_LANGUAGES: { [key in ActimoLanguageType]?: ActimoLocale } = {
  ...AVAILABLE_LANGUAGES,
};
