import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as SavedContentActions from '../actions/saved-content.actions';
import { ExtendedSavedMessage, ExtendedSavedAcademy, ExtendedSavedPost } from '@ao/shared-data-models';

export const SAVED_CONTENT_FEATURE_KEY = 'savedContent';

export interface SavedContentState {
  messages: EntityState<ExtendedSavedMessage>;
  academies: EntityState<ExtendedSavedAcademy>;
  posts: EntityState<ExtendedSavedPost>;
  loaded: boolean; // has the SavedContent list been loaded
  error?: string | null; // last known error (if any)
}

export const messagesAdapter: EntityAdapter<ExtendedSavedMessage> = createEntityAdapter<ExtendedSavedMessage>();
export const academiesAdapter: EntityAdapter<ExtendedSavedAcademy> = createEntityAdapter<ExtendedSavedAcademy>();
export const postsAdapter: EntityAdapter<ExtendedSavedPost> = createEntityAdapter<ExtendedSavedPost>();

// Initial state construction using adapters' initial states
export const initialSavedContentState: SavedContentState = {
  messages: messagesAdapter.getInitialState(),
  academies: academiesAdapter.getInitialState(),
  posts: postsAdapter.getInitialState(),
  loaded: false,
};

const reducer = createReducer(
  initialSavedContentState,
  on(SavedContentActions.loadContent, (state) => ({
    ...state,
    loaded: false,
  })),
  on(SavedContentActions.loadContentFail, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  })),
  on(SavedContentActions.loadMessagesSuccess, (state, { messages }) => ({
    ...state,
    loaded: true,
    messages: messagesAdapter.setAll(messages, state.messages),
  })),
  on(SavedContentActions.loadAcademiesSuccess, (state, { academies }) => ({
    ...state,
    loaded: true,
    academies: academiesAdapter.setAll(academies, state.academies),
  })),
  on(SavedContentActions.loadPostsSuccess, (state, { posts }) => ({
    ...state,
    loaded: true,
    posts: postsAdapter.setAll(posts, state.posts),
  })),
);

export function savedContentReducer(state: SavedContentState | undefined, action: Action) {
  return reducer(state, action);
}

// Selectors for entities
// Messages Selectors
export const { selectAll: selectAllMessages } = messagesAdapter.getSelectors();

// Academies Selectors
export const { selectAll: selectAllAcademies } = academiesAdapter.getSelectors();

// Posts Selectors
export const { selectAll: selectAllPosts } = postsAdapter.getSelectors();
