<div class="academy-info-wrapper">
  <ao-inline-progress
    class="academy-info-wrapper__inline-progress"
    [ngClass]="{ 'academy-info-wrapper__inline-progress--full-width': !displayButton }"
    [value]="progress"
    [showPercentage]="false"
    [showPercentageBar]="showCompletionState ? false : !!progress"
    [color]="colorValue"
  >
    <div class="academy-info-wrapper__progress-info">
      <ao-icon
        [name]="progress > 0 ? 'school' : 'custom-icons/school-outline'"
        [size]="20"
        [ngStyle]="{ color: progress > 0 ? colorValue : notInitiatedColor }"
      ></ao-icon>
      <span
        [ngStyle]="{ color: progress > 0 ? colorValue : notInitiatedColor }"
        [ngClass]="progress > 0 ? 'ao-font-small-bold' : 'ao-font-small'"
      >
        {{ trainingsPassed }}/{{ trainings }}
      </span>
      @if (showCompletionState) {
        <span class="ao-font-small-bold" [ngStyle]="{ color: colorValue }">{{ 'Completed' | translate }}</span>
      }
    </div>
  </ao-inline-progress>

  @if (!showCompletionState) {
    <button
      ao-button
      *ngIf="displayButton"
      [fullWidth]="false"
      [buttonSize]="'compact'"
      [rounded]="!themedContent"
      [iconSize]="16"
      class="academy-info-wrapper__button"
      [buttonColor]="!themedContent ? defaultButtonColor : null"
    >
      <div class="academy-info-wrapper__button-content">
        <span>{{ progress > 0 ? ('Continue' | translate) : ('Start [academy]' | translate) }}</span>
        <ao-icon name="play_arrow" [size]="16"></ao-icon>
      </div>
    </button>
  }
</div>
