import { MediaUploaderType } from '@ao/data-models';
import { MediaType } from '@ao/shared-constants';

export const getMediaUploaderType = (types: MediaType[]): MediaUploaderType => {
  if (types.length > 1) {
    return MediaUploaderType.Media;
  } else {
    const type = types[0];
    if (!type) {
      return null;
    }

    switch (type) {
      case 'audio':
        return MediaUploaderType.Audio;
      case 'video':
        return MediaUploaderType.Video;
      case 'font':
        return MediaUploaderType.Font;
      case 'presentation':
        return MediaUploaderType.Presentation;
      case 'image':
        return MediaUploaderType.Image;
      case 'document':
        return MediaUploaderType.Document;
      case 'spreadsheet':
        return MediaUploaderType.Spreadsheet;
      default:
        return MediaUploaderType.Media;
    }
  }
};
