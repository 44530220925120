import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@ao/environments';
import {
  ChannelSetting,
  ContactNotificationSettings,
  Notification,
  NotificationChannelSettingType,
  NotificationRequestParams,
  NotificationSetting,
  NotificationSettingsGroup,
  NotificationSettingType,
  NotificationUserSetting,
  RecursivePartial,
} from '@ao/shared-data-models';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private http: HttpClient,
    private viewerCoreFacade: ViewerCoreFacade,
  ) {}

  loadNotificationUnreadCount(contactId: number, lastSeenNotifications: Date): Observable<number> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/contacts/${contactId}/notifications/stats`;
    return this.http.get<{ data: number }>(url, { params: { createdAfter: lastSeenNotifications.toISOString() } }).pipe(
      map((result) => result.data),
      catchError((error: any) => throwError(error)),
    );
  }

  loadNotifications(
    contactId: number,
    params: NotificationRequestParams,
    lastSeenNotifications: Date,
  ): Observable<Notification[]> {
    const newParams = {
      ...params,
      createdBefore: lastSeenNotifications.toISOString(),
      createdAfter: null,
    };

    const url = `${environment.apiBaseUrl}/api/v1/viewer/contacts/${contactId}/notifications`;
    return this.http.get<{ data: Notification[] }>(url, { params: newParams }).pipe(
      map((result) => result.data),
      catchError((error: any) => throwError(error)),
    );
  }

  markNotification(contactId: number, notification: Notification, markAsRead: boolean): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/contacts/${contactId}/notifications`;
    const body = [
      {
        id: notification.id,
        read: markAsRead,
      },
    ];
    return this.http.post(url, body).pipe(catchError((error: any) => throwError(error)));
  }

  private mapSettingToChannels(setting: NotificationSetting): NotificationUserSetting[] {
    return [
      { ...setting.sms, channelSettingType: 'sms', label: 'SMS' },
      { ...setting.email, channelSettingType: 'email', label: 'Email' },
      { ...setting.push, channelSettingType: 'push', label: 'Push' },
    ];
  }

  loadUserNotificationSettings(contactId: number, deviceTypeGroup: string): Observable<NotificationSettingsGroup[]> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/contacts/${contactId}/devices/${deviceTypeGroup}/notification-settings`;

    return this.http.get<{ data: ContactNotificationSettings }>(url).pipe(
      concatLatestFrom(() => [
        this.viewerCoreFacade.socialEnabled$,
        this.viewerCoreFacade.featureFlagByKey$('social_wall'),
        this.viewerCoreFacade.chatFlagAndChatDefaultEnabled$(),
        this.viewerCoreFacade.taskManagementEnabled$,
      ]),
      map(([{ data }, socialEnabled, socialFeatureFlag, chatFeatureFlag, taskManagementEnabled]) => {
        const content = data.publishedContent;
        const mentions = data.mentions;
        const comments = data.comments;
        const groups = data.groups;
        const chat = data.chat;
        const socialTurnedOff = !socialEnabled || !socialFeatureFlag;
        const chatTurnedOff = !chatFeatureFlag;
        const taskManagementTurnedOff = !taskManagementEnabled;

        const isSettingsChannelHidden = (channel: ChannelSetting) => !channel.canOverride && !channel.value;
        const isSettingsSectionHidden = (section: NotificationSetting) =>
          isSettingsChannelHidden(section.sms) &&
          isSettingsChannelHidden(section.email) &&
          isSettingsChannelHidden(section.push);

        const disabledContentNotifications = isSettingsSectionHidden(content);
        const disabledMentionNotifications = socialTurnedOff || isSettingsSectionHidden(mentions);
        const disabledCommentNotifications = socialTurnedOff || isSettingsSectionHidden(comments);
        const disabledGroupsNotifications = socialTurnedOff || isSettingsSectionHidden(groups);
        const disabledChatNotifications = chatTurnedOff || isSettingsSectionHidden(chat);
        const disabledTasksNotifications = taskManagementTurnedOff || isSettingsSectionHidden(data.tasks);

        const settingsGroups: NotificationSettingsGroup[] = [
          {
            settingType: 'publishedContent',
            title: i18n('Content published to me'),
            description: i18n('These are notifications for all content published to you.'),
            settings: this.mapSettingToChannels(data.publishedContent),
            hidden: disabledContentNotifications,
          },
          {
            settingType: 'mentions',
            title: i18n('Mentions [referenced / tagged]'),
            description: i18n('These are notifications for when someone mentions you in a post or comment.'),
            settings: this.mapSettingToChannels(data.mentions),
            hidden: disabledMentionNotifications,
          },
          {
            settingType: 'comments',
            title: i18n('Interesting comments'),
            description: i18n('These notifications affect posts you wrote or commented on.'),
            settings: this.mapSettingToChannels(data.comments),
            hidden: disabledCommentNotifications,
          },
          {
            settingType: 'groups',
            title: i18n('Social groups'),
            description: i18n(
              'These are notifications are about <strong>new posts</strong> in the groups you’ve joined',
            ),
            settings: this.mapSettingToChannels(data.groups),
            disabledGroups: data.groups.disabledGroups.map((group) => group.groupId),
            hidden: disabledGroupsNotifications,
          },
          {
            settingType: 'chat',
            title: i18n('Chat'),
            description: i18n('These are notifications for all chat messages.'),
            settings: this.mapSettingToChannels(data.chat),
            hidden: disabledChatNotifications,
          },
          {
            settingType: 'tasks',
            title: i18n('Tasks'),
            description: i18n('These are notifications for all tasks'),
            settings: this.mapSettingToChannels(data.tasks),
            hidden: disabledTasksNotifications,
          },
        ];
        return settingsGroups;
      }),
      catchError((error: any) => throwError(error)),
    );
  }

  toggleNotificationSetting(
    settingType: NotificationSettingType,
    channelSettingType: NotificationChannelSettingType,
    newValue: boolean,
    contactId: number,
    deviceTypeGroup: string,
  ): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/contacts/${contactId}/devices/${deviceTypeGroup}/notification-settings`;
    const body: RecursivePartial<ContactNotificationSettings> = {
      [settingType]: { [channelSettingType]: { value: newValue } },
    };

    return this.http.put(url, body).pipe(catchError((error: any) => throwError(error)));
  }

  toggleGroupNotificationSetting(
    groupId: string,
    newValue: boolean,
    contactId: number,
    deviceTypeGroup: string,
    source: string,
  ): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/contacts/${contactId}/devices/${deviceTypeGroup}/notification-settings?source=${source}`;
    const body: RecursivePartial<ContactNotificationSettings> = {
      groups: { disabledGroups: [{ groupId, value: newValue }] },
    };

    return this.http.put(url, body).pipe(catchError((error: any) => throwError(error)));
  }

  markAllNotificationsAsRead(contactId: number): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/contacts/${contactId}/notifications`;
    const body = [{ read: true }];
    return this.http.post(url, body).pipe(catchError((error: any) => throwError(error)));
  }
}
