import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { AdminButtonColor, AppButtonColor, Modal } from '@ao/data-models';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

export interface AppConfirmationConfig {
  title?: string;
  text?: string;
  template?: TemplateRef<any>;
  confirmButtonText?: string;
  dismissButtonText?: string;
  confirmButtonColor?: AppButtonColor;
  dismissButtonColor?: AppButtonColor;
}

@Component({
  selector: 'ao-app-confirmation-modal',
  templateUrl: './app-confirmation-modal.component.html',
  styleUrls: ['./app-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppConfirmationModalComponent implements Required<AppConfirmationConfig>, Modal<void> {
  @HostBinding('class.ao-confirmation-modal') className = true;

  @Input() title: string;
  @Input() text: string;
  @Input() template: TemplateRef<any>;
  @Input() confirmButtonText: string = i18n('Confirm');
  @Input() confirmButtonColor: AppButtonColor = 'blue';
  @Input() dismissButtonText: string = i18n('Cancel');
  @Input() dismissButtonColor: AppButtonColor = 'transparent';
  @Input() width = 400;

  @Output() confirm = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<string>();

  onConfirm() {
    this.confirm.next();
  }

  onDismiss(reason: string) {
    this.dismiss.next(reason);
  }
}
