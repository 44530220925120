import { InterpolateParams } from '@ao/data-models';
import * as dayjs from 'dayjs';
import { localizeNumber } from './digits-info';

// locale gets applied locally, so import the languages
import './dayjs-locale-bundle';

export function interpolate(input: string, params: InterpolateParams, lang: string): string {
  // eslint-disable-next-line no-useless-escape
  input = input.replace(/\#actimo-(?:\[\[(\w+):(.*?)\]\])\#/gi, (_, format, value) => {
    return formatValue(value, format, lang);
  });
  // eslint-disable-next-line no-useless-escape
  input = input.replace(/\#([^\s#]+)\#/gi, (match, key) => {
    try {
      const fv: string = key.split(':').reduce((acc, curr) => acc && acc[curr], params);
      const [format, ...rest] = fv.split(':');
      const value = rest.join(':');
      return formatValue(value, format, lang);
    } catch (e) {
      return match;
    }
  });
  return input;
}

function formatValue(value: string, format: string, lang: string) {
  switch (format) {
    case 'datetime': {
      if (!value) return '';
      return parseDate(value).locale(lang).format('lll');
    }
    case 'date': {
      if (!value) return '';
      return parseDate(value).locale(lang).format('ll');
    }
    case 'localtime': {
      if (!value) return '';
      const m = parseDate(value).locale(lang);
      return dayjs(Date.now()).diff(m, 'day') < 1 ? m.fromNow() : m.format('lll');
    }
    case 'number': {
      return localizeNumber(parseFloat(value), lang, '1.0-1');
    }
    case 'text':
    case 'phone_number':
    case 'email':
    case 'dropdown':
    case 'link':
    default:
      return value || '';
  }
}

function parseDate(value: string) {
  if (value.match(/^\d+$/)) {
    return dayjs(parseInt(value, 10));
  } else {
    return dayjs(value);
  }
}
