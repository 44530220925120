const countries = [
  {
    country: 'Afghanistan (‫افغانستان‬‎)',
    country_code: 93,
    iso2: 'af',
    iso_3166_2: 'AF',
    iso3: 'afg',
    iso_3166_3: 'AFG',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Afghanistan',
    native_country_name: '‫افغانستان‬‎',
  },
  {
    country: 'Albania (Shqipëri)',
    country_code: 355,
    iso2: 'al',
    iso_3166_2: 'AL',
    iso3: 'alb',
    iso_3166_3: 'ALB',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Albania',
    native_country_name: 'Shqipëri',
  },
  {
    country: 'Algeria (‫الجزائر‬‎)',
    country_code: 213,
    iso2: 'dz',
    iso_3166_2: 'DZ',
    iso3: 'dza',
    iso_3166_3: 'DZA',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Algeria',
    native_country_name: '‫الجزائر‬‎',
  },
  {
    country: 'American Samoa',
    country_code: 1684,
    iso2: 'as',
    iso_3166_2: 'AS',
    iso3: 'asm',
    iso_3166_3: 'ASM',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'American Samoa',
    native_country_name: '',
  },
  {
    country: 'Andorra',
    country_code: 376,
    iso2: 'ad',
    iso_3166_2: 'AD',
    iso3: 'and',
    iso_3166_3: 'AND',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Andorra',
    native_country_name: '',
  },
  {
    country: 'Angola',
    country_code: 244,
    iso2: 'ao',
    iso_3166_2: 'AO',
    iso3: 'ago',
    iso_3166_3: 'AGO',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Angola',
    native_country_name: '',
  },
  {
    country: 'Anguilla',
    country_code: 1264,
    iso2: 'ai',
    iso_3166_2: 'AI',
    iso3: 'aia',
    iso_3166_3: 'AIA',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Anguilla',
    native_country_name: '',
  },
  {
    country: 'Antigua and Barbuda',
    country_code: 1268,
    iso2: 'ag',
    iso_3166_2: 'AG',
    iso3: 'atg',
    iso_3166_3: 'ATG',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Antigua and Barbuda',
    native_country_name: '',
  },
  {
    country: 'Argentina',
    country_code: 54,
    iso2: 'ar',
    iso_3166_2: 'AR',
    iso3: 'arg',
    iso_3166_3: 'ARG',
    timezoneBegin: '-03:00',
    areaCodes: null,
    eng_country_name: 'Argentina',
    native_country_name: '',
  },
  {
    country: 'Armenia (Հայաստան)',
    country_code: 374,
    iso2: 'am',
    iso_3166_2: 'AM',
    iso3: 'arm',
    iso_3166_3: 'ARM',
    timezoneBegin: '+04:00',
    areaCodes: null,
    eng_country_name: 'Armenia',
    native_country_name: 'Հայաստան',
  },
  {
    country: 'Aruba',
    country_code: 297,
    iso2: 'aw',
    iso_3166_2: 'AW',
    iso3: 'abw',
    iso_3166_3: 'ABW',
    timezoneBegin: '-04:00',
    areaCodes: null,
    eng_country_name: 'Aruba',
    native_country_name: '',
  },
  {
    country: 'Australia',
    country_code: 61,
    iso2: 'au',
    iso_3166_2: 'AU',
    iso3: 'aus',
    iso_3166_3: 'AUS',
    timezoneBegin: '+10:00',
    areaCodes: null,
    eng_country_name: 'Australia',
    native_country_name: '',
  },
  {
    country: 'Austria (Österreich)',
    country_code: 43,
    iso2: 'at',
    iso_3166_2: 'AT',
    iso3: 'aut',
    iso_3166_3: 'AUT',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Austria',
    native_country_name: 'Österreich',
  },
  {
    country: 'Azerbaijan (Azərbaycan)',
    country_code: 994,
    iso2: 'az',
    iso_3166_2: 'AZ',
    iso3: 'aze',
    iso_3166_3: 'AZE',
    timezoneBegin: '+04:00',
    areaCodes: null,
    eng_country_name: 'Azerbaijan',
    native_country_name: 'Azərbaycan',
  },
  {
    country: 'Bahamas',
    country_code: 1242,
    iso2: 'bs',
    iso_3166_2: 'BS',
    iso3: 'bhs',
    iso_3166_3: 'BHS',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Bahamas',
    native_country_name: '',
  },
  {
    country: 'Bahrain (‫البحرين‬‎)',
    country_code: 973,
    iso2: 'bh',
    iso_3166_2: 'BH',
    iso3: 'bhr',
    iso_3166_3: 'BHR',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Bahrain',
    native_country_name: '‫البحرين‬‎',
  },
  {
    country: 'Bangladesh (বাংলাদেশ)',
    country_code: 880,
    iso2: 'bd',
    iso_3166_2: 'BD',
    iso3: 'bgd',
    iso_3166_3: 'BGD',
    timezoneBegin: '+06:00',
    areaCodes: null,
    eng_country_name: 'Bangladesh',
    native_country_name: 'বাংলাদেশ',
  },
  {
    country: 'Barbados',
    country_code: 1246,
    iso2: 'bb',
    iso_3166_2: 'BB',
    iso3: 'brb',
    iso_3166_3: 'BRB',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Barbados',
    native_country_name: '',
  },
  {
    country: 'Belarus (Беларусь)',
    country_code: 375,
    iso2: 'by',
    iso_3166_2: 'BY',
    iso3: 'blr',
    iso_3166_3: 'BLR',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Belarus',
    native_country_name: 'Беларусь',
  },
  {
    country: 'Belgium (België)',
    country_code: 32,
    iso2: 'be',
    iso_3166_2: 'BE',
    iso3: 'bel',
    iso_3166_3: 'BEL',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Belgium',
    native_country_name: 'België',
  },
  {
    country: 'Belize',
    country_code: 501,
    iso2: 'bz',
    iso_3166_2: 'BZ',
    iso3: 'blz',
    iso_3166_3: 'BLZ',
    timezoneBegin: '-06:00',
    areaCodes: null,
    eng_country_name: 'Belize',
    native_country_name: '',
  },
  {
    country: 'Benin (Bénin)',
    country_code: 229,
    iso2: 'bj',
    iso_3166_2: 'BJ',
    iso3: 'ben',
    iso_3166_3: 'BEN',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Benin',
    native_country_name: 'Bénin',
  },
  {
    country: 'Bermuda',
    country_code: 1441,
    iso2: 'bm',
    iso_3166_2: 'BM',
    iso3: 'bmu',
    iso_3166_3: 'BMU',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Bermuda',
    native_country_name: '',
  },
  {
    country: 'Bhutan (འབྲུག)',
    country_code: 975,
    iso2: 'bt',
    iso_3166_2: 'BT',
    iso3: 'btn',
    iso_3166_3: 'BTN',
    timezoneBegin: '+05:30',
    areaCodes: null,
    eng_country_name: 'Bhutan',
    native_country_name: 'འབྲུག',
  },
  {
    country: 'Bolivia',
    country_code: 591,
    iso2: 'bo',
    iso_3166_2: 'BO',
    iso3: 'bol',
    iso_3166_3: 'BOL',
    timezoneBegin: '-04:00',
    areaCodes: null,
    eng_country_name: 'Bolivia',
    native_country_name: '',
  },
  {
    country: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    country_code: 387,
    iso2: 'ba',
    iso_3166_2: 'BA',
    iso3: 'bih',
    iso_3166_3: 'BIH',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Bosnia and Herzegovina',
    native_country_name: 'Босна и Херцеговина',
  },
  {
    country: 'Botswana',
    country_code: 267,
    iso2: 'bw',
    iso_3166_2: 'BW',
    iso3: 'bwa',
    iso_3166_3: 'BWA',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Botswana',
    native_country_name: '',
  },
  {
    country: 'Brazil (Brasil)',
    country_code: 55,
    iso2: 'br',
    iso_3166_2: 'BR',
    iso3: 'bra',
    iso_3166_3: 'BRA',
    timezoneBegin: '-03:00',
    areaCodes: null,
    eng_country_name: 'Brazil',
    native_country_name: 'Brasil',
  },
  {
    country: 'British Indian Ocean Territory',
    country_code: 246,
    iso2: 'io',
    iso_3166_2: 'IO',
    iso3: 'iot',
    iso_3166_3: 'IOT',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'British Indian Ocean Territory',
    native_country_name: '',
  },
  {
    country: 'British Virgin Islands',
    country_code: 1284,
    iso2: 'vg',
    iso_3166_2: 'VG',
    iso3: 'vgb',
    iso_3166_3: 'VGB',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'British Virgin Islands',
    native_country_name: '',
  },
  {
    country: 'Brunei',
    country_code: 673,
    iso2: 'bn',
    iso_3166_2: 'BN',
    iso3: 'brn',
    iso_3166_3: 'BRN',
    timezoneBegin: '+08:00',
    areaCodes: null,
    eng_country_name: 'Brunei',
    native_country_name: '',
  },
  {
    country: 'Bulgaria (България)',
    country_code: 359,
    iso2: 'bg',
    iso_3166_2: 'BG',
    iso3: 'bgr',
    iso_3166_3: 'BGR',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Bulgaria',
    native_country_name: 'България',
  },
  {
    country: 'Burkina Faso',
    country_code: 226,
    iso2: 'bf',
    iso_3166_2: 'BF',
    iso3: 'bfa',
    iso_3166_3: 'BFA',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Burkina Faso',
    native_country_name: '',
  },
  {
    country: 'Burundi (Uburundi)',
    country_code: 257,
    iso2: 'bi',
    iso_3166_2: 'BI',
    iso3: 'bdi',
    iso_3166_3: 'BDI',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Burundi',
    native_country_name: 'Uburundi',
  },
  {
    country: 'Cambodia (កម្ពុជា)',
    country_code: 855,
    iso2: 'kh',
    iso_3166_2: 'KH',
    iso3: 'khm',
    iso_3166_3: 'KHM',
    timezoneBegin: '+07:00',
    areaCodes: null,
    eng_country_name: 'Cambodia',
    native_country_name: 'កម្ពុជា',
  },
  {
    country: 'Cameroon (Cameroun)',
    country_code: 237,
    iso2: 'cm',
    iso_3166_2: 'CM',
    iso3: 'cmr',
    iso_3166_3: 'CMR',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Cameroon',
    native_country_name: 'Cameroun',
  },
  {
    country: 'Canada',
    country_code: 1,
    iso2: 'ca',
    iso_3166_2: 'CA',
    iso3: 'can',
    iso_3166_3: 'CAN',
    timezoneBegin: '-05:00',
    areaCodes: [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
    eng_country_name: 'Canada',
    native_country_name: '',
  },
  {
    country: 'Cape Verde (Kabu Verdi)',
    country_code: 238,
    iso2: 'cv',
    iso_3166_2: 'CV',
    iso3: 'cpv',
    iso_3166_3: 'CPV',
    timezoneBegin: '-01:00',
    areaCodes: null,
    eng_country_name: 'Cape Verde',
    native_country_name: 'Kabu Verdi',
  },
  {
    country: 'Cayman Islands',
    country_code: 1345,
    iso2: 'ky',
    iso_3166_2: 'KY',
    iso3: 'cym',
    iso_3166_3: 'CYM',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Cayman Islands',
    native_country_name: '',
  },
  {
    country: 'Central African Republic (République centrafricaine)',
    country_code: 236,
    iso2: 'cf',
    iso_3166_2: 'CF',
    iso3: 'caf',
    iso_3166_3: 'CAF',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Central African Republic',
    native_country_name: 'République centrafricaine',
  },
  {
    country: 'Chad (Tchad)',
    country_code: 235,
    iso2: 'td',
    iso_3166_2: 'TD',
    iso3: 'tcd',
    iso_3166_3: 'TCD',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Chad',
    native_country_name: 'Tchad',
  },
  {
    country: 'Chile',
    country_code: 56,
    iso2: 'cl',
    iso_3166_2: 'CL',
    iso3: 'chl',
    iso_3166_3: 'CHL',
    timezoneBegin: '-04:00',
    areaCodes: null,
    eng_country_name: 'Chile',
    native_country_name: '',
  },
  {
    country: 'China (中国)',
    country_code: 86,
    iso2: 'cn',
    iso_3166_2: 'CN',
    iso3: 'chn',
    iso_3166_3: 'CHN',
    timezoneBegin: '+08:00',
    areaCodes: null,
    eng_country_name: 'China',
    native_country_name: '中国',
  },
  {
    country: 'Christmas Island',
    country_code: 61,
    iso2: 'cx',
    iso_3166_2: 'CX',
    iso3: 'cxr',
    iso_3166_3: 'CXR',
    timezoneBegin: '+10:00',
    areaCodes: null,
    eng_country_name: 'Christmas Island',
    native_country_name: '',
  },
  {
    country: 'Cocos (Keeling) Islands',
    country_code: 61,
    iso2: 'cc',
    iso_3166_2: 'CC',
    iso3: 'cck',
    iso_3166_3: 'CCK',
    timezoneBegin: '+10:00',
    areaCodes: null,
    eng_country_name: 'Cocos (Keeling) Islands',
    native_country_name: '',
  },
  {
    country: 'Colombia',
    country_code: 57,
    iso2: 'co',
    iso_3166_2: 'CO',
    iso3: 'col',
    iso_3166_3: 'COL',
    timezoneBegin: '-05:00',
    areaCodes: null,
    eng_country_name: 'Colombia',
    native_country_name: '',
  },
  {
    country: 'Comoros (‫جزر القمر‬‎)',
    country_code: 269,
    iso2: 'km',
    iso_3166_2: 'KM',
    iso3: 'com',
    iso_3166_3: 'COM',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Comoros',
    native_country_name: '‫جزر القمر‬‎',
  },
  {
    country: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    country_code: 243,
    iso2: 'cd',
    iso_3166_2: 'CD',
    iso3: 'cod',
    iso_3166_3: 'COD',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Congo (DRC)',
    native_country_name: 'Jamhuri ya Kidemokrasia ya Kongo',
  },
  {
    country: 'Congo (Republic) (Congo-Brazzaville)',
    country_code: 242,
    iso2: 'cg',
    iso_3166_2: 'CG',
    iso3: 'cog',
    iso_3166_3: 'COG',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Congo (Republic)',
    native_country_name: 'Congo-Brazzaville',
  },
  {
    country: 'Cook Islands',
    country_code: 682,
    iso2: 'ck',
    iso_3166_2: 'CK',
    iso3: 'cok',
    iso_3166_3: 'COK',
    timezoneBegin: '-10:00',
    areaCodes: null,
    eng_country_name: 'Cook Islands',
    native_country_name: '',
  },
  {
    country: 'Costa Rica',
    country_code: 506,
    iso2: 'cr',
    iso_3166_2: 'CR',
    iso3: 'crc',
    iso_3166_3: 'CRC',
    timezoneBegin: '-06:00',
    areaCodes: null,
    eng_country_name: 'Costa Rica',
    native_country_name: '',
  },
  {
    country: 'Côte d’Ivoire',
    country_code: 225,
    iso2: 'ci',
    iso_3166_2: 'CI',
    iso3: 'civ',
    iso_3166_3: 'CIV',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Côte d’Ivoire',
    native_country_name: '',
  },
  {
    country: 'Croatia (Hrvatska)',
    country_code: 385,
    iso2: 'hr',
    iso_3166_2: 'HR',
    iso3: 'hrv',
    iso_3166_3: 'HRV',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Croatia',
    native_country_name: 'Hrvatska',
  },
  {
    country: 'Cuba',
    country_code: 53,
    iso2: 'cu',
    iso_3166_2: 'CU',
    iso3: 'cub',
    iso_3166_3: 'CUB',
    timezoneBegin: '-03:00',
    areaCodes: null,
    eng_country_name: 'Cuba',
    native_country_name: '',
  },
  {
    country: 'Cyprus (Κύπρος)',
    country_code: 357,
    iso2: 'cy',
    iso_3166_2: 'CY',
    iso3: 'cyp',
    iso_3166_3: 'CYP',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Cyprus',
    native_country_name: 'Κύπρος',
  },
  {
    country: 'Czech Republic (Česká republika)',
    country_code: 420,
    iso2: 'cz',
    iso_3166_2: 'CZ',
    iso3: 'cze',
    iso_3166_3: 'CZE',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Czech Republic',
    native_country_name: 'Česká republika',
  },
  {
    country: 'Denmark (Danmark)',
    country_code: 45,
    iso2: 'dk',
    iso_3166_2: 'DK',
    iso3: 'dnk',
    iso_3166_3: 'DNK',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Denmark',
    native_country_name: 'Danmark',
  },
  {
    country: 'Djibouti',
    country_code: 253,
    iso2: 'dj',
    iso_3166_2: 'DJ',
    iso3: 'dji',
    iso_3166_3: 'DJI',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Djibouti',
    native_country_name: '',
  },
  {
    country: 'Dominica',
    country_code: 1767,
    iso2: 'dm',
    iso_3166_2: 'DM',
    iso3: 'dma',
    iso_3166_3: 'DMA',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Dominica',
    native_country_name: '',
  },
  {
    country: 'Dominican Republic (República Dominicana)',
    country_code: 1,
    iso2: 'do',
    iso_3166_2: 'DO',
    iso3: 'dom',
    iso_3166_3: 'DOM',
    timezoneBegin: null,
    areaCodes: ['809', '829', '849'],
    eng_country_name: 'Dominican Republic',
    native_country_name: 'República Dominicana',
  },
  {
    country: 'Ecuador',
    country_code: 593,
    iso2: 'ec',
    iso_3166_2: 'EC',
    iso3: 'ecu',
    iso_3166_3: 'ECU',
    timezoneBegin: '-05:00',
    areaCodes: null,
    eng_country_name: 'Ecuador',
    native_country_name: '',
  },
  {
    country: 'Egypt (‫مصر‬‎)',
    country_code: 20,
    iso2: 'eg',
    iso_3166_2: 'EG',
    iso3: 'egy',
    iso_3166_3: 'EGY',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Egypt',
    native_country_name: '‫مصر‬‎',
  },
  {
    country: 'El Salvador',
    country_code: 503,
    iso2: 'sv',
    iso_3166_2: 'SV',
    iso3: 'slv',
    iso_3166_3: 'SLV',
    timezoneBegin: '-06:00',
    areaCodes: null,
    eng_country_name: 'El Salvador',
    native_country_name: '',
  },
  {
    country: 'Equatorial Guinea (Guinea Ecuatorial)',
    country_code: 240,
    iso2: 'gq',
    iso_3166_2: 'GQ',
    iso3: 'gnq',
    iso_3166_3: 'GNQ',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Equatorial Guinea',
    native_country_name: 'Guinea Ecuatorial',
  },
  {
    country: 'Eritrea',
    country_code: 291,
    iso2: 'er',
    iso_3166_2: 'ER',
    iso3: 'eri',
    iso_3166_3: 'ERI',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Eritrea',
    native_country_name: '',
  },
  {
    country: 'Estonia (Eesti)',
    country_code: 372,
    iso2: 'ee',
    iso_3166_2: 'EE',
    iso3: 'est',
    iso_3166_3: 'EST',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Estonia',
    native_country_name: 'Eesti',
  },
  {
    country: 'Ethiopia',
    country_code: 251,
    iso2: 'et',
    iso_3166_2: 'ET',
    iso3: 'eth',
    iso_3166_3: 'ETH',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Ethiopia',
    native_country_name: '',
  },
  {
    country: 'Falkland Islands (Islas Malvinas)',
    country_code: 500,
    iso2: 'fk',
    iso_3166_2: 'FK',
    iso3: 'flk',
    iso_3166_3: 'FLK',
    timezoneBegin: '-04:00',
    areaCodes: null,
    eng_country_name: 'Falkland Islands',
    native_country_name: 'Islas Malvinas',
  },
  {
    country: 'Faroe Islands (Føroyar)',
    country_code: 298,
    iso2: 'fo',
    iso_3166_2: 'FO',
    iso3: 'fro',
    iso_3166_3: 'FRO',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Faroe Islands',
    native_country_name: 'Føroyar',
  },
  {
    country: 'Fiji',
    country_code: 679,
    iso2: 'fj',
    iso_3166_2: 'FJ',
    iso3: 'fji',
    iso_3166_3: 'FJI',
    timezoneBegin: '+12:00',
    areaCodes: null,
    eng_country_name: 'Fiji',
    native_country_name: '',
  },
  {
    country: 'Finland (Suomi)',
    country_code: 358,
    iso2: 'fi',
    iso_3166_2: 'FI',
    iso3: 'fin',
    iso_3166_3: 'FIN',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Finland',
    native_country_name: 'Suomi',
  },
  {
    country: 'France',
    country_code: 33,
    iso2: 'fr',
    iso_3166_2: 'FR',
    iso3: 'fra',
    iso_3166_3: 'FRA',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'France',
    native_country_name: '',
  },
  {
    country: 'French Polynesia (Polynésie française)',
    country_code: 689,
    iso2: 'pf',
    iso_3166_2: 'PF',
    iso3: 'pyf',
    iso_3166_3: 'PYF',
    timezoneBegin: '-10:00',
    areaCodes: null,
    eng_country_name: 'French Polynesia',
    native_country_name: 'Polynésie française',
  },
  {
    country: 'Gabon',
    country_code: 241,
    iso2: 'ga',
    iso_3166_2: 'GA',
    iso3: 'gab',
    iso_3166_3: 'GAB',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Gabon',
    native_country_name: '',
  },
  {
    country: 'Gambia',
    country_code: 220,
    iso2: 'gm',
    iso_3166_2: 'GM',
    iso3: 'gmb',
    iso_3166_3: 'GMB',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Gambia',
    native_country_name: '',
  },
  {
    country: 'Georgia (საქართველო)',
    country_code: 995,
    iso2: 'ge',
    iso_3166_2: 'GE',
    iso3: 'geo',
    iso_3166_3: 'GEO',
    timezoneBegin: '+04:00',
    areaCodes: null,
    eng_country_name: 'Georgia',
    native_country_name: 'საქართველო',
  },
  {
    country: 'Germany (Deutschland)',
    country_code: 49,
    iso2: 'de',
    iso_3166_2: 'DE',
    iso3: 'deu',
    iso_3166_3: 'DEU',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Germany',
    native_country_name: 'Deutschland',
  },
  {
    country: 'Ghana (Gaana)',
    country_code: 233,
    iso2: 'gh',
    iso_3166_2: 'GH',
    iso3: 'gha',
    iso_3166_3: 'GHA',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Ghana',
    native_country_name: 'Gaana',
  },
  {
    country: 'Gibraltar',
    country_code: 350,
    iso2: 'gi',
    iso_3166_2: 'GI',
    iso3: 'gib',
    iso_3166_3: 'GIB',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Gibraltar',
    native_country_name: '',
  },
  {
    country: 'Greece (Ελλάδα)',
    country_code: 30,
    iso2: 'gr',
    iso_3166_2: 'GR',
    iso3: 'grc',
    iso_3166_3: 'GRC',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Greece',
    native_country_name: 'Ελλάδα',
  },
  {
    country: 'Greenland (Kalaallit Nunaat)',
    country_code: 299,
    iso2: 'gl',
    iso_3166_2: 'GL',
    iso3: 'grl',
    iso_3166_3: 'GRL',
    timezoneBegin: '-03:00',
    areaCodes: null,
    eng_country_name: 'Greenland',
    native_country_name: 'Kalaallit Nunaat',
  },
  {
    country: 'Grenada',
    country_code: 1473,
    iso2: 'gd',
    iso_3166_2: 'GD',
    iso3: 'grd',
    iso_3166_3: 'GRD',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Grenada',
    native_country_name: '',
  },
  {
    country: 'Guam',
    country_code: 1671,
    iso2: 'gu',
    iso_3166_2: 'GU',
    iso3: 'gum',
    iso_3166_3: 'GUM',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Guam',
    native_country_name: '',
  },
  {
    country: 'Guatemala',
    country_code: 502,
    iso2: 'gt',
    iso_3166_2: 'GT',
    iso3: 'gtm',
    iso_3166_3: 'GTM',
    timezoneBegin: '-06:00',
    areaCodes: null,
    eng_country_name: 'Guatemala',
    native_country_name: '',
  },
  {
    country: 'Guinea (Guinée)',
    country_code: 224,
    iso2: 'gn',
    iso_3166_2: 'GN',
    iso3: 'gin',
    iso_3166_3: 'GIN',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Guinea',
    native_country_name: 'Guinée',
  },
  {
    country: 'Guinea-Bissau (Guiné Bissau)',
    country_code: 245,
    iso2: 'gw',
    iso_3166_2: 'GW',
    iso3: 'gnb',
    iso_3166_3: 'GNB',
    timezoneBegin: '-01:00',
    areaCodes: null,
    eng_country_name: 'Guinea-Bissau',
    native_country_name: 'Guiné Bissau',
  },
  {
    country: 'Guyana',
    country_code: 592,
    iso2: 'gy',
    iso_3166_2: 'GY',
    iso3: 'guy',
    iso_3166_3: 'GUY',
    timezoneBegin: '-03:00',
    areaCodes: null,
    eng_country_name: 'Guyana',
    native_country_name: '',
  },
  {
    country: 'Haiti',
    country_code: 509,
    iso2: 'ht',
    iso_3166_2: 'HT',
    iso3: 'hti',
    iso_3166_3: 'HTI',
    timezoneBegin: '-05:00',
    areaCodes: null,
    eng_country_name: 'Haiti',
    native_country_name: '',
  },
  {
    country: 'Honduras',
    country_code: 504,
    iso2: 'hn',
    iso_3166_2: 'HN',
    iso3: 'hnd',
    iso_3166_3: 'HND',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Honduras',
    native_country_name: '',
  },
  {
    country: 'Hong Kong (香港)',
    country_code: 852,
    iso2: 'hk',
    iso_3166_2: 'HK',
    iso3: 'hkg',
    iso_3166_3: 'HKG',
    timezoneBegin: '+08:00',
    areaCodes: null,
    eng_country_name: 'Hong Kong',
    native_country_name: '香港',
  },
  {
    country: 'Hungary (Magyarország)',
    country_code: 36,
    iso2: 'hu',
    iso_3166_2: 'HU',
    iso3: 'hun',
    iso_3166_3: 'HUN',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Hungary',
    native_country_name: 'Magyarország',
  },
  {
    country: 'Iceland (Ísland)',
    country_code: 354,
    iso2: 'is',
    iso_3166_2: 'IS',
    iso3: 'is',
    iso_3166_3: 'IS',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Iceland',
    native_country_name: 'Ísland',
  },
  {
    country: 'India (भारत)',
    country_code: 91,
    iso2: 'in',
    iso_3166_2: 'IN',
    iso3: 'ind',
    iso_3166_3: 'IND',
    timezoneBegin: '+05:30',
    areaCodes: null,
    eng_country_name: 'India',
    native_country_name: 'भारत',
  },
  {
    country: 'Indonesia',
    country_code: 62,
    iso2: 'id',
    iso_3166_2: 'ID',
    iso3: 'idn',
    iso_3166_3: 'IDN',
    timezoneBegin: '+09:00',
    areaCodes: null,
    eng_country_name: 'Indonesia',
    native_country_name: '',
  },
  {
    country: 'Iran (‫ایران‬‎)',
    country_code: 98,
    iso2: 'ir',
    iso_3166_2: 'IR',
    iso3: 'irn',
    iso_3166_3: 'IRN',
    timezoneBegin: '+03:30',
    areaCodes: null,
    eng_country_name: 'Iran',
    native_country_name: '‫ایران‬‎',
  },
  {
    country: 'Iraq (‫العراق‬‎)',
    country_code: 964,
    iso2: 'iq',
    iso_3166_2: 'IQ',
    iso3: 'irq',
    iso_3166_3: 'IRQ',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Iraq',
    native_country_name: '‫العراق‬‎',
  },
  {
    country: 'Ireland',
    country_code: 353,
    iso2: 'ie',
    iso_3166_2: 'IE',
    iso3: 'irl',
    iso_3166_3: 'IRL',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Ireland',
    native_country_name: '',
  },
  {
    country: 'Isle of Man',
    country_code: 44,
    iso2: 'im',
    iso_3166_2: 'IM',
    iso3: 'imn',
    iso_3166_3: 'IMN',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Isle of Man',
    native_country_name: '',
  },
  {
    country: 'Israel (‫ישראל‬‎)',
    country_code: 972,
    iso2: 'il',
    iso_3166_2: 'IL',
    iso3: 'isr',
    iso_3166_3: 'ISR',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Israel',
    native_country_name: '‫ישראל‬‎',
  },
  {
    country: 'Italy (Italia)',
    country_code: 39,
    iso2: 'it',
    iso_3166_2: 'IT',
    iso3: 'ita',
    iso_3166_3: 'ITA',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Italy',
    native_country_name: 'Italia',
  },
  {
    country: 'Jamaica',
    country_code: 1876,
    iso2: 'jm',
    iso_3166_2: 'JM',
    iso3: 'jam',
    iso_3166_3: 'JAM',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Jamaica',
    native_country_name: '',
  },
  {
    country: 'Japan (日本)',
    country_code: 81,
    iso2: 'jp',
    iso_3166_2: 'JP',
    iso3: 'jpn',
    iso_3166_3: 'JPN',
    timezoneBegin: '+09:00',
    areaCodes: null,
    eng_country_name: 'Japan',
    native_country_name: '日本',
  },
  {
    country: 'Jersey',
    country_code: 44,
    iso2: 'je',
    iso_3166_2: 'JE',
    iso3: 'jey',
    iso_3166_3: 'JEY',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Jersey',
    native_country_name: '',
  },
  {
    country: 'Jordan (‫الأردن‬‎)',
    country_code: 962,
    iso2: 'jo',
    iso_3166_2: 'JO',
    iso3: 'jor',
    iso_3166_3: 'JOR',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Jordan',
    native_country_name: '‫الأردن‬‎',
  },
  {
    country: 'Kazakhstan (Казахстан)',
    country_code: 7,
    iso2: 'kz',
    iso_3166_2: 'KZ',
    iso3: 'kaz',
    iso_3166_3: 'KAZ',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Kazakhstan',
    native_country_name: 'Казахстан',
  },
  {
    country: 'Kenya',
    country_code: 254,
    iso2: 'ke',
    iso_3166_2: 'KE',
    iso3: 'ken',
    iso_3166_3: 'KEN',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Kenya',
    native_country_name: '',
  },
  {
    country: 'Kiribati',
    country_code: 686,
    iso2: 'ki',
    iso_3166_2: 'KI',
    iso3: 'kir',
    iso_3166_3: 'KIR',
    timezoneBegin: '+12:00',
    areaCodes: null,
    eng_country_name: 'Kiribati',
    native_country_name: '',
  },
  {
    country: 'Kosovo',
    country_code: 383,
    iso2: 'xk',
    iso_3166_2: 'XK',
    iso3: 'xkx',
    iso_3166_3: 'XKX',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Kosovo',
    native_country_name: '',
  },
  {
    country: 'Kuwait (‫الكويت‬‎)',
    country_code: 965,
    iso2: 'kw',
    iso_3166_2: 'KW',
    iso3: 'kwt',
    iso_3166_3: 'KWT',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Kuwait',
    native_country_name: '‫الكويت‬‎',
  },
  {
    country: 'Kyrgyzstan (Кыргызстан)',
    country_code: 996,
    iso2: 'kg',
    iso_3166_2: 'KG',
    iso3: 'kgz',
    iso_3166_3: 'KGZ',
    timezoneBegin: '+06:00',
    areaCodes: null,
    eng_country_name: 'Kyrgyzstan',
    native_country_name: 'Кыргызстан',
  },
  {
    country: 'Laos (ລາວ)',
    country_code: 856,
    iso2: 'la',
    iso_3166_2: 'LA',
    iso3: 'lao',
    iso_3166_3: 'LAO',
    timezoneBegin: '+07:00',
    areaCodes: null,
    eng_country_name: 'Laos',
    native_country_name: 'ລາວ',
  },
  {
    country: 'Latvia (Latvija)',
    country_code: 371,
    iso2: 'lv',
    iso_3166_2: 'LV',
    iso3: 'lva',
    iso_3166_3: 'LVA',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Latvia',
    native_country_name: 'Latvija',
  },
  {
    country: 'Lebanon (‫لبنان‬‎)',
    country_code: 961,
    iso2: 'lb',
    iso_3166_2: 'LB',
    iso3: 'lbn',
    iso_3166_3: 'LBN',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Lebanon',
    native_country_name: '‫لبنان‬‎',
  },
  {
    country: 'Lesotho',
    country_code: 266,
    iso2: 'ls',
    iso_3166_2: 'LS',
    iso3: 'lso',
    iso_3166_3: 'LSO',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Lesotho',
    native_country_name: '',
  },
  {
    country: 'Liberia',
    country_code: 231,
    iso2: 'lr',
    iso_3166_2: 'LR',
    iso3: 'lbr',
    iso_3166_3: 'LBR',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Liberia',
    native_country_name: '',
  },
  {
    country: 'Libya (‫ليبيا‬‎)',
    country_code: 218,
    iso2: 'ly',
    iso_3166_2: 'LY',
    iso3: 'lby',
    iso_3166_3: 'LBY',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Libya',
    native_country_name: '‫ليبيا‬‎',
  },
  {
    country: 'Liechtenstein',
    country_code: 423,
    iso2: 'li',
    iso_3166_2: 'LI',
    iso3: 'lie',
    iso_3166_3: 'LIE',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Liechtenstein',
    native_country_name: '',
  },
  {
    country: 'Lithuania (Lietuva)',
    country_code: 370,
    iso2: 'lt',
    iso_3166_2: 'LT',
    iso3: 'ltu',
    iso_3166_3: 'LTU',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Lithuania',
    native_country_name: 'Lietuva',
  },
  {
    country: 'Luxembourg',
    country_code: 352,
    iso2: 'lu',
    iso_3166_2: 'LU',
    iso3: 'lux',
    iso_3166_3: 'LUX',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Luxembourg',
    native_country_name: '',
  },
  {
    country: 'Macau (澳門)',
    country_code: 853,
    iso2: 'mo',
    iso_3166_2: 'MO',
    iso3: 'mac',
    iso_3166_3: 'MAC',
    timezoneBegin: '+08:00',
    areaCodes: null,
    eng_country_name: 'Macau',
    native_country_name: '澳門',
  },
  {
    country: 'North Macedonia (FYROM) (Македонија)',
    country_code: 389,
    iso2: 'mk',
    iso_3166_2: 'MK',
    iso3: 'mkd',
    iso_3166_3: 'MKD',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'North Macedonia (FYROM)',
    native_country_name: 'Македонија',
  },
  {
    country: 'Madagascar (Madagasikara)',
    country_code: 261,
    iso2: 'mg',
    iso_3166_2: 'MG',
    iso3: 'mdg',
    iso_3166_3: 'MDG',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Madagascar',
    native_country_name: 'Madagasikara',
  },
  {
    country: 'Malawi',
    country_code: 265,
    iso2: 'mw',
    iso_3166_2: 'MW',
    iso3: 'mwi',
    iso_3166_3: 'MWI',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Malawi',
    native_country_name: '',
  },
  {
    country: 'Malaysia',
    country_code: 60,
    iso2: 'my',
    iso_3166_2: 'MY',
    iso3: 'mys',
    iso_3166_3: 'MYS',
    timezoneBegin: '+08:00',
    areaCodes: null,
    eng_country_name: 'Malaysia',
    native_country_name: '',
  },
  {
    country: 'Maldives',
    country_code: 960,
    iso2: 'mv',
    iso_3166_2: 'MV',
    iso3: 'mdv',
    iso_3166_3: 'MDV',
    timezoneBegin: '+05:00',
    areaCodes: null,
    eng_country_name: 'Maldives',
    native_country_name: '',
  },
  {
    country: 'Mali',
    country_code: 223,
    iso2: 'ml',
    iso_3166_2: 'ML',
    iso3: 'mli',
    iso_3166_3: 'MLI',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Mali',
    native_country_name: '',
  },
  {
    country: 'Malta',
    country_code: 356,
    iso2: 'mt',
    iso_3166_2: 'MT',
    iso3: 'mlt',
    iso_3166_3: 'MLT',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Malta',
    native_country_name: '',
  },
  {
    country: 'Marshall Islands',
    country_code: 692,
    iso2: 'mh',
    iso_3166_2: 'MH',
    iso3: 'mhl',
    iso_3166_3: 'MHL',
    timezoneBegin: '+10:00',
    areaCodes: null,
    eng_country_name: 'Marshall Islands',
    native_country_name: '',
  },
  {
    country: 'Mauritania (‫موريتانيا‬‎)',
    country_code: 222,
    iso2: 'mr',
    iso_3166_2: 'MR',
    iso3: 'mrt',
    iso_3166_3: 'MRT',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Mauritania',
    native_country_name: '‫موريتانيا‬‎',
  },
  {
    country: 'Mauritius (Moris)',
    country_code: 230,
    iso2: 'mu',
    iso_3166_2: 'MU',
    iso3: 'mus',
    iso_3166_3: 'MUS',
    timezoneBegin: '+04:00',
    areaCodes: null,
    eng_country_name: 'Mauritius',
    native_country_name: 'Moris',
  },
  {
    country: 'Mayotte',
    country_code: 262,
    iso2: 'yt',
    iso_3166_2: 'YT',
    iso3: 'myt',
    iso_3166_3: 'MYT',
    timezoneBegin: '+04:00',
    areaCodes: null,
    eng_country_name: 'Mayotte',
    native_country_name: '',
  },
  {
    country: 'Mexico (México)',
    country_code: 52,
    iso2: 'mx',
    iso_3166_2: 'MX',
    iso3: 'mex',
    iso_3166_3: 'MEX',
    timezoneBegin: '-06:00',
    areaCodes: null,
    eng_country_name: 'Mexico',
    native_country_name: 'México',
  },
  {
    country: 'Micronesia',
    country_code: 691,
    iso2: 'fm',
    iso_3166_2: 'FM',
    iso3: 'fsm',
    iso_3166_3: 'FSM',
    timezoneBegin: '+10:00',
    areaCodes: null,
    eng_country_name: 'Micronesia',
    native_country_name: '',
  },
  {
    country: 'Moldova (Republica Moldova)',
    country_code: 373,
    iso2: 'md',
    iso_3166_2: 'MD',
    iso3: 'mda',
    iso_3166_3: 'MDA',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Moldova',
    native_country_name: 'Republica Moldova',
  },
  {
    country: 'Monaco',
    country_code: 377,
    iso2: 'mc',
    iso_3166_2: 'MC',
    iso3: 'mco',
    iso_3166_3: 'MCO',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Monaco',
    native_country_name: '',
  },
  {
    country: 'Mongolia (Монгол)',
    country_code: 976,
    iso2: 'mn',
    iso_3166_2: 'MN',
    iso3: 'mng',
    iso_3166_3: 'MNG',
    timezoneBegin: '+08:00',
    areaCodes: null,
    eng_country_name: 'Mongolia',
    native_country_name: 'Монгол',
  },
  {
    country: 'Montenegro (Crna Gora)',
    country_code: 382,
    iso2: 'me',
    iso_3166_2: 'ME',
    iso3: 'mne',
    iso_3166_3: 'MNE',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Montenegro',
    native_country_name: 'Crna Gora',
  },
  {
    country: 'Montserrat',
    country_code: 1664,
    iso2: 'ms',
    iso_3166_2: 'MS',
    iso3: 'msr',
    iso_3166_3: 'MSR',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Montserrat',
    native_country_name: '',
  },
  {
    country: 'Morocco (‫المغرب‬‎)',
    country_code: 212,
    iso2: 'ma',
    iso_3166_2: 'MA',
    iso3: 'mar',
    iso_3166_3: 'MAR',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Morocco',
    native_country_name: '‫المغرب‬‎',
  },
  {
    country: 'Mozambique (Moçambique)',
    country_code: 258,
    iso2: 'mz',
    iso_3166_2: 'MZ',
    iso3: 'moz',
    iso_3166_3: 'MOZ',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Mozambique',
    native_country_name: 'Moçambique',
  },
  {
    country: 'Myanmar (Burma) (မြန်မာ)',
    country_code: 95,
    iso2: 'mm',
    iso_3166_2: 'MM',
    iso3: 'mmr',
    iso_3166_3: 'MMR',
    timezoneBegin: '+06:30',
    areaCodes: null,
    eng_country_name: 'Myanmar (Burma)',
    native_country_name: 'မြန်မာ',
  },
  {
    country: 'Namibia (Namibië)',
    country_code: 264,
    iso2: 'na',
    iso_3166_2: 'NA',
    iso3: 'nam',
    iso_3166_3: 'NAM',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Namibia',
    native_country_name: 'Namibië',
  },
  {
    country: 'Nauru',
    country_code: 674,
    iso2: 'nr',
    iso_3166_2: 'NR',
    iso3: 'nru',
    iso_3166_3: 'NRU',
    timezoneBegin: '+12:00',
    areaCodes: null,
    eng_country_name: 'Nauru',
    native_country_name: '',
  },
  {
    country: 'Nepal (नेपाल)',
    country_code: 977,
    iso2: 'np',
    iso_3166_2: 'NP',
    iso3: 'npl',
    iso_3166_3: 'NPL',
    timezoneBegin: '+05:30',
    areaCodes: null,
    eng_country_name: 'Nepal',
    native_country_name: 'नेपाल',
  },
  {
    country: 'Netherlands (Nederland)',
    country_code: 31,
    iso2: 'nl',
    iso_3166_2: 'NL',
    iso3: 'nld',
    iso_3166_3: 'NLD',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Netherlands',
    native_country_name: 'Nederland',
  },
  {
    country: 'New Caledonia (Nouvelle-Calédonie)',
    country_code: 687,
    iso2: 'nc',
    iso_3166_2: 'NC',
    iso3: 'ncl',
    iso_3166_3: 'NCL',
    timezoneBegin: '+11:00',
    areaCodes: null,
    eng_country_name: 'New Caledonia',
    native_country_name: 'Nouvelle-Calédonie',
  },
  {
    country: 'New Zealand',
    country_code: 64,
    iso2: 'nz',
    iso_3166_2: 'NZ',
    iso3: 'nzl',
    iso_3166_3: 'NZL',
    timezoneBegin: '+12:00',
    areaCodes: null,
    eng_country_name: 'New Zealand',
    native_country_name: '',
  },
  {
    country: 'Nicaragua',
    country_code: 505,
    iso2: 'ni',
    iso_3166_2: 'NI',
    iso3: 'nic',
    iso_3166_3: 'NIC',
    timezoneBegin: '-06:00',
    areaCodes: null,
    eng_country_name: 'Nicaragua',
    native_country_name: '',
  },
  {
    country: 'Niger (Nijar)',
    country_code: 227,
    iso2: 'ne',
    iso_3166_2: 'NE',
    iso3: 'ner',
    iso_3166_3: 'NER',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Niger',
    native_country_name: 'Nijar',
  },
  {
    country: 'Nigeria',
    country_code: 234,
    iso2: 'ng',
    iso_3166_2: 'NG',
    iso3: 'nga',
    iso_3166_3: 'NGA',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Nigeria',
    native_country_name: '',
  },
  {
    country: 'Niue',
    country_code: 683,
    iso2: 'nu',
    iso_3166_2: 'NU',
    iso3: 'niu',
    iso_3166_3: 'NIU',
    timezoneBegin: '-11:00',
    areaCodes: null,
    eng_country_name: 'Niue',
    native_country_name: '',
  },
  {
    country: 'North Korea (조선 민주주의 인민 공화국)',
    country_code: 850,
    iso2: 'kp',
    iso_3166_2: 'KP',
    iso3: 'prk',
    iso_3166_3: 'PRK',
    timezoneBegin: '+09:00',
    areaCodes: null,
    eng_country_name: 'North Korea',
    native_country_name: '조선 민주주의 인민 공화국',
  },
  {
    country: 'Northern Mariana Islands',
    country_code: 1670,
    iso2: 'mp',
    iso_3166_2: 'MP',
    iso3: 'mnp',
    iso_3166_3: 'MNP',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Northern Mariana Islands',
    native_country_name: '',
  },
  {
    country: 'Norway (Norge)',
    country_code: 47,
    iso2: 'no',
    iso_3166_2: 'NO',
    iso3: 'nor',
    iso_3166_3: 'NOR',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Norway',
    native_country_name: 'Norge',
  },
  {
    country: 'Oman (‫عُمان‬‎)',
    country_code: 968,
    iso2: 'om',
    iso_3166_2: 'OM',
    iso3: 'omn',
    iso_3166_3: 'OMN',
    timezoneBegin: '+04:00',
    areaCodes: null,
    eng_country_name: 'Oman',
    native_country_name: '‫عُمان‬‎',
  },
  {
    country: 'Pakistan (‫پاکستان‬‎)',
    country_code: 92,
    iso2: 'pk',
    iso_3166_2: 'PK',
    iso3: 'pak',
    iso_3166_3: 'PAK',
    timezoneBegin: '+05:00',
    areaCodes: null,
    eng_country_name: 'Pakistan',
    native_country_name: '‫پاکستان‬‎',
  },
  {
    country: 'Palau',
    country_code: 680,
    iso2: 'pw',
    iso_3166_2: 'PW',
    iso3: 'plw',
    iso_3166_3: 'PLW',
    timezoneBegin: '+09:00',
    areaCodes: null,
    eng_country_name: 'Palau',
    native_country_name: '',
  },
  {
    country: 'Panama (Panamá)',
    country_code: 507,
    iso2: 'pa',
    iso_3166_2: 'PA',
    iso3: 'pan',
    iso_3166_3: 'PAN',
    timezoneBegin: '-05:00',
    areaCodes: null,
    eng_country_name: 'Panama',
    native_country_name: 'Panamá',
  },
  {
    country: 'Papua New Guinea',
    country_code: 675,
    iso2: 'pg',
    iso_3166_2: 'PG',
    iso3: 'png',
    iso_3166_3: 'PNG',
    timezoneBegin: '+10:00',
    areaCodes: null,
    eng_country_name: 'Papua New Guinea',
    native_country_name: '',
  },
  {
    country: 'Paraguay',
    country_code: 595,
    iso2: 'py',
    iso_3166_2: 'PY',
    iso3: 'pry',
    iso_3166_3: 'PRY',
    timezoneBegin: '-04:00',
    areaCodes: null,
    eng_country_name: 'Paraguay',
    native_country_name: '',
  },
  {
    country: 'Peru (Perú)',
    country_code: 51,
    iso2: 'pe',
    iso_3166_2: 'PE',
    iso3: 'per',
    iso_3166_3: 'PER',
    timezoneBegin: '-05:00',
    areaCodes: null,
    eng_country_name: 'Peru',
    native_country_name: 'Perú',
  },
  {
    country: 'Philippines',
    country_code: 63,
    iso2: 'ph',
    iso_3166_2: 'PH',
    iso3: 'phl',
    iso_3166_3: 'PHL',
    timezoneBegin: '+08:00',
    areaCodes: null,
    eng_country_name: 'Philippines',
    native_country_name: '',
  },
  {
    country: 'Poland (Polska)',
    country_code: 48,
    iso2: 'pl',
    iso_3166_2: 'PL',
    iso3: 'pol',
    iso_3166_3: 'POL',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Poland',
    native_country_name: 'Polska',
  },
  {
    country: 'Portugal',
    country_code: 351,
    iso2: 'pt',
    iso_3166_2: 'PT',
    iso3: 'prt',
    iso_3166_3: 'PRT',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Portugal',
    native_country_name: '',
  },
  {
    country: 'Puerto Rico',
    country_code: 1,
    iso2: 'pr',
    iso_3166_2: 'PR',
    iso3: 'pri',
    iso_3166_3: 'PRI',
    timezoneBegin: '-05:00',
    areaCodes: ['787', '939'],
    eng_country_name: 'Puerto Rico',
    native_country_name: '',
  },
  {
    country: 'Qatar (‫قطر‬‎)',
    country_code: 974,
    iso2: 'qa',
    iso_3166_2: 'QA',
    iso3: 'qat',
    iso_3166_3: 'QAT',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Qatar',
    native_country_name: '‫قطر‬‎',
  },
  {
    country: 'Romania (România)',
    country_code: 40,
    iso2: 'ro',
    iso_3166_2: 'RO',
    iso3: 'rou',
    iso_3166_3: 'ROU',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Romania',
    native_country_name: 'România',
  },
  {
    country: 'Russia (Россия)',
    country_code: 7,
    iso2: 'ru',
    iso_3166_2: 'RU',
    iso3: 'rus',
    iso_3166_3: 'RUS',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Russia',
    native_country_name: 'Россия',
  },
  {
    country: 'Rwanda',
    country_code: 250,
    iso2: 'rw',
    iso_3166_2: 'RW',
    iso3: 'rwa',
    iso_3166_3: 'RWA',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Rwanda',
    native_country_name: '',
  },
  {
    country: 'Saint Barthélemy (Saint-Barthélemy)',
    country_code: 590,
    iso2: 'bl',
    iso_3166_2: 'BL',
    iso3: 'blm',
    iso_3166_3: 'BLM',
    timezoneBegin: '-04:00',
    areaCodes: null,
    eng_country_name: 'Saint Barthélemy',
    native_country_name: 'Saint-Barthélemy',
  },
  {
    country: 'Saint Helena',
    country_code: 290,
    iso2: 'sh',
    iso_3166_2: 'SH',
    iso3: 'shn',
    iso_3166_3: 'SHN',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Saint Helena',
    native_country_name: '',
  },
  {
    country: 'Saint Kitts and Nevis',
    country_code: 1869,
    iso2: 'kn',
    iso_3166_2: 'KN',
    iso3: 'kna',
    iso_3166_3: 'KNA',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Saint Kitts and Nevis',
    native_country_name: '',
  },
  {
    country: 'Saint Lucia',
    country_code: 1758,
    iso2: 'lc',
    iso_3166_2: 'LC',
    iso3: 'lca',
    iso_3166_3: 'LCA',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Saint Lucia',
    native_country_name: '',
  },
  {
    country: 'Saint Martin (Saint-Martin (partie française))',
    country_code: 590,
    iso2: 'mf',
    iso_3166_2: 'MF',
    iso3: 'maf',
    iso_3166_3: 'MAF',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Saint Martin (Saint-Martin (partie française))',
    native_country_name: '',
  },
  {
    country: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    country_code: 508,
    iso2: 'pm',
    iso_3166_2: 'PM',
    iso3: 'spm',
    iso_3166_3: 'SPM',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Saint Pierre and Miquelon',
    native_country_name: 'Saint-Pierre-et-Miquelon',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    country_code: 1784,
    iso2: 'vc',
    iso_3166_2: 'VC',
    iso3: 'vct',
    iso_3166_3: 'VCT',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Saint Vincent and the Grenadines',
    native_country_name: '',
  },
  {
    country: 'Samoa',
    country_code: 685,
    iso2: 'ws',
    iso_3166_2: 'WS',
    iso3: 'wsm',
    iso_3166_3: 'WSM',
    timezoneBegin: '-11:00',
    areaCodes: null,
    eng_country_name: 'Samoa',
    native_country_name: '',
  },
  {
    country: 'San Marino',
    country_code: 378,
    iso2: 'sm',
    iso_3166_2: 'SM',
    iso3: 'smr',
    iso_3166_3: 'SMR',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'San Marino',
    native_country_name: '',
  },
  {
    country: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    country_code: 239,
    iso2: 'st',
    iso_3166_2: 'ST',
    iso3: 'stp',
    iso_3166_3: 'STP',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'São Tomé and Príncipe',
    native_country_name: 'São Tomé e Príncipe',
  },
  {
    country: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    country_code: 966,
    iso2: 'sa',
    iso_3166_2: 'SA',
    iso3: 'sau',
    iso_3166_3: 'SAU',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Saudi Arabia',
    native_country_name: '‫المملكة العربية السعودية‬‎',
  },
  {
    country: 'Senegal (Sénégal)',
    country_code: 221,
    iso2: 'sn',
    iso_3166_2: 'SN',
    iso3: 'sen',
    iso_3166_3: 'SEN',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Senegal',
    native_country_name: 'Sénégal',
  },
  {
    country: 'Serbia (Србија)',
    country_code: 381,
    iso2: 'rs',
    iso_3166_2: 'RS',
    iso3: 'srb',
    iso_3166_3: 'SRB',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Serbia',
    native_country_name: 'Србија',
  },
  {
    country: 'Seychelles',
    country_code: 248,
    iso2: 'sc',
    iso_3166_2: 'SC',
    iso3: 'syc',
    iso_3166_3: 'SYC',
    timezoneBegin: '+04:00',
    areaCodes: null,
    eng_country_name: 'Seychelles',
    native_country_name: '',
  },
  {
    country: 'Sierra Leone',
    country_code: 232,
    iso2: 'sl',
    iso_3166_2: 'SL',
    iso3: 'sle',
    iso_3166_3: 'SLE',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Sierra Leone',
    native_country_name: '',
  },
  {
    country: 'Singapore',
    country_code: 65,
    iso2: 'sg',
    iso_3166_2: 'SG',
    iso3: 'sgp',
    iso_3166_3: 'SGP',
    timezoneBegin: '+08:00',
    areaCodes: null,
    eng_country_name: 'Singapore',
    native_country_name: '',
  },
  {
    country: 'Slovakia (Slovensko)',
    country_code: 421,
    iso2: 'sk',
    iso_3166_2: 'SK',
    iso3: 'svk',
    iso_3166_3: 'SVK',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Slovakia',
    native_country_name: 'Slovensko',
  },
  {
    country: 'Slovenia (Slovenija)',
    country_code: 386,
    iso2: 'si',
    iso_3166_2: 'SI',
    iso3: 'svn',
    iso_3166_3: 'SVN',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Slovenia',
    native_country_name: 'Slovenija',
  },
  {
    country: 'Solomon Islands',
    country_code: 677,
    iso2: 'sb',
    iso_3166_2: 'SB',
    iso3: 'slb',
    iso_3166_3: 'SLB',
    timezoneBegin: '+11:00',
    areaCodes: null,
    eng_country_name: 'Solomon Islands',
    native_country_name: '',
  },
  {
    country: 'Somalia (Soomaaliya)',
    country_code: 252,
    iso2: 'so',
    iso_3166_2: 'SO',
    iso3: 'som',
    iso_3166_3: 'SOM',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Somalia',
    native_country_name: 'Soomaaliya',
  },
  {
    country: 'South Africa',
    country_code: 27,
    iso2: 'za',
    iso_3166_2: 'ZA',
    iso3: 'zaf',
    iso_3166_3: 'ZAF',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'South Africa',
    native_country_name: '',
  },
  {
    country: 'South Korea (대한민국)',
    country_code: 82,
    iso2: 'kr',
    iso_3166_2: 'KR',
    iso3: 'kor',
    iso_3166_3: 'KOR',
    timezoneBegin: '+09:00',
    areaCodes: null,
    eng_country_name: 'South Korea',
    native_country_name: '대한민국',
  },
  {
    country: 'South Sudan (جنوب السودان)',
    country_code: 211,
    iso2: 'ss',
    iso_3166_2: 'SS',
    iso3: 'ssd',
    iso_3166_3: 'SSD',
    timezoneBegin: '+3:00',
    areaCodes: null,
    eng_country_name: 'South Sudan',
    native_country_name: 'جنوب السودان',
  },
  {
    country: 'Spain (España)',
    country_code: 34,
    iso2: 'es',
    iso_3166_2: 'ES',
    iso3: 'esp',
    iso_3166_3: 'ESP',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Spain',
    native_country_name: 'España',
  },
  {
    country: 'Sri Lanka (ශ්‍රී ලංකාව)',
    country_code: 94,
    iso2: 'lk',
    iso_3166_2: 'LK',
    iso3: 'lka',
    iso_3166_3: 'LKA',
    timezoneBegin: '+05:30',
    areaCodes: null,
    eng_country_name: 'Sri Lanka',
    native_country_name: 'ශ්‍රී ලංකාව',
  },
  {
    country: 'Sudan (‫السودان‬‎)',
    country_code: 249,
    iso2: 'sd',
    iso_3166_2: 'SD',
    iso3: 'sdn',
    iso_3166_3: 'SDN',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Sudan',
    native_country_name: '‫السودان‬‎',
  },
  {
    country: 'Suriname',
    country_code: 597,
    iso2: 'sr',
    iso_3166_2: 'SR',
    iso3: 'sur',
    iso_3166_3: 'SUR',
    timezoneBegin: '-03:30',
    areaCodes: null,
    eng_country_name: 'Suriname',
    native_country_name: '',
  },
  {
    country: 'Swaziland',
    country_code: 268,
    iso2: 'sz',
    iso_3166_2: 'SZ',
    iso3: 'swz',
    iso_3166_3: 'SWZ',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Swaziland',
    native_country_name: '',
  },
  {
    country: 'Sweden (Sverige)',
    country_code: 46,
    iso2: 'se',
    iso_3166_2: 'SE',
    iso3: 'swe',
    iso_3166_3: 'SWE',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Sweden',
    native_country_name: 'Sverige',
  },
  {
    country: 'Switzerland (Schweiz)',
    country_code: 41,
    iso2: 'ch',
    iso_3166_2: 'CH',
    iso3: 'che',
    iso_3166_3: 'CHE',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Switzerland',
    native_country_name: 'Schweiz',
  },
  {
    country: 'Syria (‫سوريا‬‎)',
    country_code: 963,
    iso2: 'sy',
    iso_3166_2: 'SY',
    iso3: 'syr',
    iso_3166_3: 'SYR',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Syria',
    native_country_name: '‫سوريا‬‎',
  },
  {
    country: 'Taiwan (台灣)',
    country_code: 886,
    iso2: 'tw',
    iso_3166_2: 'TW',
    iso3: 'twn',
    iso_3166_3: 'TWN',
    timezoneBegin: '+08:00',
    areaCodes: null,
    eng_country_name: 'Taiwan',
    native_country_name: '台灣',
  },
  {
    country: 'Tajikistan',
    country_code: 992,
    iso2: 'tj',
    iso_3166_2: 'TJ',
    iso3: 'tjk',
    iso_3166_3: 'TJK',
    timezoneBegin: '+06:00',
    areaCodes: null,
    eng_country_name: 'Tajikistan',
    native_country_name: '',
  },
  {
    country: 'Tanzania',
    country_code: 255,
    iso2: 'tz',
    iso_3166_2: 'TZ',
    iso3: 'tza',
    iso_3166_3: 'TZA',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Tanzania',
    native_country_name: '',
  },
  {
    country: 'Thailand (ไทย)',
    country_code: 66,
    iso2: 'th',
    iso_3166_2: 'TH',
    iso3: 'tha',
    iso_3166_3: 'THA',
    timezoneBegin: '+07:00',
    areaCodes: null,
    eng_country_name: 'Thailand',
    native_country_name: 'ไทย',
  },
  {
    country: 'Timor-Leste',
    country_code: 670,
    iso2: 'tl',
    iso_3166_2: 'TL',
    iso3: 'tls',
    iso_3166_3: 'TLS',
    timezoneBegin: '+10:00',
    areaCodes: null,
    eng_country_name: 'Timor-Leste',
    native_country_name: '',
  },
  {
    country: 'Togo',
    country_code: 228,
    iso2: 'tg',
    iso_3166_2: 'TG',
    iso3: 'tgo',
    iso_3166_3: 'TGO',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'Togo',
    native_country_name: '',
  },
  {
    country: 'Tokelau',
    country_code: 690,
    iso2: 'tk',
    iso_3166_2: 'TK',
    iso3: 'tkl',
    iso_3166_3: 'TKL',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Tokelau',
    native_country_name: '',
  },
  {
    country: 'Tonga',
    country_code: 676,
    iso2: 'to',
    iso_3166_2: 'TO',
    iso3: 'ton',
    iso_3166_3: 'TON',
    timezoneBegin: '+13:00',
    areaCodes: null,
    eng_country_name: 'Tonga',
    native_country_name: '',
  },
  {
    country: 'Trinidad and Tobago',
    country_code: 1868,
    iso2: 'tt',
    iso_3166_2: 'TT',
    iso3: 'tto',
    iso_3166_3: 'TTO',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Trinidad and Tobago',
    native_country_name: '',
  },
  {
    country: 'Tunisia (‫تونس‬‎)',
    country_code: 216,
    iso2: 'tn',
    iso_3166_2: 'TN',
    iso3: 'tun',
    iso_3166_3: 'TUN',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Tunisia',
    native_country_name: '‫تونس‬‎',
  },
  {
    country: 'Turkey (Türkiye)',
    country_code: 90,
    iso2: 'tr',
    iso_3166_2: 'TR',
    iso3: 'tur',
    iso_3166_3: 'TUR',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Turkey',
    native_country_name: 'Türkiye',
  },
  {
    country: 'Turkmenistan',
    country_code: 993,
    iso2: 'tm',
    iso_3166_2: 'TM',
    iso3: 'tkm',
    iso_3166_3: 'TKM',
    timezoneBegin: '+05:00',
    areaCodes: null,
    eng_country_name: 'Turkmenistan',
    native_country_name: '',
  },
  {
    country: 'Turks and Caicos Islands',
    country_code: 1649,
    iso2: 'tc',
    iso_3166_2: 'TC',
    iso3: 'tca',
    iso_3166_3: 'TCA',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Turks and Caicos Islands',
    native_country_name: '',
  },
  {
    country: 'Tuvalu',
    country_code: 688,
    iso2: 'tv',
    iso_3166_2: 'TV',
    iso3: 'tuv',
    iso_3166_3: 'TUV',
    timezoneBegin: '+12:00',
    areaCodes: null,
    eng_country_name: 'Tuvalu',
    native_country_name: '',
  },
  {
    country: 'U.S. Virgin Islands',
    country_code: 1340,
    iso2: 'vi',
    iso_3166_2: 'VI',
    iso3: 'vir',
    iso_3166_3: 'VIR',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'U.S. Virgin Islands',
    native_country_name: '',
  },
  {
    country: 'Uganda',
    country_code: 256,
    iso2: 'ug',
    iso_3166_2: 'UG',
    iso3: 'uga',
    iso_3166_3: 'UGA',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Uganda',
    native_country_name: '',
  },
  {
    country: 'Ukraine (Україна)',
    country_code: 380,
    iso2: 'ua',
    iso_3166_2: 'UA',
    iso3: 'ukr',
    iso_3166_3: 'UKR',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Ukraine',
    native_country_name: 'Україна',
  },
  {
    country: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    country_code: 971,
    iso2: 'ae',
    iso_3166_2: 'AE',
    iso3: 'are',
    iso_3166_3: 'ARE',
    timezoneBegin: '+04:00',
    areaCodes: null,
    eng_country_name: 'United Arab Emirates',
    native_country_name: '‫الإمارات العربية المتحدة‬‎',
  },
  {
    country: 'United Kingdom',
    country_code: 44,
    iso2: 'gb',
    iso_3166_2: 'GB',
    iso3: 'gbr',
    iso_3166_3: 'GBR',
    timezoneBegin: '+00:00',
    areaCodes: null,
    eng_country_name: 'United Kingdom',
    native_country_name: '',
  },
  {
    country: 'United States',
    country_code: 1,
    iso2: 'us',
    iso_3166_2: 'US',
    iso3: 'usa',
    iso_3166_3: 'USA',
    timezoneBegin: '-05:00',
    areaCodes: null,
    eng_country_name: 'United States',
    native_country_name: '',
  },
  {
    country: 'Uruguay',
    country_code: 598,
    iso2: 'uy',
    iso_3166_2: 'UY',
    iso3: 'ury',
    iso_3166_3: 'URY',
    timezoneBegin: '-03:00',
    areaCodes: null,
    eng_country_name: 'Uruguay',
    native_country_name: '',
  },
  {
    country: 'Uzbekistan (Oʻzbekiston)',
    country_code: 998,
    iso2: 'uz',
    iso_3166_2: 'UZ',
    iso3: 'uzb',
    iso_3166_3: 'UZB',
    timezoneBegin: '+06:00',
    areaCodes: null,
    eng_country_name: 'Uzbekistan',
    native_country_name: 'Oʻzbekiston',
  },
  {
    country: 'Vanuatu',
    country_code: 678,
    iso2: 'vu',
    iso_3166_2: 'VU',
    iso3: 'vut',
    iso_3166_3: 'VUT',
    timezoneBegin: '+11:00',
    areaCodes: null,
    eng_country_name: 'Vanuatu',
    native_country_name: '',
  },
  {
    country: 'Vatican City (Città del Vaticano)',
    country_code: 39,
    iso2: 'va',
    iso_3166_2: 'VA',
    iso3: 'vat',
    iso_3166_3: 'VAT',
    timezoneBegin: '+01:00',
    areaCodes: null,
    eng_country_name: 'Vatican City',
    native_country_name: 'Città del Vaticano',
  },
  {
    country: 'Venezuela',
    country_code: 58,
    iso2: 've',
    iso_3166_2: 'VE',
    iso3: 'ven',
    iso_3166_3: 'VEN',
    timezoneBegin: '-04:00',
    areaCodes: null,
    eng_country_name: 'Venezuela',
    native_country_name: '',
  },
  {
    country: 'Vietnam (Việt Nam)',
    country_code: 84,
    iso2: 'vn',
    iso_3166_2: 'VN',
    iso3: 'vnm',
    iso_3166_3: 'VNM',
    timezoneBegin: '+07:00',
    areaCodes: null,
    eng_country_name: 'Vietnam',
    native_country_name: 'Việt Nam',
  },
  {
    country: 'Wallis and Futuna',
    country_code: 681,
    iso2: 'wf',
    iso_3166_2: 'WF',
    iso3: 'wlf',
    iso_3166_3: 'WLF',
    timezoneBegin: '+12:00',
    areaCodes: null,
    eng_country_name: 'Wallis and Futuna',
    native_country_name: '',
  },
  {
    country: 'Western Sahara (‫الصحراء الغربية‬‎)',
    country_code: 212,
    iso2: 'eh',
    iso_3166_2: 'EH',
    iso3: 'esh',
    iso_3166_3: 'ESH',
    timezoneBegin: null,
    areaCodes: null,
    eng_country_name: 'Western Sahara',
    native_country_name: '‫الصحراء الغربية‬‎',
  },
  {
    country: 'Yemen (‫اليمن‬‎)',
    country_code: 967,
    iso2: 'ye',
    iso_3166_2: 'YE',
    iso3: 'yem',
    iso_3166_3: 'YEM',
    timezoneBegin: '+03:00',
    areaCodes: null,
    eng_country_name: 'Yemen',
    native_country_name: '‫اليمن‬‎',
  },
  {
    country: 'Zambia',
    country_code: 260,
    iso2: 'zm',
    iso_3166_2: 'ZM',
    iso3: 'zmb',
    iso_3166_3: 'ZMB',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Zambia',
    native_country_name: '',
  },
  {
    country: 'Zimbabwe',
    country_code: 263,
    iso2: 'zw',
    iso_3166_2: 'ZW',
    iso3: 'zwe',
    iso_3166_3: 'ZWE',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Zimbabwe',
    native_country_name: '',
  },
  {
    country: 'Åland Islands',
    country_code: 358,
    iso2: 'ax',
    iso_3166_2: 'AX',
    iso3: 'ala',
    iso_3166_3: 'ALA',
    timezoneBegin: '+02:00',
    areaCodes: null,
    eng_country_name: 'Åland Islands',
    native_country_name: '',
  },
];

const countriesHash = countries.reduce((acc, row) => {
  acc[row.iso2] = row;
  return acc;
}, {});

export function getByIso2(iso2) {
  return countriesHash[iso2] || false;
}

export function getCountries() {
  return countries;
}
