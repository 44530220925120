import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

export const selectableRecipientFieldsObj = {
  id: i18n('ID'),
  employeeId: i18n('Employee ID'),
  name: i18n('Name'),
  email: i18n('Email'),
  addr_country: i18n('Country'),
  department: i18n('Department'),
  title: i18n('Title'),
  company: i18n('Company'),
  company_reg: i18n('Company reg. No'),
  addr_line_1: i18n('Address Line 1'),
  addr_line_2: i18n('Address Line 2'),
  addr_state: i18n('State [address]'),
  addr_city: i18n('City'),
  addr_zip: i18n('ZIP'),
  timezone: i18n('Timezone'),
  country_code: i18n('Phone country code'),
  phone_number: i18n('Mobile number'),
  tags: i18n('Tags'),
  source_data: i18n('Source Data (external id)'),
  opt_out: i18n('Contact opted out'),
  manager_id: i18n('Manager ID'),
  manager_name: i18n('Manager Name'),
  group_names: i18n('Group'), // calculated
} as const;

export type ContactCustomFields =
  | 'data1'
  | 'data2'
  | 'data3'
  | 'data4'
  | 'data5'
  | 'data6'
  | 'data7'
  | 'data8'
  | 'data9'
  | 'data10'
  | 'data11'
  | 'data12'
  | 'data13'
  | 'data14'
  | 'data15'
  | 'data16'
  | 'data17'
  | 'data18'
  | 'data19'
  | 'data20';

export const getKeyToKeyObj = (obj: Record<string, any>): Record<keyof typeof obj, keyof typeof obj> => {
  return Object.keys(obj).reduce((acum, key) => {
    acum[key] = key;
    return acum;
  }, {});
};
