import { z } from 'zod';

/**
 * Generic types
 */
const TaskRecipientSchema = z.object({
  type: z.enum(['contact', 'group']),
  id: z.number(),
});

const UnixTimestampDate = z.coerce
  .date()
  .min(new Date('1970-01-01T00:00:01.000Z'))
  .max(new Date('2038-01-19T03:14:07.000Z'));

const PaginationSchema = z.object({
  page: z.string().default('0').pipe(z.coerce.number().min(0)),
  pageSize: z.string().default('50').pipe(z.coerce.number().min(1)),
});

const NonEmptyString = z.string().min(1);

const PrioritySchema = z.enum(['PRIORITY_1', 'PRIORITY_2', 'PRIORITY_3']);
const FrequencySchema = z.enum(['daily', 'weekly', 'monthly']);

/**
 * Schema of GET /task
 */
export const GetTaskListSchema = z.intersection(
  PaginationSchema,
  z.object({
    orderDir: z.enum(['asc', 'desc']).default('desc'),
    orderBy: z.enum(['title', 'createdAt', 'dueDate', 'priority', 'lastChildTask.dueDate']).default('createdAt'),
  }),
);

export type GetTaskListInput = z.input<typeof GetTaskListSchema>;
export type GetTaskListParsedInput = z.output<typeof GetTaskListSchema>;

/**
 * Schema of GET /viewer/task
 */
export const GetTaskListAsRecipientSchema = z.intersection(
  PaginationSchema,
  z.object({
    status: z.enum(['open', 'completed']).optional(),
    orderDir: z.enum(['asc', 'desc']).default('desc'),
    orderBy: z.enum(['title', 'createdAt', 'dueDate', 'priority', 'lastStatusUpdatedAt']).default('createdAt'),
  }),
);

export type GetTaskListAsRecipientQueryInput = z.input<typeof GetTaskListAsRecipientSchema>;
export type GetTaskListAsRecipientParsedInput = z.output<typeof GetTaskListAsRecipientSchema>;

/**
 * Schema of POST /task
 */
export const CreateTaskSchema = z.object({
  title: NonEmptyString,
  headline: z.string().nullish(),
  description: z.string().nullish(),
  priority: PrioritySchema.nullish(),
  dueDate: z.string().pipe(UnixTimestampDate).nullish(),
  mediaFileIds: z.array(z.number()).optional(),
});

export type CreateTaskInput = z.input<typeof CreateTaskSchema>;
export type CreateTaskParsedInput = z.output<typeof CreateTaskSchema>;

/**
 * Schema of POST /taskTemplate
 */
export const CreateTaskTemplateSchema = z.object({
  title: NonEmptyString,
  headline: z.string().nullish(),
  description: z.string().nullish(),
  priority: PrioritySchema.nullish(),
  dueDate: z.string().pipe(UnixTimestampDate).nullish(),
  frequency: FrequencySchema.nullish(),
  frequencyDelayInSeconds: z.number().nullish(),
  mediaFileIds: z.array(z.number()).optional(),
  type: z.enum(['oneTime', 'recurrent']).optional(),
  completionType: z.enum(['oneToOne', 'oneToMany']).optional(),
  draftRecipients: z.array(TaskRecipientSchema).optional(),
  templateRecipients: z.array(TaskRecipientSchema).optional(),
});

export type CreateTaskTemplateInput = z.input<typeof CreateTaskTemplateSchema>;
export type CreateTaskTemplateParsedInput = z.output<typeof CreateTaskTemplateSchema>;

/**
 * Schema of PATCH /task
 */
export const EditTaskSchema = CreateTaskSchema.partial();
export type EditTaskInput = z.input<typeof EditTaskSchema>;
export type EditTaskParsedInput = z.output<typeof EditTaskSchema>;

/**
 * Schema of PATCH /task
 */
export const EditTaskTemplateSchema = CreateTaskTemplateSchema.partial();
export type EditTaskTemplateInput = z.input<typeof EditTaskTemplateSchema>;
export type EditTaskTemplateParsedInput = z.output<typeof EditTaskTemplateSchema>;

/**
 * Schema of PUT /task/:id/sendingOptions
 */
export const EditTaskSendingOptionsSchema = z.object({
  channelSmsEnabled: z.boolean().optional(),
  channelSmsMsgTemplate: NonEmptyString.nullish(),

  channelEmailEnabled: z.boolean().optional(),
  channelEmailSubject: NonEmptyString.nullish(),
  channelEmailFromName: NonEmptyString.nullish(),
  channelEmailFromAddress: NonEmptyString.nullish(),

  channelPushEnabled: z.boolean().optional(),
  channelPushTitle: NonEmptyString.nullish(),
  channelPushBody: NonEmptyString.nullish(),

  deliveryPreference: z.enum(['all', 'sms', 'email', 'push']).array().default(['all']).optional(),
});

export type EditTaskSendingOptionsInput = z.input<typeof EditTaskSendingOptionsSchema>;
export type EditTaskSendingOptionsParsedInput = z.output<typeof EditTaskSendingOptionsSchema>;

/**
 * Schema of PUT /taskTemplate/:id/sendingOptions
 */
export const EditTaskTemplateSendingOptionsSchema = EditTaskSendingOptionsSchema;
export type EditTaskTemplateSendingOptionsInput = z.input<typeof EditTaskTemplateSendingOptionsSchema>;
export type EditTaskTemplateSendingOptionsParsedInput = z.output<typeof EditTaskTemplateSendingOptionsSchema>;

/**
 * Schema of POST /task/:id/send
 */
export const QueueDispatchSchema = z.object({
  recipients: z.array(TaskRecipientSchema),
});

export type QueueDispatchInput = z.input<typeof QueueDispatchSchema>;
export type QueueDispatchParsedInput = z.output<typeof QueueDispatchSchema>;

/**
 * Schema of POST /task/:id/schedule
 */
export const ScheduleDispatchSchema = z.object({
  recipients: z.array(TaskRecipientSchema),
});

export type ScheduleDispatchInput = z.input<typeof ScheduleDispatchSchema>;
export type ScheduleDispatchParsedInput = z.output<typeof ScheduleDispatchSchema>;

/**
 * Schema of genericdispatchd lambda sendTask
 */
export const QueueSendTaskGenericDispatchdSchema = z.object({
  taskId: z.number(),
  contactIds: z.array(z.number()),
  contactListIds: z.array(z.number()),
});

export type QueueSendTaskGenericDispatchdInput = z.input<typeof QueueSendTaskGenericDispatchdSchema>;
export type QueueSendTaskGenericDispatchdParsedInput = z.output<typeof QueueSendTaskGenericDispatchdSchema>;

/**
 * Schema of genericdispatchd lambda sendTaskCycle
 */
export const ScheduleTaskCycleGenericDispatchdSchema = z.object({
  taskTemplateId: z.number(),
});

export type ScheduleTaskCycleGenericDispatchdInput = z.input<typeof ScheduleTaskCycleGenericDispatchdSchema>;
export type ScheduleTaskCycleGenericDispatchdParsedInput = z.output<typeof ScheduleTaskCycleGenericDispatchdSchema>;

/**
 * Schema of POST /task/:id/removeRecipients
 */
export const RemoveRecipientsSchema = z.object({
  recipients: z.array(TaskRecipientSchema),
});

export type RemoveRecipientsInput = z.input<typeof RemoveRecipientsSchema>;

/**
 * Schema of GET /task/:id/analytics/recipients
 */
const BaseTaskAnalyticsRecipientsSchema = z.object({
  orderDir: z.enum(['asc', 'desc']).default('asc'),
  orderBy: z.enum(['name', 'status', 'publishedAt', 'completedAt', 'department']).default('name'),
  search: z.string().optional(),
});

export const GetTaskAnalyticsRecipientsWithPaginationSchema = z.intersection(
  PaginationSchema,
  BaseTaskAnalyticsRecipientsSchema,
);

export const GetTaskAnalyticsRecipientsSchema = z.intersection(
  PaginationSchema.partial(),
  BaseTaskAnalyticsRecipientsSchema,
);

export type GetTaskAnalyticsRecipientsWithPaginationInput = z.input<
  typeof GetTaskAnalyticsRecipientsWithPaginationSchema
>;
export type GetTaskAnalyticsRecipientsWithPaginationParsedInput = z.output<
  typeof GetTaskAnalyticsRecipientsWithPaginationSchema
>;
export type GetTaskAnalyticsRecipientsParsedInput = z.output<typeof GetTaskAnalyticsRecipientsSchema>;

/**
 * Schema of GET /task/:id/analytics/groups
 */

const BaseTaskAnalyticsGroupRecipientsSchema = z.object({
  orderDir: z.enum(['asc', 'desc']).default('asc'),
  orderBy: z.enum(['name', 'avgCompletionRate', 'publishedAt', 'employees']).default('name'),
  search: z.string().optional(),
});
export const GetTaskAnalyticsGroupRecipientsWithPaginationSchema = z.intersection(
  PaginationSchema,
  BaseTaskAnalyticsGroupRecipientsSchema,
);
export const GetTaskAnalyticsGroupRecipientsSchema = z.intersection(
  PaginationSchema.partial(),
  BaseTaskAnalyticsGroupRecipientsSchema,
);

export type GetTaskAnalyticsGroupsWithPaginationSchemaInput = z.input<
  typeof GetTaskAnalyticsGroupRecipientsWithPaginationSchema
>;
export type GetTaskAnalyticsGroupsWithPaginationSchemaParsedInput = z.output<
  typeof GetTaskAnalyticsGroupRecipientsWithPaginationSchema
>;
export type GetTaskAnalyticsGroupRecipientsParsedInput = z.output<typeof GetTaskAnalyticsGroupRecipientsSchema>;

/**
 * Schema of GET /task/:id/analytics/departments
 */
const BaseGetTaskAnalyticsDepartmentsSchema = z.object({
  orderDir: z.enum(['asc', 'desc']).default('asc'),
  orderBy: z.enum(['name', 'avgCompletionRate', 'employees']).default('name'),
  search: z.string().optional(),
});

export const GetTaskAnalyticsDepartmentsWithPaginationSchema = z.intersection(
  PaginationSchema,
  BaseGetTaskAnalyticsDepartmentsSchema,
);

export const GetTaskAnalyticsDepartmentsSchema = z.intersection(
  PaginationSchema.partial(),
  BaseGetTaskAnalyticsDepartmentsSchema,
);

export type GetTaskAnalyticsDepartmentsWithPaginationSchemaInput = z.input<
  typeof GetTaskAnalyticsDepartmentsWithPaginationSchema
>;
export type GetTaskAnalyticsDepartmentsSchemaWithPaginationParsedInput = z.output<
  typeof GetTaskAnalyticsDepartmentsWithPaginationSchema
>;

export type GetTaskAnalyticsDepartmentsSchemaParsedInput = z.output<typeof GetTaskAnalyticsDepartmentsSchema>;
