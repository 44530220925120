import { HttpErrorResponse } from '@angular/common/http';
import { MsgModuleUpload } from '@ao/data-models';
import { createAction, props } from '@ngrx/store';
import { MediaItem } from '@ao/shared-data-models';

export const UploadFile = createAction(
  '[Message/Module Upload] Upload File',
  props<{ module: MsgModuleUpload; file: File }>(),
);
export const UploadFileProgress = createAction(
  '[Message/Module Upload] Upload File Progress',
  props<{ module: MsgModuleUpload; data: number; file: File }>(),
);
export const UploadFileSuccess = createAction(
  '[Message/Module Upload] Upload File Success',
  props<{ module: MsgModuleUpload; data: MediaItem; file: File; hasDelayedProcessing?: boolean }>(),
);
export const UploadFileError = createAction(
  '[Message/Module Upload] Upload File Error',
  props<{ module: MsgModuleUpload; data: HttpErrorResponse }>(),
);

export const UploadRemoveFile = createAction(
  '[Message/Module Upload] Remove File',
  props<{ module: MsgModuleUpload; fileId: number }>(),
);
export const UploadRemoveFileSuccess = createAction(
  '[Message/Module Upload] Remove File Success',
  props<{ module: MsgModuleUpload }>(),
);
export const UploadRemoveFileError = createAction(
  '[Message/Module Upload] Remove File Error',
  props<{ module: MsgModuleUpload; data: HttpErrorResponse }>(),
);

export const UploadChangeTitle = createAction(
  '[Message/Module Upload] File Update Title',
  props<{ module: MsgModuleUpload; fileId: number; title: string }>(),
);
export const UploadChangeTitleSuccess = createAction(
  '[Message/Module Upload] File Update Title Success',
  props<{ module: MsgModuleUpload }>(),
);
export const UploadChangeTitleError = createAction(
  '[Message/Module Upload] File Update Title Error',
  props<{ module: MsgModuleUpload; data: HttpErrorResponse }>(),
);

export const PollUploadFile = createAction(
  '[Message/Module Upload] Poll uploaded file processing',
  props<{ module: MsgModuleUpload; mediaItem: MediaItem }>(),
);
export const PollUploadFileSuccess = createAction(
  '[Message/Module Upload] Poll uploaded file processing Success',
  props<{ module: MsgModuleUpload; mediaItem: MediaItem }>(),
);
export const PollUploadFileFail = createAction(
  '[Message/Module Upload] Poll uploaded file processing Fail',
  props<{ module: MsgModuleUpload; fileId: number }>(),
);
export const PollUploadFileUpdate = createAction(
  '[Message/Module Upload] Poll uploaded file processing update',
  props<{ module: MsgModuleUpload; fileId: number; progress: number }>(),
);
