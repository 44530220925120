import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules,
  ROUTER_CONFIGURATION,
  RouterModule,
  Routes,
  UrlSegment,
} from '@angular/router';
import { AuthErrorComponent, ErrorComponent, LoginComponent, RedirectComponent } from '@ao/viewer-core';
import { ViewerTaskManagementGuard } from '@ao/viewer-task-management';
import { ViewerLoginGuard } from '@ao/viewer-core';

export function routerConfigFactory(): ExtraOptions {
  return {
    preloadingStrategy: PreloadAllModules,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'disabled',
    useHash: !window?.history?.pushState,
  };
}

export function originKeycodeMatcher(segments: UrlSegment[]) {
  const [origin, keycode] = segments;
  if (origin && origin.path === 'verify') {
    return {
      consumed: [origin],
      posParams: { keycode: origin },
    };
  }
  if (origin && origin.path === 'analytics') {
    return null;
  }
  if (origin && origin.path === 'task') {
    return null;
  }
  if (origin && keycode && /^[meclqp]$/.test(origin.path) && /^[a-z0-9]+$/i.test(keycode.path)) {
    return {
      consumed: [origin, keycode],
      posParams: { origin, keycode },
    };
  } else {
    return null;
  }
}

export function analyticsOriginMatcher(segments: UrlSegment[]) {
  const [origin, keycode] = segments;

  if (origin && origin.path === 'analytics') {
    return {
      consumed: [origin, keycode],
      posParams: { origin, keycode },
    };
  } else {
    return null;
  }
}

const routes: Routes = [
  {
    matcher: originKeycodeMatcher,
    children: [
      {
        path: '',
        loadChildren: () => import('@ao/viewer/message').then((m) => m.ViewerMessageModule),
      },
    ],
  },
  {
    matcher: analyticsOriginMatcher,
    children: [
      {
        path: '',
        loadChildren: () => import('@ao/viewer/analytics').then((m) => m.ViewerAnalyticsModule),
      },
    ],
  },
  {
    path: 'task',
    canActivate: [ViewerTaskManagementGuard],
    loadChildren: () => import('@ao/viewer-task-management').then((m) => m.ViewerTaskManagementModule),
    data: { featureFlag: 'task_management' },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [ViewerLoginGuard],

    children: [{ path: 'test', component: LoginComponent, data: { forceSso: true } }],
  },
  { path: 'redirect', component: RedirectComponent },
  { path: 'auth-error', component: AuthErrorComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { canceledNavigationResolution: 'computed' })],
  providers: [{ provide: ROUTER_CONFIGURATION, useFactory: routerConfigFactory }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
