import { Injectable } from '@angular/core';
import { ContactInfoFields, MessageList, MessageListRequestParams } from '@ao/data-models';
import { Store } from '@ngrx/store';
import * as profileActions from './+state/profile-store.actions';
import * as profileSelectors from './+state/profile-store.selectors';

@Injectable({ providedIn: 'root' })
export class ProfileFacade {
  profileEntities$ = this.store.select(profileSelectors.selectProfileEntities);
  currentProfile$ = this.store.select(profileSelectors.getCurrentProfile);
  currentProfileId$ = this.store.select(profileSelectors.getCurrentProfileId);
  currentProfileLoading$ = this.store.select(profileSelectors.getCurrentProfileLoading);
  currentProfileMessageList$ = this.store.select(profileSelectors.getCurrentProfileMessageList);
  subordinatesPage$ = this.store.select(profileSelectors.selectSubordinatesPage);

  constructor(private store: Store) {}

  loadProfile({ profileId }: { profileId: number }) {
    return this.store.dispatch(profileActions.LoadProfile({ profileId }));
  }
  loadInsightData({ insightType }: { insightType: string }) {
    return this.store.dispatch(profileActions.LoadInsightData({ insightType }));
  }
  loadSubordinates({
    profileId,
    searchQuery,
    pageSize,
    pageNumber,
  }: {
    profileId: number;
    searchQuery: string;
    pageSize?: number;
    pageNumber?: number;
  }) {
    return this.store.dispatch(profileActions.LoadSubordinates({ profileId, searchQuery, pageSize, pageNumber }));
  }
  sendHomepage({ profileId }: { profileId: number }) {
    return this.store.dispatch(profileActions.SendHomepage({ profileId }));
  }
  updateContact({ targetId, newValue, oldValue }: { targetId: number; newValue: any; oldValue?: any }) {
    return this.store.dispatch(profileActions.UpdateProfileSettings({ targetId, newValue, oldValue }));
  }
  profileUpdateContactInfo({
    id,
    newValue,
    sendHomePage,
    avatar,
  }: {
    id: number;
    newValue: ContactInfoFields;
    sendHomePage?: boolean;
    avatar?: Blob;
  }) {
    return this.store.dispatch(profileActions.ProfileUpdateContactInfo({ id, newValue, sendHomePage, avatar }));
  }
  uploadContactAvatar({ targetId, newValue, oldValue }: { targetId: number; newValue: any; oldValue: any }) {
    return this.store.dispatch(profileActions.UploadContactAvatar({ targetId, newValue, oldValue }));
  }
  updateProfileInsights({ contactId }: { contactId: number }) {
    return this.store.dispatch(profileActions.UpdateProfileInsights({ contactId }));
  }
  loadInsightHistory({ insightType, before }: { insightType: string; before?: number }) {
    return this.store.dispatch(profileActions.LoadInsightHistory({ insightType, before }));
  }
  clearProfileMessageListMessages({ profileId }: { profileId: number }) {
    return this.store.dispatch(profileActions.ClearProfileMessageListMessages({ profileId }));
  }
  loadProfileMessageListData({
    profileId,
    list,
    params,
    appendMessages,
  }: {
    profileId: number;
    list: MessageList;
    params?: MessageListRequestParams;
    appendMessages?: boolean;
  }) {
    return this.store.dispatch(profileActions.LoadProfileMessageListData({ profileId, list, params, appendMessages }));
  }
}
