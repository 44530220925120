import { LOADING_STATE, LOADING_STATE_PAGINATED, MessageList, Profile } from '@ao/data-models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as profileActions from './profile-store.actions';

export const PROFILE_FEATURE_KEY = 'profile';
export const profileAdapter: EntityAdapter<Profile> = createEntityAdapter();
export interface ProfileState extends EntityState<Profile> {
  loadingStateById: Record<number, LOADING_STATE>;
}

export interface ProfilePartialState {
  readonly [PROFILE_FEATURE_KEY]: ProfileState;
}

export const initialState: ProfileState = profileAdapter.getInitialState({
  loadingStateById: {},
});

function updateMessageList(state: ProfileState, profileId: number, messagelist: MessageList): ProfileState {
  const profile = state.entities[profileId];
  return profile
    ? profileAdapter.updateOne(
        {
          id: profileId,
          changes: {
            data: {
              ...profile.data,
              messagelist: {
                ...(profile.data.messagelist || {}),
                ...messagelist,
              },
            },
          },
        },
        state,
      )
    : state;
}

const profileReducer = createReducer(
  initialState,
  on(profileActions.LoadProfile, (state: ProfileState, { profileId }): ProfileState => {
    return {
      ...state,
      loadingStateById: {
        ...state.loadingStateById,
        [profileId]: LOADING_STATE.loading,
      },
    };
  }),
  on(profileActions.LoadProfileSuccess, (state: ProfileState, { profile, profileId }): ProfileState => {
    return {
      ...state,
      ...profileAdapter.upsertOne(profile, state),
      loadingStateById: {
        ...state.loadingStateById,
        [profileId]: LOADING_STATE.loaded,
      },
    };
  }),
  on(profileActions.LoadProfileFail, (state: ProfileState, { profileId }): ProfileState => {
    return {
      ...state,
      loadingStateById: {
        ...state.loadingStateById,
        [profileId]: LOADING_STATE.error,
      },
    };
  }),
  on(profileActions.LoadSubordinates, (state: ProfileState, { profileId, pageNumber }): ProfileState => {
    return profileAdapter.updateOne(
      {
        id: profileId,
        changes: {
          subordinates: {
            ...state.entities[profileId].subordinates,
            loadingState: pageNumber === 1 ? LOADING_STATE_PAGINATED.loading : LOADING_STATE_PAGINATED.loadingMore,
          },
        },
      },
      state,
    );
  }),
  on(profileActions.LoadSubordinatesSuccess, (state: ProfileState, { data, profileId }): ProfileState => {
    const pageContent =
      data.currentPageNumber === 1
        ? data.pageContent
        : [...state.entities[profileId].subordinates.pageContent, ...data.pageContent];

    const initialTotalCount = state.entities[profileId].subordinates.initialTotalCount
      ? state.entities[profileId].subordinates.initialTotalCount
      : data.totalCount;

    return profileAdapter.updateOne(
      {
        id: profileId,
        changes: {
          subordinates: {
            ...data,
            pageContent,
            initialTotalCount,
            loadingState:
              data.currentPageNumber === 1 ? LOADING_STATE_PAGINATED.loaded : LOADING_STATE_PAGINATED.loadedMore,
          },
        },
      },
      state,
    );
  }),
  on(profileActions.LoadSubordinatesFail, (state: ProfileState, { profileId }): ProfileState => {
    return profileAdapter.updateOne(
      {
        id: profileId,
        changes: {
          subordinates: {
            ...state.entities[profileId]?.subordinates,
            loadingState: !state.entities[profileId]?.subordinates?.pageContent
              ? LOADING_STATE_PAGINATED.error
              : LOADING_STATE_PAGINATED.errorMore,
          },
        },
      },
      state,
    );
  }),
  on(profileActions.LoadInsightDataSuccess, (state: ProfileState, { data, profileId }): ProfileState => {
    const profile = state.entities?.[profileId];
    const profileInsightsValues = profile?.data?.insightsValues || [];
    // Add 'empty state' chart data to rating
    const emptyDetails = {
      type: 'rating',
      categories: {},
      dates: {},
      sendouts: [],
      overall: {},
      highcharts: {
        categoryOrder: ['Behavior', 'Performance'],
      },
    };
    const newData = {
      ...data,
      details: data.title === 'rating' && Object.keys(data.details || {}).length === 0 ? emptyDetails : data.details,
    };

    // If there is already an instance of the insightsValue, replace it, otherwise append it.
    const insightsValues = profileInsightsValues.map((val) => {
      return val.type === newData.type ? newData : val;
    });
    return profileAdapter.updateOne(
      {
        id: profileId,
        changes: {
          data: {
            ...profile.data,
            insightsValues,
          },
        },
      },
      state,
    );
  }),
  on(
    profileActions.LoadInsightHistorySuccess,
    (state: ProfileState, { data, profileId }): ProfileState => {
      const profile = state.entities?.[profileId];
      const profileInsightsValues = profile?.data?.insightsValues || [];
      const insightsValues = profileInsightsValues.map((val) => {
        if (val.title === data.title && val.type === data.type) {
          return {
            ...val,
            details: {
              ...val.details,
              sendouts: [...(val.details?.sendouts || []), ...data.details.sendouts],
            },
          };
        }
        return val;
      });

      return profileAdapter.updateOne(
        {
          id: profileId,
          changes: {
            data: {
              ...profile.data,
              insightsValues,
            },
          },
        },
        state,
      );
    },

    // apply changes straight away since the toggle component changes values instantly/locally and we would not be able to pick up the revert changes on fail
  ),
  on(
    profileActions.UpdateProfileSettings,
    profileActions.UpdateEmitterProfileContact,
    (state: ProfileState, { targetId, newValue }): ProfileState => {
      const profile = state.entities?.[targetId];

      // if contact data is used as insights values, we should update those as well.
      const profileInsightsValues = profile?.data?.insightsValues || [];
      const newInsightsValues = profileInsightsValues.map((val) => {
        return {
          ...val,
          value: newValue[val.key] ? newValue[val.key] : val.value,
        };
      });

      return profileAdapter.updateOne(
        {
          id: targetId,
          changes: {
            data: {
              ...profile.data,
              insightsValues: newInsightsValues,
            },
            ...newValue,
          },
        },
        state,
      );
    },
  ),
  on(profileActions.UpdateProfileSettingsFail, (state: ProfileState, { targetId, oldValue, error }): ProfileState => {
    return profileAdapter.updateOne(
      {
        id: targetId,
        changes: oldValue,
      },
      state,
    );
  }),
  on(profileActions.UploadContactAvatar, (state: ProfileState, { targetId, newValue }): ProfileState => {
    return profileAdapter.updateOne(
      {
        id: targetId,
        changes: {
          avatarUploading: true,
        },
      },
      state,
    );
  }),
  on(profileActions.UploadContactAvatarSuccess, (state: ProfileState, { targetId, newValue }): ProfileState => {
    return profileAdapter.updateOne(
      {
        id: targetId,
        changes: {
          avatarImages: newValue.data[0].avatarImages,
          avatarUploading: false,
        },
      },
      state,
    );
  }),
  on(profileActions.SendHomepage, (state: ProfileState, { profileId }): ProfileState => {
    return profileAdapter.updateOne(
      {
        id: profileId,
        changes: {
          sendHomepage: 'loading',
        },
      },
      state,
    );
  }),
  on(profileActions.SendHomepageSuccess, (state: ProfileState, { profileId }): ProfileState => {
    return profileAdapter.updateOne(
      {
        id: profileId,
        changes: {
          sendHomepage: 'success',
        },
      },
      state,
    );
  }),
  on(profileActions.SendHomepageFail, (state: ProfileState, { profileId }): ProfileState => {
    return profileAdapter.updateOne(
      {
        id: profileId,
        changes: {
          sendHomepage: 'failure',
        },
      },
      state,
    );
  }),
  on(profileActions.SendHomepageReset, (state: ProfileState, { profileId }): ProfileState => {
    return profileAdapter.updateOne(
      {
        id: profileId,
        changes: {
          sendHomepage: 'reset',
        },
      },
      state,
    );
  }),
  on(profileActions.ProfileUpdateContactInfo, (state: ProfileState, { id, avatar }): ProfileState => {
    return profileAdapter.updateOne(
      {
        id,
        changes: {
          ...(avatar ? { avatarUploading: true } : {}),
          updateInProgress: true,
        },
      },
      state,
    );
  }),
  on(
    profileActions.ProfileUpdateContactInfoSuccess,
    (state: ProfileState, { targetId, newValue, avatarImages }): ProfileState => {
      const profile = state.entities?.[targetId];

      // if contact data is used as insights values, we should update those as well.
      const profileInsightsValues = profile?.data?.insightsValues || [];
      const newInsightsValues = profileInsightsValues.map((val) => {
        return {
          ...val,
          value: newValue[val.key] ? newValue[val.key] : val.value,
        };
      });

      const allowContactInfo = newValue.allow_contact_info;

      return profileAdapter.updateOne(
        {
          id: targetId,
          changes: {
            data: {
              ...profile.data,
              insightsValues: newInsightsValues,
            },
            ...newValue,
            ...(avatarImages ? { avatarImages, avatarUploading: false } : {}),
            ...(typeof newValue.allow_contact_info !== 'undefined' ? { allowContactInfo } : {}),
            updateInProgress: false,
          },
        },
        state,
      );
    },
  ),
  on(
    profileActions.LoadProfileMessageListData,
    (state: ProfileState, { profileId, list, appendMessages, params = {} }): ProfileState => {
      const { page, searchTerm, filter, subtype } = params;

      return updateMessageList(state, profileId, {
        ...list,
        messages: appendMessages ? [...(list.messages || [])] : [],
        key: subtype,
        filter,
        page,
        searchTerm,
        loading: true,
        loaded: false,
        loadError: null,
      });
    },
  ),
  on(
    profileActions.LoadProfileMessageListDataSuccess,
    (state: ProfileState, { profileId, list, data, params, appendMessages }): ProfileState => {
      const { page, searchTerm, filter, subtype } = params;
      const { options, messages } = data;

      return updateMessageList(state, profileId, {
        ...list,
        key: subtype,
        filter,
        page,
        searchTerm,
        messages: appendMessages ? [...(list.messages || []), ...messages] : messages,
        totalCount: options.totalCount,
        loaded: true,
        loading: false,
        loadError: null,
      });
    },
  ),
  on(profileActions.LoadProfileMessageListDataFail, (state: ProfileState, { profileId, error }): ProfileState => {
    return updateMessageList(state, profileId, {
      loaded: false,
      loading: false,
      loadError: error,
    });
  }),
  on(profileActions.ClearProfileMessageListMessages, (state: ProfileState, { profileId }): ProfileState => {
    return updateMessageList(state, profileId, {
      messages: [],
      loaded: false,
      loading: false,
      loadError: null,
    });
  }),
);

export function reducer(state: ProfileState | undefined, action: Action) {
  return profileReducer(state, action);
}
