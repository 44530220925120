import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { viewerProfileKeyMapper } from '../../common/viewer-profile-key-mapper';

@Component({
  selector: 'ao-insights-title',
  templateUrl: './insights-title.component.html',
  styleUrls: ['./insights-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsTitleComponent {
  _keyTitle = '';

  @Input() type: string;
  @Input() title: string;
  @Input() set key(key: string) {
    this._keyTitle = viewerProfileKeyMapper[key];
  }
}
