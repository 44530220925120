import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WINDOW } from '../services/tokens';

@Injectable()
export class AoIdHeaderInterceptor implements HttpInterceptor {
  constructor(@Inject(WINDOW) private window: Window) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const aoId = this.window['aoId'];
    if (req.url.includes('/api/v1')) {
      const modifiedReq = req.clone({
        headers: req.headers.set('aoid', aoId),
      });
      return next.handle(modifiedReq);
    }
    return next.handle(req);
  }
}

export const AoIdHeaderInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AoIdHeaderInterceptor,
  multi: true,
};
