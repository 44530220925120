import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, Self } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ACTIMO_COLORS, AvatarSize } from '@ao/data-models';
import { ImageSource } from '@ao/shared-data-models';
import { color as c, getImageSourceMinSize } from '@ao/utilities';

const availableSizes = {
  tiny: '24px',
  smaller: '26px',
  small: '32px',
  table: '40px',
  medium: '48px',
  big: '64px',
  biggest: '80px',
  huge: '96px',
};

@Component({
  selector: 'ao-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  providers: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnChanges {
  @Input() initials = '';
  @Input() name: string;
  @Input() size: AvatarSize = 'big';

  _bgColor = ACTIMO_COLORS.ink.lighter;
  @Input() set bgColor(value: string) {
    this._bgColor = c(value);
  }
  get bgColor() {
    return this._bgColor;
  }
  _txtColor = '#FFFFFF';
  @Input() set txtColor(value: string) {
    this._txtColor = c(value);
  }
  get txtColor() {
    return this._bgColor;
  }
  @Input() loading?: boolean;
  @Input() skeleton?: boolean;
  @Input() image: SafeUrl | string;
  @Input() fade = true;

  get initialImageUrl() {
    if (this.images?.length > 0) {
      return getImageSourceMinSize(this.images);
    }
    return this.image;
  }

  @Input() set images(value: ImageSource[]) {
    if (value) {
      this._images = value;
      this.sizes = availableSizes[this.size];
    }
  }

  get images(): ImageSource[] {
    return this._images;
  }

  @Output() hasError = new EventEmitter<Event>();

  sizes: string;

  private _loaded = false;
  private _images: ImageSource[] = null;

  get displayInitials(): string {
    return this.initials ? this.initials : this.getInitials();
  }

  constructor(@Self() private ngClass: NgClass) {}

  ngOnChanges(): void {
    this.checkClasses();
  }

  onImageLoad() {
    this._loaded = true;
    this.checkClasses();
  }

  getInitials(): string {
    if (!this.name || this.name === 'Unnamed user') {
      return '';
    }

    const regex = /[^\p{L}\s]/gu;
    const cleanedFirstName = this.name?.replace(regex, '');

    const res = cleanedFirstName
      .split(' ')
      .map((word: string) => word[0])
      .join('')
      .substring(0, 2);

    return res;
  }

  serveFallbackImage(err: Event) {
    this.image = null;
    this.images = [];

    this.hasError.emit(err);
  }

  private checkClasses() {
    this.ngClass.ngClass = [
      'ao-avatar',
      `ao-avatar--size-${this.size}`,
      ...(this._loaded || !this.fade ? [`ao-avatar--loaded`] : []),
    ];
    this.ngClass.ngDoCheck();
  }
}
