import { inject, Injectable } from '@angular/core';
import { NativeBridgeService, WINDOW } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { NotificationsFacade } from '../notifications-store.facade';
import { NotificationsService } from '../notifications-store.service';
import * as notificationsActions from './notifications-store.actions';

@Injectable()
export class NotificationsEffects {
  private nativeBridge = inject(NativeBridgeService);
  private viewerCoreFacade = inject(ViewerCoreFacade);
  private notificationsFacade = inject(NotificationsFacade);
  private notificationsService = inject(NotificationsService);
  private actions$ = inject(Actions);
  private window = inject(WINDOW);

  loadNotificationHasUnread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.LoadNotificationHasUnread),
      concatLatestFrom(() => [this.viewerCoreFacade.contactId$, this.viewerCoreFacade.lastSeenNotifications$]),
      filter(([_, __, lastSeenNotifications]) => !!lastSeenNotifications),
      mergeMap(([_, contactId, lastSeenNotifications]) => {
        return this.notificationsService
          .loadNotificationUnreadCount(contactId, lastSeenNotifications)
          .pipe(
            map((statsUnreadCount) =>
              notificationsActions.LoadNotificationHasUnreadSuccess({ unreadCount: statsUnreadCount }),
            ),
          );
      }),
    );
  });

  LoadNotificationHasUnreadSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.LoadNotificationHasUnreadSuccess),
      map(({ unreadCount }) => {
        return notificationsActions.SetNotificationUnreadCount({ unreadCount });
      }),
    );
  });

  loadNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.LoadNotifications),
      concatLatestFrom(() => [this.notificationsFacade.filterUnread$]),
      map(([{ params, filterUnread, shouldLoadFresh }, previousFilterUnread]) => {
        return filterUnread === previousFilterUnread && !shouldLoadFresh
          ? notificationsActions.LoadMoreNotifications({ params, filterUnread })
          : notificationsActions.LoadFreshNotifications({ params, filterUnread });
      }),
    );
  });

  loadMoreNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.LoadMoreNotifications),
      concatLatestFrom(() => [this.viewerCoreFacade.contactId$, this.viewerCoreFacade.lastSeenNotifications$]),
      mergeMap(([{ params, filterUnread }, contactId, lastSeenNotifications]) => {
        const urlParams = {
          ...params,
          ...(filterUnread ? { read: false } : {}),
        };
        return this.notificationsService.loadNotifications(contactId, urlParams, lastSeenNotifications).pipe(
          map((notifications) => notificationsActions.LoadMoreNotificationsSuccess({ notifications, filterUnread })),
          catchError((error) => of(notificationsActions.LoadMoreNotificationsFail({ error }))),
        );
      }),
    );
  });

  loadFreshNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.LoadFreshNotifications),
      concatLatestFrom(() => [this.viewerCoreFacade.contactId$]),
      mergeMap(([{ params, filterUnread }, contactId]) => {
        const urlParams = {
          ...params,
          ...{ offset: 0 },
          ...(filterUnread ? { read: false } : {}),
        };
        const lastSeenNotifications = new Date();
        return this.notificationsService.loadNotifications(contactId, urlParams, lastSeenNotifications).pipe(
          map((notifications) =>
            notificationsActions.LoadFreshNotificationsSuccess({ notifications, filterUnread, lastSeenNotifications }),
          ),
          catchError((error) => of(notificationsActions.LoadFreshNotificationsFail({ error }))),
        );
      }),
    );
  });

  loadFreshNotificationsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.LoadFreshNotificationsSuccess),
      concatLatestFrom(() => this.viewerCoreFacade.contactStatus$),
      map(([{ lastSeenNotifications }, contactStatus]) => {
        this.viewerCoreFacade.updateContactStatus({
          ...contactStatus,
          lastSeenNotifications,
        });

        return notificationsActions.SetNotificationUnreadCount({ unreadCount: 0 });
      }),
    );
  });

  markNotificationAsRead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.MarkNotificationAsRead),
      concatLatestFrom(() => this.viewerCoreFacade.contactId$),
      mergeMap(([{ notification }, contactId]) => {
        return this.notificationsService.markNotification(contactId, notification, true).pipe(
          map(() => notificationsActions.MarkNotificationAsReadSuccess({ notification, isRead: true })),
          catchError((error) => of(notificationsActions.MarkNotificationAsReadFail({ error }))),
        );
      }),
    );
  });

  markNotificationAsUnread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.MarkNotificationAsUnread),
      concatLatestFrom(() => this.viewerCoreFacade.contactId$),
      mergeMap(([{ notification }, contactId]) => {
        return this.notificationsService.markNotification(contactId, notification, false).pipe(
          map(() => notificationsActions.MarkNotificationAsUnreadSuccess({ notification, isRead: false })),
          catchError((error) => of(notificationsActions.MarkNotificationAsUnreadFail({ error }))),
        );
      }),
    );
  });

  markAllNotificationsAsRead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.MarkAllNotificationsAsRead),
      concatLatestFrom(() => this.viewerCoreFacade.contactId$),
      mergeMap(([_, contactId]) => {
        return this.notificationsService.markAllNotificationsAsRead(contactId).pipe(
          map(() => notificationsActions.MarkAllNotificationsAsReadSuccess()),
          catchError((error) => of(notificationsActions.MarkNotificationAsReadFail({ error }))),
        );
      }),
    );
  });

  loadUserNotificationSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.LoadUserNotificationSettings),
      concatLatestFrom(() => [this.viewerCoreFacade.contactId$, this.viewerCoreFacade.contactDeviceTypeGroup$]),
      mergeMap(([_, contactId, contactDeviceTypeGroup]) => {
        return this.notificationsService.loadUserNotificationSettings(contactId, contactDeviceTypeGroup).pipe(
          map((settingsGroups) => notificationsActions.LoadUserNotificationSettingsSuccess({ settingsGroups })),
          catchError((error) => of(notificationsActions.LoadUserNotificationSettingsFail({ error }))),
        );
      }),
    );
  });

  toggleNotificationSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.ToggleNotificationSetting),
      concatLatestFrom(() => [this.viewerCoreFacade.contactId$, this.viewerCoreFacade.contactDeviceTypeGroup$]),
      mergeMap(([{ settingType, channelSettingType, newValue }, contactId, contactDeviceTypeGroup]) => {
        return this.notificationsService
          .toggleNotificationSetting(settingType, channelSettingType, newValue, contactId, contactDeviceTypeGroup)
          .pipe(
            map(() =>
              notificationsActions.ToggleNotificationSettingSuccess({ settingType, channelSettingType, newValue }),
            ),
            catchError((error) => of(notificationsActions.ToggleNotificationSettingFail({ error }))),
          );
      }),
    );
  });

  toggleGroupNotificationSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationsActions.ToggleGroupNotificationSetting),
      concatLatestFrom(() => [this.viewerCoreFacade.contactId$, this.viewerCoreFacade.contactDeviceTypeGroup$]),
      mergeMap(([{ groupId, newValue, source }, contactId, contactDeviceTypeGroup]) => {
        return this.notificationsService
          .toggleGroupNotificationSetting(groupId, newValue, contactId, contactDeviceTypeGroup, source)
          .pipe(
            map(() => notificationsActions.ToggleGroupNotificationSettingSuccess({ groupId })),
            catchError((error) => of(notificationsActions.ToggleGroupNotificationSettingFail({ error }))),
          );
      }),
    );
  });

  setNativeUnreadNotificationCount = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.SetNotificationUnreadCount),
      map(({ unreadCount }) => {
        const nativeSetCountSuccess = this.nativeBridge.setNativeUnreadNotificationCount(unreadCount);
        if (nativeSetCountSuccess) {
          return notificationsActions.SetNativeNotificationUnreadCountSuccess();
        }
        return notificationsActions.SetNativeNotificationUnreadCountFail({ error: 'App not running in native' });
      }),
    ),
  );
}
