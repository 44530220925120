<cdk-virtual-scroll-viewport
  minBufferPx="360"
  maxBufferPx="720"
  class="ao-notification-list__notifications"
  [itemSize]="100"
  orientation="vertical"
>
  <ao-suspense [state]="skeletonState">
    <ng-template #aoSuspenseSkeleton>
      <ng-container *ngFor="let item of [0, 1, 2]">
        <ao-notification-list-skeleton></ao-notification-list-skeleton>
      </ng-container>
    </ng-template>
    <ng-template #aoSuspenseContent>
      <div *cdkVirtualFor="let notification of notifications; trackBy: trackById; let index = index">
        <ao-notification-list-item
          [notification]="notification"
          [groupsUserSettings]="groupsUserSettings"
          (menuActionSelected)="onNotificationActionClick($event, notification)"
          (notificationClicked)="onNotificationClick(notification)"
        ></ao-notification-list-item>
        <div class="ao-notification-list__notifications--separator"></div>
      </div>
    </ng-template>
  </ao-suspense>
</cdk-virtual-scroll-viewport>
