import {
  EmitterChannel,
  Language,
  MessageError,
  MessageNew,
  MsgModule,
  Sidebar,
  Theme,
  TRANSLATION_STATUS,
} from '@ao/data-models';
import { DEFAULT_THEME, DEFAULT_THEME_ID } from '@ao/shared-constants';
import { getModuleRef } from '@ao/utilities';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const APP_FEATURE_KEY = 'viewer-app';

export interface AppState {
  error: MessageError;
  messages: EntityState<MessageNew>;
  modules: EntityState<MsgModule>;
  themes: EntityState<Theme>;
  sidebars: EntityState<Sidebar>;
  moduleTranslations: EntityState<{ id: string; value: string }>;
  emitterChannels: EmitterChannel[];
  autoTranslateLangs: Language[];
  autoTranslationStatus: TRANSLATION_STATUS;
  showAutoTranslationModal: boolean;
  showUpdateContactInfoModal: boolean;
  menuSideBarIsOpen: boolean;
  socialSidebarIsOpen: boolean;
}

export interface AppPartialState {
  readonly [APP_FEATURE_KEY]: AppState;
}

export const messageAdapter: EntityAdapter<MessageNew> = createEntityAdapter({
  selectId: (message) => message.keycode,
});
export const moduleAdapter: EntityAdapter<MsgModule> = createEntityAdapter({
  selectId: (module) => getModuleRef(module),
});
export const themeAdapter: EntityAdapter<Theme> = createEntityAdapter();
export const sidebarAdapter: EntityAdapter<Sidebar> = createEntityAdapter();
export const moduleTranslationsAdapter: EntityAdapter<{
  id: string;
  value: string;
}> = createEntityAdapter();

export const initialState: AppState = {
  error: {},
  messages: messageAdapter.getInitialState(),
  modules: moduleAdapter.getInitialState(),
  themes: {
    ids: [DEFAULT_THEME_ID],
    entities: { [DEFAULT_THEME_ID]: DEFAULT_THEME },
  },
  sidebars: sidebarAdapter.getInitialState(),
  moduleTranslations: moduleTranslationsAdapter.getInitialState(),
  emitterChannels: [],
  autoTranslateLangs: [],
  autoTranslationStatus: TRANSLATION_STATUS.original,
  showAutoTranslationModal: false,
  showUpdateContactInfoModal: false,
  menuSideBarIsOpen: false,
  socialSidebarIsOpen: false,
};
