import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { ViewerLoginConfig } from '@ao/data-models';
import { environment } from '@ao/environments';
import { BrowserService, cleanParams, QueryParams } from '@ao/utilities';
import { defer, from, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, first, map, switchAll, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CoreApiService {
  private readonly BASE_URL = `${environment.apiBaseUrl}/api`;

  // Expects an array always, picks first
  private getOneLegacy<T>(url: string, query: QueryParams = {}) {
    return this.http.get(this.BASE_URL + url, { params: cleanParams(query) }).pipe(
      map((res: any) => <T[]>res.data),
      map((data) => (data || [])[0]),
    );
  }

  private post<T>(url: string, body: any = {}) {
    return this.http.post(this.BASE_URL + url, body).pipe(map((res: any) => <T[]>res.data));
  }

  private postOne<T>(url: string, body: any = {}) {
    return this.post<T>(url, body).pipe(map((data) => data[0]));
  }

  constructor(
    private http: HttpClient,
    private browserService: BrowserService,
    private firebaseMessaging: AngularFireMessaging,
  ) {}

  getClientLoginConfig(redirectUrl) {
    return this.getOneLegacy<ViewerLoginConfig>('/v1/identityProvider/app/client-login-config', { redirectUrl });
  }

  // actimo login authenticate contact
  authenticateContact(requestId: string, passcode: string, redirectUrl?: string) {
    const url = `${environment.apiBaseUrl}/api/v1/device/${requestId}/verify`;
    return this.http.put(url, { code: passcode, webApp: true, redirectUrl });
  }

  logout(keycode: string, origin: string) {
    return this.postOne<string>(`/v1/identityProvider/app/logout`, { keycode, origin });
  }

  // actimo request login
  requestLogin(value: { email?: string; phone_number?: string }) {
    const url = `${environment.apiBaseUrl}/api/v1/device/register`;
    return this.http.put(url, { ...value }).pipe(
      map((res: any) => res),
      catchError((error) => throwError(() => error)),
    );
  }

  // set login cookie for a client
  appAuth(contactId: number, contactAuthCode: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/device/${contactId}/app-auth`;
    return this.http.put(url, {}, { headers: { 'auth-code': contactAuthCode } });
  }

  getEmitterChannel(origin: string, keycode: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/${origin}/${keycode}/emitterChannel`;
    return this.http.get(url).pipe(
      map((res: any) => res.data),
      catchError((error) => throwError(() => error)),
    );
  }

  loadViewerSettings(): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/settings`;
    return this.http.get(url, { withCredentials: true }).pipe(
      map((res: any) => res.data),
      catchError((error) => throwError(() => error)),
    );
  }

  initChat(chatAppId: string, chatAuthToken: string): Observable<any> {
    return defer(() => import('@cometchat/chat-sdk-javascript')).pipe(
      switchMap(({ CometChat }) => {
        const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion('eu').build();
        return from(CometChat.init(chatAppId, appSetting)).pipe(
          switchMap((loggedInUser) => {
            if (loggedInUser) {
              return from(CometChat.login(chatAuthToken)).pipe(
                map((user) => of(user)),
                tap(() => {
                  if (this.browserService.isNativeAppCookieSet()) {
                    return from(CometChat.callExtension('push-notification', 'GET', 'v2/tokens', null)).pipe(
                      tap((response: { web: string[] }) => {
                        if (response.web?.length > 0) {
                          response.web.forEach((token) => {
                            if (token) {
                              this.firebaseMessaging.deleteToken(token).pipe(first()).subscribe();
                            }
                          });
                        }
                      }),
                    );
                  }
                }),
              );
            }
          }),
          catchError((err) => throwError(() => new Error(err))),
        );
      }),
      switchAll(),
    );
  }
}
