import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Prevent body scroll and overscroll.
 * Tested on mac, iOS chrome / Safari, Android Chrome.
 *
 * Modified version of https://gist.github.com/thuijssoon/fd238517b487a45ce78d8f7ddfa7fee9
 *
 * NB: it sets `html, body {overflow: hidden;}`
 * and: `-webkit-overflow-scrolling: touch;` for the element that should scroll.
 */
@Injectable({ providedIn: 'root' })
export class DisableScrolling {
  _stack: (string | HTMLElement)[] = [];
  _selector = '';
  _element: HTMLElement = null;
  _clientY = null;
  _clientX = null;
  _body: HTMLElement;
  _html: HTMLElement;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this._body = this.document.body;
    this._html = this.document.documentElement;
  }

  public on() {
    this.disableScrolling(true);
  }

  /**
   * Disables global scrolling and allows scrolling only for the provided element.
   * This function manages a stack of scrollable elements, ensuring that only the
   * latest element in the stack has scrolling enabled, while disabling scrolling
   * for the rest of the page.
   *
   * @param {string | HTMLElement} selectorOrElement - A CSS selector string or an HTML element.
   *    This specifies the element for which scrolling should be allowed while all other
   *    scrolling is disabled. If a string is provided, it will be used to query the DOM.
   *
   * @example
   * // Allow scrolling only on an element with the id 'scrollable-div'
   * disableScrolling.onExcept('#scrollable-div');
   *
   * @example
   * // Allow scrolling only on a specific HTML element
   * const element = document.getElementById('scrollable-div');
   * disableScrolling.onExcept(element);
   */
  public onExcept(selectorOrElement: string | HTMLElement) {
    this.disableScrolling(false);

    this._stack.push(selectorOrElement);

    const latestElement = this._stack[this._stack.length - 1];
    this.disableScrolling(true, latestElement);
  }

  /**
   * Removes the most recently added scrollable element and allows scrolling for the previous one.
   */
  public off() {
    this._stack.pop();
    this.disableScrolling(false);

    if (this._stack.length > 0) {
      const previousElement = this._stack[this._stack.length - 1];
      this.disableScrolling(true, previousElement);
    }
  }

  /**
   * Clears all scroll locks and re-enables scrolling globally.
   */
  public offAll() {
    this._stack = [];
    this.disableScrolling(false);
  }

  private isElement(o) {
    return typeof HTMLElement === 'object'
      ? o instanceof HTMLElement
      : o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string';
  }

  // Prevent default unless within _selector
  private preventBodyScroll = (event) => {
    if (this._element === null || (!event.target.closest(this._selector) && event.cancelable)) {
      event.preventDefault();
    }
  };

  // Cache the clientY co-ordinates for comparison
  private captureTouches = (event) => {
    // only respond to a single touch
    if (event.targetTouches.length === 1) {
      this._clientY = event.targetTouches[0].clientY;
      this._clientX = event.targetTouches[0].clientX;
    }
  };

  // Transverse DOM parents to find the nearest scrollable element
  private findNearestScrollContainer(event: TouchEvent): HTMLElement | null {
    let element = <HTMLElement>event.srcElement;
    if (event.type !== 'touchmove' || !(element instanceof HTMLElement)) {
      return null;
    }

    while (!(element instanceof HTMLDocument || element === this._element)) {
      const overflowX = window.getComputedStyle(element)['overflow-x'];
      const overflowY = window.getComputedStyle(element)['overflow-y'];
      const allowedTypes = ['auto', 'scroll'];
      const hasScrollableWidth = element.scrollWidth / element.clientWidth;
      const hasScrollableHeight = element.scrollHeight / element.clientHeight;
      if (
        (allowedTypes.includes(overflowX) && hasScrollableWidth) ||
        (allowedTypes.includes(overflowY) && hasScrollableHeight)
      ) {
        return element;
      }
      element = element.parentElement;
    }

    return null;
  }

  // Detect whether the element is at the top or the bottom
  // of their scroll and prevent the user from scrolling beyond
  private preventOverscroll = (event) => {
    // only respond to a single touch
    if (event.targetTouches.length !== 1) return;

    const scrollContainer = this.findNearestScrollContainer(event) || this._element;

    const clientY = event.targetTouches[0].clientY - this._clientY;
    const clientX = event.targetTouches[0].clientX - this._clientX;
    const horizontalScroll = Math.abs(clientX) > Math.abs(clientY);

    const scroll = horizontalScroll ? scrollContainer.scrollLeft : scrollContainer.scrollTop;
    const moved = horizontalScroll ? clientX : clientY;
    const containerSize = horizontalScroll ? scrollContainer.clientWidth : scrollContainer.clientHeight;
    const containerScrollSize = horizontalScroll ? scrollContainer.scrollWidth : scrollContainer.scrollHeight;

    // The element at the start of its scroll, and the user scrolls back (inverted for touchmove)
    // - or -
    // The element at the end of its scroll, and the user scrolls ahead (inverted for touchmove)
    // (https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions)
    // A very small decimal width is added to the scroll which prevents the condition from registering correctly
    // - Using floor solves that problem
    if ((moved > 0 && scroll === 0) || (moved < 0 && Math.floor(containerScrollSize - scroll) <= containerSize)) {
      if (event.cancelable) event.preventDefault();
    }
  };

  // Disable body scroll. Scrolling with the selector is allowed
  // if a selector is provided.
  private disableScrolling(allow: boolean, selectorOrElement?: string | HTMLElement): void {
    if (typeof selectorOrElement !== 'undefined') {
      if (this.isElement(selectorOrElement)) {
        this._element = selectorOrElement as HTMLElement;
        this._selector = this._element.classList
          .toString()
          .split(' ')
          .map((c) => `.${c}`)
          .join(' ');
      } else {
        this._selector = selectorOrElement as string;
        this._element = this.document.querySelector(this._selector);
      }
    }

    this.toggleListenersAndStyles(allow);
  }

  private toggleListenersAndStyles(allow: boolean) {
    const action = allow ? 'add' : 'remove';

    // Toggle scrolling to container and prevent overscroll
    if (this._element !== null) {
      this._element.style.setProperty('-webkit-overflow-scrolling', allow ? 'touch' : '');
      this._element[`${action}EventListener`]('touchstart', this.captureTouches, false);
      this._element[`${action}EventListener`]('touchmove', this.preventOverscroll, false);
    }

    // Toggle scroll freeze on body, html (desktop)
    this._body.style.overflow = allow ? 'hidden' : '';
    this._html.style.overflow = allow ? 'hidden' : '';

    // Toggle scroll freeze on body, html (mobile)
    this._body[`${action}EventListener`]('touchmove', this.preventBodyScroll, allow ? { passive: false } : false);
  }
}
