import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ACTIMO_COLORS, CustomIconName } from '@ao/data-models';
import { getThresholdStatus } from '@ao/utilities';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

const sizes = {
  tiny: {
    stroke: 3,
    width: 26,
  },
  small: {
    stroke: 8,
    width: 64,
  },
  large: {
    stroke: 6,
    width: 120,
  },
};

@Component({
  selector: 'ao-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeComponent {
  _value: number = null;
  _first = true;
  _size: 'tiny' | 'small' | 'large' = 'large';
  _strokeWidth = 6;
  _maxWidth = 120;
  _iconSize = '100';

  // range value should be between 0 and 100
  @Input() lowRange: number | null = null;
  @Input() mediumRange: number | null = null;
  @Input() set size(value: 'tiny' | 'small' | 'large') {
    this._size = value;
    this._strokeWidth = sizes[value].stroke;
    this._maxWidth = sizes[value].width;
    this._iconSize = this._size == 'small' ? '35' : '100';
  }
  @Input() showDisplayValue = true;
  @Input() iconName: CustomIconName = null;

  // value should be between 0 to 1 for it to work with localizedPercentage pipe inside animated number
  @Input() set value(value: number) {
    if (this._first) {
      this._first = false;
      this._value = value;
      setTimeout(() => {
        this.cdr.markForCheck();
      });
    } else {
      this._value = value;
    }
  }
  get value() {
    return this._value;
  }

  @Input() displayValue: number;

  get hasDisplayValue() {
    return ![null, undefined].includes(this.displayValue);
  }

  get _empty() {
    return this.hasDisplayValue ? typeof this.displayValue !== 'number' : typeof this._value !== 'number';
  }

  get _radius(): number {
    return this._maxWidth * 0.5 - this._strokeWidth * 0.5;
  }

  get _offset(): number {
    // This function will interpolate at0 in 0deg 180deg and at90 in 90deg 270deg
    const letterSize = 4;
    const sep = 5;
    const at0 = sep + letterSize / 2;
    const at90 = sep + (this.targetText.length / 2) * letterSize;
    const angle = (this.mediumRange / 100) * 2 * Math.PI;
    return at0 + (at90 - at0) * 0.5 * (1 - Math.cos(angle * 2));
  }

  get _targetRotate(): string {
    const angle = (this.mediumRange / 100) * 360;
    return `rotate(${angle} ${this._center})`;
  }

  get _targetTextRotate(): string {
    // reverse _targetRotate from the center of the target text
    const angle = (this.mediumRange / 100) * 360;
    return `rotate(${-angle - 90} ${this._maxWidth + this._offset} ${this._maxWidth * 0.5})`;
  }

  get _center() {
    return `${this._maxWidth * 0.5} ${this._maxWidth * 0.5}`;
  }

  private get total() {
    return (360 / 180) * Math.PI * this._radius;
  }

  private get fullCircle() {
    return 2 * Math.PI * this._radius;
  }

  get _dasharray() {
    const amount = this.total * this.value;
    return `${amount}, ${this.fullCircle - amount}`;
  }

  private targetText = 'Target';

  constructor(private cdr: ChangeDetectorRef, private translate: TranslateService) {
    this.translate
      .get('Target')
      .pipe(take(1))
      .subscribe((result) => {
        this.targetText = result;
      });
  }

  getColor(value: number): string {
    if (!value) {
      return ACTIMO_COLORS.ink.base;
    }
    const medium = this.mediumRange || 0;
    const low = this.lowRange || this.mediumRange;
    return getThresholdStatus(value * 100, medium, low).color;
  }
}
