import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ViewerCoreService } from '../store/services/viewer-core-store.service';
import { Observable, map, catchError, of } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ViewerLoginGuard implements CanActivate {
  constructor(private router: Router, private viewerCoreService: ViewerCoreService, private location: Location) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.viewerCoreService.login(this.location.path()).pipe(
      map((res: any) => {
        if (!res.redirect) {
          return true;
        }
        this.router.navigate([`${res.redirect}`]);
        return false;
      }),
      catchError(() => {
        return of(true);
      }),
    );
  }
}
