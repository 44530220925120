<div class="ao-notification-list-skeleton">
  <div class="ao-notification-list-skeleton__social-notification">
    <div class="ao-notification-list-skeleton__avatar-wrapper">
      <div class="ao-notification-list-skeleton--avatar"></div>
    </div>
    <div class="ao-notification-list-skeleton__social-notification-details">
      <div class="ao-notification-list-skeleton--name"></div>
      <div class="ao-notification-list-skeleton--details">
        <div class="ao-notification-list-skeleton--details--small"></div>
        <div class="ao-notification-list-skeleton--details--big"></div>
      </div>
    </div>
  </div>

  <div class="ao-notification-list-skeleton__message-notification">
    <div class="ao-notification-list-skeleton--thumbnail"></div>
    <div class="ao-notification-list-skeleton__message-notification-details">
      <div class="ao-notification-list-skeleton--title"></div>
      <div class="ao-notification-list-skeleton--subtitle"></div>
      <div class="ao-notification-list-skeleton--details">
        <div class="ao-notification-list-skeleton--details--small"></div>
        <div class="ao-notification-list-skeleton--details--big"></div>
      </div>
    </div>
  </div>
</div>
