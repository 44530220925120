import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { CommonUiModule } from '@ao/common-ui';

@Component({
  selector: 'ao-search-recent-query',
  templateUrl: './search-recent-query.component.html',
  styleUrl: './search-recent-query.component.scss',
  standalone: true,
  imports: [CommonModule, CommonUiModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchRecentQueryComponent {
  query = input.required<string>();

  removed = output();
  quickSearched = output();
}
