import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtendedSavedPost } from '@ao/shared-data-models';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router) {}

  navigateToPost(post: ExtendedSavedPost, route: ActivatedRoute) {
    const basePath = 'social';
    const primaryPath = `${post.groupInfo.name.toLowerCase().replace(/([^\w]|_)+/g, '-')}-${post.groupInfo.id}`;
    const postId = post.id;

    this.router.navigate(['../../', basePath, primaryPath, { outlets: { post: [postId] } }], {
      relativeTo: route,
    });
  }
  setActiveTab(activeTab: string, route: ActivatedRoute) {
    this.router.navigate([activeTab], {
      relativeTo: route.parent,
      replaceUrl: true,
    });
  }
  navigateToProfile(contactId: number, route: ActivatedRoute) {
    this.router.navigate(['../../', 'profile', contactId], {
      relativeTo: route,
    });
  }
}
