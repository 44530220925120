import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, Self, SimpleChanges } from '@angular/core';
import { ACTIMO_COLORS } from '@ao/data-models';

export type SpinnerSize =
  | 'inherit'
  | 'auto'
  | 'full'
  | 8
  | 12
  | 16
  | 18
  | 20
  | 24
  | 26
  | 30
  | 32
  | 36
  | 40
  | 42
  | 48
  | 72
  | 128;
const spinnerColors = {
  blue: ACTIMO_COLORS.blue.base,
  ink: ACTIMO_COLORS.ink.base,
};

@Component({
  selector: 'ao-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  providers: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @HostBinding('class.ao-spinner') className = true;
  @Input() set color(value) {
    this._color = spinnerColors[value] || value;
  }
  @Input() progress: number;
  // in case we have a very heavy load, the css render queue can be overwhelmed
  // for those places, we can use rotate() only solution (wont use gpu)
  @Input() fastRender?: boolean = false;
  @Input() size?: SpinnerSize = 'auto';

  @HostBinding('class.ao-spinner--indefinite')
  get indefinite() {
    return typeof this.progress !== 'number';
  }

  private readonly radius = 10.5;

  _color = '#3a494f';

  get dashArray() {
    const full = 2 * Math.PI * this.radius;
    return `${(this.progress / 100) * full}, ${((100 - this.progress) / 100) * full}`;
  }

  constructor(@Self() private ngClass: NgClass) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngClass.ngClass = ['ao-spinner', `ao-spinner--size-${this.size}`];
    this.ngClass.ngDoCheck();
  }
}
