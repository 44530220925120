import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  HostBinding,
  Input,
  TemplateRef,
} from '@angular/core';
import { ACTIMO_COLORS, AvatarSize, BrandingColor, GenericListItemType, IconSize } from '@ao/data-models';
import { ImageSource } from '@ao/shared-data-models';

@Directive({ selector: '[aoListItemSubTitleTemplate]' })
export class ListItemSubTitleTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'ao-generic-list-item',
  templateUrl: './generic-list-item.component.html',
  styleUrls: ['./generic-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericListItemComponent {
  @HostBinding('class.ao-generic-list-item') className = true;
  @HostBinding('class.ao-generic-list-item--compact') @Input() compact = false;

  @Input() iconName?: string;
  @Input() iconColor?: BrandingColor;
  @Input() iconBackgroundColor?: BrandingColor;
  @Input() graphicSize = 48;
  @Input() iconSize?: IconSize = 24;
  @Input() avatarSize?: AvatarSize = 'medium';
  @Input() graphicWithLeftRoundCorners = true;
  @Input() image?: string;
  @Input() images?: ImageSource[];
  @Input() initials?: string;
  @Input() listItemType: GenericListItemType = 'none';
  @Input() listSubTitle: TemplateRef<any>;

  @ContentChild(ListItemSubTitleTemplateDirective, { read: TemplateRef })
  genericListItemSubTitleTemplate: TemplateRef<any>;

  get color() {
    return this.iconColor ? ACTIMO_COLORS[this.iconColor].base : ACTIMO_COLORS.ink.lighter;
  }

  get backgroundColor() {
    return this.listItemType === 'icon' && this.iconBackgroundColor
      ? ACTIMO_COLORS[this.iconBackgroundColor]?.light
      : 'transparent';
  }
}
