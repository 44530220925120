<div
  class="ao-academy-section__header"
  [ngClass]="{ 'ao-academy-section__header--disabled': disabled }"
  (click)="_toggleCollapsed()"
>
  <div class="ao-font-body1-bold ao-academy-section__title">{{ sectionTitle }}</div>
  <ng-container *ngIf="disabled; else progressBar">
    <ao-status-circle class="ao-academy-section__locked" [status]="'gray'"></ao-status-circle>
  </ng-container>
  <ng-template #progressBar>
    <div class="ao-academy-section__progress">
      <div class="ao-academy-section__progress__container">
        <div
          class="ao-academy-section__progress__number"
          [ngClass]="{ 'ao-academy-section__progress__number--not-started': progress === 0 }"
        >
          {{ trainingsCompleted }}/{{ this._trainings.length }}
        </div>
        <ao-gauge class="ao-academy-section__progress__gauge" [value]="progress" size="tiny" [showDisplayValue]="false">
        </ao-gauge>
      </div>
    </div>
  </ng-template>
</div>

<div class="ao-academy-section__content" [ngClass]="{ 'ao-academy-section__content--collapsed': collapsed }">
  <div class="ao-academy-section__content-slider" *ngIf="!collapsed" [@expandInOut]>
    <ao-academy-section-training
      *ngFor="let training of _trainings; trackBy: trackById"
      [training]="training"
      [sectionDisabled]="disabled"
      [academyId]="academyId"
      [isJustOneTraining]="_trainings.length === 1"
    >
    </ao-academy-section-training>
  </div>
</div>

<div class="ao-font-small ao-academy-section__footer" (click)="_toggleCollapsed()">
  {{ collapsed ? ('Expand' | translate) : ('Collapse' | translate) }}
</div>
