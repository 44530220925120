import { Observable, OperatorFunction, concatMap, map, take } from 'rxjs';

export const waitForInit = <T>(init$: Observable<unknown>): OperatorFunction<T, T> => {
  return (source$: Observable<T>) =>
    source$.pipe(
      concatMap((action) =>
        init$.pipe(
          take(1),
          map(() => action),
        ),
      ),
    );
};
