import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BrowserService, noop } from '@ao/utilities';

@Component({
  selector: 'ao-social-wall-search-input',
  templateUrl: './social-wall-search-input.component.html',
  styleUrls: ['./social-wall-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SocialWallSearchInputComponent),
    },
  ],
})
export class SocialWallSearchInputComponent implements AfterViewInit {
  @HostBinding('class.ao-social-wall-search-input') className = true;
  @ViewChild('input', { static: false }) input: ElementRef;

  @Input() placeholder: string;

  get hasValue() {
    return Boolean(this._value);
  }

  _value: string = null;
  private _onChange = noop;
  private _onTouched = noop;

  constructor(
    private cdr: ChangeDetectorRef,
    private browserService: BrowserService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngAfterViewInit() {
    if (this.browserService.isIOS) {
      // call blur to remove input focus on iOS aggressively focusing on the first input element in modals
      setTimeout(() => {
        if (this.input && this.input.nativeElement) {
          this.input.nativeElement.blur();
        }
      }, 50); // small delay to ensure modal has fully opened
    }
  }

  onClearClick() {
    this._value = '';
    this.onChange('');
    this.focus();
  }

  onChange(value: string) {
    this._onChange(value);
  }

  focus() {
    (<HTMLElement>this.input.nativeElement).focus();
  }

  isFocused() {
    return this.document.activeElement === this.input.nativeElement;
  }

  writeValue(value: any) {
    this._value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }
}
