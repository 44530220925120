import { trigger, state, transition, style, animate } from '@angular/animations';

export function fadeInOut(name = 'fadeInOut', fadeInDuration = 400, fadeOutDuration = 400) {
  return trigger(name, [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(fadeInDuration)]),
    transition(':leave', animate(fadeOutDuration, style({ opacity: 0 }))),
  ]);
}

export function fadeIn(name = 'fadeIn', duration = 400) {
  return trigger(name, [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(duration)]),
  ]);
}
