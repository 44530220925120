import { Directive, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from '../utils/noop';

/**
 * By default raw input[type="file"] dont pass the file to the formGroup.
 * Use it by adding `aoFileInput` to the input element
 * Example:
 * <form [formGroup]="_form">
 *   <input type="file" aoFileInput formControlName="file"/>
 * </form>
 */
@Directive({
  selector: 'input[type="file"][aoFileInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileInputDirective),
    },
  ],
})
export class FileInputDirective implements ControlValueAccessor {
  _multiple = false;
  @Input() set multiple(value: any) {
    if (value === '') {
      // <input type="file" aoInputFile multiple/> lands in this case
      this._multiple = true;
    } else {
      this._multiple = Boolean(value);
    }
  }

  @Input() resetAfterChange = true;

  private _onChange = noop;
  private _onTouched = noop;

  @HostListener('change', ['$event'])
  onFile(event: Event) {
    const input = <HTMLInputElement>event.target;
    if (input.files.length) {
      this._onChange(this._multiple ? Array.from(input.files) : input.files[0]);
      if (this.resetAfterChange) {
        input.value = null;
      }
    }
    this._onTouched();
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  // eslint-disable-next-line
  writeValue(value: any) {}
}
