<ao-message-modal-card [modalInsideModal]="false" [open]="true" [headerText]="title" (close)="onDismiss('CANCEL')">
  <p class="ao-confirmation-modal__text">
    {{ text | translate }}
  </p>

  <ng-template aoMessageModalCardFooter>
    <div class="ao-confirmation-modal__footer-buttons">
      <button
        class="ao-confirmation-modal__footer-button"
        ao-button
        type="button"
        [buttonColor]="dismissButtonColor"
        (click)="onDismiss('dismiss')"
      >
        {{ dismissButtonText | translate }}
      </button>
      <button
        class="ao-confirmation-modal__footer-button"
        ao-button
        type="submit"
        [buttonColor]="confirmButtonColor"
        (click)="onConfirm()"
      >
        {{ confirmButtonText | translate }}
      </button>
    </div>
  </ng-template>
</ao-message-modal-card>
