import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Language } from '@ao/data-models';
import { filterWithCharacterFolding } from '@ao/utilities';

@Component({
  selector: 'ao-auto-translation-modal',
  templateUrl: './auto-translation-modal.component.html',
  styleUrls: ['./auto-translation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoTranslationModalComponent {
  @HostBinding('class.ao-auto-translation-modal') className = true;
  @Input() open = false;
  @Input() selectedLanguage: Language;
  @Input() set availableLanguages(value: Language[]) {
    this.languages = value;
    this.filteredLanguages = value;
  }
  @Output() dismiss = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<Language>();

  languages: Language[];
  filteredLanguages: Language[];
  searchVisible = false;
  searchInput = '';

  onDismiss() {
    this.toggleSearch(true);
    this.dismiss.emit();
  }

  onConfirm() {
    this.confirm.emit(this.selectedLanguage);
  }

  onSearch(search: string) {
    this.filteredLanguages = search
      ? filterWithCharacterFolding(this.languages, search, (item) => item.languageName)
      : this.languages;
  }

  toggleSearch(forceClose = false) {
    this.searchVisible = forceClose ? false : !this.searchVisible;
  }

  selectLanguage(language: Language) {
    this.selectedLanguage = language;
    this.toggleSearch();
  }

  resetLanguage() {
    this.selectedLanguage = {
      languageCode: null,
      languageName: 'None',
    };
    this.toggleSearch();
  }
}
