<article class="ao-messagelist-item-skeleton ao-messagelist-item--card">
  <!-- main content -->
  <div class="o-media o-media--reverse">
    <!-- left side (text content) -->
    <div class="o-media__body">
      <!-- categories-->
      <ao-category-tags class="ao-messagelist-item-skeleton__categories ao-skeleton-background"></ao-category-tags>

      <!-- title and description -->
      <div
        class="ao-messagelist-item-skeleton__text ao-messagelist-item-skeleton__text-header ao-skeleton-background"
      ></div>
    </div>
  </div>

  <!-- Footer / interaction area -->
  <footer>
    <!-- extra info -->
    <div class="ao-messagelist-item-skeleton__meta">
      <!-- publish date -->
      <div class="ao-messagelist-item-skeleton__date ao-skeleton-background"></div>

      <!-- social (interaction)stats -->
      <div class="ao-messagelist-item-skeleton__stats">
        <!-- likes -->
        <div class="ao-messagelist-item-skeleton__stat ao-skeleton-background"></div>
        <!-- comments -->
        <div class="ao-messagelist-item-skeleton__stat ao-skeleton-background"></div>
        <!-- views -->
        <div class="ao-messagelist-item-skeleton__stat ao-skeleton-background"></div>
      </div>
    </div>
  </footer>
</article>
