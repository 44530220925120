import { animate, style, transition, trigger, query, group } from '@angular/animations';

export const scaleIn = trigger('pageTransitions', [
  transition('* <=> *', [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        left: 0,
        width: '100%',
        opacity: 0,
        transform: 'scale(0) translateX(100%)',
      }),
    ]),
    query(':enter', [animate('600ms ease', style({ opacity: 1, transform: 'scale(1) translateX(0)' }))]),
  ]),
]);

export const slider = trigger('pageTransitions', [
  transition('* => isLeft', slideToLeft()),
  transition('* => isRight', slideToRight()),
  transition('isRight => *', slideToLeft()),
  transition('isLeft => *', slideToRight()),
]);

export function fade(duration = '600ms', easing = 'ease') {
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          opacity: 1,
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ opacity: 0 })]),
    group([
      query(':leave', [animate(`${duration} ${easing}`, style({ opacity: 0 }))], { optional: true }),
      query(':enter', [animate(`${duration} ${easing}`, style({ opacity: 1 }))], { optional: true }),
    ]),
  ];
}

export function slideToLeft(duration = '600ms', easing = 'ease') {
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ left: '-100%' })], { optional: true }),
    group([
      query(':leave', [animate(`${duration} ${easing}`, style({ left: '100%' }))], { optional: true }),
      query(':enter', [animate(`${duration} ${easing}`, style({ left: '0%' }))], { optional: true }),
    ]),
  ];
}

export function slideToRight(duration = '600ms', easing = 'ease') {
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ right: '-100%' })], { optional: true }),
    group([
      query(':leave', [animate(`${duration} ${easing}`, style({ right: '100%' }))], { optional: true }),
      query(':enter', [animate(`${duration} ${easing}`, style({ right: '0%' }))], { optional: true }),
    ]),
  ];
}

export const transformer = trigger('pageTransitions', [
  transition('* => isLeft', transformTo({ x: -100, y: -100, rotate: -720 })),
  transition('* => isRight', transformTo({ x: 100, y: -100, rotate: 90 })),
  transition('isRight => *', transformTo({ x: -100, y: -100, rotate: 360 })),
  transition('isLeft => *', transformTo({ x: 100, y: -100, rotate: -360 })),
]);

export function transformTo({ x = 100, y = 0, rotate = 0 }) {
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ transform: `translate(${x}%, ${y}%) rotate(${rotate}deg)` })]),
    group([
      query(
        ':leave',
        [animate('600ms ease-out', style({ transform: `translate(${x}%, ${y}%) rotate(${rotate}deg)` }))],
        { optional: true },
      ),
      query(':enter', [animate('600ms ease-out', style({ transform: `translate(0%, 0%) rotate(0)` }))]),
    ]),
  ];
}
