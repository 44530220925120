<div class="ao-inline-progress__content" [ngClass]="{ 'ao-inline-progress__content--reversed': reversed }">
  <div class="ao-inline-progress__value" [style.color]="color" *ngIf="showPercentage">
    {{ value | localizedPercent: '1.0-0' }}
  </div>
  <ng-content />
  <ao-progress-bar
    [ngStyle]="showPercentageBar ? {} : { display: 'none' }"
    class="ao-inline-progress__bar"
    [color]="color"
    [value]="value || 0"
  ></ao-progress-bar>
  <div class="ao-inline-progress__label" *ngIf="label">
    {{ label }}
  </div>
</div>
