import dayjs from 'dayjs';

export function dateIsToday(date: string) {
  return dayjs(date).isToday();
}
export function dateIsPassed(date: string) {
  return dayjs(date).isBefore(dayjs(), 'day');
}
export function dateIsFuture(date: string) {
  return dayjs(date).isAfter(dayjs(), 'day');
}
