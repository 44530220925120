import { Routes } from '@angular/router';
import { TaskDetailsPageComponent } from './containers/task-details-page/task-details-page.component';
import { TaskListPageComponent } from './containers/task-list-page/task-list-page.component';

export const viewerTaskManagementRoutes: Routes = [
  { path: '', component: TaskListPageComponent },
  {
    path: 'completed',
    component: TaskListPageComponent,
    data: { activeTab: 'completed' },
  },
  { path: ':id', component: TaskDetailsPageComponent },
];
