import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProfileEffects } from './+state/profile-store.effects';
import * as fromProfile from './+state/profile-store.reducer';
import { ProfileFacade } from './profile-store.facade';
import { ProfileService } from './profile-store.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromProfile.PROFILE_FEATURE_KEY, fromProfile.reducer),
    EffectsModule.forFeature([ProfileEffects]),
  ],
  providers: [ProfileFacade, ProfileService],
})
export class ProfileStoreModule {}
