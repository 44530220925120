<div
  class="ao-suspense__content"
  [ngClass]="{ 'ao-suspense__content__loading': (state$ | async) === LOADING_STATE.loading }"
  *ngIf="(state$ | async) === LOADING_STATE.loaded || ((state$ | async) !== LOADING_STATE.error && preload)"
  [@fadeIn]="(state$ | async) === LOADING_STATE.loaded ? 'visible' : 'hidden'"
>
  <ng-template [ngTemplateOutlet]="content"></ng-template>
</div>
<div
  [style.background-color]="_backgroundColor"
  *ngIf="(state$ | async) === LOADING_STATE.error"
  [style.color]="_textColor"
  class="ao-suspense__error"
>
  <ng-template [ngTemplateOutlet]="error"></ng-template>
</div>
<div
  [style.background-color]="_backgroundColor"
  class="ao-suspense__skeleton"
  [class.ao-suspense__skeleton--absolute]="absolute"
  [@fadeOut]="{ value: loading, params: { delay } }"
  *ngIf="(state$ | async) === LOADING_STATE.loading as loading"
>
  <ng-template [ngTemplateOutlet]="skeleton"></ng-template>
</div>
