import { DYNAMIC_GROUP_NAMES, NSocialPost, QuillDelta, SocialPost, TRANSLATION_STATUS } from '@ao/data-models';
import { fold, getRouteOutlets, getRouteParams, getRouteQuery, isNumeric } from '@ao/utilities';
import { viewerCoreSelectors } from '@ao/viewer-core';
import { Dictionary } from '@ngrx/entity';
import { Selector, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSocial from './social-store.reducer';

export const getSocialState = createFeatureSelector<fromSocial.State>(fromSocial.SOCIAL_FEATURE_KEY);

// params selectors
export const getOriginKeycode = createSelector(
  getRouteParams,
  (params) => <[string, string]>[params.origin, params.keycode],
);
export const getCurrentGroupKey = createSelector(
  getRouteParams,
  (params) => params && params.groupId && <string>params.groupId.replace(/^.*-/, ''),
);

export const getSelectedPostId = createSelector(
  getRouteOutlets,
  getSocialState,
  (outlets, state) => (outlets['post'] && +outlets['post'].postId) || state.messagePostId,
);
export const getSelectedEditPostId = createSelector(
  getRouteOutlets,
  getSocialState,
  (outlets, state) => (outlets['edit'] && +outlets['edit'].postId) || state.messagePostId,
);
export const getSelectedLikesPostId = createSelector(
  getRouteOutlets,
  getSocialState,
  (outlets, state) => (outlets['likes'] && +outlets['likes'].postId) || state.messagePostId,
);
export const getSelectedViewsPostId = createSelector(
  getRouteOutlets,
  getSocialState,
  (outlets, state) => (outlets['views'] && +outlets['views'].postId) || state.messagePostId,
);
export const getQueryUnread = createSelector(getRouteQuery, (query) => typeof query.unread !== 'undefined');

// root level selectors
export const getGroupEntities = createSelector(getSocialState, (state) =>
  fromSocial.groupsAdapter.getSelectors().selectEntities(state.groups),
);
export const getAllGroups = createSelector(getSocialState, (state) =>
  fromSocial.groupsAdapter.getSelectors().selectAll(state.groups),
);
export const getPostEntities = createSelector(getSocialState, (state) =>
  fromSocial.postsAdapter.getSelectors().selectEntities(state.posts),
);
export const getAllPosts = createSelector(getSocialState, (state) =>
  fromSocial.postsAdapter.getSelectors().selectAll(state.posts),
);

export const getContactsWithReactionsForSelectedPost = createSelector(getSocialState, (state) => {
  return fromSocial.reactionsAdapter.getSelectors().selectAll(state.reactions);
});

export const getAllTopLevelPosts = createSelector(getAllPosts, (posts) => {
  return posts
    .filter((p) => !p.parentId)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
});
export const getAllTopLevelPostsWithPinned = createSelector(getAllPosts, (posts) => {
  return posts
    .filter((p) => !p.parentId)
    .sort((a, b) => {
      // If there is a pinned post it always has to be shown first
      if (a.pinned) return -1;
      if (b.pinned) return 1;
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
});
export const getPostTranslationEntities = createSelector(getSocialState, (state) =>
  fromSocial.postTranslationsAdapter.getSelectors().selectEntities(state.postTranslations),
);
export const getActiveTranslations = createSelector(getSocialState, (state) => state.activeTranslations);
export const getCommentsByPost = createSelector(getSocialState, (state) => state.comments);
export const getLastCommentsByPost = createSelector(getSocialState, (state) => state.lastComments);
export const getReactions = createSelector(getSocialState, (state) => state.reactions);
export const getContactsById = createSelector(getSocialState, (state) => state.contacts);
export const getAttachmentsByRef = createSelector(getSocialState, (state) => state.attachmentsByRef);
export const getAttachments = createSelector(getSocialState, (state) => state.attachments);
export const getLimbo = createSelector(getSocialState, (state) => state.limbo);
export const getFeedState = createSelector(getSocialState, (state) => state.feed);
export const getViews = createSelector(getSocialState, (state) => state.views);
export const getPollingMedia = createSelector(getSocialState, (state) => state.pollingMedia);
export const getAllNewPostsPerGroup = createSelector(getSocialState, (state) => state.newPostsPerGroup);

const buildPost =
  (
    posts: Dictionary<NSocialPost>,
    translations: Dictionary<{ id: number; content: QuillDelta; status: TRANSLATION_STATUS }>,
    activeTranslations: number[],
    autoTranslateLang: string,
    contacts: fromSocial.State['contacts'],
    comments: fromSocial.State['comments'],
    lastComments: fromSocial.State['lastComments'],
  ) =>
  (postId: number): SocialPost => {
    const post = posts[postId];
    let translationsStatus = TRANSLATION_STATUS.unneccesary;
    const postHasContent = !!post?.content && Object.keys(post.content).length > 0;
    if (postHasContent && autoTranslateLang && autoTranslateLang !== post?.language) {
      translationsStatus = activeTranslations.includes(postId)
        ? translations[postId].status
        : TRANSLATION_STATUS.original;
    }

    return (
      post && {
        ...post,
        author: contacts[post.author],
        comments: (comments[post.id] || []).map(
          buildPost(posts, translations, activeTranslations, autoTranslateLang, contacts, comments, lastComments),
        ),
        lastComments: (lastComments[post.id] || []).map(
          buildPost(posts, translations, activeTranslations, autoTranslateLang, contacts, comments, lastComments),
        ),
        content:
          translationsStatus === TRANSLATION_STATUS.translated && activeTranslations.includes(postId)
            ? translations[postId].content
            : post.content,
        translationsStatus,
      }
    );
  };

const allTransformedPostsSelector = (withPinned = false, noTranslations = false) =>
  createSelector(
    withPinned ? getAllTopLevelPostsWithPinned : getAllTopLevelPosts,
    getPostEntities,
    getPostTranslationEntities,
    getActiveTranslations,
    getContactsById,
    getCommentsByPost,
    getLastCommentsByPost,
    viewerCoreSelectors.selectUserLang as Selector<any, string>,
    (allPosts, posts, translations, activeTranslations, contacts, comments, lastComments, autoTranslateLang) => {
      const overwriteActiveTranslations = noTranslations ? [] : activeTranslations;
      return allPosts
        .map((p) => p.id)
        .map(
          buildPost(
            posts,
            translations,
            overwriteActiveTranslations,
            autoTranslateLang,
            contacts,
            comments,
            lastComments,
          ),
        );
    },
  );

export const getCurrentGroup = createSelector(
  getGroupEntities,
  getCurrentGroupKey,
  (groupEntities, groupKey) => groupEntities?.[groupKey],
);

export const getShowPostsWithPinned = createSelector(
  getCurrentGroupKey,
  getQueryUnread,
  (groupKey, filterUnread) => isNumeric(groupKey) && !filterUnread,
);

export const getOneContact = (contactId: number) => createSelector(getContactsById, (contacts) => contacts[contactId]);
export const getOneGroup = (groupId: string) => createSelector(getGroupEntities, (groups) => groups?.[groupId]);

const filterPostsByGroupKey = (groupKey: string, posts: SocialPost[]) => {
  const groupIdKey = DYNAMIC_GROUP_NAMES.includes(groupKey) ? 'dynamicGroupId' : 'groupId';
  return (posts || []).filter((post) => `${post[groupIdKey]}` === groupKey);
};

export const getPostsByGroupKey = (groupKey: string) =>
  createSelector(allTransformedPostsSelector(), (posts) => filterPostsByGroupKey(groupKey, posts));
export const getPostsForSelectedGroup = createSelector(
  getCurrentGroupKey,
  allTransformedPostsSelector(),
  (groupKey, posts) => filterPostsByGroupKey(groupKey, posts),
);
export const getPostsForSelectedGroupWithPinned = createSelector(
  getCurrentGroupKey,
  allTransformedPostsSelector(true),
  (groupKey, posts) => filterPostsByGroupKey(groupKey, posts),
);
export const getSharePreviewByMessageId = (messageId: number) =>
  createSelector(getSocialState, (state) => state.shareAttachmentPreviews[messageId]);

const selectedPostSelector = (postIdSelector: Selector<any, number>, noTranslations = false) => {
  return createSelector(allTransformedPostsSelector(false, noTranslations), postIdSelector, (posts, postId) => {
    return posts.find((p) => p.id === postId) || null;
  });
};

export const getSelectedPost = selectedPostSelector(getSelectedPostId);
export const getSelectedLikesPost = selectedPostSelector(getSelectedLikesPostId);
export const getSelectedEditPost = selectedPostSelector(getSelectedEditPostId, true);

export const getCommentsForSelectedPost = createSelector(
  getSelectedPostId,
  getPostEntities,
  getPostTranslationEntities,
  getActiveTranslations,
  viewerCoreSelectors.selectUserLang,
  getContactsById,
  getCommentsByPost,
  getLastCommentsByPost,
  (postId, posts, translations, activeTranslations, autoTranslateLang, contacts, comments, lastComments) => {
    return comments[postId]
      ? comments[postId].map(
          buildPost(posts, translations, activeTranslations, autoTranslateLang, contacts, comments, lastComments),
        )
      : null;
  },
);

export const getPendingPosts = createSelector(getLimbo, (limbo) => {
  return Object.keys(limbo)
    .filter((id) => !limbo[id].parentId)
    .map((id) => {
      return {
        tempId: limbo[id].tempId,
        action: limbo[id].action,
      };
    });
});

export const getPendingPostsForSelectedPost = createSelector(
  getLimbo,
  getSelectedPostId,
  getCommentsByPost,
  getPostEntities,
  (limbo, postId, comments, posts) => {
    const all = [postId].concat(...(comments[postId] || []).map((id) => [id, ...(comments[id] || [])]));
    return Object.keys(limbo)
      .filter((id) => all.includes(limbo[id].postId) || all.includes(limbo[id].parentId))
      .map((id) => {
        const parentId = limbo[id].action === 'CREATING' ? limbo[id].parentId : posts[limbo[id].postId].parentId;
        return {
          tempId: limbo[id].tempId,
          action: limbo[id].action,
          postId: limbo[id].postId,
          parentId: parentId,
          linkPreview: limbo[id].linkPreview,
        };
      });
  },
);

export const getViewsForSelectedPost = createSelector(
  getSelectedViewsPostId,
  getViews,
  getContactsById,
  (postId, views, contacts) => {
    return views[postId] ? views[postId].map((id) => contacts[id]) : null;
  },
);

export const getAttachmentsForSelectedEditPost = createSelector(
  getSelectedEditPost,
  getAttachments,
  getAttachmentsByRef,
  (post = {} as SocialPost, attachments = {}, attachmentsByRef = {}) => {
    const ids = attachmentsByRef[`POST-${post?.id}`] || [];
    return ids.map((attachmentId) => attachments[attachmentId]);
  },
);

export const selectSocialFeedName = createSelector(getGroupEntities, (groupEntities) =>
  groupEntities?.['ALL_POSTS'] ? groupEntities['ALL_POSTS'].name : '',
);
export const selectSocialHasGroups = createSelector(getAllGroups, (groups) => groups && groups.length > 0);
export const selectSocialGroups = createSelector(
  getAllGroups,
  viewerCoreSelectors.selectUserLang as Selector<any, string>,
  (groups, contentLanguage) => {
    return (groups || []).sort((a, b) => a.name.localeCompare(b.name, contentLanguage || 'en-US'));
  },
);

export const getMessageAttachmentForSelectedPost = createSelector(getSocialState, getSelectedPost, (state, post) => {
  const attachmentId = post?.attachments?.find((attachment) => attachment.type === 'message')?.id;
  return attachmentId ? state.shareAttachmentPreviews[attachmentId] : null;
});

export const allSocialGroupsWithNewPostsCount = createSelector(
  selectSocialGroups,
  getAllNewPostsPerGroup,
  (value, newGroupPosts) => {
    const _groups = [];
    const _dynamicGroups = [];
    for (const group of value || []) {
      const gId = Number(group.id);
      const newPosts = !isNaN(gId) ? newGroupPosts[gId] : 0;

      if (group.type === 'GROUP' || group.type === 'PRIVATE') {
        _groups.push({ ...group, newPosts });
      } else if (group.type === 'DYNAMIC') {
        _dynamicGroups.push({ ...group, newPosts });
      }
    }
    _groups.sort((a, b) => fold(a.name).localeCompare(fold(b.name)));
    return { groups: _groups, dynamicGroups: _dynamicGroups };
  },
);
