<ng-container *ngIf="type === 'text'; else defaultTitle">
  {{ _keyTitle ? (_keyTitle | translate) : (title | translate) }}
</ng-container>
<ng-template #defaultTitle>
  @if (title) {
    {{ title }}
  } @else {
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'engagement'">{{ 'Open rate' | translate }}</ng-container>
      <ng-container *ngSwitchCase="'knowledge'">{{ 'Know-how' | translate }}</ng-container>
      <ng-container *ngSwitchCase="'points'">{{ 'Points' | translate }}</ng-container>
      <ng-container *ngSwitchCase="'pulse'">{{ 'Engagement' | translate }}</ng-container>
      <ng-container *ngSwitchCase="'rating'">{{ 'One-on-one' | translate }}</ng-container>
      <ng-container *ngSwitchCase="'progress'">{{ 'Progress' | translate }}</ng-container>
    </ng-container>
  }
</ng-template>
