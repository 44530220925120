import { AbstractControl, FormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const atLeastOneValidator =
  (validator: ValidatorFn, keys?: string[]) =>
  (group: UntypedFormGroup): ValidationErrors | null => {
    const hasAtLeastOne =
      group &&
      group.controls &&
      Object.keys(group.controls).some((k) =>
        keys ? keys.includes(k) && !validator(group.controls[k]) : !validator(group.controls[k]),
      );

    return hasAtLeastOne ? null : { atLeastOne: true };
  };

export function mustHaveOneValueTrue(mustHaveKeys: string[]): ValidatorFn {
  return (group) => {
    const values = group.value;
    const valid = mustHaveKeys.some((key) => values[key]);
    return valid ? null : { onevalidtype: true };
  };
}

export function atLeastOneInArray(formArray: AbstractControl<FormArray>) {
  return (formArray as any).controls.length ? null : { atLeastOneError: true };
}

export function maxLengthWithoutHTMLTags(maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = control.value;
    const text = tmp.textContent || tmp.innerText || '';
    const length = text.length;
    if (length >= maxLength) {
      return { maxlength: { requiredLength: maxLength, actualLength: length } };
    }
    return null;
  };
}
