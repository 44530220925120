import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { WINDOW, onceDefined, onceWithLatest } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { combineLatestWith, map } from 'rxjs';

export const searchGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const viewerCoreFacade = inject(ViewerCoreFacade);
  const _window: Window = inject(WINDOW);

  // Ensure that viewer settings are loaded if not already
  onceWithLatest(viewerCoreFacade.viewerSettings$, (settings) => {
    if (!settings) {
      viewerCoreFacade.loadViewerSettings();
    }
  });

  return viewerCoreFacade.searchEnabled$.pipe(
    onceDefined(),
    combineLatestWith(viewerCoreFacade.viewerSettings$.pipe(onceDefined())),
    map(([searchEnabled, viewerSettings]) => {
      if (searchEnabled && viewerSettings) {
        return true; // Return true if the condition is met
      } else {
        // Navigate to error page and return a UrlTree
        return router.parseUrl(
          '/error?customMessage=FEATURE_DISABLED&previousPath=' +
            encodeURIComponent(_window.location.pathname + _window.location.search),
        );
      }
    }),
  );
};
