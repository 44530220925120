import { MsgModuleChart } from '@ao/data-models';
import { createAction, props } from '@ngrx/store';

export const LoadChartData = createAction(
  '[Message/Module Chart] Load Chart Data',
  props<{ module: MsgModuleChart }>(),
);
export const LoadChartDataSuccess = createAction(
  '[Message/Module Chart] Load Chart Data Success',
  props<{ module: MsgModuleChart; data: any }>(),
);
export const LoadChartDataFail = createAction('[Message/Module Chart] Load Chart Data Fail', props<{ error: any }>());
