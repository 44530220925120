import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { WINDOW } from '@ao/utilities';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { GenericPageHeaderTypes } from '../generic-page-header/generic-page-header.component';

@Component({
  selector: 'ao-generic-viewer-error',
  templateUrl: './generic-viewer-error.component.html',
  styleUrls: ['./generic-viewer-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericViewerErrorComponent implements OnInit {
  @HostBinding('class.ao-generic-viewer-error') className = true;
  @Input() errorType?: GenericPageHeaderTypes = 'error';
  @Input() errorCode?: string;
  @Input() domain?: string;
  @Input() destination?: string;
  @Input() title?: string;
  @Input() subTitle?: string;
  @Input() text?: string;
  @Input() supportName?: string;
  @Input() buttonText?: string;
  @Input() refreshButton = false;
  @Input() homeButton = false;
  @Output() openSupport = new EventEmitter();
  @Output() logout = new EventEmitter();

  _defaultSubtitle = {
    error: i18n('Error'),
    warning: i18n('Error'),
  };

  /* TODO This line should be removed or revisited - done in a very patchy way accessing store inside the component */
  constructor(@Inject(WINDOW) private window: Window) {}

  sessionId: string;

  ngOnInit(): void {
    this.sessionId = this.window['aoId'];
  }

  backToHome() {
    // avoiding angular router - the login rerouting is induced by node.js (and its safer to force a manual refresh upon an error)
    this.window.location.href = '/';
  }
  refreshPage() {
    this.window.location.replace(this.window.location.href.split('?')[0]);
  }

  onLogout() {
    this.logout.emit();
  }
}
