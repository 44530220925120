import { inject, Injectable } from '@angular/core';
import { environment, VERSION } from '@ao/environments';
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { WINDOW } from './tokens';

interface DatadogInit {
  applicationId: string;
  clientToken: string;
  service: string;
}

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  _window = inject(WINDOW);
  init(initValues: DatadogInit) {
    datadogRum.init({
      ...initValues,
      site: 'datadoghq.eu',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      enableExperimentalFeatures: ['clickmap'],
      trackViewsManually: true,
      defaultPrivacyLevel: 'mask',
      allowedTracingUrls: [(url) => url.startsWith(`${window.location.origin}/api/v1`)],
      version: VERSION,
      env: environment.envName,
    });

    datadogRum.setGlobalContextProperty('aoid', this._window['aoId']);
    datadogRum.startSessionReplayRecording();
  }

  initLogs(initValues: LogsInitConfiguration) {
    datadogLogs.init({
      ...initValues,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      version: VERSION,
      env: environment.envName,
      beforeSend: (_event, context) => {
        if (context?.isAborted) {
          return false;
        }
      },
    });
    datadogLogs.setGlobalContextProperty('aoid', this._window['aoId']);
  }

  log(message: string, messageContext?: object) {
    datadogLogs.logger.log(message, messageContext);
  }
  warn(err: string, context?: object, error?: Error) {
    datadogLogs.logger.warn(err, context, error);
  }
  error(err: string, context?: object, error?: Error) {
    datadogLogs.logger.error(err, context, error);
  }

  startView(name: string) {
    datadogRum.startView(name);
  }

  setUser(id: number) {
    datadogRum.setUser({ id: id.toString() });
    datadogLogs.setUser({ id: id.toString() });
  }

  setClient(clientId: number) {
    datadogRum.setGlobalContextProperty('clientId', clientId);
    datadogLogs.setGlobalContextProperty('clientId', clientId);
  }

  setMessage(messageId: number | null) {
    datadogRum.setGlobalContextProperty('messageId', messageId);
    datadogLogs.setGlobalContextProperty('messageId', messageId);
  }
}
