import { MustHave } from '../common';
import { ImageHandlerMedia } from '../media';

export enum MessageType {
  Normal = 'normal',
  Pulse = 'pulse',
  Rating = 'rating',
  Survey = 'survey',
  Academy = 'academy',
}

export enum NotificationItemType {
  Message = 'message',
  Post = 'post',
}

export enum NotificationType {
  SocialWallPostCreated = 'sw-post-notify',
  SocialWallCommentCreated = 'sw-comment-notify',
  MessagePostReplyCreated = 'sm-reply-notify',
  SocialWallCommentReplyCreated = 'sw-reply-notify',
  SocialWallPostMentionCreated = 'sw-mention-notify',
  MessagePostMentionCreated = 'sm-mention-notify',
  MessagePostCommentCreated = 'sm-comment-notify',
  ContactUpdated = 'ct-update-notify', // TODO: check what notification is this (not in the docs/design)
  MessagePublished = 'publish-message-notify',
  TaskPublished = 'tm-publish-notify',
}

export enum PostGroupType {
  Message = 'message',
  ContactList = 'contact_list',
}

export enum PostParentType {
  Message = 'message',
  ContactList = 'contact_list',
  Post = 'post',
}

export interface MessagePublishedNotificationDetails {
  messageId: number;
  type: MessageType;
  subject: string;
  bodyText: string;
  message: {
    id: number;
    type: MessageType;
    commentsPage?: number[];
    title?: string;
    titlePreview?: string;
    text?: string;
    textPreview?: string;
    media?: ImageHandlerMedia;
    mediaPreview?: ImageHandlerMedia;
    categories?: [{ categoryId: number; name: string }];
  };
  academy?: {
    id: number;
    media: ImageHandlerMedia;
    mediaPreview?: ImageHandlerMedia;
    title?: string;
    titlePreview?: string;
    text?: string;
    textPreview?: string;
  };
}

export interface PostNotificationDetails {
  postId: number;
  rootPostId: number;
  messageId?: string;
  group?: {
    id: number;
    name: string;
    type: PostGroupType;
  };
  parent?: {
    id: number;
    type: PostParentType;
  };
  rootPostAuthorId?: number;
  rootPostAuthor?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  authorId: number;
  author?: {
    id: number;
    firstName: string;
    lastName: string;
    avatarUrl?: string;
  };
}

export interface Notification {
  id: number;
  clientId: number;
  contactId: number;
  type: NotificationType;
  data: NotificationDetails;
  read: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface TaskNotificationDetails {
  taskHeadline: string;
  taskId: number;
}

export type NotificationUpdate = MustHave<Notification, 'clientId' | 'contactId'>;
export type NotificationTab = 'all' | 'unread';

export type NotificationRequestParams = {
  read?: boolean;
  offset?: number;
  limit?: number;
  order?: string;
  createdAfter?: Date;
  createdBefore?: Date;
};

export type NotificationDetails =
  | PostNotificationDetails
  | TaskNotificationDetails
  | MessagePublishedNotificationDetails;

export interface ClientChannelSetting {
  default: boolean;
  canOverride: boolean;
}

export type ChannelsInClientNotificationSetting = { [key in NotificationChannelSettingType]: ClientChannelSetting };

export type ClientNotificationSetting = ChannelsInClientNotificationSetting & {
  deliveryPreference: string[];
};

export type NotificationChannelSettingType = 'email' | 'push' | 'sms';
export type NotificationSettingType = 'publishedContent' | 'tasks' | 'mentions' | 'comments' | 'groups' | 'chat';
export type NotificationSettingRowType = NotificationSettingType | 'disabledGroups';

export type ChannelSetting = {
  value: boolean;
  default?: boolean;
  canOverride?: boolean;
  groupId?: string;
};
export type NotificationSetting = {
  [key in NotificationChannelSettingType]: ChannelSetting;
} & {
  disabledGroups?: ChannelSetting[];
};

export type ContactNotificationSettings = {
  [key in NotificationSettingType]: NotificationSetting;
};

export interface NotificationUserSetting {
  channelSettingType: NotificationChannelSettingType;
  value: boolean;
  canOverride?: boolean;
  label?: string;
  isContactInfoMissing?: boolean;
  isInactive?: boolean;
  isEmailBounced?: boolean;
}

export interface NotificationSettingsGroup {
  settingType: NotificationSettingType;
  title: string;
  description: string;
  settings: NotificationUserSetting[];
  disabledGroups?: string[];
  activeList?: string;
  hidden?: boolean;
}

export interface ContactNotificationSettingsRow {
  clientId: number;
  contactId: number;
  itemType: NotificationSettingRowType;
  itemId: NotificationChannelSettingType | string;
  deviceTypeGroup?: string;
  value: boolean;
}
export interface ClientNotificationSettings {
  social: ClientNotificationSetting;
  publishedContent: ClientNotificationSetting;
}

export type FirebaseInputMessage = {
  title: string;
  body: string;
  push_key: string;
  data?: FirebaseInputMessageData;
  contactId?: number;
  badge?: string;
};

export type FirebaseInputMessageData = {
  silent?: string;
  link?: string;
  [key: string]: string | undefined;
};

export type ContactDevice = {
  id: number;
  contactId: number;
  pushKey: string;
  deviceType: string;
  browserType: string;
  browserId: string;
};

export type PushNotificationBody = {
  contact_id: number;
  client_id: number;
  push_keys: string;
  num_devices_sent_to: number;
  type: string;
  title: string;
  body: string;
  content: {
    link: string;
    silent: boolean;
  };
};
