import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsgAction, MsgActionable } from '@ao/data-models';
import { environment } from '@ao/environments';
import { SocialFacade } from '@ao/social-store';
import { BrowserService, interpolate, makeHttps, NativeBridgeService, onceWithLatest, WINDOW } from '@ao/utilities';
import { AppService } from '@ao/viewer-app-store';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { AppFacade } from '../app-store.facade';

@Injectable({ providedIn: 'root' })
export class MessageActionHandler {
  private appService = inject(AppService);
  private http = inject(HttpClient);
  private browser = inject(BrowserService);
  private nativeBridge = inject(NativeBridgeService);
  private router = inject(Router);
  private window = inject(WINDOW);
  private appFacade = inject(AppFacade);
  private viewerCoreFacade = inject(ViewerCoreFacade);
  private socialFacade = inject(SocialFacade);

  trigger(item: MsgActionable, source?: string): boolean {
    const messageAction: MsgAction[] = item.actions;
    let result = true;
    for (const action of messageAction || []) {
      if (!this.triggerAction(action, source)) {
        result = false;
      }
    }
    return result;
  }

  triggerAction(action: MsgAction, source?: string): boolean {
    const type = action.type;
    let param = action.param;
    const openNewWindow = this.shouldOpeninNewWindow(action);

    if (!type) {
      return false;
    }
    switch (type) {
      // For future reference, the work needed by the following actions is mainly done at the backend
      case 'send_message':
      case 'recipientlist_add':
      case 'recipientlist_remove':
      case 'notify_by_email':
        break;

      case 'dial_phone_number':
        location.href = 'tel:' + param;
        break;

      case 'go_to_message': {
        if (!this.shouldOpeninNewWindow(action)) {
          const queryParams = { source };
          this.appFacade.redirectToMessage({ messageId: param, queryParams });
        }
        break;
      }

      case 'go_to_insights': {
        if (!this.shouldOpeninNewWindow(action)) {
          this.appFacade.redirectToInsight({ insight: param });
        }

        break;
      }

      case 'go_to_academy': {
        const queryParams = { source };
        this.appFacade.redirectToAcademy({ academyId: param, queryParams, openNewWindow });
        break;
      }

      case 'href':
        onceWithLatest(
          this.appFacade.messageInterpolationContext$,
          this.appFacade.messageContentLanguage$,
          this.viewerCoreFacade.featureFlagByKey$('allow_insecure_links'),
          (context, contentLanguage, allowInsecure) => {
            param = interpolate(param, context, contentLanguage);
            if (openNewWindow) {
              // can be used like this only as immediate callback to a click, not in response to api success, otherwise the browser will block it.
              // In certain modules we have integrated the functionality to trigger before the api, so skip those in here
              if (['feedback', 'buttongroup', 'slider', 'contactinfo', 'confirm'].indexOf(source) === -1) {
                this.openNewWindowSecurely(param, allowInsecure);
              }
            } else {
              this.window.location.href = makeHttps(param, allowInsecure);
            }
          },
        );
        break;

      case 'go_to_page_number':
        this.appFacade.goToPage(param - 1);
        break;

      case 'download':
        onceWithLatest(
          this.appFacade.messageInterpolationContext$,
          this.appFacade.messageContentLanguage$,
          this.viewerCoreFacade.featureFlagByKey$('allow_insecure_links'),
          (context, contentLanguage, allowInsecure) => {
            param = interpolate(param, context, contentLanguage);
            const unsplashImageId = action.unsplash_image_id;

            if (unsplashImageId) {
              // Track Unsplash image download. Allow the request to finish within .5 seconds
              this.http
                .get(`${environment.apiBaseUrl}/api/v1/unsplash/track-download/${unsplashImageId}`)
                .pipe(
                  timeout(500),
                  catchError((error) => {
                    return of(error);
                  }),
                )
                .subscribe(() => this.triggerDownload(param, allowInsecure));
            } else {
              this.triggerDownload(param, allowInsecure);
            }
          },
        );
        break;

      case 'redirect_original':
        onceWithLatest(this.viewerCoreFacade.origin$, this.viewerCoreFacade.keycode$, (origin, keycode) => {
          this.window.location.href = `/${origin}/${keycode}`;
        });
        break;
      case 'go_to_social':
        onceWithLatest(
          this.socialFacade.socialGroupEntities$,
          this.viewerCoreFacade.origin$,
          this.viewerCoreFacade.keycode$,
          (groups, origin, keycode) => {
            const hasAccess = !param || (param && groups[param]);
            const group = groups[param] || groups['ALL_POSTS'];
            const endPath = hasAccess
              ? `${group.name.toLowerCase().replace(/([^\w]|_)/g, '-')}-${group.id}`
              : 'no-group-access';
            const components = ['/', origin, keycode, 'social', endPath];

            this.router.navigate(components);
          },
        );
        break;
      case 'go_to_directory':
        onceWithLatest(this.viewerCoreFacade.origin$, this.viewerCoreFacade.keycode$, (origin, keycode) => {
          this.router.navigate(['/', origin, keycode, 'directory']);
        });
        break;
    }
    return true;
  }

  getUnreadCounts(items: MsgActionable[], value: Record<string, any>): number[] {
    return items.map((item) => {
      const messageActions: MsgAction[] = item.actions || [];
      for (const action of messageActions) {
        if (action.type === 'go_to_message' && action.param) {
          const param = action.param.toString();
          if (Object.prototype.hasOwnProperty.call(value, param)) {
            return value[param] || 0;
          }
        }
      }
      return 0;
    });
  }

  openNewWindow(item: MsgActionable, allowInsecure: boolean): boolean {
    // to avoid popup blocker, we need to trigger open in a new link actions straight away instead of dispatch/effect
    // this applies to all message actions trigerred in a new window (href, go_to_message, go_to_insights)
    const messageActions: MsgAction[] = item.actions || [];
    for (const action of messageActions) {
      const openNewWindow = this.shouldOpeninNewWindow(action);
      if (openNewWindow && action.param) {
        if (action.type === 'href') {
          onceWithLatest(
            this.appFacade.messageInterpolationContext$,
            this.appFacade.messageContentLanguage$,
            (context, contentLanguage) => {
              const param = interpolate(action.param, context, contentLanguage);
              this.openNewWindowSecurely(param, allowInsecure);
            },
          );
        } else if (action.type === 'go_to_message') {
          onceWithLatest(this.viewerCoreFacade.keycode$, (keycode) => {
            const url = this.appService.goToMessageUrl(keycode, action.param);
            this.window.open(url);
          });
        } else if (action.type === 'go_to_insights') {
          onceWithLatest(
            this.viewerCoreFacade.keycode$,
            this.viewerCoreFacade.contact$,
            this.viewerCoreFacade.origin$,
            (keycode, contact, origin) => {
              this.window.open(`${this.window.origin}/${origin}/${keycode}/profile/${contact.id}/${action.param}`);
            },
          );
        }
        return true;
      }
    }
    return false;
  }

  openNewWindowSecurely(url: string, allowInsecure: boolean) {
    // secure outbound links, see: https://tinyurl.com/y52ruggl
    this.window.open(makeHttps(url, allowInsecure), '_blank', 'noopener, noreferrer');
  }

  shouldOpeninNewWindow(action: MsgAction) {
    return (
      // never open in new window when in standalone mode on iOS
      !this.browser.isStandaloneIOS && <any>action.open_in_new_window !== '0' && Boolean(action.open_in_new_window)
    );
  }

  // Helper method for actions that needs to trigger from a track event
  shouldTriggerImmediately(msgActions: MsgAction[] = []) {
    return (msgActions || []).some((action: MsgAction) => {
      const openNewWindow = this.shouldOpeninNewWindow(action);

      // 'mailto:' links need to happen in the user interaction context otherwise safari will block
      const isMailTo = action.type === 'href' && action.param.startsWith('mailto:');

      // 'go_to_message' in a new window needs to happen in the user interaction context otherwise safari will block
      const msgInNewWindow = action.type === 'go_to_message' && openNewWindow;

      // 'go_to_social' can execute immediately as it doesn't cancel tracking
      const isGoToSocial = action.type === 'go_to_social';

      return msgInNewWindow || isMailTo || isGoToSocial;
    });
  }

  triggerDownload(url: string, allowInsecure: boolean) {
    // Try to handle Native App
    if (this.browser.isNativeAppCookieSet()) {
      const nativeCallSuccess = this.nativeBridge.downloadFile(url, !!allowInsecure);
      if (nativeCallSuccess) {
        return;
      }
    }

    // other browsers should be handled programmatically
    const link = document.createElement('a');
    link.href = makeHttps(url, allowInsecure);
    link.style.display = 'none';
    if (typeof link.download !== 'undefined') {
      link.download = url.substring(url.lastIndexOf('/') + 1, url.length);
    }
    document.body.appendChild(link);

    if (document.createEvent) {
      const e = document.createEvent('MouseEvents');
      e.initEvent('click', true, true);
      link.dispatchEvent(e);
    } else {
      link.click();
    }
    document.body.removeChild(link);
  }
}
