import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MsgModule, MsgModuleText, Theme } from '@ao/data-models';
import { environment } from '@ao/environments';
import { BrowserService, ICON_LINKS, IconLink, contrast, hexToRgb } from '@ao/utilities';

@Injectable({ providedIn: 'root' })
export class MessageHeadService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private title: Title,
    private meta: Meta,
    private browserService: BrowserService,
  ) {}

  setTitle(title: string) {
    if (title) {
      this.title.setTitle(title);
      this.meta.updateTag({ name: 'apple-mobile-web-app-title', content: title });
      this.meta.updateTag({ property: 'og:title', content: title }, 'property="og:title"');
    }
  }

  // I activate the favicon with the given name / identifier.
  updateThemeAndIcons(theme: Theme, isPWA: boolean) {
    if (theme) {
      this.removePreviousElements();

      const baseUrl = theme?.favicon_url
        ? theme.favicon_url.slice(0, theme.favicon_url.lastIndexOf('/')).replace(/^https?:/, '')
        : '/assets/icons';
      for (const iconLink of ICON_LINKS) {
        this.createLinkElement({
          ...iconLink,
          href: `${baseUrl}/${iconLink.href}`,
        });
      }
      // Add MS meta tags
      [['TileImage', 144], ...[70, 150, 310].map((size) => [`square${size}x${size}`, size])].forEach(([name, size]) => {
        this.meta.updateTag({ name: `msapplication-${name}`, content: `${baseUrl}/mstile-${size}x${size}.png` });
      });

      // Chrome, Firefox OS and Opera
      // @see https://developers.google.com/web/fundamentals/design-and-ux/browser-customization/
      const themeColor = theme?.nav_bg_color ? theme.nav_bg_color : 'ffffff';
      this.meta.updateTag({ name: 'theme-color', content: `#${themeColor}` });
      this.meta.updateTag({ name: 'msapplication-TileColor', content: `#${themeColor}` });

      // iOS: maximize contrast when creating native-like experience
      if (isPWA) {
        this.meta.updateTag({ name: 'apple-mobile-web-app-capable', value: 'yes' });
        const c = contrast(hexToRgb('ffffff'), hexToRgb(themeColor));
        if (c < 5) {
          this.meta.updateTag({ name: 'apple-mobile-web-app-status-bar-style', value: 'default' });
        }
      }
      this.updateViewport();
    }
  }

  updateMetaTags(pageModules: MsgModule[]) {
    if (pageModules?.length) {
      const firstTextModule = pageModules.find((m) => m.type === 'text') as MsgModuleText;
      if (firstTextModule) {
        this.meta.updateTag(
          { property: 'og:description', content: firstTextModule.raw_text },
          'property="og:description"',
        );
      } else {
        this.meta.removeTag('property="og:description"');
      }
    } else {
      this.meta.removeTag('property="og:description"');
      this.meta.removeTag('property="og:description"');
    }
    this.updateViewport();
  }

  updateManifest(origin: string, keycode: string) {
    let element = this.document.querySelector('link[rel="manifest"]');
    if (!element) {
      element = this.document.createElement('link');
      element.setAttribute('rel', 'manifest');
      this.document.head.appendChild(element);
    }
    const manifestURL = `${environment.apiBaseUrl}/api/v1/viewer/${origin}/${keycode}/manifest.webmanifest`;
    element.setAttribute('href', manifestURL);
    this.updateViewport();
  }

  private removePreviousElements() {
    const elements = this.document.querySelectorAll('link[rel*="icon"], meta[name*="msapplication-"]');
    for (const element of Array.from(elements)) {
      element.parentNode.removeChild(element);
    }
  }

  private createLinkElement(iconLink: IconLink) {
    const element = this.document.createElement('link');
    Object.keys(iconLink).forEach((key) => element.setAttribute(key, iconLink[key]));
    this.document.head.appendChild(element);
  }

  private updateViewport() {
    if (
      this.browserService.isNativeAppCookieSet() ||
      this.browserService.isStandaloneIOS ||
      this.browserService.isStandaloneOther
    ) {
      this.meta.updateTag({
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover',
      });
    } else {
      this.meta.updateTag({
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, viewport-fit=cover',
      });
    }
  }
}
