<div
  *ngIf="loading; else previewContent"
  class="ao-link-preview-card__loading-container"
  [style.height]="previewLayout === 'card' ? '254px' : '42px'"
>
  <ao-spinner class="ao-link-preview-card__loading-spinner"></ao-spinner>
</div>

<ng-template #previewContent>
  <ng-container *ngIf="previewLayout === 'card'; else listItem">
    <a (click)="openLink()">
      <div class="ao-link-preview-card__card">
        <div
          *ngIf="imageUrl"
          class="ao-link-preview-card__card__image"
          [style.backgroundImage]="'url(' + imageUrl + ')'"
        >
          <button *ngIf="showCloseButton" class="ao-link-preview-card__card__close-button" (click)="onClose($event)">
            <ao-icon name="close" [size]="18"></ao-icon>
          </button>
        </div>
        <div class="ao-link-preview-card__card__info">
          <div class="ao-link-preview-card__card__site-info">
            <div class="ao-font-small-bold ao-link-preview-card__card__title">{{ previewTitle }}</div>
            <div class="ao-font-tiny ao-link-preview-card__card__url">{{ hostName }}</div>
          </div>
          <button
            *ngIf="!imageUrl && showCloseButton"
            class="ao-link-preview-card__card__close-button"
            (click)="onClose($event)"
          >
            <ao-icon name="close" [size]="18"></ao-icon>
          </button>
        </div>
      </div>
    </a>
  </ng-container>
  <ng-template #listItem>
    <ao-generic-list-item
      class="ao-link-preview-card__list-item"
      @fadeIn
      [listItemType]="listItemType"
      [iconSize]="32"
      [iconName]="iconName"
      [iconColor]="iconColor"
      [iconBackgroundColor]="iconBackgroundColor"
      [image]="imageUrl"
      [graphicSize]="listItemType === 'coverImage' ? 48 : 0"
      [graphicWithLeftRoundCorners]="true"
      [compact]="true"
    >
      <div
        class="ao-font-body2-bold ao-link-preview-card__title"
        [ngClass]="{ 'ao-link-preview-card__title--disabled': previewType === 'disabled' }"
      >
        {{ previewTitle | translate }}
      </div>
      <ng-template aoListItemSubTitleTemplate>
        <div class="ao-font-small ao-link-preview-card__description">{{ description | translate }}</div>
      </ng-template>
    </ao-generic-list-item>
  </ng-template>
</ng-template>
