<cdk-virtual-scroll-viewport
  class="ao-infinite-scroll__scroll"
  [itemSize]="itemSize"
  [minBufferPx]="minBufferPx"
  [maxBufferPx]="maxBufferPx"
  [orientation]="orientation"
  (scrolledIndexChange)="onScroll()"
>
  <ng-container *cdkVirtualFor="let item of items; trackBy: trackById; let index = index">
    <ng-container
      [ngTemplateOutlet]="itemTemplateRef"
      [ngTemplateOutletContext]="{ $implicit: item, index }"
    ></ng-container>
  </ng-container>
  <ng-content></ng-content>
</cdk-virtual-scroll-viewport>
