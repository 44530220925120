import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { ERROR_CONSTANT } from '@ao/data-models';
import { onceDefined, WINDOW } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ViewerFeatureFlagGuard {
  constructor(
    private viewerCoreFacade: ViewerCoreFacade,
    private router: Router,
    @Inject(WINDOW) private _window: Window
  ) {}

  canLoad(route: Route, segments: UrlSegment[]) {
    return this.viewerCoreFacade.keycode$.pipe(
      withLatestFrom(this.viewerCoreFacade.origin$),
      take(1),
      switchMap(([origin, keycode]) => {
        const featureFlag = route.data && route.data.featureFlag;
        if (!featureFlag) {
          throw new Error(`Property "featureFlag" is missing in route.data`);
        }
        return this.hasFeatureFlag(featureFlag, origin, keycode);
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.viewerCoreFacade.keycode$.pipe(
      withLatestFrom(this.viewerCoreFacade.origin$),
      take(1),
      switchMap(([origin, keycode]) => {
        const featureFlag = route.data && route.data.featureFlag;
        if (!featureFlag) {
          throw new Error(`Property "featureFlag" is missing in route.data`);
        }
        return this.hasFeatureFlag(featureFlag, origin, keycode);
      })
    );
  }

  private hasFeatureFlag(
    featureFlag: string | string[],
    origin: string,
    keycode: string
  ) {
    return this.viewerCoreFacade.featureFlags$.pipe(
      onceDefined(),
      map((featureFlags) => {
        if (typeof featureFlag === 'string' && featureFlags[featureFlag]) {
          return true;
        } else if (
          Array.isArray(featureFlag) &&
          featureFlag.every((ff) => !!featureFlags[ff])
        ) {
          return true;
        } else {
          setTimeout(() =>
            this.router.navigate(['error'], {
              queryParams: {
                origin,
                keycode,
                customMessage: ERROR_CONSTANT.FEATURE_DISABLED.key,
                previousPath:
                  this._window.location.pathname + this._window.location.search,
              },
            })
          );
          return false;
        }
      })
    );
  }
}
