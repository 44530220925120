import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchState } from '../reducers/search.reducers';

export const selectSearchState = createFeatureSelector<SearchState>('search');

export const selectQuery = createSelector(selectSearchState, (state: SearchState) => state.query);

export const selectResults = createSelector(selectSearchState, (state: SearchState) => state.results);

export const selectLoading = createSelector(selectSearchState, (state: SearchState) => state.isLoading);

export const selectError = createSelector(selectSearchState, (state: SearchState) => state.error);

export const selectPagination = createSelector(selectSearchState, (state: SearchState) => state.pagination);

export const selectFilters = createSelector(selectSearchState, (state: SearchState) => state.filters);

export const selectSorting = createSelector(selectSearchState, (state: SearchState) => state.sorting);

export const selectRecentSearches = createSelector(selectSearchState, (state: SearchState) => state.recentSearches);

export const selectLastVisitedItem = createSelector(
  selectSearchState,
  (state: SearchState) => state.lastVisitedItemIndex,
);

export const paginationAvailable = createSelector(selectSearchState, (state: SearchState) => state.paginationAvailable);
