import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Self, SimpleChanges } from '@angular/core';

type Spacings = '' | 'tiny' | 'default' | 'big';
const className = 'ao-hr-element';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ao-hr-element',
  template: '',
  styleUrls: ['./hr-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgClass],
})
export class HrElementComponent implements OnChanges, OnInit {
  @Input() spacing?: Spacings = '';
  @Input() class = ''; // override the standard class attr with a new one.

  constructor(@Self() private ngClass: NgClass) {}

  ngOnInit() {
    this.updateClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateClasses();
  }

  private updateClasses() {
    const existing = (this.class || '').match(/[^\s]+/g);
    const classes = [
      className,
      ...(existing ? existing : []),
      ...(this.spacing ? [`${className}--spacing-${this.spacing}`] : []),
    ];

    this.ngClass.ngClass = classes;
    this.ngClass.ngDoCheck();
  }
}
