<ao-message-modal *ngIf="open" [open]="open" [width]="maxWidth" (overlayClick)="onClose()" (backdropClick)="onClose()">
  <div
    class="ao-message-modal-card__overlay"
    (click)="onClose()"
    [class.ao-message-modal-card__overlay--modal-inside-modal]="modalInsideModal"
  >
    <ao-card
      class="ao-message-modal-card__wrapper"
      [style.maxWidth]="maxWidth || '100%'"
      [class.ao-message-modal-card__wrapper--login-page]="loginPage"
      [style.width]="autoWidth ? 'auto' : '100%'"
      [style.overflow]="overflowVisible ? 'visible' : 'auto'"
      [@container]="'in'"
      cdkTrapFocus
      cdkTrapFocusAutoCapture
      (click)="preventBubbling($event)"
    >
      <!-- close button -->
      <button
        *ngIf="showClose"
        class="ao-message-modal-card__close"
        type="button"
        [attr.aria-label]="'Close [modal]' | translate"
        (click)="onClose()"
      >
        <ao-icon name="close" [size]="24"></ao-icon>
      </button>

      <div *ngIf="headerText" class="ao-message-modal-card__header">
        <!-- custom html headers -->
        <p class="ao-modal__header__top" *ngIf="headerText">{{ headerText | translate }}</p>
      </div>

      <!-- content -->
      <div
        class="ao-message-modal-card__content"
        [style.overflow]="overflowVisible ? 'visible' : 'auto'"
        [ngClass]="{ 'ao-message-modal-card__content--has-footer': aoModalFooter }"
      >
        <ng-content></ng-content>
      </div>
      <div class="ao-message-modal-card__footer" [style.display]="aoModalFooter ? 'block' : 'none'">
        <ng-template *ngIf="aoModalFooter" [ngTemplateOutlet]="aoModalFooter"></ng-template>
      </div>
    </ao-card>
  </div>
</ao-message-modal>
