import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { SEARCH_STATE_KEY, searchReducer } from './+state/reducers/search.reducers';
import { SearchEffects } from './+state/effects/search.effects';
import { metaReducers } from './+state/reducers/meta-reducers';
import { SearchFacade } from './search-facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(SEARCH_STATE_KEY, searchReducer, { metaReducers }),
    EffectsModule.forFeature([SearchEffects]),
  ],
  providers: [SearchFacade],
  exports: [StoreModule, EffectsModule],
})
export class SearchStoreModule {}
