<div *ngIf="valueName" class="ao-progress-linear__label">
  <div class="ao-font-tiny ao-progress-linear__label-name">{{ valueName }}</div>
  <div class="ao-font-body2 ao-progress-linear__label-value">
    {{ value }}{{ value >= 0 && maxValue === 100 ? '%' : '' }}
  </div>
</div>
<ao-progress-bar [color]="color" [value]="value / (maxValue || 100)" [size]="size"></ao-progress-bar>

<div *ngIf="_hasTarget" class="ao-progress-linear__target" [style.left.%]="(target / maxValue) * 100"></div>
<div
  *ngIf="_hasTarget"
  class="ao-font-tiny ao-progress-linear__target-label"
  [style.right]="'calc(' + (100 - (target / maxValue) * 100) + '% - 1em)'"
>
  {{ targetLabel }}
</div>
