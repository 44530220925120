import { ComponentRef, createNgModule, Injector, NgModuleRef, ViewContainerRef } from '@angular/core';

export async function lazyLoadModuleComponent<T, C>(config: {
  loader: () => Promise<any>;
  moduleName: string;
  componentGetMethod?: string;
  containerRef: ViewContainerRef;
  injector: Injector;
}): Promise<ComponentRef<C>> {
  let moduleRef: NgModuleRef<T>;
  await config
    .loader()
    .then((m) => m[config.moduleName])
    .then((m) => {
      moduleRef = createNgModule(m, config.injector);
    });
  const moduleComponetGetterMethod = config.componentGetMethod || 'getComponent';
  const component = moduleRef.instance[moduleComponetGetterMethod]();
  const compRef: ComponentRef<C> = config.containerRef.createComponent(component, { ngModuleRef: moduleRef });
  return compRef;
}
