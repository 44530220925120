// TODO remove this Record<string, any>
export const specialChannels: Record<string, any> = {};
Object.defineProperties(specialChannels, {
  ALL_POSTS: { value: 'ALL_POSTS', writable: false },
  MY_POSTS: { value: 'MY_POSTS', writable: false },
  MY_MENTIONS: { value: 'MY_MENTIONS', writable: false },
  MY_REPORTS_POSTS: { value: 'MY_REPORTS_POSTS', writable: false },
});
// TODO remove this Record<string, any>
export const specialChannelNames: Record<string, any> = {};
Object.defineProperties(specialChannelNames, {
  ALL_POSTS: { value: 'All posts', writable: false },
  MY_POSTS: { value: 'My posts', writable: false },
  MY_MENTIONS: { value: 'My mentions', writable: false },
  MY_REPORTS_POSTS: { value: `My team's posts`, writable: false },
});
