<div class="ao-media-attachment__wrapper">
  <div class="ao-media-attachment__inner" [ngClass]="{ 'ao-media-attachment--bg-dark': blackBg }">
    <div
      class="ao-media-attachment__thumbnail"
      [style.background-image]="thumbnailUrl ? 'url(' + thumbnailUrl + ')' : false"
    ></div>

    <div class="ao-media-attachment__overlay" *ngIf="overlay"></div>

    <div class="ao-media-attachment__center" *ngIf="iconName || videoIcon">
      <ao-icon
        [name]="videoIcon ? 'play_circle_outline' : iconName"
        [size]="32"
        [color]="iconColor ? iconColor : iconDark ? 'ink' : 'snow'"
      ></ao-icon>
    </div>

    <div class="ao-media-attachment__center ao-media-attachment__spinner" *ngIf="spinner">
      <ao-spinner [progress]="progress" [size]="36"></ao-spinner>
    </div>
    <div *ngIf="progress" class="ao-media-attachment__current-progress ao-media-attachment__center">
      {{ progress }}%
    </div>
    <div *ngIf="label" class="ao-font-tiny ao-media-attachment__label">
      <div class="ao-media-attachment__label-left">
        {{ label || 'Uploading' | translate }}
      </div>
      <div *ngIf="labelSuffix" class="ao-media-attachment__label-right">{{ labelSuffix }}</div>
    </div>
  </div>
  <div
    role="button"
    [title]="'Remove' | translate"
    class="ao-media-attachment__remove"
    (click)="onRemoveClick()"
    *ngIf="removable"
  >
    <ao-icon name="close"></ao-icon>
  </div>
</div>
