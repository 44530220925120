<div class="ao-gauge" [ngClass]="{ 'ao-gauge--small': _size === 'small', 'ao-gauge--tiny': _size === 'tiny' }">
  <ao-custom-icon
    *ngIf="iconName"
    class="ao-gauge__inside-icon"
    [size]="_iconSize"
    [icon]="iconName"
    [active]="true"
    [ngClass]="{ 'ao-gauge__inside-icon--large': _size === 'large' }"
  >
  </ao-custom-icon>
  <div class="ao-gauge__container">
    <svg class="ao-gauge__svg" width="100%" [attr.viewBox]="'0 0 ' + _maxWidth + ' ' + _maxWidth">
      <g class="ao-gauge__group" [attr.transform]="'rotate(90 ' + _center + ')'">
        <circle
          class="ao-gauge__track"
          [ngClass]="{
            'ao-gauge__track--yellow': iconName === 'communicate',
            'ao-gauge__track--green': iconName === 'train',
            'ao-gauge__track--blue': iconName === 'lead'
          }"
          [attr.cx]="_maxWidth * 0.5"
          [attr.cy]="_maxWidth * 0.5"
          [attr.r]="_radius"
          [attr.stroke-width]="_strokeWidth"
        ></circle>
        <circle
          class="ao-gauge__bar"
          [ngClass]="{ 'ao-gauge__bar--empty': _empty }"
          [attr.cx]="_maxWidth * 0.5"
          [attr.cy]="_maxWidth * 0.5"
          [style.opacity]="value > 0 ? 1 : 0"
          [style.color]="getColor(value)"
          [attr.r]="_radius"
          [attr.stroke-width]="_strokeWidth"
          [attr.stroke-dasharray]="_dasharray"
        ></circle>
        <g *ngIf="mediumRange" [attr.transform]="_targetRotate">
          <line
            class="ao-gauge__target"
            [attr.stroke-width]="_strokeWidth * 0.5"
            [attr.x1]="_maxWidth + 1.5"
            [attr.x2]="_maxWidth - 1.5 - _strokeWidth"
            [attr.y1]="_maxWidth * 0.5"
            [attr.y2]="_maxWidth * 0.5"
          ></line>
          <text
            *ngIf="_size === 'large'"
            class="ao-gauge__target-text"
            [attr.transform]="_targetTextRotate"
            dominant-baseline="end"
            text-anchor="middle"
            [attr.x]="_maxWidth + _offset"
            [attr.y]="_maxWidth * 0.5"
            dy="2"
            translate
          >
            Target
          </text>
        </g>
      </g>
    </svg>
  </div>
  <div class="ao-gauge__detail-wrapper" *ngIf="showDisplayValue">
    <div class="ao-gauge__detail">
      <div class="ao-gauge__legend">
        <ng-content></ng-content>
      </div>
      <!--maybe need to edit this-->
      <div class="ao-gauge__label ao-gauge__label--black" *ngIf="!_empty">
        <ao-animated-number
          [value]="hasDisplayValue ? displayValue : value"
          [percent]="!hasDisplayValue"
        ></ao-animated-number>
      </div>
      <div class="ao-gauge__label" *ngIf="_empty">-</div>
    </div>
  </div>
</div>
