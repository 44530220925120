import { Injectable } from '@angular/core';
import { ampliViewerFrontend, ShareButtonPressedProperties } from '@ao-amplitude/viewer-frontend';

@Injectable({ providedIn: 'root' })
export class ViewerTrackingService {
  shareButtonPressed(
    messageId: ShareButtonPressedProperties['itemId'],
    options?: {
      type?: ShareButtonPressedProperties['itemType'];
      shareButtonPressed?: ShareButtonPressedProperties['shareButtonPressed'];
    },
  ) {
    ampliViewerFrontend.shareButtonPressed({
      itemId: messageId,
      itemType: options?.type || 'message', // default only because it's the first place implemented
      shareButtonPressed: options?.shareButtonPressed || 'shareToGroup', // default only because it's the first place implemented
    });
  }

  taskCompleted(taskId: number, taskCompletionContext: 'overview' | 'topCheckbox' | 'bottomButton') {
    ampliViewerFrontend.taskCompleted({ taskId, taskCompletionContext });
  }
}
