<div class="ao-pagination">
  <ao-progress-linear size="small" color="blue" [value]="((currentPage + 1) / numPages) * 100"></ao-progress-linear>
  <div
    class="ao-pagination__icon ao-pagination__icon-left"
    [ngClass]="{ 'ao-pagination__icon--disabled': currentPage === 0 }"
  >
    <ao-icon role="button" [size]="36" name="keyboard_arrow_left" (click)="onPreviousPage()"></ao-icon>
  </div>
  <span class="ao-pagination__page-number">{{ currentPage + 1 }}/{{ numPages }}</span>
  <div
    class="ao-pagination__icon ao-pagination__icon-right"
    [ngClass]="{ 'ao-pagination__icon--disabled': currentPage + 1 === numPages }"
  >
    <ao-icon role="button" [size]="36" name="keyboard_arrow_right" (click)="onNextPage()"></ao-icon>
  </div>
</div>
