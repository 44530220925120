import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ControlValueAccessor,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { AVAILABLE_LANGUAGES, PhoneInputCountry } from '@ao/data-models';
import { filterWithCharacterFolding, getCountries } from '@ao/utilities';
import { TranslateService } from '@ngx-translate/core';
import { isValidNumber } from 'libphonenumber-js';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import scrollIntoView from 'scroll-into-view-if-needed';

const PLUS = /^\s*(?:\+|00)/;

@Component({
  selector: 'ao-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PhoneInputComponent), multi: true },
    { provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => PhoneInputComponent), multi: true },
  ],
})
export class PhoneInputComponent implements OnInit, OnDestroy, ControlValueAccessor, AsyncValidator {
  @HostBinding('class.phone-input') className = true;
  @HostBinding('attr.aria-haspopup') ariaHaspopup = 'listbox';
  @HostBinding('class.phone-input--medium') get sizeSmall() {
    return this.inputSize === 'small';
  }
  @HostBinding('class.phone-input--medium') get sizeMedium() {
    return this.inputSize === 'medium';
  }
  @HostBinding('class.phone-input--large') get sizeLarge() {
    return this.inputSize === 'large';
  }

  @Input() inputSize: 'small' | 'medium' | 'large' = 'small';
  @HostBinding('class.phone-input--error')
  @Input()
  hasError = false;

  private _defaultCountry;
  private _defaultCountrySet = false;
  @Input() set defaultCountry(value: string) {
    this._defaultCountrySet = true;
    this._defaultCountry = (value || '').toUpperCase();
  }
  get defaultCountry() {
    return this._defaultCountry;
  }

  @Input() defaultGeoIpCountryCode?: string;
  @Input() implementedInAdmin = false;

  @Output() countrySelected = new EventEmitter<PhoneInputCountry>();
  @Output() valueUpdated = new EventEmitter();

  @ViewChildren('options', { read: ElementRef }) public _options: QueryList<ElementRef>;
  @ViewChild('input', { read: ElementRef, static: false }) _input: ElementRef;

  private countries: PhoneInputCountry[] = [];
  filteredCountries: PhoneInputCountry[] = [];
  _selected: PhoneInputCountry = null;
  get selected(): PhoneInputCountry {
    return this._selected;
  }
  set selected(value: PhoneInputCountry) {
    if (this._selected !== value) {
      this.countrySelected.emit(value);
    }
    this._selected = value;
  }
  _highlighted: string;
  get highlighted(): string {
    return this._highlighted;
  }
  set highlighted(value: string) {
    this.showDropdown = true;
    const index = this.filteredCountries.findIndex((c) => c.countryCode === value);
    this.setHighlightedByIndex(index);
  }

  showDropdown = false;
  disabled = false;
  value = '';

  countryFilter: string;
  lastButtonKeyUp = Date.now();

  private destroy$ = new Subject<void>();

  constructor(private translate: TranslateService, public element: ElementRef, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const keys = getCountries().map((c) => `COUNTRY_${c.iso_3166_2}`);
    this.translate
      .stream(keys)
      .pipe(
        takeUntil(this.destroy$),
        map((values) => {
          const results = getCountries().map((c) => {
            const name = <string>values[`COUNTRY_${c.iso_3166_2}`];
            return {
              dial: c.country_code.toString(),
              countryCode: c.iso_3166_2,
              countryName: name,
            };
          });
          // SpecifyAccept a special ordering we want at top
          let preferredCountries = [];
          // Add in preferred countries by language
          if (this.translate.currentLang) {
            let selectedLocale = Object.values(AVAILABLE_LANGUAGES).find(
              ({ locale }) => this.translate.currentLang === locale,
            );
            selectedLocale =
              selectedLocale ||
              Object.values(AVAILABLE_LANGUAGES).find(
                ({ locale }) => this.translate.currentLang.split('-')[0] === locale,
              );
            selectedLocale =
              selectedLocale ||
              Object.values(AVAILABLE_LANGUAGES).find(
                ({ locale }) => this.translate.currentLang.split('-')[0] === locale.split('-')[0],
              );
            if (selectedLocale) {
              preferredCountries.push(...selectedLocale.preferredCountries);
            }
          }
          // Add in preferred country by GeoIP
          if (this.defaultGeoIpCountryCode) {
            const strippedCode =
              this.defaultGeoIpCountryCode.charAt(0) === '+'
                ? this.defaultGeoIpCountryCode.slice(1)
                : this.defaultGeoIpCountryCode;
            const matchingCountry = results.find((r) => r.dial === strippedCode);
            if (matchingCountry) {
              preferredCountries.push(matchingCountry.countryCode);
            }
          }
          // Get unique values
          preferredCountries = Array.from(new Set(preferredCountries));
          // Sort the countries by if it is listed in this specialOrder, then abc order afterwards
          results.sort((a, b) => {
            if (preferredCountries.includes(a.countryCode) && preferredCountries.includes(b.countryCode)) {
              return preferredCountries.indexOf(a.countryCode) - preferredCountries.indexOf(b.countryCode);
            } else if (preferredCountries.includes(a.countryCode)) {
              return -1;
            } else if (preferredCountries.includes(b.countryCode)) {
              return 1;
            } else {
              return a.countryName.localeCompare(b.countryName, this.translate.currentLang);
            }
          });
          return results;
        }),
      )
      .subscribe((countries) => {
        this.countries = countries;
        this.filteredCountries = countries;

        if (this.value) {
          this.selectCountryFromValue(this.value);
          if (this.startsWithPlus(this.value) && this.selected) {
            this.updatePhoneInput(this.selected.countryCode);
          }
        }

        if (!this.selected) {
          this.cdr.detectChanges();
          this.selectCountry(this._defaultCountry, false);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onButtonKeyup(event: KeyboardEvent) {
    const index = this.filteredCountries.findIndex((c) => c.countryCode === this.highlighted);
    switch (event.code) {
      case 'ArrowDown':
        this.setHighlightedByIndex(Math.min(index + 1, this.filteredCountries.length - 1));
        break;
      case 'ArrowUp':
        this.setHighlightedByIndex(Math.max(index - 1, 0));
        break;
      case 'Enter':
        this.selectCountry(this.highlighted);
        break;
    }
  }

  onButtonKeypress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      return; // When hitting enter, select the country prefix and close the dropdown
    }
    if (Date.now() - this.lastButtonKeyUp > 2000) {
      this.countryFilter = '';
    }
    const key = event.key;
    this.countryFilter += key !== null ? key : '';
    this.lastButtonKeyUp = Date.now();
    this.filterCountries();
  }

  onInputKeydown(event: KeyboardEvent) {
    if (!event) {
      return;
    }
    const modified = event.ctrlKey || event.metaKey;
    if (
      ['Backspace', 'Delete', 'Tab', 'Enter', 'Escape', 'Home', 'End', 'ArrowLeft', 'ArrowRight'].includes(event.key) ||
      // Allow: Ctrl+A Ctrl+C Ctrl+V Ctrl+X
      ('acvx'.includes(event.key) && modified)
    ) {
      return;
    }
    if (event.key && /^[0-9 ]*$/.test(event.key)) {
      return;
    } else if (event.key === '+' && (<HTMLInputElement>this._input.nativeElement).selectionStart === 0) {
      return;
    } else {
      event.preventDefault();
    }
  }

  onInputPaste(event: ClipboardEvent) {
    event.preventDefault();
    let text = '';
    if (typeof event.clipboardData === 'undefined') {
      text = (<any>window).clipboardData.getData('Text');
    } else {
      text = event.clipboardData.getData('text/plain');
    }
    const input = <HTMLInputElement>event.target;
    // eslint-disable-next-line no-useless-escape
    text = text.trim().replace(/[^\d\+]/g, '');
    if (input.selectionStart === 0 && text.startsWith('+')) {
      text = '+' + text.replace(/\+/g, '');
    } else {
      text = text.replace(/\+/g, '');
    }
    this.value = this.value.substring(0, input.selectionStart) + text + this.value.substring(input.selectionEnd);
    this.updatePhone();
  }

  private filterCountries() {
    this.filteredCountries = filterWithCharacterFolding(this.countries, this.countryFilter, (c) => c.countryName);
    this._highlighted =
      this.filterCountries && this.filteredCountries && this.filteredCountries.length
        ? this.filteredCountries[0].countryCode
        : undefined;
  }

  private setHighlightedByIndex(index: number) {
    if (index >= 0) {
      this._highlighted = this.filteredCountries[index].countryCode;
      const elem = this._options.find((_, i) => index === i);
      setTimeout(() => {
        scrollIntoView(<HTMLElement>elem.nativeElement, { scrollMode: 'if-needed' });
      });
    }
  }

  selectCountry(countryCode: string, focus = true) {
    this.updatePhoneInput(countryCode);
    this.updateValue();
    const input = <HTMLInputElement>this._input.nativeElement;
    if (focus) {
      input.focus();
      input.setSelectionRange(this.value.length, this.value.length);
    }
  }

  toggleDropdown(event?: MouseEvent) {
    this.filteredCountries = this.countries;
    if (event) {
      (<HTMLElement>event.target).focus();
    }
    this.showDropdown = !this.showDropdown;
    this.countryFilter = '';
  }

  @HostListener('keydown.escape', ['$event'])
  hideDropdownOnEscape(event: Event) {
    this.showDropdown = false;
  }

  @HostListener('window:mousedown', ['$event'])
  hideDropdownOnMousedown(event: Event) {
    if (!this.element.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  updatePhone() {
    this.selectCountryFromValue(this.value);
    this.updateValue();
    this.valueUpdated.emit(this.value);
  }

  private updatePhoneInput(countryCode: string) {
    this.showDropdown = false;
    const phoneWithoutDial: string = this.startsWithPlus(this.value)
      ? this.value.replace(PLUS, '').substr(this.selected.dial.length, this.value.length).trim()
      : this.value;

    this.selected = this.countries.find((country: PhoneInputCountry) => country.countryCode === countryCode);
    if (this.selected) {
      this.value = `+${this.selected.dial} ${phoneWithoutDial}`;
    }
  }

  private selectCountryFromValue(value: string) {
    value = value.replace(PLUS, '');

    // check if country already matches dial code
    // if so do nothing (otherwise could switch to other country)
    const dial = value.indexOf(' ') ? value.slice(0, value.indexOf(' ')) : value;
    if (this.selected && this.selected.dial === dial) {
      return;
    }

    let longest: PhoneInputCountry = null;
    for (const country of this.countries) {
      if (value.startsWith(country.dial) && ((longest && country.dial.length > longest.dial.length) || !longest)) {
        longest = country;
      }
    }
    this.selected = longest;
  }

  private updateValue() {
    if (this.onModelChange) {
      this.onModelChange(this.value.replace(/\s*/g, ''));
    }
    if (this.onTouch) {
      this.onTouch();
    }
  }

  private startsWithPlus(text: string): boolean {
    return Boolean(text.match(PLUS));
  }

  // CONTROL VALUE ACCESSOR
  /* eslint-disable @typescript-eslint/member-ordering */
  private onTouch: any;
  private onModelChange: any;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  registerOnChange(fn: any) {
    this.onModelChange = fn;
  }
  writeValue(value: string) {
    if (value !== null) {
      this.value = value || '';
    }
    this.selectCountryFromValue(this.value);
    if (this.startsWithPlus(this.value) && this.selected) {
      this.updatePhoneInput(this.selected.countryCode);
    }
  }

  validate(c: AbstractControl): Observable<ValidationErrors> {
    if (c.value && c.value.length > 0) {
      const valid = isValidNumber(c.value);
      return of(valid ? null : { valid: !valid });
    }

    return of(null);
  }
}
