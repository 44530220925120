import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ao-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteScrollComponent {
  @HostBinding('class.ao-infinite-scroll') className = true;

  @Input()
  set items(items: unknown[]) {
    if (!items) {
      this.items = [];
    } else {
      if (items.length <= this._items.length && !this.scrollToId) {
        this.scrollTop();
      }
      this._items = items;
    }
  }
  get items(): unknown[] {
    return this._items;
  }

  @Input() itemSize: number;
  @Input() infiniteScrollDistance = 0.8;
  @Input() minBufferPx: number;
  @Input() maxBufferPx: number;
  @Input() orientation: 'horizontal' | 'vertical';
  @Input() set scrollToId(value: number) {
    if (value) {
      this.scrollToElement(value);
    }
  }

  @Output() scrolled: EventEmitter<void> = new EventEmitter();

  @ContentChild('itemTemplate') itemTemplateRef: TemplateRef<unknown>;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewport: CdkVirtualScrollViewport;

  private _items: unknown[] = [];

  trackById: TrackByFunction<{ id: number | string }> = (index, item) => (item?.id ? item.id : index);

  onScroll(): void {
    const bottomOffset = this.viewport.measureScrollOffset('bottom');
    const triggerEmitMargin =
      this.items.length * this.itemSize - this.items.length * this.itemSize * this.infiniteScrollDistance;

    if (bottomOffset && bottomOffset < triggerEmitMargin) {
      this.scrolled.emit();
    }
  }

  scrollTop() {
    if (this.viewport) {
      this.viewport.scrollTo({ top: 0 });
    }
  }

  scrollToElement(index: number) {
    setTimeout(() => {
      this.viewport.scrollToIndex(index, 'instant');
    }, 0);
  }
}
