import { AvailableColors, BrandingColor, KahootBrandingColor, ThemeColor } from '../common';

export const ACTIMO_COLORS: AvailableColors<BrandingColor> = {
  ink: {
    lightest: '#9DA5B8',
    lighter: '#79839A',
    light: '#606980',
    base: '#353A46',
    dark: '#24272E',
  },
  snow: {
    lightest: '#FFFFFF',
    lighter: '#F9FAFC',
    light: '#EFF2F7',
    base: '#E5E9F2',
    dark: '#DDE2EE',
    darker: '#D6DCEB',
    darkest: '#C8D0E4',
  },
  blue: {
    lightest: '#F5F8FF',
    lighter: '#E1EAFF',
    light: '#7BA4FE',
    base: '#4680FE',
    dark: '#3968D4',
    darker: '#213991',
  },
  green: {
    lightest: '#E7FBF4',
    lighter: '#9FECD6',
    light: '#D2F9F2',
    base: '#14CAAA',
    dark: '#11997F',
  },
  yellow: {
    lightest: '#FFF5E2',
    lighter: '#FFE0AD',
    light: '#FFF8DF',
    base: '#FFB64D',
    dark: '#BD8233',
  },
  red: {
    lightest: '#FFEBEC',
    lighter: '#FFBBC1',
    light: '#FFE0E6',
    base: '#FA607F',
    dark: '#CC506E',
    darker: '#912B41',
  },
  warning: {
    lightest: '#FFF5E2',
    lighter: '#FFE0AD',
    light: '#FFCB7B',
    base: '#FFB64D',
    dark: '#C87F38',
    darker: '#914F25',
    darkest: '#592815',
  },
  info: {
    lightest: '#f2f7ff',
    lighter: '#e4eeff',
    light: '#a8c7ff',
    base: '#a8c7ff',
    dark: '#325ac8',
  },
};

export const KAHOOT_COLORS: AvailableColors<KahootBrandingColor> = {
  purple: {
    base: '#46178F',
    light: '#46178F',
  },
};

export const THEME_COLORS: Record<ThemeColor, { text: string }> = {
  light: {
    text: '#121212',
  },
  dark: {
    text: '#F5F5F5',
  },
};
