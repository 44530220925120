<ng-container *ngIf="audioSources && progress >= 100; else standardFile">
  <ao-audio-waveform-player
    [sources]="audioSources"
    [hasBackground]="true"
    [showBorder]="inComment"
  ></ao-audio-waveform-player>
  <button type="button" *ngIf="dismissable" class="ao-uploaded-file__dismiss" (click)="dismiss.emit()">
    <ao-icon name="close" [size]="20"></ao-icon>
  </button>
</ng-container>

<ng-template #standardFile>
  <div *ngIf="progress < 100; else icon" class="ao-uploaded-file__in-progress">
    <ng-container *ngIf="!processing">
      <ao-spinner class="ao-uploaded-file__spinner" [progress]="progress"></ao-spinner>
    </ng-container>
    <div *ngIf="processing" class="ao-uploaded-file__current-progress">{{ progress }}%</div>
  </div>
  <ng-template #icon>
    <div [aoFileTypeBg]="type" class="ao-uploaded-file__avatar-wrap">
      <ao-file-type-avatar class="ao-uploaded-file__avatar" [fileType]="type"> </ao-file-type-avatar>
    </div>
  </ng-template>

  <div class="ao-uploaded-file__info">
    <div class="ao-uploaded-file__title">
      <div class="ao-uploaded-file__title-start">{{ nameStart }}</div>
      <div class="ao-uploaded-file__title-end">{{ nameEnd }}</div>
    </div>
    <div *ngIf="size && type" class="ao-uploaded-file__type">
      <span class="ao-font-tiny">{{ shortType | uppercase }} &#8226; {{ size | byteFormat }}</span>
    </div>
    <div *ngIf="allowDescription && progress === 100" class="ao-font-body2-bold ao-uploaded-file__add-description">
      <span class="ao-font-body2-bold ao-uploaded-file__description" *ngIf="description; else addDescription">
        {{ description }}
      </span>
      <ng-template #addDescription>
        <span translate (click)="onAddDescriptionClick()">Add a short description</span>
      </ng-template>
    </div>
    <ng-container *ngIf="progress < 100">
      <div class="ao-font-tiny ao-uploaded-file__label" *ngIf="!processing" translate>Uploading</div>
      <div class="ao-font-tiny ao-uploaded-file__label" *ngIf="processing" translate>Audio will show when ready</div>
    </ng-container>
  </div>

  <div>
    <ao-icon
      class="ao-uploaded-file__edit-description"
      *ngIf="allowDescription && description"
      name="edit"
      [size]="24"
      (click)="onAddDescriptionClick()"
    ></ao-icon>
  </div>

  <button *ngIf="dismissable" type="button" class="ao-uploaded-file__dismiss" (click)="dismiss.emit()">
    <ao-icon name="close" [size]="20"></ao-icon>
  </button>
</ng-template>
