import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TrackByFunction,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserService } from '@ao/utilities';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AudioRecordingService } from '../../services/audio-recording.service';

// This is a counter used for generating unique ids.
let instanceId = 0;

@Component({
  selector: 'ao-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss'],
})
export class AudioRecorderComponent implements OnInit, OnDestroy {
  @HostBinding('class.ao-audio-recorder') className = true;
  @Input() fileNamePrefix = i18n('Audio note');
  @Input() fixedWidth = false;
  @Input() modalInsideModal = false;
  @Input() disabledMicrophoneText: string;

  @Output() toggleRecorderOn = new EventEmitter<any>();

  _id = ++instanceId;

  recordedTime;
  latestRecordinDuration?: number;
  blobUrl;
  file: File;
  showPlayButton = true;
  showPauseButton = false;
  currentTime;
  showDuration = true;
  microphoneDisabledModalOpen = false;
  androidNative: boolean;

  get id() {
    return 'ao-audio-recorder-' + this._id;
  }

  get _infinity() {
    return Infinity;
  }

  private destroy$ = new Subject<void>();

  @Output() addAudio = new EventEmitter<any>();

  constructor(
    public cdr: ChangeDetectorRef,
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private browser: BrowserService,
  ) {
    this.androidNative = this.browser.isNativeAppCookieSet() && this.browser.isAndroid;
  }

  ngOnInit() {
    this.audioRecordingService.prepareRecording(this.id);
    this.audioRecordingService
      .recordingFailed(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.recordedTime = null;
        this.toggleRecorderOn.emit(false);
      });

    this.audioRecordingService
      .getRecordedTime(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ time, seconds }) => {
        this.recordedTime = time;
        this.latestRecordinDuration = seconds;
      });

    combineLatest([this.audioRecordingService.getRecordedBlob(this.id), this.translate.get(this.fileNamePrefix)])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([data, translatedFilePrefix]) => {
        this.recordedTime = null;
        this.showPlayButton = true;
        this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
        this.file = new File([data.blob], `${translatedFilePrefix} ${new Date().toUTCString()}.wav`, {
          type: 'audio/wav',
        });
      });
  }

  ngOnDestroy() {
    this.abortRecording();
    this.audioRecordingService.clearRecording(this.id);
    this.destroy$.next();
    this.destroy$.complete();
  }

  startRecording() {
    if (!this.recordedTime) {
      this.toggleRecorderOn.emit(true);
      this.audioRecordingService.startRecording(this.id).then((started) => {
        if (!started) {
          this.microphoneDisabledModalOpen = true;
        }
      });
    }
  }

  abortRecording() {
    if (this.recordedTime) {
      this.recordedTime = null;
      this.audioRecordingService.abortRecording(this.id);
    }
  }

  stopRecording() {
    if (this.recordedTime) {
      // note: this.recordedTime = null; is applied within getRecordedBlob subscribe to avoid flicker of ngif cases
      this.audioRecordingService.stopRecording(this.id);
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
    this.file = null;
    this.toggleRecorderOn.emit(false);
    this.showDuration = true;
  }

  uploadAudio() {
    this.addAudio.emit(this.file);
    this.blobUrl = null;
    this.file = null;
  }

  trackByIndex: TrackByFunction<any> = (index, item) => index;
}
