import { LOADING_STATE, LOADING_STATE_PAGINATED } from '@ao/data-models';

export const mapToSkeletonLoader = (current: LOADING_STATE_PAGINATED): LOADING_STATE => {
  switch (current) {
    case LOADING_STATE_PAGINATED.unloaded:
      return LOADING_STATE.unloaded;
    case LOADING_STATE_PAGINATED.loading:
      return LOADING_STATE.loading;
    case LOADING_STATE_PAGINATED.error:
    case LOADING_STATE_PAGINATED.errorMore:
      return LOADING_STATE.error;

    case LOADING_STATE_PAGINATED.loaded:
    case LOADING_STATE_PAGINATED.loadingMore:
    case LOADING_STATE_PAGINATED.loadedMore:
      return LOADING_STATE.loaded;
  }
};
