<div class="ao-sidebar__wrapper" [ngStyle]="_sidebarStyle">
  <div class="ao-sidebar__top" *ngIf="!isMobileOrTabletOS && !menuExpandedDesktop">
    <button class="ao-sidebar__close" (click)="close.emit()">
      <span translate>Close</span> <ao-icon name="close"></ao-icon>
    </button>
  </div>
  <ng-container *ngIf="sidebar">
    <ao-sidebar-content
      [sidebar]="sidebar"
      [topBarClickTarget]="topBarClickTarget"
      [unreadCounts]="unreadCounts"
      [contact]="contact"
      [contactInitials]="contactInitials"
      [profileLink]="profileLink"
      (menuItemClicked)="menuItemClicked.emit($event)"
      (profileClicked)="profileClicked.emit($event)"
      (topBarClicked)="topBarClicked.emit()"
    ></ao-sidebar-content>
  </ng-container>
</div>
