import { MsgModuleMessageList } from '@ao/data-models';
import { getModuleRef } from '@ao/utilities';
import { Action, createReducer, on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, initialState, messageAdapter, moduleAdapter, sidebarAdapter, themeAdapter } from './app-store.state';
import { autoTranslationOns } from './auto-translation.reducer';
import { moduleChartOns } from './module-chart.reducer';
import { moduleConfirmOns } from './module-confirm.reducer';
import { moduleContactInfoOns } from './module-contact-info.reducer';
import { moduleDataPickerOns } from './module-data-picker.reducer';
import { moduleFeedbackOns } from './module-feedback.reducer';
import { moduleLikeOns } from './module-like.reducer';
import { moduleMessageListOns } from './module-messagelist.reducer';
import { moduleSmsVerifyOns } from './module-sms-verify.reducer';
import { moduleUploadOns } from './module-upload.reducer';

const appReducer = createReducer(
  initialState,
  on(appActions.RedirectToAcademyFail, appActions.LoadOneMessageFail, (state, { error }) => {
    return {
      ...state,
      error: { ...state.error, ...error, statusCode: error.code },
    };
  }),

  on(appActions.LoadOneMessageSuccess, (state, { message, modules }) => {
    return {
      ...state,
      error: {},
      messages: messageAdapter.setOne(message, { ...state.messages }),
      modules: moduleAdapter.setMany(modules, { ...state.modules }),
    };
  }),

  on(appActions.SavedMessageUpdate, (state, { itemKeycode }) => {
    const updatedMessage = messageAdapter.updateOne({ id: itemKeycode, changes: { saved: true } }, state.messages);
    return {
      ...state,
      messages: updatedMessage,
    };
  }),
  on(appActions.UnsavedMessageUpdate, (state, { itemKeycode }) => {
    const updatedMessage = messageAdapter.updateOne({ id: itemKeycode, changes: { saved: false } }, state.messages);

    return {
      ...state,
      messages: updatedMessage,
    };
  }),

  on(appActions.SavedMessageAcademyUpdate, (state, { academyId, messageKeycode }) => {
    const currentMessage = state.messages.entities[messageKeycode];
    if (currentMessage.academy.id === academyId) {
      const updatedMessage = messageAdapter.updateOne(
        { id: messageKeycode, changes: { academy: { ...currentMessage.academy, saved: true } } },
        state.messages,
      );
      return {
        ...state,
        messages: updatedMessage,
      };
    }
    return {
      ...state,
    };
  }),
  on(appActions.UnsavedMessageAcademyUpdate, (state, { academyId, messageKeycode }) => {
    const currentMessage = state.messages.entities[messageKeycode];
    if (currentMessage.academy.id === academyId) {
      const updatedMessage = messageAdapter.updateOne(
        { id: messageKeycode, changes: { academy: { ...currentMessage.academy, saved: false } } },
        state.messages,
      );
      return {
        ...state,
        messages: updatedMessage,
      };
    }

    return {
      ...state,
    };
  }),

  on(appActions.LoadThemesSuccess, (state, { themes }) => {
    return {
      ...state,
      themes: themeAdapter.upsertMany(themes, { ...state.themes }),
    };
  }),
  on(appActions.LoadSidebarsSuccess, (state, { sidebars }) => {
    return {
      ...state,
      sidebars: sidebarAdapter.upsertMany(sidebars, { ...state.sidebars }),
    };
  }),

  on(appActions.UpdateQuestionnaireValue, (state, { module, keycode, ...value }) => {
    const moduleRef = getModuleRef(module);
    return {
      ...state,
      messages: messageAdapter.updateOne(
        {
          id: keycode,
          changes: {
            questionnaire: {
              ...state.messages.entities[keycode].questionnaire,
              [moduleRef]: value,
            },
            quizData: {
              ...state.messages.entities[keycode].quizData,
              mostRecent: {
                ...state.messages.entities[keycode].quizData?.mostRecent,
                updated_at: nowWithBuffer(),
              },
            },
          },
        },
        { ...state.messages },
      ),
    };
  }),
  on(appActions.CompleteQuizQuestion, (state, { module, value, keycode }) => {
    const moduleRef = getModuleRef(module);
    const questionnaireValue = state.messages.entities[keycode]?.questionnaire[moduleRef]?.value;
    const clickedButtons = questionnaireValue
      ? Object.keys(questionnaireValue).filter((value) => questionnaireValue[value])
      : null;

    return {
      ...state,
      messages: messageAdapter.updateOne(
        {
          id: keycode,
          changes: {
            questionnaire: {
              ...state.messages.entities[keycode].questionnaire,
              [moduleRef]: {
                ...state.messages.entities[keycode].questionnaire[moduleRef],
                confirmed: true,
                ...{ value },
                ...('mode' in module && module.mode === 'quiz' ? { completed: true } : {}),
              },
            },
            quizData: {
              ...state.messages.entities[keycode].quizData,
              mostRecent: {
                ...state.messages.entities[keycode].quizData.mostRecent,
                updated_at: nowWithBuffer(),
              },
            },
            messageState: clickedButtons
              ? {
                  ...state.messages.entities[keycode].messageState,
                  [`${module.type}.${module.id}.click`]: clickedButtons,
                }
              : state.messages.entities[keycode].messageState,
          },
        },
        { ...state.messages },
      ),
    };
  }),
  on(appActions.SetQuestionnaireValues, (state, { values, keycode }) => {
    return {
      ...state,
      messages: messageAdapter.updateOne(
        {
          id: keycode,
          changes: {
            questionnaire: {
              // __rehydrated is a flag that is used to know when questionnaire is ready
              __rehydrated: true,
              ...values,
            },
          },
        },
        { ...state.messages },
      ),
    };
  }),

  on(appActions.EmitterSubscribeSuccess, (state, { channels }) => {
    return {
      ...state,
      emitterChannels: [...state.emitterChannels, ...channels],
    };
  }),
  on(appActions.EmitterUnsubscribeAllSuccess, (state) => {
    return {
      ...state,
      emitterChannels: [],
    };
  }),
  on(appActions.StartQuestionnaireRetake, (state, { keycode }) => {
    return {
      ...state,
      messages: messageAdapter.updateOne(
        { id: keycode, changes: { questionnaireRetake: true } },
        { ...state.messages },
      ),
    };
  }),
  on(appActions.EndQuestionnaireRetake, (state, { keycode }) => {
    return {
      ...state,
      messages: messageAdapter.updateOne(
        { id: keycode, changes: { questionnaireRetake: false } },
        { ...state.messages },
      ),
    };
  }),
  on(appActions.ToggleMenuSidebar, (state, { isOpen }) => {
    return {
      ...state,
      menuSideBarIsOpen: isOpen,
    };
  }),
  on(appActions.ToggleSocialSidebar, (state, { isOpen }) => {
    return {
      ...state,
      socialSidebarIsOpen: isOpen,
    };
  }),
  on(appActions.ToggleUpdateContactInfoModal, (state, { isOpen }) => {
    return {
      ...state,
      showUpdateContactInfoModal: isOpen,
    };
  }),
  on(appActions.RedirectToMessageSuccess, (state, { messageId }) => {
    const ids = state.modules?.ids?.filter((id) => typeof id === 'string' && id.startsWith('messagelist'));
    const modules = [];

    ids.forEach((id) => {
      const messagelistModule = state.modules?.entities[id] as MsgModuleMessageList;

      if (messagelistModule) {
        // Check if the message is in a messagelist module
        const messageIndex = (messagelistModule.messages || []).findIndex((message) => messageId === message.id);
        if (messageIndex > -1) {
          const messages = [...messagelistModule.messages];
          if (messagelistModule.unreadOnly) {
            // If the feed is unready only, remove the message from the messagelist
            messages.splice(messageIndex, 1);
          } else {
            // Manually set unread to false
            messages[messageIndex] = { ...messages[messageIndex], unread: false };
          }
          modules.push({
            ...messagelistModule,
            messages,
          });
        }
      }
    });
    return {
      ...state,
      modules: moduleAdapter.upsertMany(modules, { ...state.modules }),
    };
  }),

  ...moduleChartOns,
  ...moduleConfirmOns,
  ...moduleContactInfoOns,
  ...moduleDataPickerOns,
  ...moduleFeedbackOns,
  ...moduleLikeOns,
  ...moduleMessageListOns,
  ...moduleSmsVerifyOns,
  ...moduleUploadOns,
  ...autoTranslationOns,
);

export function reducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}

function nowWithBuffer() {
  const nowWithBuffer: Date | string = new Date();
  return new Date(nowWithBuffer.getTime() + 1000).toISOString();
}
