import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

export const viewerProfileKeyMapper: Record<string, string> = {
  first_name: i18n('First name'),
  last_name: i18n('Last name'),
  title: i18n('Title'),
  department: i18n('Department'),
  company: i18n('Company'),
  company_reg: i18n('Company Reg. No'),
  addr_line_1: i18n('Address Line 1'),
  addr_line_2: i18n('Address Line 2'),
  addr_state: i18n('State'),
  addr_city: i18n('City'),
  addr_zip: i18n('Zip code'),
  addr_country: i18n('Country'),
  country_code: i18n('Phone country code'),
  phone_number: i18n('Mobile number'),
  email: i18n('Email'),
  employee_id: i18n('Employee ID'),
  source_data: i18n('Source data (external id)'),
};
