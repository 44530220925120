import { MsgModuleFeedback } from '@ao/data-models';
import { getModuleRef } from '@ao/utilities';
import { on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, moduleAdapter } from './app-store.state';

export const moduleFeedbackOns = [
  on(appActions.LoadFeedbackSuccess, (state: AppState, { module, data }): AppState => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            comments: data.all,
            data,
          },
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.FeedbackSubmitSuccess, appActions.FeedbackUpdate, (state: AppState, { module, comment }): AppState => {
    const moduleRef = getModuleRef(module);
    const currentModule = state.modules.entities[moduleRef] as MsgModuleFeedback;
    const commentExists = (currentModule.comments || []).some(({ id }) => comment.id === id);
    // Sometimes the comment arrives from the emitter update before than the api... crazy
    const changes = commentExists
      ? currentModule
      : {
          comments: [...currentModule.comments, comment],
        };
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: moduleRef,
          changes,
        },
        { ...state.modules },
      ),
    };
  }),
];
