import { Injectable } from '@angular/core';
import { withLatestFromLazy } from '@ao/utilities';
import { viewerCoreActions, ViewerCoreFacade } from '@ao/viewer-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AppFacade } from '../../app-store.facade';
import { AppService } from '../../services/app-store.service';

@Injectable()
export class InsightsEffects {
  constructor(
    private actions$: Actions,
    private appService: AppService,
    private appFacade: AppFacade,
    private viewerCoreFacade: ViewerCoreFacade,
  ) {}

  loadInsightsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.LoadInsights),
      withLatestFromLazy(
        this.appFacade.insights$,
        this.appFacade.currentMessage$,
        this.viewerCoreFacade.contact$,
        this.viewerCoreFacade.contactAuthCode$,
      ),
      filter(([_, insights]) => insights && insights.active),
      switchMap(([_, __, currentMessage, contact, contactAuthCode]) => {
        return this.appService.getInsightValues(contact.id, contact.id, contactAuthCode, currentMessage.id).pipe(
          map((insightsValues) => viewerCoreActions.LoadInsightsSuccess({ data: insightsValues })),
          catchError((error) => of(viewerCoreActions.LoadInsightsFail({ error }))),
        );
      }),
    ),
  );
}
