import { HttpClient, HttpEventType, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MultipartUploadService } from '@ao/common-ui';
import {
  Paginated,
  QuillDelta,
  SocialContact,
  SocialGroup,
  SocialGroupType,
  SocialPost,
  SocialPostsRequestParams,
  SocialPrivateGroup,
  SocialWallLinkPreview,
  UploadPartProgressEvent,
} from '@ao/data-models';
import { environment } from '@ao/environments';
import { AttachmentInput, MediaItem, MessageAttachmentPreview, ReactionType } from '@ao/shared-data-models';
import { isNumeric } from '@ao/utilities';
import * as loadImage from 'blueimp-load-image';
import { Observable, Subject, from, of, throwError } from 'rxjs';
import { catchError, last, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class SocialService {
  private readonly BASE_URL = `${environment.apiBaseUrl}/api/v1/viewer`;

  constructor(
    private http: HttpClient,
    private multipartUploadService: MultipartUploadService,
  ) {}

  getPosts({
    keycode,
    limit,
    groupKey,
    unread,
    before,
  }: SocialPostsRequestParams): Observable<{ data: SocialPost[]; hasMore: boolean }> {
    const url = `${this.BASE_URL}/${keycode}/social/posts`;
    return this.http.get<{ data: SocialPost[]; hasMore: boolean }>(url, {
      params: {
        groupId: groupKey ? groupKey : 'ALL_POSTS',
        unread: unread ? '1' : '0',
        limit: limit ? limit.toString() : '5',
        order: isNumeric(groupKey) && !unread ? 'pinned,indexible_created_at' : 'indexible_created_at',
        ...(before ? { before: before.toString() } : {}),
      },
      withCredentials: true,
    });
  }

  getPost(keycode: string, postId: number): Observable<SocialPost> {
    const url = `${this.BASE_URL}/${keycode}/social/posts/${postId}`;
    return this.http.get<SocialPost>(url, { withCredentials: true });
  }

  getGroupMembers(
    keycode: string,
    groupKey: string,
    search: string = null,
    limit: number,
    offset = 0,
    excludeMyself = false,
    checkInGroup = null,
    groupType: string = null,
  ) {
    const url = `${this.BASE_URL}/${keycode}/social/members`;
    let params = new HttpParams();
    params = params.set('search', search);
    params = params.set('limit', String(limit));
    params = params.set('offset', String(offset));
    params = params.set('excludeMyself', excludeMyself ? '1' : '0');
    params = params.set('checkInGroup', checkInGroup ? '1' : '0');
    if (groupKey !== null) {
      params = params.set('groupId', groupKey);
    }
    if (groupType !== null) {
      params = params.set('groupType', groupType);
    }
    return this.http
      .get(url, { withCredentials: true, params: params })
      .pipe(map((res: any) => <Paginated<SocialContact & { inGroup?: boolean }>>res.data[0]));
  }

  getContact(keycode: string, contactId: number): Observable<SocialContact> {
    const url = `${this.BASE_URL}/${keycode}/social/contacts/${contactId}`;
    return this.http.get<{ data: SocialContact }>(url, { withCredentials: true }).pipe(map((res) => res.data));
  }

  getPostReactions(keycode: string, postId: number, reactionType?: ReactionType): Observable<SocialContact[]> {
    const url = `${this.BASE_URL}/${keycode}/social/posts/${postId}/reactions`;
    const params = reactionType ? { reactionType } : {};
    return this.http.get<SocialContact[]>(url, { withCredentials: true, params });
  }

  createPost(
    keycode: string,
    groupId: number,
    groupType: SocialGroupType,
    content: any,
    media: MediaItem[],
    parentId: number = null,
    linkPreview?: SocialWallLinkPreview,
    attachments?: AttachmentInput[],
  ) {
    const url = `${this.BASE_URL}/${keycode}/social/posts`;
    return this.http.post<SocialPost>(url, {
      groupId,
      groupType,
      content,
      media,
      parentPostId: parentId,
      linkPreview,
      attachments,
    });
  }

  editPost(
    keycode: string,
    postId: number,
    groupId: number,
    content: any,
    media: MediaItem[],
    linkPreview?: SocialWallLinkPreview,
    attachments?: AttachmentInput[],
    pinned?: boolean,
  ) {
    const url = `${this.BASE_URL}/${keycode}/social/posts/${postId}`;
    return this.http.put<SocialPost>(url, {
      groupId,
      content,
      media,
      linkPreview,
      attachments,
      ...(pinned !== undefined ? { pinned } : {}),
    });
  }

  deletePost(keycode: string, postId: number) {
    const url = `${this.BASE_URL}/${keycode}/social/posts/${postId}`;
    return this.http.delete<null>(url);
  }

  uploadMedia(keycode: string, groupId: number, file: File, progress: Subject<[number, number]>) {
    const url = `${this.BASE_URL}/${keycode}/social/groups/${groupId}/media`;
    const fd = new FormData();
    fd.append('files', file);
    const request = new HttpRequest('POST', url, fd, {
      reportProgress: true,
      withCredentials: true,
    });
    return this.http.request(request).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          progress.next([event.loaded, event.total]);
        }
      }),
      last(),
      tap(() => progress.complete()),
      map((res) => (<HttpResponse<MediaItem>>res).body),
    );
  }

  getMedia(keycode: string, groupId: number, mediaId: number) {
    const url = `${this.BASE_URL}/${keycode}/social/groups/${groupId}/media/${mediaId}`;
    return this.http.get<MediaItem>(url, { withCredentials: true });
  }

  trackEvent(statpack: string) {
    const url = `${environment.apiBaseUrl}/api/v1/event/registerBulk`;
    return this.http.post(url, [statpack]);
  }

  reactToPost(keycode: string, postId: number, reactionType: ReactionType) {
    const url = `${this.BASE_URL}/${keycode}/social/posts/${postId}/react`;
    return this.http.post<null>(url, { reactionType });
  }

  unreactPost(keycode: string, postId: number) {
    const url = `${this.BASE_URL}/${keycode}/social/posts/${postId}/react`;
    return this.http.delete<null>(url, {});
  }

  getPostViews(keycode: string, postId: number, search: string): Observable<SocialContact[]> {
    const url = `${this.BASE_URL}/${keycode}/social/posts/${postId}/views?search=${search}`;
    return this.http.get<SocialContact[]>(url, { withCredentials: true });
  }

  getMessageViews(keycode: string, id: number, search: string): Observable<SocialContact[]> {
    const url = `${this.BASE_URL}/${keycode}/messages/${id}/views?search=${search}`;
    return this.http.get<SocialContact[]>(url, { withCredentials: true });
  }

  loadTranslation(postId: number, keycode: string, lang: string): Observable<QuillDelta> {
    const url = `${this.BASE_URL}/${keycode}/social/posts/${postId}`;
    let params = new HttpParams();
    params = params.set('language', lang);
    params = params.set('recursive', 'false');
    return this.http.get<SocialPost>(url, { params, withCredentials: true }).pipe(map((res) => res.content));
  }

  getSocialGroups(keycode: string): Observable<SocialGroup[]> {
    const url = `${this.BASE_URL}/${keycode}/social/groups`;
    return this.http.get<SocialGroup[]>(url).pipe(
      // ensure groupId is a string (BE sends it as a number | string)
      map((groups) => groups.map((group) => ({ ...group, id: `${group.id}` }))),
      catchError((error: any) => throwError(error)),
    );
  }
  getSocialGroupsNewPostCount(): Observable<{ [groupId: number]: number }> {
    const url = `${this.BASE_URL}/social/groups/newPostCount`;
    return this.http
      .get<{ [groupId: number]: number }>(url)
      .pipe(catchError((error: unknown) => throwError(() => error)));
  }

  createGroup(keycode: string, members: number[]) {
    const url = `${this.BASE_URL}/${keycode}/social/groups`;
    return this.http
      .post(url, {
        name: '',
        members,
      })
      .pipe(map((res: any) => <SocialPrivateGroup>res.data[0]));
  }

  leaveGroup(keycode: string, groupId: number) {
    const url = `${this.BASE_URL}/${keycode}/social/groups/${groupId}/remove-me`;
    return this.http.post(url, null).pipe(map((res: any) => null));
  }

  editGroup(
    keycode: string,
    groupId: number,
    data: {
      name?: string;
      description?: string;
      add?: number[];
      remove?: number[];
      image?: Blob;
      mediaFileId?: number;
    },
  ) {
    const formData = new FormData();
    const { name, description, add, remove, image, mediaFileId } = data;
    if (typeof name !== 'undefined') formData.append('name', name || '_');
    if (typeof description !== 'undefined') formData.append('description', description || '_');
    if (image) formData.append('files', image, 'image.png');
    if (add && add.length) formData.append('add', add.join(','));
    if (remove && remove.length) formData.append('remove', remove.join(','));
    if (typeof mediaFileId !== 'undefined') formData.append('mediaFileId', mediaFileId.toString());

    const url = `${this.BASE_URL}/${keycode}/social/groups/${groupId}`;
    return this.http.put(url, formData).pipe(map((res: any) => <SocialPrivateGroup>res.data[0]));
  }

  uploadSocialWallMediaByConfig(
    {
      file,
      keycode,
      groupId,
      progress,
      isS3DirectUploadEnabled,
      fromSocialWall,
    }: {
      file: File;
      keycode: string;
      groupId: number;
      progress: Subject<[number, number]>;
      isS3DirectUploadEnabled: boolean;
      fromSocialWall?: boolean;
    },
    progressSubject?: Subject<UploadPartProgressEvent>,
  ) {
    if (!isS3DirectUploadEnabled) {
      return this.uploadMedia(keycode, groupId, file, progress);
    }

    return (
      file.type.startsWith('image')
        ? from(loadImage(file, {})).pipe(
            map((res) => {
              return { width: res.originalWidth, height: res.originalHeight };
            }),
          )
        : of({ width: null, height: null })
    ).pipe(
      switchMap(({ width, height }) => {
        const socialGroupsUrl = `${this.BASE_URL}/${keycode}/social/groups/${groupId}/media/save`;
        const startUrl = `${this.BASE_URL}/${keycode}/media/upload/start`;
        const completeUrl = `${this.BASE_URL}/${keycode}/media/upload/complete`;
        return this.multipartUploadService
          .multipartUpload({ file, width, height, startUrl, completeUrl, dontSaveFolderItem: true, progressSubject })
          .pipe(
            switchMap((res) =>
              fromSocialWall ? this.http.post(socialGroupsUrl, { mediaFileId: res.id }).pipe(map(() => res)) : of(res),
            ),
          );
      }),
    );
  }

  getPostAttachmentsPreview(keycode, type, id): Observable<{ data: MessageAttachmentPreview }> {
    const url = `${this.BASE_URL}/${keycode}/social/attachments/${type}/${id}/preview`;
    return this.http.get<{ data: MessageAttachmentPreview }>(url, { withCredentials: true });
  }

  checkGroupMembersAccess(keycode, type, id, groupId): Observable<{ data: boolean }> {
    const url = `${this.BASE_URL}/${keycode}/social/attachments/${type}/${id}/checkAccess?groupId=${groupId}`;
    return this.http.get<{ data: boolean }>(url, { withCredentials: true });
  }
}
