<ao-upload-file-drop-area [class.has-image]="hasUploadedImage || imageUrl">
  <input #filePicker name="file" type="file" accept="image/*" (change)="onFile($event)" />
  <ao-icon [size]="48" name="add_circle"></ao-icon>
</ao-upload-file-drop-area>

<div class="ao-app-media-upload__canvas-area" [hidden]="!hasUploadedImage && !imageUrl">
  <button (click)="showMediaOverlay = !showMediaOverlay" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <ao-icon [name]="'camera_alt'" [size]="16"></ao-icon>
  </button>

  <canvas [hidden]="!hasUploadedImage" #canvas height="200" width="200" (wheel)="onMouseWheelEvent($event)"></canvas>

  <img
    *ngIf="imageUrl && !hasUploadedImage"
    [src]="imageUrl"
    [height]="height"
    width="width"
    (error)="handleImageError()"
  />
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="showMediaOverlay"
  (backdropClick)="showMediaOverlay = false"
  (overlayOutsideClick)="showMediaOverlay = false"
>
  <ao-popover>
    <ao-popover-item
      [label]="'Upload media' | translate"
      icon="collections"
      (click)="filePicker.click(); this.showMediaOverlay = false"
    ></ao-popover-item>
    <ao-popover-item
      [label]="'Remove image' | translate"
      icon="delete"
      color="red"
      (click)="removeImage()"
      *ngIf="hasRemoveImage"
    ></ao-popover-item>
  </ao-popover>
</ng-template>
