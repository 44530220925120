import { ChangeDetectionStrategy, Component, HostBinding, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LOADING_STATE } from '@ao/data-models';
import { environment } from '@ao/environments';
import { onceWithLatest, WINDOW } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';

@Component({
  selector: 'ao-task-page-guard',
  templateUrl: './task-page-guard.component.html',
  styleUrls: ['./task-page-guard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPageGuardComponent implements OnInit {
  viewerCore = inject(ViewerCoreFacade);
  window = inject(WINDOW);
  router = inject(Router);
  activeRoute = inject(ActivatedRoute);
  theme$ = this.viewerCore.defaultTheme$;
  @HostBinding('class.ao-task-page-guard') className = true;

  unblocked = false;

  get isIsolatedRoute() {
    return this.router.url.startsWith('/task');
  }

  ngOnInit(): void {
    // currently the 'task' routes dont fetch any data on the logged in used to determine if they have access to the tasks
    // so we need to wait for the loading state to be loaded before we can determine if they have access to the tasks with the feature flag
    // do this now to rely on the server to redirect to correct homepage keycode for the user
    // todo: this can be change when/if we always load the nessessary user data on app load regardless of the keycode

    // sideeffect of this is that the user will be redirected to the homepage if they refresh the page on a task page

    if (this.isIsolatedRoute) {
      onceWithLatest(this.viewerCore.viewerSettings$, (setting) => {
        const taskId = this.activeRoute.snapshot.params['id'];
        const path = [setting.homepage?.origin, setting.homepage?.keycode, 'task'];
        if (taskId) {
          path.push(taskId);
        }
        this.router.navigate(path);
      });
    } else {
      onceWithLatest(this.viewerCore.loadingState$, this.viewerCore.taskManagementEnabled$, (ls, tmCompanyContext) => {
        if (!tmCompanyContext) {
          this.router.navigate(['error'], {
            queryParams: {
              customMessage: 'FEATURE_DISABLED',
              previousPath: this.window.location.pathname + this.window.location.search,
            },
          });
          return;
        }

        if (!environment.production) {
          this.viewerCore.setAppReady();
          this.unblocked = true;
          return;
        }

        if (ls === LOADING_STATE.loaded) {
          this.unblocked = true;
        } else {
          this.window.location.href = '/';
        }
      });
    }
  }
}
