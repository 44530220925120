<div *ngIf="loading; else content" class="ao-avatar__loader">
  <ao-spinner color="blue"></ao-spinner>
</div>
<ng-template #content>
  <img
    *ngIf="initialImageUrl"
    (load)="onImageLoad()"
    class="ao-avatar__image"
    [aoSrc]="image"
    [aoSrcset]="images"
    [sizes]="sizes"
    (error)="serveFallbackImage($event)"
  />
  <div
    class="ao-avatar__initials"
    [style.background-color]="_bgColor"
    [style.color]="_txtColor"
    *ngIf="displayInitials || skeleton"
  >
    <span>{{ displayInitials }}</span>
  </div>

  <div
    class="ao-avatar__placeholder"
    [style.background-color]="_bgColor"
    [style.color]="_txtColor"
    *ngIf="!displayInitials && !initialImageUrl && !skeleton"
  >
    <ao-icon name="person"></ao-icon>
  </div>
</ng-template>
