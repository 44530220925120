export const HELPDESK_URLS = {
  customDomain: 'https://helpdesk.actimo.com/en/articles/78832-domain-dkim-spf-record',
  loginTrouble: 'https://helpdesk.actimo.com/en/articles/78613-how-to-login-to-actimo',
  setReminders: 'https://helpdesk.actimo.com/en/articles/84917-how-to-publish-a-message-in-actimo#h_e2a85d4197',
  advancedSettingsAccess:
    'https://helpdesk.actimo.com/en/articles/84917-how-to-publish-a-message-in-actimo#h_db137094f2',
  advancedSettingsControl:
    'https://helpdesk.actimo.com/en/articles/84917-how-to-publish-a-message-in-actimo#h_0160dee6cc',
  advancedSettingsOther:
    'https://helpdesk.actimo.com/en/articles/84917-how-to-publish-a-message-in-actimo#h_27c5db7ef3',
  buildAcademy: 'https://helpdesk.actimo.com/en/articles/78856-how-to-build-an-academy',
  oneOnOne: 'https://helpdesk.actimo.com/en/articles/78553-one-on-one',
  pulseSurvey: 'https://helpdesk.actimo.com/en/articles/78793-pulse-survey',
  controlCenter: 'https://helpdesk.actimo.com/en/articles/78765-control-center-admin',
  kahootChallenge: 'https://helpdesk.actimo.com/en/articles/78719-kahoot-module',
  directory: 'https://helpdesk.actimo.com/en/articles/78636-employee-directory',
  productUpdates: 'https://helpdesk.actimo.com/en/collections/32533-newsletters',
  quizModule: 'https://helpdesk.actimo.com/en/articles/78751-quiz-module',
  pushNotifications: 'https://helpdesk.actimo.com/en/articles/84917-how-to-publish-a-message-in-actimo#h_795bdb9247',
  googleMaps: 'https://helpdesk.actimo.com/en/articles/78778-add-google-maps-to-your-messages',
  // static used ./frontend/apps/admin/src/static/reset-password.html
  resetPassword: 'https://helpdesk.actimo.com/en/articles/110888-forgotten-or-reset-password',
  ssoViewer: 'https://helpdesk.actimo.com/en/articles/78741-app-single-sign-on-sso',
  ssoAdmin: 'https://helpdesk.actimo.com/en/articles/78760-admin-sso-single-sign-on-and-how-to-activate-it',
};
