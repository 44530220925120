import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Inject, OnDestroy, Optional, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InterpolateParams } from '@ao/data-models';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { interpolate } from '../../utils/interpolate';

@Pipe({
  name: 'interpolateParams',
  pure: false,
})
export class InterpolateParamsPipe implements PipeTransform, OnDestroy {
  private langSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Optional() private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private cdf: ChangeDetectorRef,
  ) {
    if (translate) {
      // no cleanup, alive for the whole session
      this.langSubscription = translate.onLangChange.subscribe(() => {
        this.cdf.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  // if used for normal text, pass isString:true, otherwise characters like `&` will be ecoded to `&amp` fx.
  transform(value: string, params: InterpolateParams = {}, isString = false): any {
    const lang = (this.translate && this.translate.currentLang) || 'en';
    const el = this.createElement(value);
    const treeWalker = this.document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null);
    while (treeWalker.nextNode()) {
      if (treeWalker.currentNode.parentElement instanceof HTMLAnchorElement) {
        treeWalker.currentNode.parentElement.href = interpolate(
          treeWalker.currentNode.parentElement.href,
          params,
          lang,
        );
      }
      treeWalker.currentNode.nodeValue = interpolate(treeWalker.currentNode.nodeValue, params, lang);
    }

    return isString ? el.innerText : el.innerHTML;
  }

  private createElement(value: string) {
    const el = this.document.createElement('div');
    el.innerHTML = value;
    return el;
  }
}
