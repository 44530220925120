<!-- Explanation for "name" field: https://bytes.grubhub.com/disabling-safari-autofill-for-a-single-line-address-input-b83137b5b1c7  -->
<!--If component is implemented in viewer-->
<input
  *ngIf="!implementedInAdmin"
  #input
  ao-input
  type="tel"
  class="phone-input__input"
  name="”notASearchField”"
  autocomplete="off"
  [disabled]="disabled"
  [placeholder]="'Enter phone number' | translate"
  [(ngModel)]="value"
  (ngModelChange)="updatePhone()"
  (keydown)="onInputKeydown($event)"
  (paste)="onInputPaste($event)"
/>
<!--If component is implemented in admin-->
<input
  *ngIf="implementedInAdmin"
  #input
  ao-actimo-input
  type="tel"
  class="phone-input__input phone-input__input--admin"
  name="”notASearchField”"
  autocomplete="off"
  [disabled]="disabled"
  [placeholder]="'Enter phone number' | translate"
  [(ngModel)]="value"
  (ngModelChange)="updatePhone()"
  (keydown)="onInputKeydown($event)"
  (paste)="onInputPaste($event)"
/>

<button
  type="button"
  class="phone-input__country-button"
  (click)="toggleDropdown($event)"
  (keyup)="onButtonKeyup($event)"
  (keypress)="onButtonKeypress($event)"
  [disabled]="disabled"
>
  <ao-icon *ngIf="!selected" name="phone"></ao-icon>
  <ao-country-flag *ngIf="selected" [country]="selected?.countryCode"></ao-country-flag>
  <ao-icon name="arrow-drop-down" [size]="18"></ao-icon>
</button>
<ul role="listbox" class="phone-input__dropdown" *ngIf="showDropdown">
  <li
    #options
    role="option"
    class="phone-input__option"
    [ngClass]="{ 'phone-input__option--highlighted': country.countryCode === highlighted }"
    *ngFor="let country of filteredCountries"
    (click)="selectCountry(country.countryCode)"
    (mouseover)="highlighted = country.countryCode"
  >
    <ao-country-flag [country]="country.countryCode"></ao-country-flag>
    <span class="phone-input__option-name"
      >{{ country.countryName }}
      <span class="phone-input__option-dial">+{{ country.dial }}</span>
    </span>
  </li>
</ul>
