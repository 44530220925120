import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeHandlerService } from '@ao/common-ui';
import { Contact, IconSize, Theme } from '@ao/data-models';
import { color as c } from '@ao/utilities';

@Component({
  selector: 'ao-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent {
  @Input() avatarUrl: string;
  @Input() theme: Partial<Theme>;
  @Input() contact?: Contact = null;
  @Input() contactInitials?: string;
  @Input() profileLink?: string[];
  @Input() showSocialInNavbar: boolean;
  @Input() socialLink?: string[];
  @Input() notificationsLink: string[] = null;
  @Input() hasNotificationsUnread = false;
  @Input() iconSize: IconSize = 28;
  @Input() showSocialWall = false;

  @Output() profileClicked = new EventEmitter<string[]>();
  @Output() socialClick = new EventEmitter<string[]>();
  @Output() notificationsClick = new EventEmitter<string[]>();
  @Output() homePageClick = new EventEmitter<Event>();

  constructor(private themeHandler: ThemeHandlerService, private cdr: ChangeDetectorRef) {
    this.themeHandler.registerStyle('NavBarComponent', (theme) => {
      this.theme = theme;
      this.cdr.markForCheck();
      return `
      .ao-navbar {
        background-color: ${c(theme?.nav_bg_color, 'transparent')};
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        min-height: 52px;
        }
      `;
    });
  }
}
