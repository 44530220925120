import { Language } from '@ao/data-models';
import { createAction, props } from '@ngrx/store';

export const ToggleAutoTranslationModal = createAction('[App] Toggle AutoTranslation Modal');
export const LoadAutoTranslationLangs = createAction('[App] Load AutoTranslation Langs');
export const LoadAutoTranslationLangsSuccess = createAction(
  '[App] Load AutoTranslation Langs Success',
  props<{ autoTranslateLangs: Language[] }>(),
);
export const LoadAutoTranslationLangsFail = createAction(
  '[App] Load AutoTranslation Langs Fail',
  props<{ error: any }>(),
);

export const LoadMessageTranslation = createAction(
  '[Message] Load Message Translation',
  props<{ messageId: number }>(),
);
export const LoadMessageTranslationSuccess = createAction(
  '[Message] Load Message Translation Success',
  props<{ data: Record<string, string> }>(),
);
export const LoadMessageTranslationFail = createAction(
  '[Message] Load Message Translation Fail',
  props<{ error: any; messageId: number }>(),
);
export const RevertMessageTranslation = createAction('[Message] Revert Message Translation');
