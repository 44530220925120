import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DYNAMIC_GROUP_NAMES } from '@ao/data-models';
import { MediaItem } from '@ao/shared-data-models';
import { WINDOW, getRouteDataPage, withLatestFromLazy } from '@ao/utilities';
import { ViewerCoreFacade, viewerCoreActions } from '@ao/viewer-core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Action, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subject, forkJoin, from, merge, of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { SocialFacade } from '../social-store.facade';
import { SocialService } from '../social-store.service';
import * as socialActions from './social-store.actions';

@Injectable()
export class SocialEffects {
  constructor(
    private viewerCoreFacade: ViewerCoreFacade,
    private socialFacade: SocialFacade,
    private actions$: Actions,
    private routerStore: Store,
    private router: Router,
    private socialApi: SocialService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private route: ActivatedRoute,

    @Inject(WINDOW) private window: Window,
  ) {}

  loadSocialGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.LoadSocialGroups),
      withLatestFromLazy(this.viewerCoreFacade.keycode$),
      mergeMap(([_, keycode]) => {
        return this.socialApi.getSocialGroups(keycode).pipe(
          switchMap((groups) =>
            of(socialActions.LoadSocialGroupsSuccess({ groups }), socialActions.LoadSocialGroupsNewPostCount()),
          ),
          catchError((error) => of(socialActions.LoadSocialGroupsFail(error))),
        );
      }),
    ),
  );

  loadSocialGroupsNewPostCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.LoadSocialGroupsNewPostCount),
      mergeMap((_) => {
        return this.socialApi.getSocialGroupsNewPostCount().pipe(
          map((newPostsPerGroup) => {
            return socialActions.LoadSocialGroupsNewPostCountSuccess({ newPostsPerGroup });
          }),
          catchError((error) => of(socialActions.LoadSocialGroupsNewPostCountFail(error))),
        );
      }),
    ),
  );

  loadContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LoadContact),
      filter(({ contactId }) => !!contactId),
      delay(300),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ contactId }, keycode]) => {
        return this.socialApi.getContact(keycode, contactId).pipe(
          map((contact) => socialActions.LoadContactSuccess({ contact })),
          catchError((error) => [socialActions.LoadContactFail({ contactId, error })]),
        );
      }),
    );
  });

  createPost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.CreatePost),
      withLatestFrom(this.viewerCoreFacade.keycode$, this.socialFacade.limbo$, this.socialFacade.selectedPostId$),
      mergeMap(([{ tempId, showToast, isMessageWithSocialFooter }, keycode, limbo, selectedPostId]) => {
        const { parentId, groupId, groupType, content, attachments, linkPreview, shareAttachments, dynamicGroupId } =
          limbo[tempId];
        const media$ = attachments.map((id) => {
          return this.socialFacade.attachments$.pipe(
            delay(1), // Allow next action to kick in
            takeUntil(
              this.actions$.pipe(
                ofType(socialActions.CancelCreatePost),
                first(({ tempId: cancelTempId }) => cancelTempId === tempId),
              ),
            ),
            select((a) => a[id]),
            filter((attachment) => attachment.uploaded),
            take(1),
            map((a) => a.media),
          );
        });

        return (attachments.length ? forkJoin(media$) : of([] as MediaItem[])).pipe(
          mergeMap((media) => {
            const newGroupType = [null, undefined].includes(groupType) ? 'contact_list' : 'message';
            return this.socialApi
              .createPost(keycode, groupId, newGroupType, content, media, parentId, linkPreview, shareAttachments)
              .pipe(
                mergeMap((newPost) => {
                  const post = {
                    ...newPost,
                    ...(dynamicGroupId ? { dynamicGroupId } : {}),
                  };
                  post.messageAttachmentId =
                    post.attachments?.length > 0
                      ? post.attachments.find((attachment) => attachment.type === 'message')?.id
                      : null;

                  const returnedActions = [
                    socialActions.CreatePostSuccess({
                      post,
                      tempId,
                      showToast,
                    }),
                    socialActions.PostViewed({ postId: post.id }),
                  ] as Action[];

                  if (parentId) {
                    returnedActions.push(
                      socialActions.LoadOnePost({
                        postId: isMessageWithSocialFooter ? selectedPostId : parentId,
                        isMessageWithSocialFooter,
                      }),
                    );
                  }

                  return post.messageAttachmentId
                    ? [
                        ...returnedActions,
                        socialActions.LoadShareMessagePreview({
                          messageId: post.messageAttachmentId,
                        }),
                      ]
                    : returnedActions;
                }),
                catchError((error) =>
                  of(
                    socialActions.CreatePostFail({
                      error,
                      hasAttachments: shareAttachments?.length > 0,
                      tempId,
                    }),
                  ),
                ),
              );
          }),
        );
      }),
    );
  });

  createPostSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.CreatePostSuccess),
      filter(({ post, tempId, showToast }) => showToast && post?.attachments.length > 0),
      map(() => {
        return viewerCoreActions.ShowGenericMessageToast({
          toast: {
            title: i18n('Shared to group!'),
            listItemType: 'iconAvatar',
            iconColor: 'green',
            iconName: 'check_circle',
          },
        });
      }),
    );
  });

  createPostFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.CreatePostFail),
      map(() => {
        return viewerCoreActions.ShowGenericMessageToast({
          toast: {
            title: i18n('Error sharing post'),
            listItemType: 'iconAvatar',
            iconColor: 'red',
            iconName: 'error',
          },
        });
      }),
    );
  });

  editPost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.EditPost),
      withLatestFrom(this.viewerCoreFacade.keycode$, this.socialFacade.limbo$, this.socialFacade.postEntities$),
      mergeMap(([{ tempId, postId, media, pinned }, keycode, limbo, nposts]) => {
        const { content, attachments, linkPreview, shareAttachments } = limbo[tempId];
        // When pinning/unpinning the media is not in the limbo, so we need to get it from the post
        const existingMedia = media || [];
        const media$ = attachments.map((id) => {
          return this.socialFacade.attachments$.pipe(
            delay(1),
            takeUntil(
              this.actions$.pipe(
                ofType(socialActions.CancelEditPost),
                first(({ tempId: cancelTempId }) => cancelTempId === tempId),
              ),
            ),
            select((a) => a[id]),
            filter((attachment) => attachment.uploaded),
            take(1),
            map((a) => a.media),
          );
        });
        return (attachments.length ? forkJoin<MediaItem[]>([...media$]) : of([])).pipe(
          mergeMap((media) => {
            return this.socialApi
              .editPost(
                keycode,
                postId,
                nposts[postId].groupId,
                content,
                media.length > 0 ? media : existingMedia,
                linkPreview,
                shareAttachments,
                pinned,
              )
              .pipe(map((post) => socialActions.EditPostSuccess({ post, tempId, pinned })));
          }),
          catchError((error) => of(socialActions.EditPostFail({ postId, error }))),
        );
      }),
    );
  });

  postPinnedUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.EditPostSuccess),
      filter(({ post, pinned }) => pinned === true || pinned === false),
      switchMap(({ post, pinned }) => {
        const pinnedToastTitle = pinned ? i18n('Post pinned') : i18n('Post unpinned');
        const pinnedPostText = pinned
          ? i18n('The post has been pinned to the top of the group.')
          : i18n('The post has been unpinned from to the top of the group.');

        return of(
          viewerCoreActions.ShowGenericMessageToast({
            toast: {
              title: pinnedToastTitle,
              text: pinnedPostText,
              listItemType: 'iconAvatar',
              iconColor: 'green',
              iconName: 'check_circle',
              displayDuration: 2,
            },
          }),
        );
      }),
    );
  });

  // todo: pinned success toast

  loadPost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LoadOnePost),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ postId, isMessageWithSocialFooter }, keycode]) => {
        return this.socialApi.getPost(keycode, postId).pipe(
          map((post) =>
            socialActions.LoadOnePostSuccess({
              post,
              isMessageWithSocialFooter,
            }),
          ),
          catchError((error) => of(socialActions.LoadOnePostFail({ postId, error }))),
        );
      }),
    );
  });

  loadPostFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(socialActions.LoadOnePostFail),
        withLatestFromLazy(
          this.routerStore.select(getRouteDataPage),
          this.socialFacade.currentGroup$,
          this.viewerCoreFacade.origin$,
          this.viewerCoreFacade.keycode$,
        ),
        tap(([{ error }, pageType, group, origin, keycode]) => {
          // a load post fail on social wall should reroute to error pages
          if (pageType === 'SOCIAL') {
            // message most likely deleted
            if (error.status === 404) {
              this.router.navigate(
                [
                  '/',
                  origin,
                  keycode,
                  'social',
                  `${group.name.toLowerCase().replace(/([^\w]|_)/g, '-')}-${group.id}`,
                  'post-not-found',
                ],
                { relativeTo: this.route },
              );
              // any other issues
            } else {
              this.router.navigate(['error'], {
                queryParams: {
                  origin,
                  keycode,
                  previousPath: this.window.location.pathname + this.window.location.search,
                },
              });
            }
          }
        }),
      );
    },
    { dispatch: false },
  );

  loadPosts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LoadPosts),
      switchMap((props) => {
        return of(props).pipe(
          withLatestFrom(
            this.viewerCoreFacade.keycode$,
            this.socialFacade.pollingMedia$,
            this.socialFacade.postsByGroupId$({ groupKey: props.groupKey }),
          ),
        );
      }),
      mergeMap(([{ groupKey, limit, append, unread }, keycode, pollingMedia, currentPosts]) => {
        const notPinned = currentPosts?.filter((p) => !p.pinned);
        const before =
          append && notPinned?.length > 0 ? new Date(notPinned[notPinned.length - 1].createdAt).valueOf() : null;
        return this.socialApi.getPosts({ keycode, groupKey, limit, unread, before }).pipe(
          mergeMap(({ data, hasMore }) => {
            const posts = data.map((post) => {
              const messageAttachmentId =
                post.attachments?.length > 0
                  ? post.attachments.find((attachment) => attachment.type === 'message')?.id
                  : null;
              return {
                ...post,
                ...(DYNAMIC_GROUP_NAMES.includes(groupKey) ? { dynamicGroupId: groupKey } : {}),
                ...{ messageAttachmentId },
              };
            });
            const needsFetching = (media: MediaItem) => {
              return (
                ['video', 'audio'].includes((media.type || '').split('/')[0]) &&
                media.progress !== 100 &&
                (!media.sources || !media.sources.length)
              );
            };
            const mediaActions = posts.reduce((acc, post) => {
              // Get the media items we need to fetch
              const mediaItems = (post.media || [])
                .filter((m) => needsFetching(m) && !pollingMedia[m.id])
                .map((m) => {
                  return socialActions.PollMediaItem({
                    postId: post.id,
                    groupId: post.groupId,
                    mediaId: m.id,
                  });
                });
              return [...acc, ...mediaItems];
            }, []);
            const messageAttachmentActions = posts
              .filter((post) => post.messageAttachmentId)
              .map((post) => {
                return socialActions.LoadShareMessagePreview({
                  messageId: post.messageAttachmentId,
                });
              });

            return [
              socialActions.LoadPostsSuccess({ posts, append, hasMore }),
              ...mediaActions,
              ...messageAttachmentActions,
            ];
          }),
          catchError((error) => of(socialActions.LoadPostsFail({ error }))),
        );
      }),
    );
  });

  loadPostsFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(socialActions.LoadPostsFail),
        withLatestFrom(
          this.viewerCoreFacade.origin$,
          this.viewerCoreFacade.keycode$,
          this.routerStore.select(getRouteDataPage),
        ),
        tap(([{ error }, origin, keycode, routeDataPage]) => {
          // a load post fail on social page should navigate to an error page
          if (routeDataPage === 'SOCIAL') {
            if (error.error.code !== 'MESSAGE_EXPIRED' && error.error.code !== 'ITEM_NOT_FOUND') {
              this.router.navigate(['/', origin, keycode, 'social', 'no-group-access']);
            } else {
              this.router.navigate(['error'], {
                queryParams: {
                  origin,
                  keycode,
                  previousPath: this.window.location.pathname + this.window.location.search,
                },
              });
            }
          }
        }),
      );
    },
    { dispatch: false },
  );

  loadPostReactions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LoadPostReactions),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ postId, reactionType }, keycode]) => {
        return this.socialApi
          .getPostReactions(keycode, postId, reactionType)
          .pipe(map((contacts) => socialActions.LoadPostReactionsSuccess({ postId, contacts })));
      }),
    );
  });

  reactToPost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.ReactToPost),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ postId, reactionType }, keycode]) => {
        return this.socialApi
          .reactToPost(keycode, postId, reactionType)
          .pipe(map(() => socialActions.ReactToPostSuccess({ postId, reactionType })));
      }),
      catchError((error) => of(socialActions.ReactToPostFail({ error }))),
    );
  });

  unreactPost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.UnreactPost),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ postId }, keycode]) => {
        return this.socialApi
          .unreactPost(keycode, postId)
          .pipe(map(() => socialActions.UnreactPostSuccess({ postId })));
      }),
      catchError((error) => of(socialActions.UnreactPostFail({ error }))),
    );
  });

  postViewed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(socialActions.PostViewed),
        withLatestFrom(this.socialFacade.postEntities$),
        mergeMap(([{ postId }, posts]) => {
          const post = posts?.[postId];
          if (!post) {
            // todo: to squelch errors, but this should never happen. Investigate where the source might have undefined posts
            return EMPTY;
          }

          return this.socialApi.trackEvent(post.statpack[`socialWallPost:${postId}`].view);
        }),
      );
    },
    { dispatch: false },
  );

  groupViewed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(socialActions.GroupViewed),
        withLatestFrom(this.socialFacade.socialGroupEntities$),
        mergeMap(([{ groupKey }, groups]) => {
          if (DYNAMIC_GROUP_NAMES.includes(groupKey)) {
            return of(EMPTY);
          }
          return this.socialApi.trackEvent(groups[groupKey].statpack[`socialWallGroup:${groupKey}`].view).pipe(
            switchMap(() => {
              this.socialFacade.loadSocialGroupsNewPostCount();
              return of(EMPTY);
            }),
          );
        }),
      );
    },
    { dispatch: false },
  );

  deletePost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.DeletePost),
      withLatestFrom(
        this.socialFacade.lastCommentsByPost$,
        this.viewerCoreFacade.keycode$,
        this.socialFacade.selectedPostId$,
      ),
      mergeMap(([{ postId, isMessageWithSocialFooter }, lastComments, keycode, selectedPostId]) => {
        const parentId = Object.keys(lastComments).find((key) => lastComments[key][0] === postId);
        return this.socialApi.deletePost(keycode, postId).pipe(
          switchMap(() =>
            from([
              socialActions.DeletePostSuccess({ postId }),
              // // if post was the last comment of parent the new latest must be loaded
              ...(parentId && Number(parentId)
                ? [
                    socialActions.LoadOnePost({
                      postId: isMessageWithSocialFooter ? selectedPostId : Number(parentId),
                      isMessageWithSocialFooter,
                    }),
                  ]
                : []),
            ]),
          ),
        );
      }),
    );
  });

  uploadAttachment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.CreatePostAttachment),
      withLatestFrom(this.viewerCoreFacade.keycode$, this.viewerCoreFacade.isS3DirectUploadEnabled$),
      mergeMap(([{ ref, attachmentId, file, groupId, fromSocialWall }, keycode, isS3DirectUploadEnabled]) => {
        const progress = new Subject<[number, number]>();

        return merge(
          this.socialApi
            .uploadSocialWallMediaByConfig({
              file,
              keycode,
              groupId,
              progress,
              isS3DirectUploadEnabled,
              fromSocialWall,
            })
            .pipe(
              switchMap((completeResult) => {
                const type = (completeResult.type || '').split('/')[0];
                const hasDelayedProcessing =
                  (type === 'image' && !!isS3DirectUploadEnabled) || ['video', 'audio'].includes(type);

                if (hasDelayedProcessing) {
                  // Videos and audio files are processed after upload, so we need to keep tabs on when it completes
                  return merge(
                    of(
                      socialActions.CreatePostAttachmentSuccess({
                        ref,
                        attachmentId,
                        media: completeResult,
                        hasDelayedProcessing: true,
                      }),
                    ),
                    of({}).pipe(
                      delay(1000), // start polling after 1 second (repeats every 3 seconds)
                      mergeMap(() => this.socialFacade.attachments$),
                      take(1),
                      mergeMap((attachments) => {
                        // if the attachment is still presetn we poll to see if the media has been processed
                        if (attachments[attachmentId]) {
                          return of(
                            socialActions.PollPostAttachment({
                              ref,
                              attachmentId,
                              groupId,
                              mediaId: completeResult.id,
                            }),
                          );
                        } else {
                          return EMPTY;
                        }
                      }),
                    ),
                  );
                } else {
                  return of(
                    socialActions.CreatePostAttachmentSuccess({
                      ref,
                      attachmentId,
                      media: completeResult,
                    }),
                  );
                }
              }),
              catchError((error) => {
                const content = i18n('This filetype is not allowed for upload');
                const button = i18n('Ok');
                this.translate
                  .get([content, button])
                  .pipe(take(1))
                  .subscribe(({ [content]: contentTxt, [button]: buttonTxt }) => {
                    this.snackBar.open(contentTxt, buttonTxt, {
                      horizontalPosition: 'center',
                      duration: 2500,
                    });
                  });
                return [
                  socialActions.CreatePostAttachmentFail({
                    ref,
                    attachmentId,
                    error,
                  }),
                  socialActions.RemovePostAttachment({ ref, attachmentId }),
                ];
              }),
            ),
          progress.pipe(
            throttleTime(1000, undefined, { leading: true, trailing: true }),
            map(([current, total]) =>
              socialActions.CreatePostAttachmentProgress({
                ref,
                attachmentId,
                progress: Math.round((current / total) * 100),
              }),
            ),
          ),
        ).pipe(
          // Cancel if post creation has been cancelled
          takeUntil(this.socialFacade.attachments$.pipe(first((attachments) => !attachments[attachmentId]))),
        );
      }),
    );
  });

  pollPostAttachment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.PollPostAttachment),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ ref, attachmentId, groupId, mediaId }, keycode]) => {
        return this.socialApi
          .getMedia(keycode, groupId, mediaId)
          .pipe(
            mergeMap((media) => {
              const type = (media.type || '').split('/')[0];
              const sourceKey = type === 'image' ? 'images' : 'sources';
              if (media.status === 'Error') {
                return <Observable<Action>>of(
                  socialActions.PollPostAttachmentFail({
                    ref,
                    attachmentId,
                    media,
                  }),
                );
              } else if (
                media.type.includes('video') &&
                media.status === 'Transcoding' &&
                media.url &&
                media.thumbnailUrl.includes('.png')
              ) {
                // POC consider video ready when transcoding is started, and use original file from
                // media.url and  media.thumbnailUrl contains .png
                return of(
                  socialActions.PollPostAttachmentSuccess({
                    ref,
                    attachmentId,
                    media,
                  }),
                );
              } else if (media.status === 'Ready' && media[sourceKey]?.length > 0) {
                // Sometimes we get 'Ready' without sources yet.
                return of(
                  socialActions.PollPostAttachmentSuccess({
                    ref,
                    attachmentId,
                    media,
                  }),
                );
              } else {
                // 'Uploaded', 'Transcoding' and 'Ready' with no sources
                // when 'Ready' the api returns progress 0
                const progress = media.status === 'Ready' ? 100 : media.progress;
                return merge(
                  of(
                    socialActions.PollPostAttachmentUpdate({
                      attachmentId,
                      progress,
                      media,
                    }),
                  ),
                  of(
                    socialActions.PollPostAttachment({
                      ref,
                      attachmentId,
                      groupId,
                      mediaId: media.id,
                    }),
                  ).pipe(delay(3000)),
                );
              }
            }),
            catchError((_error) => [
              socialActions.PollPostAttachmentFail({
                ref,
                attachmentId,
                media: {},
              }),
              socialActions.RemovePostAttachment({ ref, attachmentId }),
            ]),
          )
          .pipe(
            // Cancel if post creation has been cancelled
            takeUntil(this.socialFacade.attachments$.pipe(first((attachments) => !attachments[attachmentId]))),
          );
      }),
    );
  });

  loadPostViews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LoadPostViews),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ postId, search }, keycode]) => {
        return this.socialApi.getPostViews(keycode, postId, search).pipe(
          map((contacts) => socialActions.LoadPostViewsSuccess({ postId, contacts })),
          catchError((error) => [socialActions.LoadPostViewsFail({ error })]),
        );
      }),
    );
  });

  loadPostViewsFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(socialActions.LoadPostViewsFail),
        withLatestFromLazy(this.viewerCoreFacade.keycode$, this.viewerCoreFacade.origin$),
        tap(([_, origin, keycode]) => {
          this.router.navigate(['/', origin, keycode, 'social', 'ALL_POSTS'], {
            replaceUrl: true,
          });
        }),
      );
    },
    { dispatch: false },
  );

  loadMessageViews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LoadMessageViews),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ postId, messageId, search }, keycode]) => {
        return this.socialApi.getMessageViews(keycode, messageId, search).pipe(
          map((contacts) => socialActions.LoadPostViewsSuccess({ postId, contacts, messageId })),
          catchError((error) => [socialActions.LoadMessageViewsFail({ error })]),
        );
      }),
    );
  });

  loadMessageViewsFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(socialActions.LoadMessageViewsFail),
        withLatestFromLazy(this.viewerCoreFacade.keycode$, this.viewerCoreFacade.origin$),
        tap(([_, origin, keycode]) => {
          this.router.navigate(['/', origin, keycode], { replaceUrl: true });
        }),
      );
    },
    { dispatch: false },
  );

  pollMediaItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.PollMediaItem),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ postId, groupId, mediaId }, keycode]) => {
        return this.socialApi
          .getMedia(keycode, groupId, mediaId)
          .pipe(
            mergeMap((media) => {
              if (media.status === 'Error') {
                return <Observable<Action>>of(socialActions.PollMediaItemFail({ postId, media }));
              } else if (media.sources && media.sources.length > 0) {
                return of(socialActions.PollMediaItemSuccess({ postId, media }));
              } else {
                return merge(
                  of(
                    socialActions.PollMediaItemUpdate({
                      postId,
                      progress: media.progress,
                      mediaId: media.id,
                    }),
                  ),
                  of(
                    socialActions.PollMediaItem({
                      postId,
                      groupId,
                      mediaId: media.id,
                    }),
                  ).pipe(delay(3000)),
                );
              }
            }),
          )
          .pipe(
            // Cancel if post is deleted
            takeUntil(this.socialFacade.postEntities$.pipe(first((posts) => !posts[postId]))),
          );
      }),
    );
  });

  loadPostTranslation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LoadPostTranslation),
      withLatestFrom(this.viewerCoreFacade.keycode$, this.viewerCoreFacade.userLang$),
      mergeMap(([{ postId }, keycode, lang]) => {
        return this.socialApi.loadTranslation(postId, keycode, lang).pipe(
          map((content) => {
            return socialActions.LoadPostTranslationSuccess({
              postId,
              content,
            });
          }),
          catchError((error) => [socialActions.LoadPostTranslationFail({ postId, error })]),
        );
      }),
    );
  });

  createGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.CreateGroup),
      withLatestFrom(this.viewerCoreFacade.contactId$, this.viewerCoreFacade.keycode$),
      mergeMap(([{ members }, myId, keycode]) => {
        return this.socialApi
          .createGroup(keycode, [myId, ...members])
          .pipe(map((group) => socialActions.CreateGroupSuccess({ group })));
      }),
    );
  });

  editGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.EditGroup),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ groupId, navigate, ...data }, keycode]) => {
        return this.socialApi
          .editGroup(keycode, groupId, data)
          .pipe(map((group) => socialActions.EditGroupSuccess({ group, navigate })));
      }),
    );
  });

  leaveGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LeaveGroup),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ groupId }, keycode]) => {
        return this.socialApi
          .leaveGroup(keycode, groupId)
          .pipe(map(() => socialActions.LeaveGroupSuccess({ groupId })));
      }),
    );
  });

  leaveGroupSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LeaveGroupSuccess),
      map(() => {
        // Force a reload of posts (previous reload of posts starts too early)
        return socialActions.LoadPosts({
          groupKey: 'ALL_POSTS',
          unread: false,
        });
      }),
    );
  });

  redirectToGroup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(socialActions.CreateGroupSuccess, socialActions.EditGroupSuccess, socialActions.LeaveGroupSuccess),
        filter((action) => {
          if (
            action.type === socialActions.EditGroupSuccess.type &&
            typeof action?.navigate !== 'undefined' &&
            !action?.navigate
          ) {
            return false;
          }
          return true;
        }),
        withLatestFrom(this.viewerCoreFacade.origin$, this.viewerCoreFacade.keycode$),
        tap(([action, origin, keycode]) => {
          let slug: string;
          if (action.type === socialActions.LeaveGroupSuccess.type) {
            slug = 'ALL_POSTS';
          } else {
            const { group } = action;
            slug = `${group.name.toLowerCase().replace(/([^\w]|_)/g, '-')}-${group.id}`;
          }
          this.router.navigate(['/', origin, keycode, 'social', slug]);
        }),
      );
    },
    { dispatch: false },
  );

  routeChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map(() => {
        return socialActions.RevertAllPostTranslations();
      }),
    ),
  );

  revertAllPostTranslations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(viewerCoreActions.UpdateContactInfoSuccess),
      filter(({ newValue }) => (newValue.preferred_languages || []).length > 0),
      map(() => socialActions.RevertAllPostTranslations()),
    );
  });

  redirectToPost$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(socialActions.RedirectToPost),
        withLatestFrom(this.viewerCoreFacade.origin$, this.viewerCoreFacade.keycode$),
        tap(([{ postId, groupId, groupName }, origin, keycode]) => {
          const slug = `${groupName?.toLowerCase().replace(/([^\w]|_)/g, '-')}-${groupId}`;
          this.router.navigate(['/', origin, keycode, 'social', slug, { outlets: { post: `${postId}` } }]);
        }),
      );
    },
    { dispatch: false },
  );

  redirectToPostComments$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(socialActions.RedirectToPostComments),
        withLatestFrom(this.viewerCoreFacade.origin$, this.viewerCoreFacade.keycode$),
        tap(([{ postId, groupId, groupName, commentId }, origin, keycode]) => {
          const slug = `${groupName?.toLowerCase().replace(/([^\w]|_)/g, '-')}-${groupId}`;
          this.router.navigate(['/', origin, keycode, 'social', slug, { outlets: { post: `${postId}` } }], {
            fragment: `comments:${commentId}`,
          });
        }),
      );
    },
    { dispatch: false },
  );

  loadShareMessagePreview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LoadShareMessagePreview),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ messageId }, keycode]) => {
        return this.socialApi.getPostAttachmentsPreview(keycode, 'message', messageId).pipe(
          map(({ data }) => {
            return socialActions.LoadShareMessagePreviewSuccess({
              messageId,
              data,
            });
          }),
          catchError((error) => of(socialActions.LoadShareMessagePreviewFail({ messageId, error }))),
        );
      }),
    );
  });

  checkGroupMembersAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.CheckGroupMembersAccess),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      switchMap(([{ messageId, groupId }, keycode]) => {
        return this.socialApi.checkGroupMembersAccess(keycode, 'message', messageId, groupId).pipe(
          map((result) =>
            socialActions.CheckGroupMembersAccessSuccess({
              messageId,
              allGroupMembersHaveAccess: result?.data,
            }),
          ),
          catchError((error) => of(socialActions.CheckGroupMembersAccessFail({ error }))),
        );
      }),
    );
  });
}
