export enum BrowserName {
  AmazonSilk = 'Amazon Silk',
  Android = 'Android Browser',
  Bada = 'Bada',
  Blackberry = 'BlackBerry',
  Chrome = 'Chrome',
  Chromium = 'Chromium',
  Epiphany = 'Epiphany',
  Firefox = 'Firefox',
  Focus = 'Focus',
  Generic = 'Generic',
  Googlebot = 'Googlebot',
  GoogleSearch = 'Google Search',
  Ie = 'Internet Explorer',
  KMeleon = 'K-Meleon',
  Maxthon = 'Maxthon',
  Edge = 'Microsoft Edge',
  Mz = 'MZ Browser',
  Naver = 'NAVER Whale Browser',
  Opera = 'Opera',
  OperaCoast = 'Opera Coast',
  Phantomjs = 'PhantomJS',
  Puffin = 'Puffin',
  Qupzilla = 'QupZilla',
  Qq = 'QQ Browser',
  Qqlite = 'QQ Browser Lite',
  Safari = 'Safari',
  Sailfish = 'Sailfish',
  SamsungInternet = 'Samsung Internet for Android',
  Seamonkey = 'SeaMonkey',
  Sleipnir = 'Sleipnir',
  Swing = 'Swing',
  Tizen = 'Tizen',
  Uc = 'UC Browser',
  Vivaldi = 'Vivaldi',
  Webos = 'WebOS Browser',
  Wechat = 'WeChat',
  Yandex = 'Yandex Browser',
}

export enum BrowserOSName {
  WindowsPhone = 'Windows Phone',
  Windows = 'Windows',
  MacOS = 'macOS',
  iOS = 'iOS',
  Android = 'Android',
  WebOS = 'WebOS',
  BlackBerry = 'BlackBerry',
  Bada = 'Bada',
  Tizen = 'Tizen',
  Linux = 'Linux',
  ChromeOS = 'Chrome OS',
  PlayStation4 = 'PlayStation 4',
  Roku = 'Roku',
}

export enum BrowserPlatformType {
  tablet = 'tablet',
  mobile = 'mobile',
  desktop = 'desktop',
  tv = 'tv',
}

export enum BrowserEngine {
  EdgeHTML = 'EdgeHTML',
  Blink = 'Blink',
  Trident = 'Trident',
  Presto = 'Presto',
  Gecko = 'Gecko',
  WebKit = 'WebKit',
}
