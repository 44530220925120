import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ao-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @HostBinding('class.ao-card') className = true;
  @HostBinding('class.ao-card--viewer-styling')
  @Input()
  viewerStyling?: boolean = false;
  @HostBinding('class.ao-card--border')
  @Input()
  cardBorder?: boolean = false;

  // shadow intensity
  @Input() depth = 1;

  @HostBinding('style.box-shadow') get boxShadow() {
    const depth = typeof this.depth === 'undefined' || this.depth === null ? 1 : this.depth;
    const opacity = Math.min(Math.max(depth * 0.1, 0), 1);
    return this.sanitizer.bypassSecurityTrustStyle(`0px 0px 16px 0px rgba(0, 0, 0, ${opacity})`);
  }

  constructor(private sanitizer: DomSanitizer) {}
}
