<div class="ao-rating-header">
  <ao-insights-title [title]="title" [type]="'rating'"> </ao-insights-title>
  <div class="ao-rating-header__name-block">
    <h2 class="ao-rating-header__name">{{ name }}</h2>
    <button
      class="ao-font-tiny ao-rating-header__button ao-rating-header__button--history"
      (click)="historyClick.emit()"
      translate
    >
      Go to history
    </button>
  </div>
  <!-- button for editing one-on-one -->
  <button
    *ngIf="showEdit"
    class="ao-rating-header__button ao-rating-header__button--edit"
    (click)="editClick.emit()"
    translate
  >
    Edit
  </button>
</div>
