import { Injectable } from '@angular/core';
import { CometChatUser } from '@ao/data-models';
import { Observable, defer, from } from 'rxjs';
import { map, switchAll } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  getInitialHasUnreadMessages(): Observable<boolean> {
    return defer(() => import('@cometchat/chat-sdk-javascript')).pipe(
      map(({ CometChat }) => {
        const userConver = new CometChat.ConversationsRequestBuilder().setLimit(30).build();
        return from(userConver.fetchNext()).pipe(
          map(
            (conversations: any[]) =>
              conversations
                .filter((conver) => conver.getUnreadMessageCount() > 0)
                .map((conver) => {
                  CometChat.markAsDelivered(conver.getLastMessage()).then(
                    // eslint-disable-next-line
                    () => {},
                    (error: unknown) => {
                      console.warn('An error occurred when marking the message as delivered.', error);
                    },
                  );
                  return conver;
                }).length > 0,
          ),
        );
      }),
      switchAll(),
    );
  }

  getLoggedInUser(): Observable<CometChatUser> {
    return from(
      import('@cometchat/chat-sdk-javascript').then(({ CometChat }) => {
        return CometChat.getLoggedinUser() as unknown as Promise<CometChatUser>;
      }),
    );
  }

  fetchConversationAdmins(groupID: string) {
    const scopes: string[] = ['admin', 'owner'];

    return from(
      import('@cometchat/chat-sdk-javascript').then(({ CometChat }) => {
        const conversationAdmins: CometChat.GroupMembersRequest = new CometChat.GroupMembersRequestBuilder(groupID)
          .setLimit(0)
          .setScopes(scopes)
          .build();
        return conversationAdmins.fetchNext();
      }),
    );
  }
}
