import { transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ACTIMO_EMAIL_REGEX,
  AuthInProgress,
  ClientListItem,
  DeliveryMethods,
  LoginAuthState,
  LoginContact,
  SupportSettings,
  ViewerLoginSettings,
} from '@ao/data-models';
import { BrowserService, expandInOut, slideToLeft, slideToRight } from '@ao/utilities';

// ***
// on a brand new account, you are logged in automatically.
// if you afterwards open the message on a different device or clear cookies, you can request a reset code to allow that device as well (cookies will keep you logged in otherwise)
// ***

const inputSlideAnimation = 400;

@Component({
  selector: 'ao-login-auth',
  templateUrl: './login-auth.component.html',
  styleUrls: ['./login-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    expandInOut(),
    trigger('pageTransitions', [
      transition('* => isLeft', slideToLeft(inputSlideAnimation + 'ms', 'ease-in-out')),
      transition('* => isRight', slideToRight(inputSlideAnimation + 'ms', 'ease-in-out')),
      transition('isRight => *', slideToLeft(inputSlideAnimation + 'ms', 'ease-in-out')),
      transition('isLeft => *', slideToRight(inputSlideAnimation + 'ms', 'ease-in-out')),
      transition('* => verificationPageOne', slideToLeft(inputSlideAnimation + 'ms', 'ease-in-out')),
      transition('* => verificationPageTwo', slideToRight(inputSlideAnimation + 'ms', 'ease-in-out')),
      transition('* => clientPicker', slideToRight(inputSlideAnimation + 'ms', 'ease-in-out')),
      transition('* => emailSuccess', slideToRight(inputSlideAnimation + 'ms', 'ease-in-out')),
      transition('* => error', slideToRight(inputSlideAnimation + 'ms', 'ease-in-out')),
      transition('* => loggingIn', slideToRight('200ms', 'ease-in-out')),
      transition('* => cookiesDisabled', slideToRight(inputSlideAnimation + 'ms', 'ease-in-out')),
      transition('* => authenticating', slideToRight('200ms', 'ease-in-out')),
    ]),
  ],
})
export class LoginAuthComponent implements OnChanges {
  @HostBinding('class.ao-login-auth') className = true;

  private _defaultCountryCode: string;
  @Input() set defaultCountryCode(value: string) {
    if (value && !(this._contact && this._contact.phone)) {
      this._form.controls['sms'].setValue(value);
    }
    this._defaultCountryCode = value;
  }
  get defaultCountryCode() {
    return this._defaultCountryCode;
  }
  @Input() set contact(value: LoginContact) {
    this._contact = value;
    if (value && value.phone) {
      this._form.controls['sms'].setValue(value.phone.countryCode);
    }
  }
  @Input() state: LoginAuthState;
  @Input() remainingAttempts: number;
  @Input() authInProgress: AuthInProgress;
  @Input() isPWA = false;
  @Input() clientList: ClientListItem[];
  @Input() supportSettings: SupportSettings;
  @Input() loginSettings: ViewerLoginSettings;

  // handling callback success/fail message
  @Output() inputComplete = new EventEmitter<string>();
  @Output() resetRequest = new EventEmitter<{ type: string; value: string }>();
  @Output() pickClient = new EventEmitter<ClientListItem>();
  @Output() openSupport = new EventEmitter();
  @Output() showErrorToast = new EventEmitter<string>();
  @Output() logout = new EventEmitter();

  constructor(
    private browser: BrowserService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this._form = this.formBuilder.group({
      sms: ['', Validators.compose([Validators.required])],
      email: [
        { value: '', disabled: true },
        Validators.compose([Validators.required, Validators.email, Validators.pattern(ACTIMO_EMAIL_REGEX)]),
      ],
    });
  }

  _passcode = '';
  _contact: LoginContact = null;
  _selectedClient: ClientListItem = null;
  isPrivate = false;

  _deliveryMethod: DeliveryMethods = 'sms';

  _displayErrors = false;
  _form: UntypedFormGroup;
  _selectedDeliveryData = null;
  _animating = false;

  // TODO: temporarily disable to try to fix BUGS-2072, check if we still want this functionality
  /*
  ngOnInit() {
    // show popup if private mode
    /*
    this.browser.isPrivateMode().then((val) => {
      if (val) {
        this.showErrorToast.emit('incognito');
      }
    });
  }*/

  ngOnChanges(changes: SimpleChanges) {
    this.reset();
    // TODO: temporarily disable to try to fix BUGS-2072, check if we still want this functionality
    // this.browser.isPrivateMode().then((isPrivate: boolean) => (this.isPrivate = isPrivate));
  }

  doAuth() {
    if (this._passcode.length === 4) {
      // poke the smart component
      this.inputComplete.emit(this._passcode);
    }
  }

  reset() {
    this._passcode = '';
  }

  newResetRequest() {
    this.resetRequest.emit({ type: 'new', value: null });
  }

  setDeliveryMethod(method: DeliveryMethods) {
    this._animating = true;
    this._displayErrors = false;

    if (method === 'sms') {
      this._form.controls.sms.enable();
      this._form.controls.email.disable();
    } else if (method === 'email') {
      this._form.controls.email.enable();
      this._form.controls.sms.disable();
    } else {
      // failsafe
      return;
    }

    this._deliveryMethod = method;

    // the slide animation needs overflow clipped
    setTimeout(() => {
      this._animating = false;
    }, inputSlideAnimation);
  }

  onSubmit() {
    // // validation
    if (!this._form.valid) {
      // display all error texts & set highlights to full red
      this._displayErrors = true;
      // poke angular to render changes
      this._form.markAsDirty();
      this._form.updateValueAndValidity();
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }

      return;
    }

    this._displayErrors = false;
    this.resetRequest.emit({ type: this._deliveryMethod, value: this._form.controls[this._deliveryMethod].value });
    this._selectedDeliveryData = this._form.controls[this._deliveryMethod].value;
  }

  onClientSelected(client: ClientListItem) {
    this._selectedClient = client;
    this.pickClient.emit(client);
  }

  // adjust left <--> right slide animation direction
  get inputTransition() {
    return this._deliveryMethod === 'sms' ? 'isRight' : 'isLeft';
  }

  stateTransition(state: LoginAuthState) {
    return state;
  }
}
