<ng-container [ngSwitch]="type">
  <div class="ao-error-state-item__directory" *ngSwitchCase="ErrorStateItemType.Directory">
    <ao-icon-avatar [size]="'biggest'" [avatarColor]="'yellow'" [iconName]="'warning'"></ao-icon-avatar>
    <p class="ao-font-title4 ao-error-state-item__directory--title" translate>The content couldn’t be loaded</p>
    <p class="ao-font-body2 ao-error-state-item__directory--subtitle" translate>
      Please check if you are connected to the internet and try again.
    </p>
    <button
      class="ao-error-state-item__directory--button"
      type="button"
      ao-button
      buttonSize="medium"
      buttonColor="blue"
      [fullWidth]="false"
      (click)="reloadPage()"
      translate
    >
      Reload the Page
    </button>
  </div>
</ng-container>
