<ng-container>
  <div class="ao-login-auth__top-spacing"></div>
  <div class="ao-login-auth__container" [ngSwitch]="state" [@pageTransitions]="stateTransition(state)">
    <!-- verificationPageOne (login) - insert phone / email -->
    <div class="ao-login-auth__content" *ngSwitchCase="'verificationPageOne'">
      <ao-generic-page-header
        class="ao-login-auth__page-header"
        [type]="'text'"
        [title]="
          loginSettings.appName === 'Actimo'
            ? ('Find your company app' | translate)
            : ('Log in to continue' | translate)
        "
        [subTitle]="'Welcome to #companyName#' | translate: { companyName: loginSettings.appName }"
      >
      </ao-generic-page-header>

      <!-- email / phone tabs -->
      <ao-tabs class="ao-login-auth__tabs">
        <a ao-tab [active]="_deliveryMethod === 'sms'" (click)="setDeliveryMethod('sms')">{{ 'Phone' | translate }}</a>
        <a ao-tab [active]="_deliveryMethod === 'email'" (click)="setDeliveryMethod('email')">{{
          'Email' | translate
        }}</a>
      </ao-tabs>

      <form [formGroup]="_form" (ngSubmit)="onSubmit()">
        <div
          class="ao-login-auth__input-wrapper"
          [ngSwitch]="_deliveryMethod"
          [@pageTransitions]="inputTransition"
          [style.overflow]="_animating ? 'hidden' : 'visible'"
        >
          <div *ngSwitchCase="'sms'">
            <ao-phone-input
              class="ao-login-auth__phone-input"
              [hasError]="_displayErrors && _form.controls.sms.invalid"
              formControlName="sms"
              [defaultGeoIpCountryCode]="defaultCountryCode"
              name="phone"
            >
            </ao-phone-input>
            <div
              class="ao-font-small-bold ao-login-auth__error"
              [@expandInOut]
              *ngIf="_displayErrors && _form.controls.sms.invalid"
              translate
            >
              Please enter a valid phone number
            </div>
          </div>

          <div *ngSwitchCase="'email'">
            <input
              ao-input
              [hasError]="_displayErrors && _form.controls.email.invalid"
              class="ao-login-auth__email"
              type="email"
              formControlName="email"
              [placeholder]="'Enter email address' | translate"
            />
            <div
              class="ao-font-small-bold ao-login-auth__error"
              [@expandInOut]
              *ngIf="_displayErrors && _form.controls.email.invalid"
              translate
            >
              Please enter a valid email address
            </div>
          </div>
        </div>

        <button ao-button buttonSize="medium" [disabled]="!!authInProgress" type="submit">
          <span translate *ngIf="!authInProgress; else loadSpinner">Send me a code [access pin]</span>
          <ng-template #loadSpinner>
            <!-- verified with product - show this text along with a spinner -->

            <span
              class="ao-font-body2"
              [innerHTML]="'Sending verification code #!spinner#' | translate | replaceTemplate: '#!spinner#':t1"
            ></span>
            <ng-template #t1>
              <ao-spinner [size]="20" class="ao-login-auth__button-spinner" [color]="'#ffffff'"></ao-spinner>
            </ng-template>
          </ng-template>
        </button>
        <p
          class="ao-font-tiny ao-login-auth__privacy-policy"
          [innerHTML]="
            'By logging in, you confirm to have read and understood our #!privacy policy#.'
              | translate
              | replaceTemplate: '#!privacy policy#':t1
          "
        ></p>
        <ng-template #t1
          ><a
            class="ao-font-tiny-bold ao-login-auth__privacy-policy__link"
            href="https://www.actimo.com/privacy-policy/"
            target="_blank"
            translate
            >privacy policy</a
          ></ng-template
        >
      </form>
    </div>

    <!-- verificationPageTwo (verification code (pin) input) -->
    <div class="ao-login-auth__content" *ngSwitchCase="'verificationPageTwo'">
      <ao-generic-page-header
        [type]="'text'"
        [title]="'Enter verification code' | translate"
        [subTitle]="'Please verify' | translate"
      ></ao-generic-page-header>

      <div *ngIf="remainingAttempts > 0; else noAttemptsLeft">
        <div class="ao-login-auth__inputs">
          <ao-pin-code-input
            [disabled]="!!authInProgress"
            [(ngModel)]="_passcode"
            (ngModelChange)="doAuth()"
          ></ao-pin-code-input>
        </div>
      </div>

      <ng-template #noAttemptsLeft>
        <div class="ao-font-body2-bold ao-login-auth__header__attempts-remaining">
          <p translate>You don't have any attempts left for that code.</p>
          <p translate>Please request a new one. [requesting a new verification code]</p>
        </div>
      </ng-template>

      <div
        class="ao-font-body2-bold ao-login-auth__header__attempts-remaining"
        *ngIf="remainingAttempts > 0 && remainingAttempts < 5"
      >
        <p translate>We couldn't verify you with that code, please try again</p>
        <p
          translate
          [translateParams]="{
            count: remainingAttempts
          }"
        >
          (you have #count# attempts left)
        </p>
      </div>

      <div class="ao-login-auth__footer">
        <p
          class="ao-font-body2 ao-login-auth__footer__text"
          [innerHTML]="
            'If <strong>#deliveryData#</strong> is linked to an account you will receive a code shortly.'
              | translate: { deliveryData: _selectedDeliveryData }
          "
        ></p>
        <a class="ao-font-body2-bold ao-login-auth__footer__link" target="_blank" (click)="newResetRequest()" translate
          >Go back to the login page</a
        >
      </div>
    </div>

    <!-- client picker -->
    <div class="ao-login-auth__content" *ngSwitchCase="'clientPicker'">
      <ao-login-client-picker
        class="ao-login-auth__client-picker"
        [clientList]="clientList"
        [firstName]="_contact?.name"
        [initials]="_contact?.initials"
        [avatar]="_contact?.avatarUrl"
        (pickClient)="onClientSelected($event)"
      ></ao-login-client-picker>
    </div>

    <!-- Cookies disabled  -->
    <div class="ao-login-auth__content" *ngSwitchCase="'cookiesDisabled'">
      <div>
        <ao-generic-viewer-error
          errorType="warning"
          [title]="'You have cookies disabled' | translate"
          [text]="'Please enable cookies and refresh the page in order to get access to this content.' | translate"
          [refreshButton]="true"
          (openSupport)="openSupport.emit()"
          (logout)="logout.emit()"
        >
        </ao-generic-viewer-error>
      </div>
    </div>

    <!-- loggingIn - rerouting -->
    <div class="ao-login-auth__content" *ngSwitchCase="'authenticating'">
      <ao-spinner class="ao-login-auth__spinner"></ao-spinner>
    </div>

    <!-- loggingIn - rerouting -->
    <div class="ao-login-auth__content" *ngSwitchCase="'loggingIn'">
      <ao-generic-page-header
        [type]="'text'"
        [subTitle]="
          _selectedClient
            ? ('Welcome to #clientName#' | translate: { clientName: _selectedClient.clientName })
            : ('Welcome' | translate)
        "
        [title]="'Logging you in...' | translate"
      ></ao-generic-page-header>
      <ao-spinner [size]="32" color="#4680FE" class="ao-login-auth__spinner"></ao-spinner>
    </div>
  </div>

  <div class="ao-login-auth__bottom-spacing"></div>
</ng-container>
