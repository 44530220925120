import { Injectable } from '@angular/core';
import { TRANSLATION_STATUS } from '@ao/data-models';
import { withLatestFromLazy } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, timer } from 'rxjs';
import { catchError, debounce, map, mergeMap } from 'rxjs/operators';
import { AppFacade } from '../../app-store.facade';
import { AppService } from '../../services/app-store.service';
import * as appActions from '../actions';

@Injectable()
export class ModuleMessageListEffects {
  constructor(
    private actions$: Actions,
    private appService: AppService,
    private appFacade: AppFacade,
    private viewerCoreFacade: ViewerCoreFacade,
  ) {}

  loadMessageListData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LoadMessageListData),
      withLatestFromLazy(this.viewerCoreFacade.keycode$, this.appFacade.messageTranslationStatus$),
      debounce((event) => {
        const [{ debounce: time }] = event;
        return time ? timer(time) : of(event);
      }),
      mergeMap(([{ module, params, appendMessages }, keycode, translationStatus]) => {
        const mergedParams = {
          ...params,
          ...(translationStatus === TRANSLATION_STATUS.translated ? { shouldTranslate: true } : {}),
        };
        return this.appService.getMessageListData(keycode, module.subtype ? null : module.id, mergedParams).pipe(
          map((data) => appActions.LoadMessageListDataSuccess({ module, data, appendMessages })),
          catchError((error) => of(appActions.LoadMessageListDataFail({ module, error }))),
        );
      }),
    ),
  );
}
