import {
  combineLatest,
  concat,
  fromEvent,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  partition,
  pipe,
  zip,
} from 'rxjs';
import { filter, map, mergeMap, repeatWhen, shareReplay, take, takeUntil } from 'rxjs/operators';

export function onceWithLatest<T>(observable: Observable<T>, fn: (v: T) => void);
export function onceWithLatest<T, T2>(o1: Observable<T>, o2: Observable<T2>, fn: (v1: T, v2: T2) => void);
export function onceWithLatest<T, T2, T3>(
  o1: Observable<T>,
  o2: Observable<T2>,
  o3: Observable<T3>,
  fn: (v1: T, v2: T2, v3: T3) => void,
);
export function onceWithLatest<T, T2, T3, T4>(
  o1: Observable<T>,
  o2: Observable<T2>,
  o3: Observable<T3>,
  o4: Observable<T4>,
  fn: (v1: T, v2: T2, v3: T3, v4: T4) => void,
);
export function onceWithLatest<T, T2, T3, T4, T5>(
  o1: Observable<T>,
  o2: Observable<T2>,
  o3: Observable<T3>,
  o4: Observable<T4>,
  o5: Observable<T5>,
  fn: (v1: T, v2: T2, v3: T3, v4: T4, v5: T5) => void,
);
export function onceWithLatest<T, T2, T3, T4, T5, T6>(
  o1: Observable<T>,
  o2: Observable<T2>,
  o3: Observable<T3>,
  o4: Observable<T4>,
  o5: Observable<T5>,
  o6: Observable<T6>,
  fn: (v1: T, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6) => void,
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function onceWithLatest<T, T2>(...observables: Array<Observable<any> | ((...values: any[]) => void)>) {
  const callback = <(...values: any[]) => void>observables.pop();
  combineLatest([...observables])
    .pipe(take(1))
    .subscribe((values) => callback(...values));
}

export function defined<T>(): MonoTypeOperatorFunction<T> {
  return filter((val) => !(typeof val === 'undefined' || val === null));
}

export function onceDefined<T>(): MonoTypeOperatorFunction<T> {
  return pipe(defined(), take(1));
}

export function documentVisibility(document: Document) {
  return concat(of({}), fromEvent(document, 'visibilitychange')).pipe(
    map(() => document.visibilityState === 'visible'),
  );
}

export function withLatestFromLazy<T, T1>(observable: Observable<T1>): OperatorFunction<T, [T, T1]>;
export function withLatestFromLazy<T, T1, T2>(o1: Observable<T1>, o2: Observable<T2>): OperatorFunction<T, [T, T1, T2]>;
export function withLatestFromLazy<T, T1, T2, T3>(
  o1: Observable<T1>,
  o2: Observable<T2>,
  o3: Observable<T3>,
): OperatorFunction<T, [T, T1, T2, T3]>;
export function withLatestFromLazy<T, T1, T2, T3, T4>(
  o1: Observable<T1>,
  o2: Observable<T2>,
  o3: Observable<T3>,
  o4: Observable<T4>,
): OperatorFunction<T, [T, T1, T2, T3, T4]>;
export function withLatestFromLazy<T, T1, T2, T3, T4, T5>(
  o1: Observable<T1>,
  o2: Observable<T2>,
  o3: Observable<T3>,
  o4: Observable<T4>,
  o5: Observable<T5>,
): OperatorFunction<T, [T, T1, T2, T3, T4, T5]>;
export function withLatestFromLazy<T>(...observables: Observable<any>[]): OperatorFunction<T, [T, ...any[]]> {
  return mergeMap((item: T) => {
    return combineLatest(observables).pipe(
      take(1),
      map((rest) => <[T, ...any[]]>[item, ...rest]),
    );
  });
}

export function withPrevious<T>(): OperatorFunction<T, [T, T]> {
  return (source) => zip(concat(of<T>(null), source), source);
}

// Operator used to subscribe to an observable only when the page is visible (not in a background tab, part of a minimized window, or with the OS screen lock active)
export function whenPageVisible(): <T>(source: Observable<T>) => Observable<T> {
  const visibilitychange$ = fromEvent(document, 'visibilitychange').pipe(
    shareReplay({ refCount: true, bufferSize: 1 }),
  );
  const [pageVisible$, pageHidden$] = partition(visibilitychange$, () => document.visibilityState === 'visible');

  return function <T>(source: Observable<T>) {
    return source.pipe(
      takeUntil(pageHidden$),
      repeatWhen(() => pageVisible$),
    );
  };
}
