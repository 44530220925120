import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { onceDefined, WINDOW } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ViewerTaskManagementGuard implements CanActivate {
  constructor(
    private router: Router,
    private viewerCoreFacade: ViewerCoreFacade,
    @Inject(WINDOW) private window: Window,
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.viewerCoreFacade.loadViewerSettings();
    return this.viewerCoreFacade.viewerSettings$.pipe(
      onceDefined(),
      map((setting) => {
        if (setting?.error && (setting?.error as any)?.code === 401) {
          this.router.navigate(['/login']);
          return false;
        }
        if (setting?.featureFlags?.task_management) {
          return true;
        } else {
          this.router.navigate(['error'], {
            queryParams: {
              customMessage: 'FEATURE_DISABLED',
              previousPath: this.window.location.pathname + this.window.location.search,
            },
          });
          return false;
        }
      }),
    );
  }
}
