import { Injectable } from '@angular/core';
import { MessageListRequestParams, MsgModuleMessageList } from '@ao/data-models';
import { withLatestFromLazy } from '@ao/utilities';
import { viewerCoreActions, ViewerCoreFacade } from '@ao/viewer-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppFacade } from '../../app-store.facade';
import { AppService } from '../../services/app-store.service';
import * as appActions from '../actions';

@Injectable()
export class AutoTranslationEffects {
  constructor(
    private actions$: Actions,
    private appFacade: AppFacade,
    private appService: AppService,
    private viewerCoreFacade: ViewerCoreFacade,
  ) {}

  routeChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map(() => {
        return appActions.RevertMessageTranslation();
      }),
    ),
  );

  revertAllPostTranslations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(viewerCoreActions.UpdateContactInfoSuccess),
      filter(({ newValue }) => (newValue.preferred_languages || []).length > 0),
      map(() => appActions.RevertMessageTranslation()),
    );
  });

  toggleAutoTranslationModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.ToggleAutoTranslationModal),
      withLatestFromLazy(this.appFacade.showAutoTranslationModal$, this.appFacade.autoTranslationLangs$),
      filter(([_, isShowing, langs]) => isShowing && !langs.length),
      map(() => appActions.LoadAutoTranslationLangs()),
    ),
  );

  loadAutoTranslationLangs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LoadAutoTranslationLangs),
      withLatestFromLazy(this.viewerCoreFacade.keycode$),
      switchMap(([_, keycode]) => {
        return this.appService.loadAutoTranslationLangs(keycode).pipe(
          map((autoTranslateLangs) => appActions.LoadAutoTranslationLangsSuccess({ autoTranslateLangs })),
          catchError((error) => of(appActions.LoadAutoTranslationLangsFail({ error }))),
        );
      }),
    ),
  );

  loadMessageTranslation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appActions.LoadMessageTranslation),
      withLatestFrom(
        this.viewerCoreFacade.keycode$,
        this.appFacade.currentMessageModules$,
        this.appFacade.currentAcademyId$,
        this.appFacade.currentMessagePage$,
      ),
      mergeMap(([{ messageId }, keycode, modules, academyId]) => {
        const messageTranslation$ = this.appService
          .loadTranslation(keycode, academyId)
          .pipe(map((data) => appActions.LoadMessageTranslationSuccess({ data })));
        const messageListTranslations$ = (
          modules.filter((m) => m.type === 'messagelist' && m.messages?.length) as MsgModuleMessageList[]
        )
          .map((module) => {
            const paramKeys = ['page', 'searchTerm'];
            const params: MessageListRequestParams = paramKeys.reduce(
              (acc, key) => {
                return { ...acc, ...(key in module ? { [key]: module[key] } : {}) };
              },
              { shouldTranslate: true },
            );
            const moduleId = module.subtype ? null : module.id;

            return Array.from({ length: params.page }, (_, i) => {
              const pageParams = { ...params, page: i + 1 };
              const appendMessages = pageParams.page > 1;
              return this.appService
                .getMessageListData(keycode, moduleId, pageParams)
                .pipe(map((data) => appActions.LoadMessageListDataSuccess({ module, data, appendMessages })));
            });
          })
          .reduce((acc, list) => acc.concat(list), []);
        return forkJoin([...messageListTranslations$, messageTranslation$]).pipe(
          mergeMap((a) => a),
          catchError((error) => [appActions.LoadMessageTranslationFail({ messageId, error })]),
        );
      }),
    );
  });
}
