<div class="ao-slider">
  <input
    type="range"
    [disabled]="disabled"
    [(ngModel)]="value"
    [attr.min]="min"
    [attr.max]="max"
    [attr.step]="step"
    (mouseup)="onMouseup()"
    (touchend)="onMouseup()"
    (ngModelChange)="_setValue($event)"
  />
</div>
