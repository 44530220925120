<div *ngIf="isGhostMode" class="viewer-ghost-mode">
  <div class="viewer-ghost-mode__ghost">
    <div class="viewer-ghost-mode__eyes">
      <div class="viewer-ghost-mode__eye viewer-ghost-mode__left-eye">
        <div class="viewer-ghost-mode__iris"></div>
      </div>
      <div class="viewer-ghost-mode__eye viewer-ghost-mode__right-eye">
        <div class="viewer-ghost-mode__iris"></div>
      </div>
    </div>
    <div class="viewer-ghost-mode__tail"></div>
  </div>
</div>
