import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

// Custom messages to be displayed inside "viewer-error" component

export interface ErrorMessage {
  key: string;
  header: string;
  text: string;
  errorType?: 'error' | 'warning' | 'info';
  subHeader?: string;
}

// NOT FOUND
export const NO_HOMEPAGE: ErrorMessage = {
  key: 'NO_HOMEPAGE',
  header: i18n("We couldn't find your home page"),
  text: i18n(
    "It looks like your organisation hasn't set a home page for your app, or that you haven't been granted access to it. We recommend contacting your internal support to help fix the issue.",
  ),
  errorType: 'error',
  subHeader: i18n('Homepage Not Found'),
};
export const GET_PROFILE_FAIL: ErrorMessage = {
  key: 'GET_PROFILE_FAIL',
  header: i18n("We couldn't load your profile"),
  text: i18n('If this error persists, we recommend contacting your manager to check that your profile is enabled.'),
  errorType: 'error',
  subHeader: i18n('No Profile'),
};
export const ITEM_NOT_FOUND: ErrorMessage = {
  key: 'ITEM_NOT_FOUND',
  header: i18n("We couldn't find the page you requested"),
  text: i18n("It could mean the page has been deleted, or perhaps that there's a typo in the link."),
  errorType: 'error',
  subHeader: i18n('Message Not Found'),
};
export const DOMAIN_NOT_FOUND: ErrorMessage = {
  key: 'DOMAIN_NOT_FOUND',
  header: i18n("We couldn't find that domain"),
  text: i18n('We recommend checking for any typos, or reaching out to your internal support, if the problem persists.'),
  errorType: 'error',
  subHeader: i18n('Domain Not Found'),
};
// CANT CREATE
export const CREATE_CONTACT_NO_HOME: ErrorMessage = {
  key: 'CREATE_CONTACT_NO_HOME',
  header: i18n('Unable to create contact'),
  text: i18n(
    'Please include a homepage link in the message header in order to use this message for adding new employees.',
  ),
  errorType: 'error',
  subHeader: i18n('No Homepage Link'),
};
export const CREATE_CONTACT_NOT_MANAGER: ErrorMessage = {
  key: 'CREATE_CONTACT_NOT_MANAGER',
  header: i18n('Unable to create contact'),
  text: i18n('Sorry, only managers can create new contacts. Please contact your internal support to change your role.'),
  errorType: 'error',
  subHeader: i18n('Not Manager'),
};
export const CREATE_CONTACT_NOT_OWN_PROFILE: ErrorMessage = {
  key: 'CREATE_CONTACT_NOT_OWN_PROFILE',
  header: i18n('Unable to create contact'),
  text: i18n('Sorry, you can only add employees only from your own profile page'),
  errorType: 'error',
  subHeader: i18n('Wrong Profile'),
};
// INACTIVE, EXPIRED
export const INACTIVE: ErrorMessage = {
  key: 'INACTIVE',
  header: i18n('This page has been deactivated'),
  text: i18n(
    "The page you're trying to access has been taken offline. If you think there's been a mistake, please contact your internal support.",
  ),
  errorType: 'warning',
  subHeader: i18n('Message Inactive'),
};
export const CONTACT_INACTIVE: ErrorMessage = {
  key: 'CONTACT_INACTIVE',
  header: i18n('Your user has been deactivated'),
  text: i18n(
    'Sorry, your profile has been deactivated. Please get in touch with your manager or internal support for help.',
  ),
  errorType: 'warning',
  subHeader: i18n('Contact Inactive'),
};
export const MESSAGE_EXPIRED: ErrorMessage = {
  key: 'MESSAGE_EXPIRED',
  header: i18n('This page has expired'),
  text: i18n("Sorry, the content you're trying to access is no longer available."),
  errorType: 'warning',
  subHeader: i18n('Message Expired'),
};
// NO ACCESS
export const NO_ACCESS: ErrorMessage = {
  key: 'NO_ACCESS',
  header: i18n("You don't have access to this page"),
  text: i18n(
    "Sorry, you don't seem to have access to this content. Please contact your internal support, if there's been a mistake.",
  ),
  errorType: 'warning',
  subHeader: i18n('No Access'),
};
export const NO_PUBLIC_ACCESS: ErrorMessage = {
  key: 'NO_PUBLIC_ACCESS',
  header: i18n('This page has been deactivated'),
  text: i18n("Sorry, the message you're trying to access is not publicly available."),
  errorType: 'warning',
  subHeader: i18n('No Public Access'),
};
export const NO_MESSAGE_ACCESS: ErrorMessage = {
  key: 'NO_MESSAGE_ACCESS',
  header: i18n("You don't have access to this message"),
  text: i18n(
    'This message does not appear to be published to you. Please contact your company admin if you believe you should have access.',
  ),
  errorType: 'warning',
  subHeader: i18n('No Access'),
};
// DISABLED
export const SOCIAL_WALL_DISABLED: ErrorMessage = {
  key: 'SOCIAL_WALL_DISABLED',
  header: i18n('Social wall is not available'),
  text: i18n("We're sorry, but it looks like the Social Wall feature has not been enabled for this app."),
  errorType: 'info',
  subHeader: i18n('Social Wall Disabled'),
};
export const FEATURE_DISABLED: ErrorMessage = {
  key: 'FEATURE_DISABLED',
  header: i18n('This feature is not available'),
  text: i18n("We're sorry, but it looks like the feature you're trying to access has not been enabled for this app."),
  errorType: 'info',
  subHeader: i18n('Feature Disabled'),
};

// AUTH FAILED
export const AUTHENTICATION_FAILED: ErrorMessage = {
  key: 'AUTHENTICATION_FAILED',
  header: i18n('Unable to authenticate'),
  text: i18n('We were unable to authenticate you. Please try again, or contact support for help.'),
  errorType: 'error',
  subHeader: i18n('Authentication Failed'),
};
export const AUTH_FAILED: ErrorMessage = {
  key: 'AUTH_FAILED',
  header: i18n('Invalid login credentials'),
  text: i18n('The login credentials you entered seem to be incorrect. If this error persists, please contact support.'),
  errorType: 'error',
  subHeader: i18n('Authentication Failed'),
};

// UNKNOWN
export const UNKNOWN: ErrorMessage = {
  key: 'UNKNOWN',
  header: i18n('An unknown error occured'),
  text: i18n(
    "We're sorry, but something went wrong, and we're not quite sure what it was. If this error persists, please get in touch.",
  ),
  errorType: 'error',
  subHeader: i18n('Unknown Error'),
};

// RATING - NO EMPLOYEES TO RATE

export const NO_EMPLOYEES_TO_RATE: ErrorMessage = {
  key: 'NO_EMPLOYEES_TO_RATE',
  header: i18n('No active employees'),
  text: i18n('You currently do not have any active employees to perform the one-on-one with.'),
  errorType: 'warning',
  subHeader: i18n('Warning'),
};

export const RATE_LIMIT_REACHED: ErrorMessage = {
  key: 'RATE_LIMIT_REACHED',
  header: i18n(' Login attempts limit reached'),
  text: i18n('Please try again later'),
  errorType: 'error',
  subHeader: i18n('Authentication Failed'),
};
