import { Injectable } from '@angular/core';
import { SocialGroupType, SocialWallLinkPreview } from '@ao/data-models';
import { AttachmentInput, MediaItem, ReactionType } from '@ao/shared-data-models';
import { Store } from '@ngrx/store';
import * as SocialActions from './+state/social-store.actions';
import * as SocialSelectors from './+state/social-store.selectors';

@Injectable({ providedIn: 'root' })
export class SocialFacade {
  limbo$ = this.store.select(SocialSelectors.getLimbo);
  attachments$ = this.store.select(SocialSelectors.getAttachments);
  postEntities$ = this.store.select(SocialSelectors.getPostEntities);
  postTranslationEntities$ = this.store.select(SocialSelectors.getPostTranslationEntities);
  pollingMedia$ = this.store.select(SocialSelectors.getPollingMedia);
  lastCommentsByPost$ = this.store.select(SocialSelectors.getLastCommentsByPost);
  currentGroup$ = this.store.select(SocialSelectors.getCurrentGroup);
  currentGroupKey$ = this.store.select(SocialSelectors.getCurrentGroupKey);
  showPostsWithPinned$ = this.store.select(SocialSelectors.getShowPostsWithPinned);
  postsForSelectedGroup$ = this.store.select(SocialSelectors.getPostsForSelectedGroup);
  postsForSelectedGroupWithPinned$ = this.store.select(SocialSelectors.getPostsForSelectedGroupWithPinned);
  commentsForSelectedPost$ = this.store.select(SocialSelectors.getCommentsForSelectedPost);
  selectedPost$ = this.store.select(SocialSelectors.getSelectedPost);
  selectedPostId$ = this.store.select(SocialSelectors.getSelectedPostId);
  selectedEditPost$ = this.store.select(SocialSelectors.getSelectedEditPost);
  attachmentsForSelectedEditPost$ = this.store.select(SocialSelectors.getAttachmentsForSelectedEditPost);
  pendingPostsForSelectedPost$ = this.store.select(SocialSelectors.getPendingPostsForSelectedPost);
  messageAttachmentForSelectedPost$ = this.store.select(SocialSelectors.getMessageAttachmentForSelectedPost);
  contactsWithReactionsForSelectedPost$ = this.store.select(SocialSelectors.getContactsWithReactionsForSelectedPost);
  viewsForSelectedPost$ = this.store.select(SocialSelectors.getViewsForSelectedPost);
  attachmentsByRef$ = this.store.select(SocialSelectors.getAttachmentsByRef);
  contactsById$ = this.store.select(SocialSelectors.getContactsById);
  queryUnread$ = this.store.select(SocialSelectors.getQueryUnread);
  feedState$ = this.store.select(SocialSelectors.getFeedState);
  pendingPosts$ = this.store.select(SocialSelectors.getPendingPosts);
  selectedEditPostId$ = this.store.select(SocialSelectors.getSelectedEditPostId);
  allGroups$ = this.store.select(SocialSelectors.selectSocialGroups);
  socialFeedName$ = this.store.select(SocialSelectors.selectSocialFeedName);
  socialGroupEntities$ = this.store.select(SocialSelectors.getGroupEntities);
  socialHasGroups$ = this.store.select(SocialSelectors.selectSocialHasGroups);
  allNewPostsPerGroup$ = this.store.select(SocialSelectors.getAllNewPostsPerGroup);
  /**
   * allGroups and allNewPostsPerGroup$ combined and sorted
   */
  allSocialGroupsWithNewPostsCount$ = this.store.select(SocialSelectors.allSocialGroupsWithNewPostsCount);

  oneContactById$ = ({ contactId }: { contactId: number }) =>
    this.store.select(SocialSelectors.getOneContact(contactId));
  oneGroupById$ = ({ groupId }: { groupId: string }) => this.store.select(SocialSelectors.getOneGroup(groupId));
  postsByGroupId$ = ({ groupKey }: { groupKey: string }) =>
    this.store.select(SocialSelectors.getPostsByGroupKey(groupKey));
  postPreviewByMessage$ = ({ messageId }: { messageId: number }) =>
    this.store.select(SocialSelectors.getSharePreviewByMessageId(messageId));

  constructor(private store: Store) {}

  loadOneContact(contactId: number) {
    this.store.dispatch(SocialActions.LoadContact({ contactId }));
  }
  loadPosts({
    groupKey,
    limit,
    append,
    unread,
  }: {
    groupKey: string;
    limit?: number;
    append?: boolean;
    unread?: boolean;
  }) {
    this.store.dispatch(SocialActions.LoadPosts({ groupKey, limit, append, unread }));
  }
  loadOnePost(postId: number, isMessageWithSocialFooter = false) {
    this.store.dispatch(SocialActions.LoadOnePost({ postId, isMessageWithSocialFooter }));
  }
  reactToPost(postId: number, reactionType: ReactionType) {
    this.store.dispatch(SocialActions.ReactToPost({ postId, reactionType }));
  }
  unreactPost(postId: number) {
    this.store.dispatch(SocialActions.UnreactPost({ postId }));
  }
  deletePost(postId: number, isMessageWithSocialFooter = false) {
    this.store.dispatch(SocialActions.DeletePost({ postId, isMessageWithSocialFooter }));
  }
  postViewed({ postId, moduleId }: { postId: number; moduleId?: string }) {
    this.store.dispatch(SocialActions.PostViewed({ postId, moduleId }));
  }

  createPostAttachment({
    ref,
    attachmentId,
    groupId,
    file,
    fromSocialWall,
  }: {
    ref: string;
    attachmentId: string;
    groupId: number;
    file: File;
    fromSocialWall?: boolean;
  }) {
    this.store.dispatch(SocialActions.CreatePostAttachment({ ref, attachmentId, groupId, file, fromSocialWall }));
  }
  removePostAttachment({ ref, attachmentId }: { ref: string; attachmentId: string }) {
    this.store.dispatch(SocialActions.RemovePostAttachment({ ref, attachmentId }));
  }
  editPost({
    postId,
    content,
    tempId,
    linkPreview,
    media,
    pinned,
    shareAttachments,
  }: {
    postId: number;
    content: any;
    tempId: string;
    linkPreview?: SocialWallLinkPreview;
    media?: MediaItem[];
    shareAttachments?: AttachmentInput[];
    pinned?: boolean;
  }) {
    this.store.dispatch(
      SocialActions.EditPost({ postId, content, tempId, linkPreview, media, shareAttachments, pinned }),
    );
  }
  cancelEditPost(tempId: string) {
    this.store.dispatch(SocialActions.CancelEditPost({ tempId }));
  }
  createPost({
    tempId,
    parentId,
    content,
    groupId,
    groupType,
    linkPreview,
    shareAttachments,
    dynamicGroupId,
    showToast,
    isMessageWithSocialFooter,
  }: {
    tempId: string;
    parentId?: number;
    content: any;
    groupId?: number;
    groupType?: SocialGroupType;
    linkPreview?: SocialWallLinkPreview;
    shareAttachments?: AttachmentInput[];
    dynamicGroupId?: string;
    showToast: boolean;
    isMessageWithSocialFooter?: boolean;
  }) {
    this.store.dispatch(
      SocialActions.CreatePost({
        tempId,
        parentId,
        content,
        groupType,
        groupId,
        linkPreview,
        shareAttachments,
        dynamicGroupId,
        showToast,
        isMessageWithSocialFooter,
      }),
    );
  }
  cancelCreatePost(tempId: string) {
    this.store.dispatch(SocialActions.CancelCreatePost({ tempId }));
  }
  startEditingPost(postId: number) {
    this.store.dispatch(SocialActions.StartEditingPost({ postId }));
  }
  loadMessageViews({ postId, messageId, search = '' }: { postId: number; messageId: number; search?: string }) {
    this.store.dispatch(SocialActions.LoadMessageViews({ postId, messageId, search }));
  }
  loadPostReactions(postId: number, reactionType?: ReactionType) {
    this.store.dispatch(SocialActions.LoadPostReactions({ postId, reactionType }));
  }
  loadPostViews({ postId, search }: { postId: number; search: string }) {
    this.store.dispatch(SocialActions.LoadPostViews({ postId, search }));
  }
  loadSocialGroups() {
    this.store.dispatch(SocialActions.LoadSocialGroups());
  }
  loadSocialGroupsNewPostCount() {
    this.store.dispatch(SocialActions.LoadSocialGroupsNewPostCount());
  }
  groupViewed(groupKey: string) {
    this.store.dispatch(SocialActions.GroupViewed({ groupKey }));
  }
  loadPostTranslation(postId: number) {
    this.store.dispatch(SocialActions.LoadPostTranslation({ postId }));
  }
  revertPostTranslation(postId: number) {
    this.store.dispatch(SocialActions.RevertPostTranslation({ postId }));
  }
  createGroup(members: number[]) {
    this.store.dispatch(SocialActions.CreateGroup({ members }));
  }
  leaveGroup(groupId: number) {
    this.store.dispatch(SocialActions.LeaveGroup({ groupId }));
  }
  editGroup({
    groupId,
    name,
    description,
    add,
    remove,
    image,
    navigate,
    mediaFileId,
  }: {
    groupId: number;
    name?: string;
    description?: string;
    add?: number[];
    remove?: number[];
    image?: Blob;
    mediaFileId?: number;
    navigate?: boolean;
  }) {
    this.store.dispatch(
      SocialActions.EditGroup({ groupId, name, description, add, remove, image, navigate, mediaFileId }),
    );
  }
  redirectToPost({ postId, groupId, groupName }: { postId: number; groupId: number; groupName: string }) {
    this.store.dispatch(SocialActions.RedirectToPost({ postId, groupId, groupName }));
  }
  redirectToPostComments({
    postId,
    groupId,
    groupName,
    commentId,
  }: {
    postId: number;
    groupId: number;
    groupName: string;
    commentId: number;
  }) {
    this.store.dispatch(SocialActions.RedirectToPostComments({ postId, groupId, groupName, commentId }));
  }
  loadShareMessagePreview({ messageId }: { messageId: number }) {
    this.store.dispatch(SocialActions.LoadShareMessagePreview({ messageId }));
  }
  checkGroupMembersAccess({ messageId, groupId }: { messageId: number; groupId: string }) {
    this.store.dispatch(SocialActions.CheckGroupMembersAccess({ messageId, groupId }));
  }
}
