type MapperFunction<T, U> = (value: T) => U | U[];

export function flatMapDeep<T, U>(array: T[], fn: MapperFunction<T, U>): U[] {
  const flattenDeep = (arr: any[]): any[] => {
    return arr.reduce((acc, val) => {
      return Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val);
    }, []);
  };

  const mappedArray = array.map(fn);
  return flattenDeep(mappedArray);
}
