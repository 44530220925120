import { FeatureFlagKey, FeatureFlags } from '@ao/data-models';
import { getRouteDataPage } from '@ao/utilities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VIEWER_CORE_FEATURE_KEY, ViewerCoreState } from '../reducers/viewer-core-store.state';
// @ts-strict
export const selectViewerCoreState = createFeatureSelector<ViewerCoreState>(VIEWER_CORE_FEATURE_KEY);

export const selectLoadingState = createSelector(selectViewerCoreState, (state) => state.loadingState);

// Core selectors
export const selectCurrentOrigin = createSelector(selectViewerCoreState, (state) => state.origin);
export const selectCurrentKeycode = createSelector(selectViewerCoreState, (state) => state.keycode);
export const selectPreviousKeycode = createSelector(selectViewerCoreState, (state) => state.previousKeycode);
export const selectCurrentPageId = createSelector(selectViewerCoreState, (state) => state.pageId);
export const selectCurrentQueryParams = createSelector(selectViewerCoreState, (state) => state.queryParams);
export const selectCurrentRouterExtraState = createSelector(selectViewerCoreState, (state) => state.extraState);
export const selectToast = createSelector(selectViewerCoreState, (state) => state.toast);

// Error
export const selectErrorState = createSelector(selectViewerCoreState, (state) => state.error);
export const selectErrorCode = createSelector(selectErrorState, (state) => state?.code);
export const selectErrorLanguage = createSelector(selectErrorState, (state) => state?.contentLanguage);
export const selectErrorSupport = createSelector(selectErrorState, (state) => state?.loginConfig?.config?.support);
export const selectErrorTheme = createSelector(selectErrorState, (state) => state?.loginConfig?.theme);

// Company Context
export const selectCompanyContext = createSelector(selectViewerCoreState, (state) => state.companyContext);
export const selectSupportSettings = createSelector(selectCompanyContext, (state) => state.supportSettings);
export const selectTermsOfUse = createSelector(selectCompanyContext, (state) => state.termsOfUse);
export const selectMenuExpandedDesktop = createSelector(selectCompanyContext, (state) => state.menuExpandedDesktop);
export const selects3UploadEnabled = createSelector(selectCompanyContext, (state) => state.s3UploadEnabled);
export const selectCustomFields = createSelector(selectCompanyContext, (state) => state.customFields);
export const selectSocialEnabled = createSelector(
  selectCompanyContext,
  (state) => state.socialEnabled && !!state.clientFeatureFlags?.social_wall,
);

export const selectChatEnabled = createSelector(
  selectCompanyContext,
  (state) => !!state.clientFeatureFlags.chat && !!state.chatEnabled,
);
export const selectSearchEnabled = createSelector(
  selectCompanyContext,
  (state) => !!state.clientFeatureFlags.better_search_engine && !!state.searchEnabled,
);
export const selectSocialName = createSelector(selectCompanyContext, (state) => state.socialName);

export const selectDirectoryIsEnabled = createSelector(
  selectCompanyContext,
  (state) => state.directoryEnabled && !!state.clientFeatureFlags.employee_directory,
);
export const selectDefaultLanguage = createSelector(selectCompanyContext, (state) => state.defaultLanguage);
export const selectAutoTranslationEnabled = createSelector(
  selectCompanyContext,
  (state) => state.autoTranslationEnabled,
);
export const selectTrackingAttributes = createSelector(selectCompanyContext, (state) => state.trackingAttributes);
export const selectTaskManagementEnabled = createSelector(selectCompanyContext, (companyContextState) => {
  return companyContextState.taskManagementEnabled;
});

// User Context
export const selectUserContext = createSelector(selectViewerCoreState, (state) => state.userContext);
export const selectUnreadCounts = createSelector(selectUserContext, (state) => state.unreadCounts);
export const selectContact = createSelector(selectUserContext, (state) => state.contact);
export const selectContactInitials = createSelector(selectContact, (contact) => {
  return (contact?.first_name?.[0] || '') + (contact?.last_name?.[0] || '');
});
export const selectContactId = createSelector(selectUserContext, (state) => state.contact?.id);
export const selectContactAuthCode = createSelector(selectUserContext, (state) => state.contactAuthCode);
export const selectProfileSettings = createSelector(selectUserContext, (state) => state.profileSettings);
export const selectPendingRecurringMsgs = createSelector(selectUserContext, (state) => state.pendingRecurringMsgs);
export const selectEmployees = createSelector(selectUserContext, (state) => state.employees);
export const selectRelationships = createSelector(selectUserContext, (state) => state.relationships);
export const selectMessageCountry = createSelector(selectUserContext, (state) => state.country);
export const selectUserLang = createSelector(
  selectUserContext,
  selectAutoTranslationEnabled,
  (state, autoTranslationsEnabled) => {
    return autoTranslationsEnabled && state.contact?.preferred_languages && state.contact.preferred_languages.length
      ? state.contact.preferred_languages[0]
      : null;
  },
);

// Status
export const selectContactModalStatus = createSelector(selectUserContext, (state) => state.contact?.modal_status);
export const selectContactStatus = createSelector(selectUserContext, (state) => state.contact?.contact_status);
export const selectContactOptOut = createSelector(selectUserContext, (state) => state.contact?.opt_out);
export const selectUserIsManager = createSelector(selectUserContext, (state) => state?.employees?.length > 0);
export const selectSystemAllowPush = createSelector(
  selectUserContext,
  (state) => state?.contact?.contactDeviceData?.system_allow_push,
);
export const selectContactDeviceTypeGroup = createSelector(
  selectUserContext,
  (state) => state?.contact?.contactDeviceData?.device_type_group,
);
export const selectLastSeenNotifications = createSelector(selectContactStatus, (state) => state?.lastSeenNotifications);

// Viewer settings
export const selectViewerSettings = createSelector(selectViewerCoreState, (state) => state?.viewerSettings);
export const selectViewerSettingsChatUserId = createSelector(selectViewerSettings, (state) => state?.chat.chatUserUid);
export const selectFeatureFlags = createSelector(
  selectViewerCoreState,
  (state) => state?.viewerSettings?.featureFlags || state?.companyContext?.clientFeatureFlags,
);
export const selectViewerSettingsHomepage = createSelector(selectViewerSettings, (state) => state?.homepage);
export const getFeatureFlagByKey = ({ key }: { key: FeatureFlagKey }) =>
  createSelector(selectFeatureFlags, (state: FeatureFlags) => {
    const value = state?.[key];
    return !!value;
  });
export const selectDefaultTheme = createSelector(selectViewerSettings, (state) => state.defaultTheme);
export const selectNotificationsEnabled = getFeatureFlagByKey({
  key: 'notification_center',
});
export const selectVoiceNotesEnabled = getFeatureFlagByKey({
  key: 'voice_notes',
});
export const selectPageHasHeader = createSelector(
  getRouteDataPage,
  (page) =>
    ![
      'PROFILE',
      'NOTIFICATIONS',
      'NOTIFICATION_SETTINGS',
      'NOTIFICATION_SETTINGS_PUBLISHED_CONTENT',
      'NOTIFICATION_SETTINGS_MENTIONS',
      'NOTIFICATION_SETTINGS_COMMENTS',
      'NOTIFICATION_SETTINGS_GROUPS',
      'NOTIFICATION_SETTINGS_CHAT',
      'NOTIFICATION_SETTINGS_TASKS',
      'SOCIAL',
      'SOCIAL_WALL',
      'DIRECTORY',
      'PROFILE',
      'CHAT',
      'TASK',
      'knowledge',
      'rating',
      'engagement',
      'points',
      'progress',
      'pulse',
      'CREATE_CONTACT',
      'SAVED_CONTENT',
      'SEARCH',
    ].includes(page),
);
export const selectPageHasNavBar = createSelector(
  getRouteDataPage,
  (page) => !['CHAT', 'SAVED_CONTENT'].includes(page),
);
export const selectNavbarHidden = createSelector(selectViewerCoreState, (state) => state.navbarHidden);

// NATIVE
export const selectStatusBarTheme = createSelector(selectViewerCoreState, (state) => state.statusBarTheme);
