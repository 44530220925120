<div class="ao-viewer-toast-snackbar">
  <div class="ao-viewer-toast-snackbar__container" [style.align-items]="'center'">
    <ao-generic-list-item
      [listItemType]="toast.listItemType"
      [iconName]="toast.iconName || null"
      [iconColor]="toast.iconColor || null"
      [initials]="toast.initials || ''"
      [image]="toast.image || null"
      [images]="toast.images || null"
    >
      <strong>{{ toast.title | translate }}</strong>
      <ng-container *ngIf="toast.text || toast.link">
        <ng-template aoListItemSubTitleTemplate>
          <span *ngIf="toast.text" [innerHTML]="toast.text | translate"></span>
          <span
            *ngIf="toast.link"
            class="ao-viewer-toast-snackbar__link"
            [innerHTML]="toast.link | translate"
            (click)="toastActionClick.emit()"
          ></span>
        </ng-template>
      </ng-container>
    </ao-generic-list-item>
    <div *ngIf="closeButton" class="ao-viewer-toast-snackbar__close-button">
      <ao-icon name="close" (click)="dismissed.emit(toast)"></ao-icon>
    </div>
  </div>
</div>
