import { Directive, HostBinding, Input } from '@angular/core';
import { ImageSource } from '@ao/shared-data-models';

@Directive({ selector: '[aoSrcset]' })
export class SourceSetDirective {
  @Input('aoSrcset') srcSet: ImageSource[];

  @HostBinding('srcset') get srcset(): string {
    return this.srcSet ? this.srcSet.map((image) => `${image.url} ${image.size.width}w`).join(', ') : '';
  }
}
