import { DataLoading } from '@ao/data-models';
import { RecipientTaskDetails, RecipientTaskListItem } from '@ao/shared-data-models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export type TaskEntity = RecipientTaskListItem;
export type TaskDetailsEntity = RecipientTaskDetails;

export const VIEWER_TASK_STATE_KEY = 'tasks';

export interface ViewerTaskState extends EntityState<TaskEntity> {
  isLoading: boolean;
  taskIdsUpdating?: number[];
  taskDetails?: { [key: number]: DataLoading<TaskDetailsEntity> };
  newTasksCount?: number;
  pagination?: {
    pageSize?: number;
    page?: number;
    totalCount?: number;
  };
}

export const adapter: EntityAdapter<TaskEntity> = createEntityAdapter<TaskEntity>();

export const initialState: ViewerTaskState = adapter.getInitialState({
  isLoading: false,
});
