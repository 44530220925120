import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ViewerLoginErrorCode, ViewerLoginProvider, ViewerLoginSettings } from '@ao/data-models';

@Component({
  selector: 'ao-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsoLoginComponent {
  @HostBinding('class.ao-sso-login') className = true;

  @Input() providers: ViewerLoginProvider[] = [] as ViewerLoginProvider[];
  @Input() loginSettings: ViewerLoginSettings = {} as ViewerLoginSettings;
  @Input() error: string;
  @Input() redirectUrl = '';
  @Input() nativeApp = false;
  @Input() isAdminPreview = false;

  @Output() troubleClick = new EventEmitter();
  @Output() findDifferentAppClick = new EventEmitter();

  codes = ViewerLoginErrorCode;

  loginUrl(provider: ViewerLoginProvider) {
    const url = provider.authorizationUrl;
    return this.redirectUrl ? `${url}&redirectUrl=${encodeURI(this.redirectUrl)}` : url;
  }

  isCustom(provider: ViewerLoginProvider) {
    return provider.provider === 'custom';
  }

  getIconPath(provider: ViewerLoginProvider) {
    return `custom-icons/${provider.provider}`;
  }
}
