import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ao-preview-info-top-header',
  templateUrl: './preview-info-top-header.component.html',
  styleUrls: ['./preview-info-top-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewInfoTopHeaderComponent {
  @Input() isSmallScreen: boolean;
  @Output() closePreviewTopHeader = new EventEmitter<void>();
}
