import { Pipe, PipeTransform } from '@angular/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { ReactionType } from '@ao/shared-data-models';

// localizes a number
@Pipe({
  name: 'reactionType',
  pure: false,
})
export class ReactionTypePipe implements PipeTransform {
  transform(value: ReactionType): string {
    switch (value) {
      case ReactionType.Laugh:
        return i18n('Laughing');
      case ReactionType.Like:
        return i18n('Like');
      case ReactionType.Love:
        return i18n('Love');
      case ReactionType.Surprised:
        return i18n('Surprised');
      case ReactionType.Ponder:
        return i18n('Interesting');
      default:
        return value;
    }
  }
}
