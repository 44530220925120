<div class="ao-sidebar-content-content__wrapper" [ngStyle]="_sidebarStyle">
  <div
    class="ao-sidebar-content__branding"
    [ngClass]="{ 'ao-sidebar-content__branding--click-target': topBarClickTarget }"
    *ngIf="sidebar?.show_branding"
    [ngStyle]="_headerStyle"
    (click)="topBarClicked.emit()"
  >
    <img
      class="ao-sidebar-content__branding-image"
      *ngIf="sidebar?.show_branding_bg_image && sidebar?.branding_bg_image_url"
      [aoSrc]="sidebar?.branding_bg_image_url"
      [aoSrcset]="sidebar?.branding_bg_images"
    />
    <div class="ao-font-body1 ao-sidebar-content__branding-title" *ngIf="!!sidebar?.branding_title">
      {{ sidebar?.branding_title }}
    </div>
  </div>
  <ul class="ao-sidebar-content__menu" *ngIf="sidebar?.menu_data || profileLink">
    <li *ngIf="profileLink" class="ao-sidebar-content__section ao-sidebar-content__section--profile">
      <button class="ao-sidebar-content__profile" (click)="profileClicked.emit(profileLink)">
        <ao-avatar
          class="ao-sidebar-content__profile-image"
          size="small"
          [initials]="contactInitials"
          [images]="contact?.avatarImages"
        >
        </ao-avatar>
        <span class="ao-sidebar-content__profile-text">
          <span class="ao-font-title4 ao-sidebar-content__profile-text__name">{{ contact?.name }}</span>
          <span class="ao-font-body2" translate>See your profile</span>
        </span>
      </button>
    </li>
    <li class="ao-sidebar-content__section" *ngFor="let section of sidebar?.menu_data; let sectionIndex = index">
      <div *ngIf="section.title" class="ao-font-small-bold ao-sidebar-content__section-title">{{ section.title }}</div>
      <ul class="ao-sidebar-content__items" *ngIf="section.items">
        <li class="ao-sidebar-content__item" *ngFor="let item of section.items; let itemIndex = index">
          <a class="ao-font-body1 ao-sidebar-content__link" (click)="menuItemClicked.emit(item)">
            <span class="ao-sidebar-content__link-icon">
              <ao-icon *ngIf="item.icon" [size]="18" [name]="item.icon"></ao-icon>
            </span>
            <ao-badge
              class="ao-sidebar-content__unread"
              *ngIf="item.show_unread && unreadCounts && unreadCounts[sectionIndex][itemIndex]"
              [count]="unreadCounts[sectionIndex][itemIndex]"
            ></ao-badge>
            <span class="ao-sidebar-content__link-text">{{ item.title }}</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>
