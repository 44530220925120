const image = ['image/*', '.gif', '.jpg', '.jpeg', '.png'];
const video = ['video/*', '.mp4', '.mov'];
const audio = ['audio/*'];
const pdf = ['application/pdf', '.pdf'];

const sheet = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xls',
  '.xlsx',
  '.csv',
];

const doc = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.doc',
  '.docx',
  '.dot',
];

const presentation = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.ppt',
  '.pptx',
];

const font = ['font/opentype', 'font/ttf', 'font/woff', 'font/eot', 'font/svg', 'font/otf'];

export const mimeTypes = {
  image,
  video,
  audio,
  pdf,
  doc,
  sheet,
  presentation,
  font,
};

export type MimeTypeCategories = keyof typeof mimeTypes;

export const acceptedMimeTypes: string[] = [
  ...image,
  ...video,
  ...audio,
  ...pdf,
  ...sheet,
  ...doc,
  ...presentation,
  ...font,
];

export const acceptedMediaMimeTypes: string[] = [...image, ...video];

export const acceptedFilesMimeTypes: string[] = [...audio, ...pdf, ...sheet, ...doc, ...presentation];

export function getMimeCategory(mimeType: string): MimeTypeCategories | undefined {
  let category = undefined;
  const [mimeDiscreteType] = mimeType.split('/');

  Object.entries(mimeTypes).some(([cat, values]) => {
    return values.some((value) => {
      const [discreteType, subType] = value.split('/');
      const match = subType === '*' ? discreteType === mimeDiscreteType : value === mimeType;
      if (match) {
        category = cat;
      }
      return match;
    });
  });

  return category;
}
