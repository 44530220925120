<!-- complete quiz -->
<ng-container *ngIf="data$ | async as data">
  <ao-notification *ngIf="data.showQuizHeader">
    <!-- Immediately available retake-->
    <ng-container *ngIf="data.showFinishQuiz || data.showRetakeQuiz; else countdown">
      <span *ngIf="data.showRetakeQuiz" class="ao-message-notifications__notification-header">
        {{
          data.remainAttempts === -1 || data.remainAttempts > 20
            ? ("You unfortunately didn't pass. Try again?" | translate)
            : ("You've failed this training. You have 1 more attempt(s)."
              | translate: { attempts: data.remainAttempts })
        }}
      </span>
    </ng-container>

    <!-- The  call to action buttons to be injected in the content-->
    <!-- ao-notification is picking up "button" component to put in a special container, it is however able to pick only immediate child elements that match or bear ngProjectAs, hence the duplicated if -->
    <ng-container *ngIf="data.showFinishQuiz || data.showRetakeQuiz" ngProjectAs="button">
      <button *ngIf="data.showFinishQuiz" (click)="onClickFinishQuiz()" translate>Finish</button>
      <button *ngIf="data.showRetakeQuiz && !retakeClicked" (click)="onClickRetakeQuiz()" translate>Retake</button>
      <ao-spinner *ngIf="data.showRetakeQuiz && retakeClicked" color="white"></ao-spinner>
    </ng-container>

    <!-- custom user-defined retake message -->
    <ng-container *ngIf="!data.isQuizCompleted">
      <div
        class="ao-message-notifications__notification-header"
        [innerHTML]="
          'You have answered #answeredQuestions# of #questionsTotal# questions.'
            | translate
              : {
                  answeredQuestions: '#answeredQuestions#',
                  questionsTotal: '#questionsTotal#'
                }
            | interpolateParams: data.context
        "
      ></div>
    </ng-container>

    <!-- Retake countdown active-->
    <ng-template #countdown>
      <ng-container *ngIf="data.hasQuizRetakeCountdown && data.isQuizCompleted">
        <div class="ao-message-notifications__notification-header">
          <span>
            {{
              data.remainAttempts === -1 || data.remainAttempts > 20
                ? ("You unfortunately didn't pass. Try again in:" | translate)
                : ("You've failed this training. You have 1 more attempt(s) and can retake the quiz in:"
                  | translate: { attempts: data.remainAttempts })
            }}
          </span>
          <b> {{ data.retakeTime | countdown }}</b>
        </div>
      </ng-container>
    </ng-template>
  </ao-notification>
</ng-container>

<!-- Survey done indicator -->
<ao-notification *ngIf="(showCompletedSurveyNotification$ | async) && !(data$ | async)?.showQuizHeader">
  <div translate>You have already completed this survey</div>
</ao-notification>

<!-- General notifications -->
<ao-notification *ngFor="let message of pendingRecurringMsgs$ | async">
  <div [ngSwitch]="message.type">
    <ng-container *ngSwitchCase="'rating'">
      <h3>
        {{ message.title && message.title !== 'rating' ? message.title : ('One-on-one' | translate) }}
      </h3>
      <span
        >{{ message.pending }}
        {{
          pendingTotalCount(message.pending) > 1 ? ('employees pending' | translate) : ('employee pending' | translate)
        }}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'pulse'">
      <h3>
        {{ message.title && message.title !== 'pulse' ? message.title : ('Pulse' | translate) }}
      </h3>
      <span translate [translateParams]="{ pending: message.totalUnanswered }"># survey(s) to answer</span>
    </ng-container>
  </div>
  <button (click)="onClickAnswerMessage(message.crosslink_keycode)" translate>Answer now</button>
</ao-notification>
